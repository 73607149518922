import React, { useEffect, useRef, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import SidebarToggleButton from "../components/SidebarToggleButton";
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { logoutApiUrl, transportLogoutDetails } from "../../config/keyClockConfig";
import { Dropdown as Select, Menu } from "../../PrimeReactComponents";
import { getTransportOnUser } from "../controllers/UserController"
function Header() {
  const userInfoSelector = useSelector(state => state.userInfo);
  const companyReciveId = userInfoSelector?.resultData?.company_id
  const toast = useRef(null);
  const [reciveOption, setReciveOption] = useState()
  const [selectedUser, setSelectedUser] = useState();
  const menuRight = useRef(null);
  const navigate = useNavigate();
//   let tokenData = ''
//   let tokenDataModifyed = ''
//   let userModiFyedId = ''
//   let transModiFyedId = ''
//   let customerModifiedId = ''

//   tokenData = localStorage.getItem('transportData');
//  console.log("thjis is log ....",tokenData);
//  if(tokenData === null || tokenData === '' ){
//   // window.location.href = '/transport'
//    navigate('/transport');
//    } 
//    tokenDataModifyed = tokenData !== "undefined" ? JSON.parse(tokenData) : {}
//    localStorage.setItem('transId', tokenDataModifyed?.company_id);

//    localStorage.setItem('userId1', tokenDataModifyed?.user_id);
 
//    localStorage.setItem('userId', tokenDataModifyed?.relation_id);
//    userModiFyedId = localStorage.getItem('userId1');
 
//    transModiFyedId = localStorage.getItem('transId');

//    customerModifiedId = localStorage.getItem('userId');


  const getTransport = async () => {
    const userId = userInfoSelector?.resultData?.user_id

    if (userId !== undefined && userId !== '') {
      const reciveOption1 = await getTransportOnUser({ user_id: userId, user_type: "1" })
      setReciveOption(reciveOption1.data)
    }

  }
  useEffect(() => {
    getTransport()
  }, [userInfoSelector])



  const getCompanyOption = () => {
    const option = [];
    reciveOption?.map((ele) => {
      option.push({ value: ele.company_id, label: ele.company_name })
    })
    return option;
  }

  const onUserChange = (e) => {
    const tokenData = localStorage.getItem('transportData');
    const tokenDataModifyed = tokenData !== "undefined" ? JSON.parse(tokenData) : {}
    const newUpdated = { ...tokenDataModifyed, company_id: e.value }
    localStorage.setItem('transportData', JSON.stringify(newUpdated));
    //localStorage.setItem('transportData', JSON.stringify(newUpdated));
    localStorage.setItem('transId', e.value);
    setSelectedUser(e.value)
    window.location.reload();
  //navigate('/transport/dashboard')
  }

  const logOut = async () => {
    let access_token = localStorage.getItem('keyCloakaccesstokenData');
    let refresh_token = localStorage.getItem('keyCloakrefreshData');
    let details = { ...transportLogoutDetails, "refresh_token": refresh_token };
   // localStorage.removeItem('transportData');
   //console.log("this rremove token first",localStorage.getItem('transportData'));
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    await fetch(logoutApiUrl, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    },
    () => { toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Logout Successfully !!", life: 3000, }); })
    localStorage.removeItem('transportData');
   setTimeout(() => {navigate('/transport')},500) 
  }

  const SelectedItem = (data) => {
    // console.log(data);
    // return <li><i className="fa-light"><input type="radio"></input></i> {data.label} </li>
    return <li><i className="fa-light"></i> {data.label} </li>
  }
  const items = [
    {
      label: 'User Profile',
      icon: 'fa-light fa-user',
      command: () => {
        navigate('/transport/profile');
      }
    },
    {
      label: 'Change Password',
      icon: 'fa-light fa-key',
      command: () => {
        navigate('/transport/profile/change-password');
      }
    },
    { separator: true},
    {
      label: 'Logout',
      icon: 'fa-light fa-right-from-bracket',
      command: () => {
        logOut()
      }
    },
  ];



  return (
    <>
    <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" style={{minWidth:170}} />
      <Toast ref={toast} />
      <div className="top-strip">
        <div className="container-fluid">
          <div className="d-flex gap-3 align-items-center">
            <SidebarToggleButton />
            <div className="dropdown coles-supermarket">
              <button type="button" className="w-100 d-block btn shadow-none fw600 p-0">
                Welcome to  {userInfoSelector?.companyData?.company_name?.charAt(0)?.toUpperCase() + userInfoSelector?.companyData?.company_name?.slice(1)} 


              </button>
            </div>

            <div className="d-flex align-items-center ms-auto gap-3">
              {reciveOption && reciveOption.length > 1 && (<> <label htmlFor=""  >Company</label>

                <Select
                  itemTemplate={SelectedItem}
                  options={getCompanyOption()}
                  name="user"
                  placeholder="Please select User"
                  value={companyReciveId}
                  onChange={(e) => { onUserChange(e) }}
                  className="w-80 px-3"

                />
                {/* <ErrorMessage name="country_id" component="span" className="from-error-span" /> */}
              </>)}
              <ul className="header-right-links d-flex align-items-center">
                <li className="ms-4 ">                
                 <button className="btn p-0" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup>
                    <span width="30" height="30" className="profile-icon rounded-circle">
                        {(userInfoSelector?.resultData?.userData?.first_name)?.charAt(0)} {(userInfoSelector?.resultData?.userData?.last_name)?.charAt(0)}
                      </span>
                      <span className="d-none d-lg-inline ms-4">
                        {userInfoSelector?.resultData?.userData?.first_name} {userInfoSelector?.resultData?.userData?.last_name}
                      </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
