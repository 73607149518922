import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { InputText, Dropdown as Select, Calendar, confirmDialog, InputTextarea, Toast } from "../../PrimeReactComponents";
import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import { getCountry, getState } from '../../services/commonController';
import { customerInitialState, customerValidateSchema } from '../Validations/CustomersValidation';
import { addData, uploadDocument,getUniquData } from '../controllers/customerController';

import Loader from "../../features/loader/Loader";
//import { getRandomNumber } from '../../utility/helper';



function Add() {
  const { transportUserInfo } = useSelector(state => state);
  const reciveLocalData = transportUserInfo.company_id
  const navigate = useNavigate();
  const toast = useRef(null);
  const [initialState, setInitialState] = useState(customerInitialState);
  const [loader, setLoader] = useState();
  const [country, setCountry] = useState();
  const [states, setStates] = useState();
  const pathname = window.location.pathname


  let customerArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Customers')?.map(val => {
    if (!customerArr.includes(val?.menuActionData?.action_name)) {
      customerArr.push(val?.menuActionData?.action_name)
    }
  })

  if (pathname.split('/')[3] === 'add') {
    if (!customerArr.includes('Add')) {
      navigate('/transport/404');
    }
  }


  let allFilesArr = []

  const getAllCountries = async () => {
    const results = await getCountry();
    setCountry(results.data)
  }

  const getAllStates = async () => {
    const results = await getState();
    setStates(results.data)
  }

  const getCountryOption = () => {
    const option = [];
    country?.map((ele) => {
      option.push({ value: ele.id, label: ele.country_name })
    })
    return option;
  }

  const getStateOption = (CoutryId) => {
    const option = [];
    states?.filter((ele) => {
      if (ele.country_id == CoutryId) {
        option.push({ value: ele.id, label: ele.state_name })
      }
    })
    return option;
  }

  useEffect(() => {
  
    getAllCountries()
    getAllStates()
    getUniqueCustomerNum()
   // setInitialState(prev => ({ ...prev, customer_number: getRandomNumber('CUST') }))
  }, [])

const getUniqueCustomerNum =async()=>{
  const params={
    type:"Customers",
  }
 const reciveData =await getUniquData(params);
 console.log("this is log....",reciveData);
 setInitialState(prev => ({ ...prev, customer_number: reciveData.data }))
}

  const customerSubmit = async (values, { resetForm }) => {
    // const all_values = { ...values, relationid: reciveLocalData, company_id: reciveLocalData, customer_document_details: { driver_doc: allFilesArr } };
    const all_values = { ...values, relationid: reciveLocalData, company_id: reciveLocalData };

    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoader(true)
        addData(all_values).then(({ status, type, message }) => {
          if (status) {
            setLoader(false)
            resetForm({ all_values: '' })
            toast.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

            setTimeout(() => {
              navigate('/transport/customers')
            }, 3000)

          } else {
            setLoader(false)
            toast.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          }
        })
      }
    });

  }

  const handleOnChange = async (event, index) => {

    if (event.target.files[0].name !== null) {
      let image_name = event.target.files[0]

      if (image_name && (image_name.type === 'image/jpg' || image_name.type === 'image/jpeg' || image_name.type === 'image/png' || image_name.type === 'application/pdf')) {
        if (image_name !== undefined) {
          const formData = new FormData();
          formData.append(`driver_doc`, image_name)

          const headers = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };

          const res = await uploadDocument(formData, headers)
          allFilesArr.push(res.data)

        }
      } else {
        toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'Please select a valid JPG, JPEG, PNG, PDF file Only.', life: 3000, });
      }

    }

  };


  //validationSchema={customerValidateSchema}

  return (

    <>
      <Toast ref={toast} position="top-center" />
      <Formik initialValues={initialState} enableReinitialize validationSchema={customerValidateSchema} onSubmit={customerSubmit}>
        {
          ({ values, handleChange, setFieldValue }) => (
            <div className="page-wrapper-without-aisde">
              <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
                <div className="container d-flex flex-column h-100 ">
                  <Form className="d-flex flex-column overflow-auto" encType="multipart/form-data">

                    <div className="container d-flex flex-column h-100 ">
                      <div className="px-4 mt-3">
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                            <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Add New Customer</h2>
                          </div>
                          <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                            <Link to="../" className="btn-gradient btn fz14 me-3">
                              <i className="fa-regular fa-times"></i> Cancel
                            </Link>
                            <button type="submit" className="bg-green text-white btn fz14 ">
                              <i className="fa-regular fa-save"></i> Save
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="overflow-auto flex-grow-1 mt-5 px-4">
                        <div className="card p-4">
                          <div className="card-body">
                            <div className="row gx-5">
                              <input type="text" className="form-control px-3" hidden />
                              <div className="col-12 mb-3 justify-content-between d-flex">
                                <h4 className=" fw600 fz16 text-dark">Customer</h4>
                              </div>
                              <div className="col-sm-6 mb-4">
                                <label htmlFor="" className="mb-2 d-block">Customer Number  <span className="text-danger">*</span></label>
                                <Field name="customer_number" render={({ field }) => (
                                  <>
                                    <InputText className="form-control px-3" {...field} readOnly disabled />
                                    <ErrorMessage name="customer_number" component="span" className="from-error-span" />
                                  </>
                                )} />

                              </div>
                              <div className="col-sm-6 mb-4">
                                <label htmlFor="" className="mb-2">Company Name  <span className="text-danger">*</span></label>
                                <Field name="customer_company_name" render={({ field }) => (
                                  <>
                                    <InputText className="form-control px-3" {...field} maxLength={30} />
                                    <ErrorMessage name="customer_company_name" component="span" className="from-error-span" />
                                  </>
                                )} />
                              </div>
                              <div className="col-sm-6 mb-4">
                                <label htmlFor="" className="mb-2">First Name  <span className="text-danger">*</span></label>
                                <Field name="first_name" render={({ field }) => (
                                  <>
                                    <InputText className="form-control px-3" {...field} maxLength={50} />
                                    <ErrorMessage name="first_name" component="span" className="from-error-span" />
                                  </>
                                )} />
                              </div>
                              <div className="col-sm-6 mb-4">
                                <label htmlFor="" className="mb-2">Last Name  <span className="text-danger">*</span></label>
                                <Field name="last_name" render={({ field }) => (
                                  <>
                                    <InputText className="form-control px-3" {...field} maxLength={50} />
                                    <ErrorMessage name="last_name" component="span" className="from-error-span" />
                                  </>
                                )} />
                              </div>
                              <div className="col-sm-6 mb-4">
                                <label htmlFor="" className="mb-2">Email Address  <span className="text-danger">*</span></label>
                                <Field name="email_id" render={({ field }) => (
                                  <>
                                    <InputText className="form-control px-3" {...field} keyfilter="email" maxLength="40" />
                                    <ErrorMessage name="email_id" component="span" className="from-error-span" />
                                  </>
                                )} />
                              </div>
                              <div className="col-sm-6 mb-4">
                                <label htmlFor="" className="mb-2">Phone  <span className="text-danger">*</span></label>
                                <Field name="phone" render={({ field }) => (
                                  <>
                                    <InputText className="form-control px-3"  {...field} maxLength="10" />
                                    <ErrorMessage name="phone" component="span" className="from-error-span" />
                                  </>
                                )} />
                              </div>
                              <div className="col-sm-6 mb-4">
                                <label htmlFor="" className="mb-2">Mobile  <span className="text-danger">*</span></label>
                                <Field name="mobile" render={({ field }) => (
                                  <>
                                    <InputText className="form-control px-3"  {...field} maxLength="10" />
                                    <ErrorMessage name="mobile" component="span" className="from-error-span" />
                                  </>
                                )} />
                              </div>
                              <div className="col-12 mt-4 mb-3">
                                <h4 className=" fw600 fz16 text-dark"> Billing Address Details </h4>
                                <div className="overflow-auto mt-3 ">
                                  <table className="table  multiple-feilds-table" style={{ minWidth: "1200px" }}>
                                    <thead>
                                      <tr>
                                        <th className="fw500 fz14" width="30px">
                                          #
                                        </th>
                                        <th className="fw500 fz14" width="200px">
                                          Customer Name
                                        </th>
                                        <th className="fw500 fz14">Address   </th>
                                        <th className="fw500 fz14" width="150px">
                                          Suburb
                                        </th>
                                        <th className="fw500 fz14" width="150px">
                                          Zip Code
                                        </th>
                                        <th className="fw500 fz14" width="180px">
                                          Country
                                        </th>
                                        <th className="fw500 fz14" width="150px">
                                          State
                                        </th>
                                        <th className="fw500 fz14" width="30px"></th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                      <FieldArray name="customer_billing_address_details" render={({ push, remove }) => (
                                        values.customer_billing_address_details && values.customer_billing_address_details.map((val, idx) => (
                                          <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>
                                              <Field name={`customer_billing_address_details[${idx}].customer_name`} render={({ field }) => (
                                                <>
                                                  <InputText className="form-control px-3" {...field} />
                                                  <ErrorMessage name={`customer_billing_address_details[${idx}].customer_name`} component="span" className="from-error-span" />
                                                </>
                                              )} />

                                            </td>
                                            <td>
                                              <Field name={`customer_billing_address_details[${idx}].address`} render={({ field }) => (
                                                <>
                                                  <InputText className="form-control px-3" {...field} />
                                                  <ErrorMessage name={`customer_billing_address_details[${idx}].address`} component="span" className="from-error-span" />
                                                </>
                                              )} />

                                            </td>
                                            <td>
                                              <Field name={`customer_billing_address_details[${idx}].suburb`} render={({ field }) => (
                                                <>
                                                  <InputText className="form-control px-3" {...field} />
                                                  <ErrorMessage name={`customer_billing_address_details[${idx}].suburb`} component="span" className="from-error-span" />
                                                </>
                                              )} />

                                            </td>
                                            <td>
                                              <Field name={`customer_billing_address_details[${idx}].zip_code`} render={({ field }) => (
                                                <>
                                                  <InputText className="form-control px-3" {...field} keyfilter="int" maxLength="4" />
                                                  <ErrorMessage name={`customer_billing_address_details[${idx}].zip_code`} component="span" className="from-error-span" />
                                                </>
                                              )} />

                                            </td>
                                            <td>
                                              <Field name={`customer_billing_address_details[${idx}].country_id`} render={({ field }) => (
                                                <>
                                                  <Select className="w-100 px-3"  {...field} options={getCountryOption()} placeholder="Please select a country" />
                                                  <ErrorMessage name={`customer_billing_address_details[${idx}].country_id`} component="span" className="from-error-span" />
                                                </>
                                              )} />

                                            </td>

                                            <td>
                                              <Field name={`customer_billing_address_details[${idx}].state_id`} render={({ field }) => (
                                                <>
                                                  <Select className="w-100 px-3"  {...field} options={getStateOption(val.country_id)} placeholder="Please select a state" />
                                                  <ErrorMessage name={`customer_billing_address_details[${idx}].state_id`} component="span" className="from-error-span" />
                                                </>
                                              )} />

                                            </td>


                                            <td>
                                              {values.customer_billing_address_details.length - 1 === idx ? (
                                                <>
                                                  <div className="d-flex gap-2">

                                                    {values.customer_billing_address_details.length > 1 &&
                                                      <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0"
                                                        onClick={() => remove(idx)}>
                                                        <i className="fa-regular fa-minus-circle "></i>
                                                      </button>}
                                                    <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0"
                                                      onClick={() => push(initialState.customer_billing_address_details[0])} >
                                                      <i className="fa-regular fa-plus-circle "></i>
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" onClick={() => remove(idx)}>
                                                  <i className="fa-regular fa-minus-circle "></i>
                                                </button>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                        ))} />

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                             {/*  <div className="col-12 mt-4 mb-3">
                                <h4 className=" fw600 fz16 text-dark"> Documents</h4>
                                <div className="overflow-auto mt-3 ">
                                  <table className="table  multiple-feilds-table">
                                    <thead>
                                      <tr>
                                        <th className="fw500 fz14" width="30px">
                                          #
                                        </th>
                                        <th className="fw500 fz14">Upload  (jpg,png,jpeg,pdf)</th>
                                        <th className="fw500 fz14" width="30px"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="customer_document_details" render={({ push, remove }) => (

                                        values?.customer_document_details && values.customer_document_details.length > 0 && values?.customer_document_details.map((val, idx) => {
                                          return (
                                            <tr key={idx}>
                                              <td>{idx + 1}</td>
                                              <td>
                                                <Field name={`customer_document_details[${idx}].driver_doc`} render={({ field }) => (
                                                  <>
                                                    <InputText type="file" className="form-control px-3" onChange={(e) => { handleOnChange(e, idx); setFieldValue(`customer_document_details[${idx}].driver_doc`, e.target.files[0].name) }} />
                                                    <ErrorMessage name={`customer_document_details[${idx}].driver_doc`} component="span" className="from-error-span" />
                                                  </>

                                                )} />
                                              </td>
                                              <td>
                                                {values.customer_document_details.length - 1 === idx ? (
                                                  <>
                                                    <div className="d-flex gap-2">

                                                      {values.customer_document_details.length > 1 &&
                                                        <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0"
                                                          onClick={() => {
                                                            remove(idx)
                                                            allFilesArr.splice(idx, 1)
                                                          }
                                                          }>
                                                          <i className="fa-regular fa-minus-circle "></i>
                                                        </button>}
                                                      <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0"
                                                        onClick={() => push(initialState.customer_document_details[0])} >
                                                        <i className="fa-regular fa-plus-circle "></i>
                                                      </button>

                                                    </div>
                                                  </>
                                                ) : (
                                                  <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" onClick={() => {
                                                    remove(idx)
                                                    allFilesArr.splice(idx, 1)
                                                  }
                                                  }>
                                                    <i className="fa-regular fa-minus-circle "></i>
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      )} />
                                    </tbody>
                                  </table>
                                </div>
                              </div> */}
                              <div className="col-12 mt-4 mb-3">
                                <h4 className=" fw600 fz16 text-dark"> Multiple Notes</h4>
                                <div className="overflow-auto mt-3 ">
                                  <table className="table  multiple-feilds-table">
                                    <thead>
                                      <tr>
                                        <th className="fw500 fz14" width="30px">
                                          #
                                        </th>
                                        <th className="fw500 fz14">Notes  </th>
                                        <th className="fw500 fz14" width="30px"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="customer_notes_details" render={({ push, remove }) => (
                                        values.customer_notes_details && values.customer_notes_details.map((val, idx) => (
                                          <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>

                                              <Field name={`customer_notes_details[${idx}].notes_content`} render={({ field }) => (
                                                <>
                                                  <InputTextarea className="form-control px-3"  {...field} />
                                                  <ErrorMessage name={`customer_notes_details[${idx}].notes_content`} component="span" className="from-error-span" />
                                                </>
                                              )} />
                                            </td>

                                            <td>
                                              {values.customer_notes_details.length - 1 === idx ? (<>
                                                <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0"
                                                  onClick={() => push(initialState.customer_notes_details[0])} >
                                                  <i className="fa-regular fa-plus-circle "></i>
                                                </button>
                                                {values.customer_notes_details.length > 1 &&
                                                  <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" onClick={() => remove(idx)}>
                                                    <i className="fa-regular fa-minus-circle "></i>
                                                  </button>
                                                }
                                              </>
                                              ) : (
                                                <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" onClick={() => remove(idx)}>
                                                  <i className="fa-regular fa-minus-circle "></i>
                                                </button>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                        )
                                      )} />
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )
        }
      </Formik>

    </>
  );
}

export default Add;
