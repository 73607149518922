import React, { useRef, useState, useEffect } from "react";
import dateFormat from "dateformat";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { listData, removeData, title, activeInactiveBulk, removeDataBulk } from "../../transport-portal/controllers/DriverControllers/controllers";
import { Dropdown, Button } from "../../BootstrapComponents";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import FilterOffset from "./components/OffsetRight";
import dataTableConfig from "../../config/dataTableOptions";
import Loader from "../../features/loader/Loader";
import { useResizeListener } from 'primereact/hooks';
function List() {
  const { transportUserInfo } = useSelector(state => state);
  const reciveLocalData = transportUserInfo.company_id;
  const toast = useRef(null);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState("0px");
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState();


  let driversListArr = []
  let driversArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Drivers')?.map(val => {
    if (!driversListArr.includes(val?.menuData?.menu_name)) {
      driversListArr.push(val?.menuData?.menu_name)
    }

    if (!driversArr.includes(val?.menuActionData?.action_name)) {
      driversArr.push(val?.menuActionData?.action_name)
    }
  })

  if (driversListArr.length == 0) {
    navigate('/transport/404');
  }

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
});

useEffect(() => {
  bindWindowResizeListener();

  return () => {
      unbindWindowResizeListener();
  };
}, [bindWindowResizeListener, unbindWindowResizeListener]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    globalFilterValue: "",
    status: "",
    last_name: "",
    first_name: "",
    company_id: reciveLocalData,
    filters: {
      'company_code': { value: '', matchMode: 'contains' },
      'company_name': { value: '', matchMode: 'contains' },
      'company': { value: '', matchMode: 'contains' },
      'representative.name': { value: '', matchMode: 'contains' },
    }
  });
  // Fetching  List Data

  let loadLazyTimeout = null;
  useEffect(() => {
    loadLazyData();
  }, [lazyParams])
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: (lazyParams) }).then(data => {

        if (data?.data !== undefined) {
          setTotalRecords(data.data.data.count);
          setCustomers(data.data.data.rows);

        }
        setTableHeight(document?.querySelector(".table-wrapper")?.scrollHeight - 100 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      //page:event.pageCount,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
      sortOrder: lazyParams.sortOrder,
    }, []);
  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
      listData({ lazyEvent: (lazyParams) }).then(data => {
        // if (data?.data !== undefined) {
        setTotalRecords(data?.count);
        setCustomers(data?.rows);

      })

    } else if (value.length === 0) {
      //loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });
    }
  }

  const advanceSearch = (value) => {

    if (value) {
      setLazyParams({ ...lazyParams, first_name: value.fname, last_name: value.lname, status: value.status });
      setoffsetFilter(true);
      listData({ lazyEvent: (lazyParams) }).then(data => {
        // if (data?.data !== undefined) {
        setTotalRecords(data?.count);
        setCustomers(data?.rows);

      })

    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }



  // Delete Entry Function Start Here =====
  const deleteEntry = (id, driverName) => {
    setLoader(true);
    let driverArr = []
    let driverNameArr = [driverName]
    driverArr.push(id)

    removeDataBulk(driverArr, reciveLocalData, driverNameArr).then(({ type, message }) => {
      toast?.current?.show({ severity: type, summary: 'Info Message', detail:driverArr.length > 0 ? message:"No driver selected to delete", life: 3000, });
      loadLazyData();
      setLoader(false)
      setTimeout(() => {
        navigate('/transport/drivers')
      }, 3000)
    })
  }
  const deleteConfirm = (driver_id, driverName) => {
    setIsListUpdated(false)
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(driver_id, driverName)
      }
    });

  }
  // Delete Entry Function end Here =====

  // Active Inactive Entry Function Start Here =====
  const activeInactiveStatus = (status, id) => {
    let driverArr = []
    driverArr.push(id)

    let modifyStatus = (status === '1') ? '0' : '1'

    setLoader(true)
    activeInactiveBulk(modifyStatus, driverArr, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {

    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data?.status === '1' ? 'Inactivate' : 'Activate'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data?.status, data?.drivers?.driver_id);
      }
    });
  }
  // Active Inactive Entry Function end Here =====


  // Table body Html Entry Function Start Here =====
  const actionBodyTemplate = (rowData) => {

    return (<div className="d-flex gap-2">
      <Link to={`./edit/${rowData?.drivers?.driver_id}`} className={`btn fz16 p-0 edit-btn ${driversArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`}>
        <i className="fa-light fa-edit"></i>
      </Link>
    <Button className={`deleteRow btn fz16 p-0 delete-btn ${driversArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} variant="" onClick={() => deleteConfirm(`${rowData?.drivers?.driver_id}`, `${rowData?.drivers?.first_name + " " + rowData?.drivers?.last_name}`)}>
        <i className="fa-light fa-trash"></i>
      </Button>

    </div>);
  };
  const statusBodyTemplate = (rowData) => {
    return <button onClick={(e) => activeInactiveHandler(e, rowData)} icon="pi pi-check" label="Confirm" className={`fw600 border-0 badge py-2 fs-5 badge-bg-${rowData?.status === '1' ? "green" : "red"}`}>{rowData?.status === '1' ? "Active" : "Inactive"} <i className="fa-light fa-angle-down"></i></button>;
  };
  const userBodyTemplate = (rowData) => {
    //return <RequestEmail com_id={rowData.driver_id} com_name={rowData.company_name} />;
  };
  const addressBodyTemplate = (rowData) => {

    let customerSuburbName = (rowData?.drivers?.suburb !== '' && rowData?.drivers?.suburb !== null && rowData?.drivers?.suburb !== undefined)? `${rowData?.drivers?.suburb} ,  ` :''

    let customerStateName = (rowData?.drivers?.companystates?.state_name !== '' && rowData?.drivers?.companystates?.state_name !== null && rowData?.drivers?.companystates?.state_name !== undefined)? `${rowData?.drivers?.companystates?.state_name}  ,  ` :''
    let customerCountryName = (rowData?.drivers?.companycountries?.country_name !== '' && rowData?.drivers?.companycountries?.country_name !== null && rowData?.drivers?.companycountries?.country_name !== undefined)? `${rowData?.drivers?.companycountries?.country_name}  ,  ` :''
    let customerZipCodeName = (rowData?.drivers?.zip_code !== '' && rowData?.drivers?.zip_code !== null && rowData?.drivers?.zip_code !== undefined)? `${rowData?.drivers?.zip_code}   ` :''

    return <span>{rowData?.address}  {customerSuburbName}  {customerStateName}   {customerCountryName} {customerZipCodeName}</span>
  }
  const driverIdBodyTemplate = (rowData) => {
    return <span>{rowData?.drivers?.driver_number} </span>
  }
  const driverNameBodyTemplate = (rowData) => {
    return (<span>{rowData?.drivers?.first_name} {rowData?.drivers?.last_name}<br></br>P:{rowData?.drivers?.mobile}     E:{rowData?.drivers?.user_name}</span>)
  }

  const dateBodyTemplate = (rowData) => {
    if (rowData?.createdAt) {
      const now = new Date(rowData?.createdAt);
      return dateFormat(now, "dd mmmm, yyyy");
    }
  }
  const updated_atBodyTemplate = (rowData) => {
    if (rowData?.updatedAt) {
      const now = new Date(rowData?.created_by);
      return dateFormat(now, "dd mmmm, yyyy");
    }
    return '';

  }

  const driverCreatedBodyTemplate = (rowData) => {
    return <span>{rowData?.userDatas?.first_name} {rowData?.userDatas?.last_name} </span>
  }
  // Table body Html Entry Function end Here =====


  //Bulk
  const activeInactiveStatusBulk = (status, id) => {
    setLoader(true)
    activeInactiveBulk(status, id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail:id.length >0? message:"No Driver selected", life: 3000, });
    })
  }

  const bulkActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item?.drivers?.driver_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }


  const bulkDeActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item?.drivers?.driver_id);
    })

    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Inactivate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("0", selectedId);
      }
    });
    setSelectedCustomers();
  }

  const DeleteBulk = (id, selectedDriverName) => {
    setLoader(true)
    removeDataBulk(id, reciveLocalData, selectedDriverName).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      toast?.current?.show({ severity: type, summary: 'Info Message', detail:id.length >0 ? message:"No Driver selected to delete", life: 3000, width: 100 });

      loadLazyData();
      setLoader(false)
      setTimeout(() => {
        navigate('/transport/drivers')
      }, 3000)
    })
    // })
  }

  const bulkDelete = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item?.drivers?.driver_id);
    })
    let selectedDriverName = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedDriverName.push(item?.drivers?.first_name + " " + item?.drivers?.last_name);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId, selectedDriverName);
      }
    });
  }


  let istyping = false
  const throttleFunction = (func, delay) => {

    clearTimeout(istyping);
    istyping = setTimeout(() => {
      func();
    }, delay);
  }



  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-building"></i> &nbsp; {title}
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to="./add" className={`bg-green text-white btn fz14 me-3 ${driversArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}>
                  <i className="fa-light fa-plus-circle"></i>&nbsp; 
                  Add New
                </Link>
                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => throttleFunction(() => onGlobalFilterChange1(event), 1000)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <ul>
                      <li>
                        <Button className={`dropdown-item ${driversArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} to="#" onClick={() => bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={() => bulkActivate()} >
                          <i className="fa-light me-2 fa-check"></i>
                          Active
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={() => bulkDeActivate()}>
                          <i className="fa-light me-2 fa-ban"></i>
                          Inactive
                        </Button>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={customers} selectionMode="checkbox" lazy dataKey="user_relation_id"
              scrollable=" true" responsiveLayout="scroll"
              {...{ scrollHeight: tableHeight }}
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)} {...dataTableConfig}
            >
              <Column headerStyle={{ width: "3em" }} selectionMode="multiple"></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "9rem" }}></Column>
              <Column field="driver_number" header="Driver Id" body={driverIdBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="first_name" header="Driver Details" sortable body={driverNameBodyTemplate} style={{ flexGrow: 1, flexBasis: "400px" }}></Column>
              <Column field="address" header="Address" body={addressBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "230px" }}></Column>
              <Column field="createdAt" header="Created On" sortable dataType="date" style={{ flexGrow: 1, flexBasis: "120px" }} body={dateBodyTemplate} />
              <Column field="created_by" body={driverCreatedBodyTemplate} header="Created By" sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "60px" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
