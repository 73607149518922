import {createAsyncThunk, createSlice} from  '@reduxjs/toolkit';


const initialState = {
    entries: [],
    loader: false,
    status:'Idle'
}

export const fetchData = createAsyncThunk('company/list', async () => {
   try {
        const responseData = await fetch('https://jsonplaceholder.typicode.com/posts');
        const res = await responseData.json();
        return res;
   } catch (error) {
        return error;
   }
})

const slice = createSlice({
    name: 'CompanyList',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchData.pending, (state)=> {
            state.loader = true;
            state.status = 'pending'
        })

        builder.addCase(fetchData.rejected, (state)=> {
            state.loader = false;
            state.status = 'rejected'
        })

        builder.addCase(fetchData.fulfilled, (state, {payload})=> {
            state.loader = false;
            state.status = 'success';
            state.entries = payload;
        })
    }
})

export default slice.reducer;