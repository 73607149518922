import * as yup from 'yup';


const docManagerInitialState = {
    document_type: '',
    document_number: '',
    document_file: ''
};

const docManagerValidateSchema = yup.object().shape({
    document_type: yup.string().required('This Field is required'),
    document_number: yup.string().required('This Field is required'),
    document_file: yup.string().required('This Field is required')
})

const conFilterInitialState = {
    document_type: '',
    document_number: '',
    document_file: '',
}

const conFilterSchema = yup.object().shape({
    //document_type: yup.string().min(2, 'This Field must be at least 2 characters').max(30, 'This Field must be at most 30 characters').required('This Field is required'),
    // document_number: yup.string().required('This Field is required')
})


export { docManagerValidateSchema, docManagerInitialState, conFilterInitialState, conFilterSchema }


