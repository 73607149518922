import React, { useRef, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { InputText, Dropdown as Select, Calendar, ConfirmDialog, confirmDialog, Toast } from "../../PrimeReactComponents";
import { Button, Modal } from "../../BootstrapComponents";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment'
import { AutoComplete } from "primereact/autocomplete";
import { connsignmentValidateSchema, consignmentInitialState } from '../Validations/ConsignmentEditValidation';
import { getCountry, getState, getAllCustomer, getAllDriver, getAllCustomerAddress, getAllTruckType, getSingleCustomerAddressBook, getCustomerAddress, getCustomerAddressBook, getAllCustomerCompany, getCustomerCompanyAddress } from '../../services/commonController';

import { singleData, updateData, uploadDocument, searchManifestNumber, uploadConsignmentDocument } from '../controllers/ConsignmentController';
import Loader from "../../features/loader/Loader";
import { REACT_APP_BASE_BACKEND_URL } from '../../config'



let BASE_BACKEND_URL = REACT_APP_BASE_BACKEND_URL

function Edit() {
  const navigate = useNavigate();
  const { customerUserInfo } = useSelector(state => state);
  const reciveLocalData = customerUserInfo.company_id
  const reciveLocalUserIdData = customerUserInfo.relation_id
  const { id } = useParams();
  const toast = useRef(null);
  const [initialState, setInitialState] = useState(consignmentInitialState);
  const [loader, setLoader] = useState(true);
  const [job_det, setjob_dett] = useState([]);
  const [filename, setFilename] = useState();
  const [noItems, setnoItems] = useState();
  const [pallets, setPallets] = useState();
  const [spaces, setSpaces] = useState();
  const [weight, setWeight] = useState();
  const [country, setCountry] = useState();
  const [states, setStates] = useState();
  const [deliveryCountry, setDeliveryCountry] = useState();
  const [deliveryStates, setDeliveryStates] = useState();
  const [allCustomer, setAllCustomer] = useState();
  const [allTruck, setAllTruck] = useState();
  const [allDriver, setAllDriver] = useState();
  const [allCustomerAddress, setAllCustomerAddress] = useState();
  const [allAddressData, setAllAddressData] = useState([]);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [allDeliveryAddressData, setAllDeliveryAddressData] = useState([]);
  const [customerDeliveryAddress, setCustomerDeliveryAddress] = useState([]);
  const [pickupMinDate, setPickupMinDate] = useState();
  const [pickupMaxDate, setPickupMaxDate] = useState();
  const [deliveryMinDate, setDeliveryMinDate] = useState();
  const [bookMinDate, setBookMinDate] = useState();
  const [bookMaxDate, setBookMaxDate] = useState();
  const [manifestValue, setManifestValue] = useState('');
  const [allManifestData, setAllManifestData] = useState([]);
  const [disableManifest, setDisableManifest] = useState(false);

  const [customerCompany, setCustomerCompanyData] = useState([]);
  const [customerCompanyAddress, setCustomerCompanyAddressData] = useState([]);

  const [pickUpData, setpickUpData] = useState([]);
  const [pickUpDeliveryData, setpickUpDeliveryData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const imageArr = []
  let defaultStatusArr = []
  const pathname = window.location.pathname

  const company_id = reciveLocalData


  //Role & Permission Set 
  let customerConsignmentArr = []
  let customerPermission = JSON.parse(localStorage.getItem('rolePermissionCustomerInfo'))

  customerPermission.length > 0 && customerPermission.filter(e => e.menuData?.menu_name === 'Consignments')?.map(val => {
    if (!customerConsignmentArr.includes(val?.menuActionData?.action_name)) {
      customerConsignmentArr.push(val?.menuActionData?.action_name)
    }
  })


  if (pathname.split('/')[3] === 'edit') {
    if (!customerConsignmentArr.includes('Edit')) {
      navigate('/customer/404');
    }
  }


  const allCustomerCompanyList = async (company_id) => {
    const results = await getAllCustomerCompany(company_id);

    const option = [];
    results.data?.filter(id => id.customer_id == reciveLocalUserIdData).map((ele) => {
      option.push({ value: ele.customer_id, label: ele.customer_company_name })
    })
    setCustomerCompanyData(option)
    getCustomerCompanyAddressHandler(reciveLocalUserIdData);

  }

  const compId = reciveLocalData;


  const getCustomerAddressData = async (customerID, compId) => {
    const { data } = await getCustomerAddress(customerID, compId);
    const newAddressData = []

    data.forEach((ele, i) => {
      newAddressData.push({
        value: ele.address,
        label: ele.address
      })
    })

    setCustomerAddress(newAddressData)
    setAllAddressData(data)
  }

  const getCustomerAddressPickupEditData = async (company_id, customerID) => {

    const customerAddressBookData = await getCustomerAddressBook(company_id, customerID);

    const pickupArr = []
    if (customerAddressBookData) {

      customerAddressBookData?.data.forEach((ele, i) => {

        pickupArr.push({
          value: ele.customer_address_id,
          label: `${ele.customer_company_name}`
        })

      })

      setpickUpData(pickupArr)

    }

  }


  const getCustomerAddressDeliveryEditData = async (company_id, customerID) => {

    const customerAddressBookData = await getCustomerAddressBook(company_id, customerID);


    const pickupArr = []
    if (customerAddressBookData) {

      customerAddressBookData?.data.forEach((ele, i) => {

        pickupArr.push({
          value: ele.customer_address_id,
          label: `${ele.customer_company_name}`
        })

      })

      setpickUpDeliveryData(pickupArr)
    }


  }


  const getCustomerDeliveryAddressData = async (customerID) => {
    const { data } = await getCustomerAddress(customerID, compId);
    const newAddressData = []

    data.forEach((ele, i) => {
      newAddressData.push({
        value: ele.address,
        label: ele.address
      })
    })

    setCustomerDeliveryAddress(newAddressData)
    setAllDeliveryAddressData(data)
  }

  const getCustomerCompanyEditData = async (customerID) => {
    const { data } = await getCustomerCompanyAddress(customerID);
    const newAddressData = []

    data[0]?.customer_billing_address_details.forEach((ele, i) => {
      let address_key = `${ele.customer_billing_id}_${ele.address}`
      newAddressData.push({
        value: address_key,
        label: `${ele.customer_name} - ${ele.address}`
      })
    })

    setCustomerCompanyAddressData(newAddressData)

  }


  const getData = () => {
    const response = singleData(id);
    response.then(({ status, message, type, error, data }) => {
      let consignment = data?.data
      if (consignment) {
        let truck_details_data = consignment?.truck_details?.[0]
        let booked_date = new Date(moment(consignment?.booked_date?.split("T")[0]).format('MM/DD/YYYY'))
        let pickup_date = new Date(moment(consignment?.pickup_date?.split("T")[0]).format('MM/DD/YYYY'))
        let delivery_date = new Date(moment(consignment?.delivery_date?.split("T")[0]).format('MM/DD/YYYY'))
        const isDisableManifest = (consignment.manifest_number) ? true : false

        getBookDate(booked_date)
        getPickupDate(pickup_date)
        getDeliveryDate(delivery_date)
        setManifestValue(consignment?.manifest_number)
        setDisableManifest(isDisableManifest)
        allCustomerCompanyList(company_id)

        // getCustomerAddressData(consignment?.customer_id, company_id)
        // getCustomerDeliveryAddressData(consignment?.delivery_name)
        getCustomerCompanyEditData(consignment?.billing_customer)

        let address_key = `${consignment?.billing_address_id}_${consignment?.billing_address}`

        getCustomerAddressPickupEditData(company_id, consignment?.billing_customer)  //pickup_customer_id
        getCustomerAddressDeliveryEditData(company_id, consignment?.billing_customer)

        consignment.billing_address_id = address_key
        getAllCustomerData(company_id, consignment?.customer_id)

        consignment.booked_date = booked_date
        consignment.pickup_date = pickup_date
        consignment.delivery_date = delivery_date
        consignment.truck_details[0].truck_details = truck_details_data?.truck_type
        consignment.truck_details.truck_number = truck_details_data?.truck_number
        consignment.truck_details.truck_document_id = truck_details_data?.truck_document_id
        setInitialState(consignment) 

        setLoader(false)
      } else {
        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
        setLoader(false)
      }
    })
  }


  const handleOnChange = async (event) => {
    if (event.target.files[0].name !== null) {

      let image_name = event.target.files[0]

      if (image_name && (image_name.type === 'image/jpg' || image_name.type === 'image/jpeg' || image_name.type === 'image/png' || image_name.type === 'application/pdf')) {
        if (image_name !== undefined) {
          const formData = new FormData();
          formData.append(`driver_doc`, image_name)

          const headers = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };

          const res = await uploadDocument(formData, headers)
          // setFilename(res.data)
          imageArr.push(res.data);

        }
      } else {
        toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'Please select a valid JPG, JPEG, PNG, PDF file Only.', life: 3000, });
      }

    }
  };
  const getAllCustomerData = async (company_id, customer_id) => {
    const results = await getCustomerAddressBook(company_id, customer_id);
    setAllCustomer(results?.data)
  }


  useEffect(() => {
    // allCustomerAddressData()
    getAllTruckTypeData()
    getData();
    getAllCountries()
    getAllStates()
    getDeliveryAllCountries()
    getDeliveryAllStates()
    // getAllCustomerData(company_id)
    getAllDriverData(company_id)
    bookMinDateVal()
  }, [])

  useEffect(() => {
    // allCustomerAddressData()
    getAllTruckTypeData()
    getData();
    getAllCountries()
    getAllStates()
    getDeliveryAllCountries()
    getDeliveryAllStates()
    // getAllCustomerData(company_id)
    getAllDriverData(company_id)
    bookMinDateVal()
  }, [id])

  let bookMinDateVal = () => {
    setBookMinDate(new Date())
  }



  const allCustomerAddressData = async () => {
    const results = await getAllCustomerAddress();
    setAllCustomerAddress(results.data)
  }


  const getAllTruckTypeData = async () => {
    const results = await getAllTruckType();
    setAllTruck(results.data)
  }
  const getAllDriverData = async (company_id) => {
    const results = await getAllDriver(company_id);
    setAllDriver(results.data)
  }

  const getAllCustomerOption = () => {
    const option = [];
    allCustomer?.map((ele) => {
      option.push({ value: ele.customer_address_id, label: ele.customer_company_name })
    })
    return option;
  }

  const getAllTruckOption = () => {
    const option = [];
    allTruck?.map((ele) => {
      option.push({ value: ele.truck_id, label: ele.truck_details })
    })
    return option;
  }


  const getAllDriverOption = () => {
    const option = [];

    allDriver?.map((ele) => {
      option.push({ value: ele?.drivers?.driver_id, label: ele?.drivers?.first_name.concat(" ", ele?.drivers?.last_name) })
    })
    return option;
  }


  const getAllCountries = async () => {
    const results = await getCountry();
    setCountry(results.data)
  }

  const getAllStates = async () => {
    const results = await getState();
    setStates(results.data)
  }


  const getCountryOption = () => {
    const option = [];
    country?.map((ele) => {
      option.push({ value: ele.id, label: ele.country_name })
    })
    return option;
  }

  const getStateOption = (CoutryId) => {
    const option = [];
    states?.filter((ele) => {
      if (ele.country_id == CoutryId) {
        option.push({ value: ele.id, label: ele.state_name })
      }
    })
    return option;
  }


  // Delivery
  const getDeliveryAllCountries = async () => {
    const results = await getCountry();
    setDeliveryCountry(results.data)
  }

  const getDeliveryAllStates = async () => {
    const results = await getState();
    setDeliveryStates(results.data)
  }

  const getDeliveryCountryOption = () => {
    const option = [];
    deliveryCountry?.map((ele) => {
      option.push({ value: ele.id, label: ele.country_name })
    })
    return option;
  }

  const getDeliveryStateOption = (CoutryId) => {
    const option = [];
    deliveryStates?.filter((ele) => {
      if (ele.country_id == CoutryId) {
        option.push({ value: ele.id, label: ele.state_name })
      }
    })
    return option;
  }


  function totalCount(data) {
    setjob_dett(data)
  }


  function gettotal(name, data) {
    let total = 0;
    if (data) {
      data.forEach(element => {
        if (element) {
          if (element[name]) {
            total += Number(element[name])
            if (name === "no_of_items") {
              setnoItems(total)
            } else if (name === "pallets") {
              setPallets(total)
            } else if (name === "spaces") {
              setSpaces(total)
            } else if (name === "weight") {
              setWeight(total)
            }
          }

        }
      });
    }
    return total;
  }



  const updateConsignmentData = (formdata, id) => {

    const { updatedAt, is_deleted, status, created_by, createdAt, driver_doc, truck_details, ...InputData } = formdata
    if (imageArr !== '') {
      const source = {
        ...InputData, company_id: reciveLocalData, total_items: noItems, total_pallets: pallets, total_spaces: spaces, total_weight: weight,
        truck_details: [{
          document: imageArr ? imageArr : "",
          truck_details: formdata.truck_details[0]?.truck_details,
          truck_number: formdata.truck_details[0]?.truck_number ? formdata.truck_details[0]?.truck_number : "",
          truck_document_id: formdata.truck_details[0]?.truck_document_id ? formdata.truck_details[0]?.truck_document_id : ""
        }]
      };
      Object.assign(InputData, source)
    }


    updateData(InputData, id).then(({ type, message }) => {
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
      setLoader(false)
      setTimeout(() => {
        navigate('/customer/consignments')
      }, 3000)


    })
    setLoader(false)
  }

  const getCustomerCompanyAddressHandler = async (customerID, setFieldValue) => {

    const { data } = await getCustomerCompanyAddress(customerID);
    const newAddressData = []

    data[0]?.customer_billing_address_details.forEach((ele, i) => {
      let address_key = `${ele.customer_billing_id}_${ele.address}`
      newAddressData.push({
        value: address_key,
        label: `${ele.customer_name} - ${ele.address}`
      })
    })

    setCustomerCompanyAddressData(newAddressData)

    if (data[0]?.customer_billing_address_details.length === 1) {
      setInitialState(prev => ({ ...prev, billing_address_id: newAddressData[0].value }))
    }

    getAllCustomerData(company_id, customerID)
    getCustomerAddressPickupEditData(company_id, customerID)
    getCustomerAddressDeliveryEditData(company_id, customerID)



    // Set The Pickup Customer Name
    const pickupArr = []
    const pickupAddressArr = []

    const customerAddressBookData = await getCustomerAddressBook(company_id, customerID);


    if (customerAddressBookData?.data.length > 0) {

      customerAddressBookData?.data.forEach((ele, i) => {

        if (ele.default_status == 1) {
          defaultStatusArr.push(ele.customer_address_id);
        }

        pickupArr.push({
          value: ele.customer_address_id,
          label: `${ele.customer_company_name}`
        })

        pickupAddressArr.push({
          value: ele.address,
          label: `${ele.address}`
        })

      })

      if (defaultStatusArr[0] === pickupArr[0]?.value) {
        setFieldValue('pickup_customer_id', pickupArr[0]?.value)
      }



      // setpickUpData(pickupArr)
    }


    if (pickupArr.length >= 1 && pickupAddressArr.length >= 1) {

      setFieldValue('customer_address', '');
      setFieldValue('suburb', '');
      setFieldValue('zip_code', '');
      setFieldValue('country_id', '');
      setFieldValue('state_id', '');

      if (defaultStatusArr[0] === pickupArr[0]?.value) {
        setFieldValue('customer_address', pickupAddressArr[0]?.value);
        setFieldValue('suburb', customerAddressBookData?.data[0]?.suburb);
        setFieldValue('zip_code', customerAddressBookData?.data[0]?.zip_code);
        setFieldValue('country_id', customerAddressBookData?.data[0]?.country_id);
        setFieldValue('state_id', customerAddressBookData?.data[0]?.state_id);
      }

    } else {
      setFieldValue('customer_address', '');
      setFieldValue('suburb', '');
      setFieldValue('zip_code', '');
      setFieldValue('country_id', '');
      setFieldValue('state_id', '');
    }

    setFieldValue('delivery_address', '');
    setFieldValue('delivery_suburb', '');
    setFieldValue('delivery_zip_code', '');
    setFieldValue('delivery_country_id', '');
    setFieldValue('delivery_state_id', '');
  }


  const getCustomerAddressHandler = async (addressId, setFieldValue) => {

    const compId = reciveLocalData;
    const { data } = await getSingleCustomerAddressBook(compId, addressId);

    // setCustomerAddress(newAddressData)
    setAllAddressData(data)

    if (data) {
      setFieldValue('customer_address', data.address);
      setFieldValue('suburb', data?.suburb);
      setFieldValue('zip_code', data?.zip_code);
      setFieldValue('country_id', data?.country_id);
      setFieldValue('state_id', data?.state_id);

    } else {
      setFieldValue('customer_address', '');
      setFieldValue('suburb', '');
      setFieldValue('zip_code', '');
      setFieldValue('country_id', '');
      setFieldValue('state_id', '');
    }
  }

  const getCustomerAddressKeywordHandler = async (keywords, setFieldValue) => {


    let resultData = allAddressData.filter((ele, id) => ele.address === keywords)[0]

    if (allAddressData.length > 0) {
      setFieldValue('suburb', resultData?.suburb);
      setFieldValue('zip_code', resultData?.zip_code);
      setFieldValue('country_id', resultData?.country_id);
      setFieldValue('state_id', resultData?.state_id);

    } else {
      setFieldValue('suburb', '');
      setFieldValue('zip_code', '');
      setFieldValue('country_id', '');
      setFieldValue('state_id', '');
    }

  }


  const getCustomerAddressDeliveryHandler = async (addressId, setFieldValue) => {

    const compId = reciveLocalData;
    const { data } = await getSingleCustomerAddressBook(compId, addressId);

    // setCustomerDeliveryAddress(newDeliveryAddressData)
    setAllDeliveryAddressData(data)

    if (data) {
      setFieldValue('delivery_address', data.address);
      setFieldValue('delivery_suburb', data?.suburb);
      setFieldValue('delivery_zip_code', data?.zip_code);
      setFieldValue('delivery_country_id', data?.country_id);
      setFieldValue('delivery_state_id', data?.state_id);

    } else {
      setFieldValue('delivery_address', '');
      setFieldValue('delivery_suburb', '');
      setFieldValue('delivery_zip_code', '');
      setFieldValue('delivery_country_id', '');
      setFieldValue('delivery_state_id', '');
    }
  }

  const getCustomerAddressDeliveryKeywordHandler = async (keywords, setFieldValue) => {

    let resultData = allDeliveryAddressData.filter((ele) => ele.address === keywords)[0]

    if (allDeliveryAddressData.length > 0) {
      setFieldValue('delivery_suburb', resultData?.suburb);
      setFieldValue('delivery_zip_code', resultData?.zip_code);
      setFieldValue('delivery_country_id', resultData?.country_id);
      setFieldValue('delivery_state_id', resultData?.state_id);

    } else {
      setFieldValue('delivery_suburb', '');
      setFieldValue('delivery_zip_code', '');
      setFieldValue('delivery_country_id', '');
      setFieldValue('delivery_state_id', '');
    }

  }



  const getBookDate = (value) => {
    setPickupMinDate(value);
    setDeliveryMinDate(value)
  }

  const getPickupDate = (value) => {
    setPickupMaxDate(value)
    setDeliveryMinDate(value);
    setBookMaxDate(value)
  }

  const getDeliveryDate = (value) => {
    setPickupMaxDate(value)
  }

  const search = async (event) => {
    const company_id = reciveLocalData

    let keyword = event.query

    if (keyword !== null && keyword !== 'undefined' && keyword.length > 2) {
      const resultData = await searchManifestNumber(company_id, keyword);
      if (resultData?.data?.data.length) {
        setAllManifestData(resultData?.data?.data)
      } else {
        setAllManifestData([{ manifest_id: '', manifest_number: 'Not Found' }])
      }

    }
  }


  const getDriverMobileNumber = async (driverId, setFieldValue) => {
    const results = await getAllDriver(company_id);
    let resultData = results?.data?.filter((ele) => ele?.drivers?.driver_id === driverId).map(data => data)[0]
    setFieldValue('driver_mobile_number', resultData.drivers?.mobile);
  }

  let loadImage = function (values) {

    var image = new Image();
    var url_image = BASE_BACKEND_URL + values?.truck_details[0]?.document;
    image.src = url_image;

    if (image.width == 0) {
      if (values?.truck_details[0]?.document?.split('.')?.pop() === 'pdf') {
        return values?.truck_details[0]?.document !== null && values?.truck_details[0]?.document !== undefined && (((values?.truck_details[0]?.document?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> : <a href={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} width="100" /></a>)
      } else {
        return <img src={`${BASE_BACKEND_URL}` + 'default_image.jpg'} width="100" />;
      }
    } else {
      return values?.truck_details[0]?.document !== null && values?.truck_details[0]?.document !== undefined && (((values?.truck_details[0]?.document?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> : <a href={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} width="100" /></a>)
    }
  }


  function closeModal() {
    setModalShow(false);
  }

  function openModal(e) {
    setModalShow(true);
  }

  const uploadImageHandler = async (event) => {
    if (event.target.files[0].name !== null) {
      let image_name = event.target.files[0]

      if (image_name && (image_name.type === 'image/jpg' || image_name.type === 'image/jpeg' || image_name.type === 'image/png' || image_name.type === 'application/pdf')) {
        if (image_name !== undefined) {
          const formData = new FormData();
          formData.append(`document`, image_name)

          setSelectedFile(image_name)
        }
      } else {
        toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'Please select a valid JPG, JPEG, PNG, PDF file Only.', life: 3000, });
      }

    }

  };

  const saveFile = async (event) => {
    event.preventDefault()
    closeModal()
    getData()

    if (selectedFile !== null) {

      const headers = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      const formData = new FormData();
      formData.append('consignment_id', pathname.split('/')[4]);
      formData.append('document', selectedFile);

      const res = await uploadConsignmentDocument(formData, headers)
      if (res.error !== null) {
        toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'File size exceeds maximum limit 2 MB.', life: 3000, });
      }
      closeModal()
    }

  };

  // validationSchema={connsignmentValidateSchema}  

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
          <Formik enableReinitialize initialValues={initialState} validationSchema={connsignmentValidateSchema} onSubmit={(formdata) => {
            confirmDialog({
              message: 'Are you sure you want to update?',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                updateConsignmentData(formdata, id)
                setLoader(true)
              }
            });
          }}>
            {
              ({ values, setFieldValue }) => (

                <Form onKeyUp={totalCount(values.consignment_details)} className="d-flex flex-column overflow-auto" encType="multipart/form-data">
                  <div className="container d-flex flex-column h-100 ">

                    <div className="px-4 mt-3">
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                          <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Edit  Consignment</h2>
                        </div>
                        <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                          <Link to="../" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link>
                          <Button type="submit" variant="" className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save</Button>
                        </div>
                      </div>
                    </div>
                    <div className="overflow-auto flex-grow-1 mt-5 px-4">
                      <div className="card p-4">
                        <div className="card-body">
                          <div className="row gx-5">
                            <input className="form-control px-3" name="company_code" hidden />
                            <div className="col-12 mb-3">
                              <h4 className=" fw600 fz16 text-dark">Job Header</h4>
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Job Number  <span className="text-danger">*</span></label>
                              <Field name="job_number" render={({ field }) => <InputText className="form-control px-3"    {...field} maxLength="100" readOnly disabled />} />
                              <ErrorMessage name="job_number" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Booked Date   <span className="text-danger">*</span></label>
                              <Field name="booked_date" render={({ field }) => <Calendar minDate={bookMinDate} maxDate={bookMaxDate} onSelect={(e) => getBookDate(e.value)} className="w-100" readOnlyInput inputClassName="form-control px-3 calender-icon-bg" {...field} dateFormat="dd/mm/yy" />} />
                              <ErrorMessage name="booked_date" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Pickup Date  <span className="text-danger">*</span> </label>
                              <Field name="pickup_date" render={({ field }) => <Calendar onSelect={(e) => getPickupDate(e.value)} maxDate={pickupMaxDate} minDate={pickupMinDate} className="w-100" readOnlyInput inputClassName="form-control px-3 calender-icon-bg" {...field} dateFormat="dd/mm/yy" />} />
                              <ErrorMessage name="pickup_date" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Delivery Date  <span className="text-danger">*</span> </label>
                              <Field name="delivery_date" render={({ field }) => <Calendar minDate={deliveryMinDate} onSelect={(e) => getDeliveryDate(e.value)} className="w-100" readOnlyInput inputClassName="form-control px-3 calender-icon-bg" {...field} dateFormat="dd/mm/yy" />} />
                              <ErrorMessage name="delivery_date" component="span" className="from-error-span" />
                            </div>

                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Special Instruction</label>
                              <Field name="special_instruction" render={({ field }) => <InputText className="form-control px-3"   {...field} maxLength="100" />} />
                              <ErrorMessage name="special_instruction" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Manifest Number</label>
                              {/* <Field name="manifest_number" render={({ field }) => <InputText className="form-control px-3" keyfilter="int"  {...field} maxLength="100" />} />
                              <ErrorMessage name="manifest_number" component="span" className="from-error-span" /> */}

                              <AutoComplete name="manifest_number" disabled={disableManifest ? true : false} className="w-100" inputClassName="form-control" field="manifest_number" value={manifestValue} suggestions={allManifestData} completeMethod={search} onChange={(e) => { setManifestValue(e.value); setFieldValue('manifest_number', e.value.manifest_number) }} />
                            </div>

                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Billing Customer <span className="text-danger">*</span></label>

                              <Field name="billing_customer" render={({ field }) => <Select options={customerCompany} {...field} placeholder="Please select a name" className="w-100 px-3" maxLength="100" onChange={(e) => { getCustomerCompanyAddressHandler(e.target.value, setFieldValue); setFieldValue('billing_customer', e.value) }} filter />} />

                              <ErrorMessage name="billing_customer" component="span" className="from-error-span" />
                            </div>

                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Billing Address <span className="text-danger">*</span></label>

                              <Field name="billing_address_id" render={({ field }) => <Select name="billing_address_id" {...field} className="w-100 px-3" options={customerCompanyAddress} />} />
                              <ErrorMessage name="billing_address_id" component="span" className="from-error-span" />
                            </div>

                            <div className="col-12 mt-4 mb-3">
                              <h4 className=" fw600 fz16 text-dark">Pickup Address Details </h4>
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Customer Name  <span className="text-danger">*</span></label>

                              <Field name="pickup_customer_id" render={({ field }) => <Select options={pickUpData} {...field} placeholder="Please select a name" className="w-100 px-3" maxLength="100" onChange={(e) => { getCustomerAddressHandler(e.target.value, setFieldValue); setFieldValue('pickup_customer_id', e.value) }} filter />} />

                              <ErrorMessage name="pickup_customer_id" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Address  <span className="text-danger">*</span></label>
                              <Field name="customer_address" render={({ field }) => <InputText className="form-control px-3"   {...field} />} maxLength="100" />

                              <ErrorMessage name="customer_address" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Suburb  <span className="text-danger">*</span></label>
                              <Field name="suburb" render={({ field }) => <InputText className="form-control px-3"   {...field} maxLength="100" />} />
                              <ErrorMessage name="suburb" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Zip Code  <span className="text-danger">*</span></label>
                              <Field name="zip_code" render={({ field }) => <InputText className="form-control px-3 " keyfilter="int"  {...field} maxLength="4" />} />
                              <ErrorMessage name="zip_code" component="span" className="from-error-span" />
                            </div>

                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Country  <span className="text-danger">*</span></label>
                              <Field name="country_id" render={({ field }) => <Select options={getCountryOption()} placeholder="Please select country name" {...field} className="w-100 px-3" />} />
                              <ErrorMessage name="country_id" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">State  <span className="text-danger">*</span></label>
                              <Field name="state_id" render={({ field }) => <Select options={getStateOption(values.country_id)} placeholder="Please select a state" {...field} className="w-100 px-3" />} />
                              <ErrorMessage name="state_id" component="span" className="from-error-span" />
                            </div>
                            <div className="col-12 mt-4 mb-3">
                              <h4 className=" fw600 fz16 text-dark">Delivery Address Details </h4>
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Customer Name  <span className="text-danger">*</span></label>

                              <Field name="delivery_name" render={({ field }) => <Select options={pickUpDeliveryData} {...field} placeholder="Please select a name" className="w-100 px-3" maxLength="100" onChange={(e) => { getCustomerAddressDeliveryHandler(e.target.value, setFieldValue); setFieldValue('delivery_name', e.value) }} filter />} />
                              <ErrorMessage name="delivery_name" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Address  <span className="text-danger">*</span></label>
                              <Field name="delivery_address" render={({ field }) => <InputText className="form-control px-3"   {...field} />} maxLength="100" />


                              <ErrorMessage name="delivery_address" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Suburb  <span className="text-danger">*</span></label>
                              <Field name="delivery_suburb" render={({ field }) => <InputText className="form-control px-3"   {...field} maxLength="100" />} />
                              <ErrorMessage name="delivery_suburb" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Zip Code  <span className="text-danger">*</span></label>
                              <Field name="delivery_zip_code" render={({ field }) => <InputText className="form-control px-3 " keyfilter="int"   {...field} maxLength="4" />} />
                              <ErrorMessage name="delivery_zip_code" component="span" className="from-error-span" />
                            </div>

                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Country  <span className="text-danger">*</span></label>
                              <Field name="delivery_country_id" render={({ field }) => <Select options={getDeliveryCountryOption()} placeholder="Please select country name" {...field} className="w-100 px-3" />} />
                              <ErrorMessage name="delivery_country_id" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">State  <span className="text-danger">*</span></label>
                              <Field name="delivery_state_id" render={({ field }) => <Select options={getDeliveryStateOption(values.delivery_country_id)} placeholder="Please select a state" {...field} className="w-100 px-3" />} />
                              <ErrorMessage name="delivery_state_id" component="span" className="from-error-span" />
                            </div>
                            <div className="col-12 mt-4 mb-3">
                              <h4 className=" fw600 fz16 text-dark">Job Details</h4>
                              <div className="overflow-auto mt-3 ">
                                <FieldArray name="consignment_details" render={({ push, remove }) => {
                                  return <table className="table" style={{ minWidth: "1200px" }}>
                                    <thead>
                                      <tr>
                                        <th className="fw500 fz14" width="30px">#</th>
                                        <th className="fw500 fz14" width="130px">No of Items  </th>
                                        <th className="fw500 fz14">Freight Description  </th>
                                        <th className="fw500 fz14" width="130px">Pallets  </th>
                                        <th className="fw500 fz14" width="130px">Spaces  </th>
                                        <th className="fw500 fz14" width="130px">Weight (KG)  </th>
                                        <th className="fw500 fz14">Job Temp  </th>
                                        <th className="fw500 fz14">Recipient  <br />Ref No.  </th>
                                        <th className="fw500 fz14" width="130px">Senders Ref No.  </th>
                                        <th className="fw500 fz14" width="30px"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {values?.consignment_details && values?.consignment_details.map((val, idx) => (

                                        <tr key={idx}>
                                          <td>{idx + 1}</td>
                                          <td><Field name={`consignment_details[${idx}].no_of_items`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" keyfilter="int" />
                                              <ErrorMessage name={`consignment_details[${idx}].no_of_items`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          <td><Field name={`consignment_details[${idx}].freight_desc`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" />
                                              <ErrorMessage name={`consignment_details[${idx}].freight_desc`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          <td><Field name={`consignment_details[${idx}].pallets`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" keyfilter="int" />
                                              <ErrorMessage name={`consignment_details[${idx}].pallets`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          <td><Field name={`consignment_details[${idx}].spaces`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" keyfilter="int" />
                                              <ErrorMessage name={`consignment_details[${idx}].spaces`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          <td><Field name={`consignment_details[${idx}].weight`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" keyfilter="int" />
                                              <ErrorMessage name={`consignment_details[${idx}].weight`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          <td><Field name={`consignment_details[${idx}].job_temp`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" />
                                              <ErrorMessage name={`consignment_details[${idx}].job_temp`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          <td><Field name={`consignment_details[${idx}].recipient_no`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" />
                                              <ErrorMessage name={`consignment_details[${idx}].recipient_no`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          <td><Field name={`consignment_details[${idx}].senders_no`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" />
                                              <ErrorMessage name={`consignment_details[${idx}].senders_no`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                          </td>
                                          {values.consignment_details.length - 1 === idx ? (
                                            <td> <>
                                              <div className="d-flex gap-2">
                                                {values.consignment_details.length > 1 &&
                                                  <button type="button" onClick={() => remove(idx)}
                                                    className="btn fw500 fz14 ms-auto text-danger shadow-none px-0">
                                                    <i className="fa-regular fa-minus-circle "></i>
                                                  </button>
                                                }

                                                <button type="button"
                                                  onClick={() => push(consignmentInitialState.consignment_details[0])} className="btn fw500 fz14 ms-auto text-success shadow-none px-0">
                                                  <i className="fa-regular fa-plus-circle "></i>
                                                </button>
                                              </div>
                                            </>
                                            </td>
                                          ) : (
                                            <td>
                                              <button type="button" onClick={() => remove(idx)}
                                                className="btn fw500 fz14 ms-auto text-danger shadow-none px-0">
                                                <i className="fa-regular fa-minus-circle "></i>
                                              </button>
                                            </td>
                                          )}
                                        </tr>

                                      ))}
                                    </tbody>
                                    <tfoot>

                                      <tr>
                                        <th className="fw500 fz14" width="30px">#</th>
                                        <th className="fw500 fz14">Total Items - {gettotal('no_of_items', job_det)}</th>
                                        <th className="fw500 fz14"></th>
                                        <th className="fw500 fz14">Total Pallets - {gettotal('pallets', job_det)}</th>
                                        <th className="fw500 fz14">Total Spaces - {gettotal('spaces', job_det)}</th>
                                        <th className="fw500 fz14">Total Weight - {gettotal('weight', job_det)}</th>
                                        <th className="fw500 fz14"></th>
                                        <th className="fw500 fz14"></th>
                                        <th className="fw500 fz14"></th>
                                        <th className="fw500 fz14" width="30px"></th>
                                      </tr>

                                    </tfoot>
                                  </table>
                                }}>

                                </FieldArray>

                              </div>
                            </div>
                            <div className="col-12 mb-3 mt-4">
                              <h4 className="fw600 fz16 text-dark">Equipment</h4>
                            </div>
                            <div className="col-sm-4  mb-4">
                              <label htmlFor="" className="mb-2">Chep  </label>
                              <Field name="chep" render={({ field }) => <InputText className="form-control px-3"   {...field} />} maxLength="50" />
                              <ErrorMessage name="chep" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-4 mb-4">
                              <label htmlFor="" className="mb-2">Loscom  </label>
                              <Field name="loscom" render={({ field }) => <InputText className="form-control px-3"   {...field} />} maxLength="50" />
                              <ErrorMessage name="loscom" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-4  mb-4">
                              <label htmlFor="" className="mb-2">Plain  </label>
                              <Field name="plain" render={({ field }) => <InputText className="form-control px-3"   {...field} />} maxLength="50" />
                              <ErrorMessage name="plain" component="span" className="from-error-span" />
                            </div>
                            <div className="col-12 mb-3 mt-4">
                              {/* <h4 className="fw600 fz16 text-dark">Document</h4> */}
                              <span><button type="button" className="btn btn-primary" data-toggle="modal" onClick={() => openModal()}>
                                Update Document
                              </button></span>
                            </div>
                            <div className="col-sm-4  mb-4">
                              {/* <label htmlFor="" className="mb-2">Upload Document </label> */}
                              {/* <Field name="truck_details[0].driver_doc" render={({ field }) => <input
                                type="file" className="form-control px-3" onChange={(e) => { handleOnChange(e); setFieldValue('truck_details[0].driver_doc', e.target.files[0].name) }} />} /> */}


                              {loadImage(values)}

                              {/* {values?.truck_details[0]?.document !== null && values?.truck_details[0]?.document !== undefined && (((values?.truck_details[0]?.document?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> : <a href={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${values?.truck_details[0]?.document}`} width="100" /></a>)} */}

                              <ErrorMessage name="truck_details[0].driver_doc" component="span" className="from-error-span" />
                            </div>


                            {/*  <div className="col-sm-4 mb-4">
                              <label htmlFor="" className="mb-2">Vehicle Type  </label>
                              <Field name="truck_details[0].truck_details" render={({ field }) => <Select options={getAllTruckOption()} placeholder="Please select a vehicle type" {...field} className="w-100 px-3" />} />
                              <ErrorMessage name="truck_details[0].truck_details" component="span" className="from-error-span" />
                            </div >

                            <div className="col-sm-4  mb-4">
                              <label htmlFor="" className="mb-2">Vehicle Number  </label>
                              <Field name="truck_details[0].truck_number" render={({ field }) => <InputText className="form-control px-3" {...field} maxLength="100" />} />
                              <ErrorMessage name="truck_details[0].truck_number" component="span" className="from-error-span" />
                            </div >

                            <div className="col-12 mb-3 mt-4">
                              <h4 className="fw600 fz16 text-dark">Driver</h4>
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Driver Name  </label>
                              <Field name="driver_id" render={({ field }) => <Select options={getAllDriverOption()} placeholder="Please select a driver" {...field} className="w-100 px-3" maxLength="100" onChange={(e) => { getDriverMobileNumber(e.target.value, setFieldValue); setFieldValue('driver_id', e.value) }} />} />
                              <ErrorMessage name="driver_id" component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6   mb-4">
                              <label htmlFor="" className="mb-2">Driver Mobile   </label>
                              <Field name="driver_mobile_number" render={({ field }) => <InputText className="form-control px-3 " keyfilter="int"  {...field} maxLength="10" />} />
                              <ErrorMessage name="driver_mobile_number" component="span" className="from-error-span" />
                            </div> */}
                          </div >
                        </div >
                      </div >
                    </div >
                  </div >
                </Form >
              )
            }
          </Formik>

          {/* Modal   */}

          <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" centered>
            <Modal.Header closeButton={false}>
              <Modal.Title> Add Customer Document </Modal.Title>
              <Button className="btn-close ms-2 " onClick={() => setModalShow(false)} variant=""></Button>
            </Modal.Header>
            <Modal.Body>
              <div className="px-0 table-wrapper">

                <div className="row">
                  <div className="col-md-6 offset-3">
                    <form>
                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Upload  (jpg,png,jpeg,pdf 2 MB Only) </label>
                        <input type="file" className="form-control" name="document" onChange={(e) => uploadImageHandler(e)} />
                      </div>


                      <div className="form-group">
                        <div className="text-center btn-block p-3">
                          <input type="submit" name="submit" className="btn btn-primary" value="Save" onClick={(e) => saveFile(e)} />
                        </div>
                      </div>

                    </form>
                  </div>
                </div>


              </div>
            </Modal.Body>
          </Modal>


        </div >
      </div >
    </>
  );
}

export default Edit;
