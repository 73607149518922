import React, { useRef, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { getAllCustomer, getAllDriver, getAllCustomerAddress, getAllTruckType } from '../../services/commonController';
import { listData, deleteData, title, activeInactive, activeInactiveBulk, removeDataBulk } from "../controllers/ConsignmentController";
import { Dropdown, Button } from "../../BootstrapComponents";
import { InputText, DataTable, Column, ConfirmDialog, confirmDialog, Toast, confirmPopup } from "../../PrimeReactComponents";
import FilterOffset from "./components/OffsetRight";
import dataTableConfig from "../../config/dataTableOptions";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter'
// import useLoader from '../../features/customUseHook/useLoaderHook'
import Moment from 'react-moment';
import Loader from "../../features/loader/Loader";

import { useResizeListener } from 'primereact/hooks';


function List() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const { customerUserInfo } = useSelector(state => state);
  const reciveLocalData = customerUserInfo?.company_id;
  const reciveLocalUserData = customerUserInfo?.relation_id;
  const [tableHeight, setTableHeight] = React.useState("0px");
  const [selectedCheckbox, setSelectedCheckbox] = React.useState(null);
  const [list, setList] = useState([])
  const [allCustomer, setAllCustomer] = useState();
  const [allDriver, setAllDriver] = useState();
  const [filters, globalFilterValue, onGlobalFilterChange] = useDataTableFilter();
  const [loader, setLoader] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  //const [loader, setloader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const [offsetFilter, setoffsetFilter] = useState(false);

  let customerConsignmentListArr = []
  let customerConsignmentArr = []
  let customerPermission = JSON.parse(localStorage.getItem('rolePermissionCustomerInfo'))

  customerPermission.length > 0 && customerPermission.filter(e => e.menuData?.menu_name === 'Consignments')?.map(val => {
    if (!customerConsignmentListArr.includes(val?.menuData?.menu_name)) {
      customerConsignmentListArr.push(val?.menuData?.menu_name)
    }

    if (!customerConsignmentArr.includes(val?.menuActionData?.action_name)) {
      customerConsignmentArr.push(val?.menuActionData?.action_name)
    }
  })

  if (customerConsignmentListArr.length == 0) {
    navigate('/customer/404');
  }

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
});

useEffect(() => {
  bindWindowResizeListener();

  return () => {
      unbindWindowResizeListener();
  };
}, [bindWindowResizeListener, unbindWindowResizeListener]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    status: "",
    company_id: reciveLocalData,
    customer_id: reciveLocalUserData,
    globalFilterValue: "",
    filters: {
      'job_number': { value: '', matchMode: 'contains' },
      'manifest_number': { value: '', matchMode: 'contains' },
      'customer_address': { value: '', matchMode: 'contains' },
      'delivery_address': { value: '', matchMode: 'contains' },
      'pickup_date': { value: '', matchMode: 'contains' },
      'delivery_date': { value: '', matchMode: 'contains' },
      'driver_id': { value: '', matchMode: 'contains' },
    }
  });


  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();

  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {
        setTotalRecords(data?.data?.data?.count);
        setList(data?.data?.data?.rows);
        getAllCustomerData(lazyParams?.company_id)
        getAllDriverData(lazyParams?.company_id)
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 10,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      globalFilterValue: "",
    }, []);

  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }

  const onSelectionChange = (event) => {
    const value = event.value;
    setSelectedCustomers(value);
    setSelectAll(value.length === totalRecords);
  }

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      listData.then(data => {
        setSelectAll(true);
        setSelectedCustomers(data.rows);
      });
    }
    else {
      setSelectAll(false);
      setSelectedCustomers([]);
    }
  }

  const advanceSearch = (value) => {
    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.job_number });
      setoffsetFilter(true);

    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;

    if (value.length > 2) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
    } else if (value.length === 0) {
      // loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });
    }
  }

  const getAllCustomerData = async (company_id) => {
    const results = await getAllCustomer(company_id);
    setAllCustomer(results.data)
  }

  const getAllDriverData = async (company_id) => {
    const results = await getAllDriver(company_id);
    setAllDriver(results.data)
  }


  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2">
          <Link to={"edit/" + rowData?.consignment_id} className={`btn fz16 p-0 edit-btn ${customerConsignmentArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`}>
            <i className="fa-light fa-edit"></i>
          </Link>
          <Button className={`deleteRow btn fz16 p-0 delete-btn ${customerConsignmentArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} variant="" onClick={() => deleteConfirm(rowData?.consignment_id)}>
            <i className="fa-light fa-trash"></i>
          </Button>
          <Link to={"view/" + rowData?.consignment_id} className={`btn p-0 fz16 ${customerConsignmentArr.includes('View') ? '' : 'border-0 shadow-none disabled'}`}>
            <i className="fa-light fa-eye"></i>
          </Link>
        </div>
      </>
    );
  };

  const deleteConfirm = (data) => {
    confirmDialog({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteData(data).then(res => {
          toast?.current?.show({ severity: 'success', detail: 'Deleted Successfully', life: 3000, });
          loadLazyData();
          setLoader(false)
          setTimeout(() => {
            navigate('/customer/consignments')
          }, 3000)
        })

      },
      reject: () => {
        console.log('Not delete')
      }
    });
  }


  const activeInactiveStatus = (status, id) => {
    setLoader(true)
    activeInactive(status, id).then(({ type, message }) => {
      setIsListUpdated(true)
      setLoader(false)
      loadLazyData();
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {
    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data.status === '1' ? 'Deactivate' : 'Activate'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data.status, data.consignment_id);
      }
    });
  }

  const statusBodyTemplate = (rowData) => {
    /* return <span onClick={(e) => activeInactiveHandler(e, rowData)} className={`fw600 border-0 badge py-2 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Active" : "Inactive"}</span>; */

    if (rowData.status == '0') {
      return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-yellow`}>Booked</span>;
    } else if (rowData.status == '1') {
      return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-red`}>Confirmed</span>;
    } else {
      return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-green`}>Delivered</span>;
    }
  };

  const createdDateTemplate = (rowData) => {
    return <span>{rowData?.createdAt !== '' ? <Moment date={rowData?.createdAt} format="D MMM YYYY" /> : ''}</span>
  }

  const createdByTemplate = (rowData) => {
    return <span>{rowData?.userData?.first_name} {rowData?.userData?.last_name} </span>
  }

  const pickupDateTemplate = (rowData) => {
    return <span>{rowData?.pickup_date !== '' ? <Moment date={rowData?.pickup_date} format="D MMM YYYY" /> : ''}</span>
  }

  const deliveryDateTemplate = (rowData) => {
    return <span>{rowData?.delivery_date !== '' ? <Moment date={rowData?.delivery_date} format="D MMM YYYY" /> : ''}</span>
  }


  const driverNameTemplate = (rowData) => {
    return <span>{rowData?.driver?.first_name !== '' ? rowData?.driver?.first_name.concat(' ', rowData?.driver?.last_name) : ''}</span>
  }


  const customerTemplate = (rowData) => {
    return <span>{rowData?.customer_details?.first_name !== '' ? rowData?.customer_details?.first_name.concat(' ', rowData?.customer_details?.last_name) : ''}</span>
  }

  const pickupCustomerTemplate = (rowData) => {
    let customerName = (rowData?.customer_pickup_details?.customer_company_name !== undefined) ? `${rowData?.customer_pickup_details?.customer_company_name}  ,  ` : ''
    return <span>{customerName}  {rowData?.customer_pickup_details?.address}</span>
  }

  const deliveryCustomerTemplate = (rowData) => {
    let customerName = (rowData?.customer_delivery_details?.customer_company_name !== undefined) ? `${rowData?.customer_delivery_details?.customer_company_name}  ,  ` : ''
    return <span>{customerName}   {rowData?.customer_delivery_details?.address}</span>
  }

  const manifestTemplate = (rowData) => {
    return <span>{(rowData?.manifest_number !== 'Not Found') ? rowData?.manifest_number : ''}</span>
  }

  // BULK CHANGES

  const activeInactiveStatusBulk = (status, id) => {
    setLoader(true)
    activeInactiveBulk(status, id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }

  const bulkActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.consignment_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }

  const bulkDeActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.consignment_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Deactivate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("0", selectedId);
      }
    });
    setSelectedCustomers();
  }

  const DeleteBulk = (id) => {
    setLoader(true)
    removeDataBulk(id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const bulkDelete = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.consignment_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }
 
  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0"><i className="fa-light fa-briefcase"></i> &nbsp; Consignment</h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to={`../customer-address-books/${reciveLocalUserData}/${reciveLocalData}`} className="bg-green text-white btn fz14 me-3">
                  <i className="fa-regular fa-map"></i> Address Book
                </Link> &nbsp;&nbsp;&nbsp;

                {/* <Link to="./manifest" className="btn-outline-dark btn fz14 me-3"><i className="fa-light fa-eye"></i> Manifest List</Link> */}
                <Link to="./add" className={`bg-green text-white btn fz14 me-3 ${customerConsignmentArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}><i className="fa-light fa-plus-circle"></i>  Add New Consignment</Link>

                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                <Dropdown className="order-4 me-3">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <ul>
                      <li>
                        <Button className={`dropdown-item ${customerConsignmentArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} to="#" onClick={() => bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Button>
                      </li>
                      {/*  <li>
                        <Button className="dropdown-item" onClick={() => bulkActivate()} >
                          <i className="fa-light me-2 fa-check"></i>
                          Active
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={() => bulkDeActivate()}>
                          <i className="fa-light me-2 fa-ban"></i>
                          Inactive
                        </Button>
                      </li> */}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>

                {/*   <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-gear"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <div className="form-group px-2 border-bottom pb-2">
                      <div className="position-relative">
                        <input type="text" className="form-control fw600 rounded p-2" placeholder="Search" />
                        <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute"><i className="fa-light fa-search"></i></button>
                      </div>
                    </div>
                    <ul className="max-height-300 filterTable">
                      <li><label className="dropdown-item align-items-center d-flex py-2"> <input type="checkbox" checked="" data-val="job-type" className="me-2" /> Job Type</label></li>
                      <li><label className="dropdown-item align-items-center d-flex py-2"> <input type="checkbox" checked="" data-val="pickup-address" className="me-2" /> Pickup Address</label></li>
                      <li><label className="dropdown-item align-items-center d-flex py-2"> <input type="checkbox" checked="" data-val="delivery-address" className="me-2" /> Delivery Address</label></li>
                      <li><label className="dropdown-item align-items-center d-flex py-2"> <input type="checkbox" checked="" data-val="driver-name" className="me-2" /> Driver Name</label></li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={list} lazy selectionMode="checkbox" dataKey="job_number"   {...{ scrollHeight: tableHeight }} scrollDirection="both"
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)}
              {...dataTableConfig}
            >
              <Column headerStyle={{ width: "3em" }} selectionMode="multiple" frozen></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }}></Column>
              <Column field="job_number" header="Job Number" sortable style={{ flexGrow: 1, width: "220px" }}></Column>
              <Column field="manifest_number" header="Manifesto No." body={manifestTemplate} sortable style={{ flexGrow: 1, flexBasis: "150px" }}></Column>
              <Column field="customer_id" body={customerTemplate} header="Customer Name" sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="customer_address" header="Pickup Address" body={pickupCustomerTemplate} sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="delivery_address" header="Delivery Address" body={deliveryCustomerTemplate} sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="pickup_date" body={pickupDateTemplate} header="Pickup Date" sortable style={{ flexGrow: 1, flexBasis: "130px" }}></Column>
              <Column field="delivery_date" body={deliveryDateTemplate} header="Delivery Date" sortable style={{ flexGrow: 1, flexBasis: "150px" }}></Column>
              <Column field="driver_id" body={driverNameTemplate} header="Driver Name" sortable style={{ flexGrow: 1, flexBasis: "150px" }}></Column>
              <Column field="createdAt" header="Created On" body={createdDateTemplate} sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="created_by" header="Created By" body={createdByTemplate} sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
