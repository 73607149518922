import React, { useRef, useState } from "react";
import './timesheet.css'
import Logo1 from '../../assets/img/logo-black.png'
import LogoIcon from '../../assets/img/logo-icon.png';
import Moment from 'react-moment';


function TimesheetPDFView({ initialState }) {

    let trailerData = initialState?.trailers && initialState?.trailers.map(element => {
        return element?.truck_registration
    });

    return (
        <>
            <div>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Application Form</title>
                <div style={{ maxWidth: 1200, margin: 'auto' }}>
                    <div className="dflex01">
                        <div className="namedate">
                            <table className="table" width="100%" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td><b style={{ fontSize: 15 }}>Name:</b></td>
                                    <td><b style={{ fontSize: 15 }}>Glenn Murgatroyd. 50150586</b></td>
                                </tr>
                                    <tr>
                                        <td><b style={{ fontSize: 15 }}>Date:</b></td>
                                        <td><b style={{ fontSize: 15 }}>Monday, October 23, 2023</b></td>
                                    </tr>
                                    <tr>
                                        <td><b style={{ fontSize: 15 }}>Truck:</b></td>
                                        <td><b style={{ fontSize: 15 }}>XV00KA</b></td>
                                    </tr>
                                    <tr>
                                        <td><b style={{ fontSize: 15 }}>Trailer:</b></td>
                                        <td><b style={{ fontSize: 15 }}>T17</b></td>
                                    </tr>
                                </tbody></table>
                        </div>
                        <div className="startend">
                            <table className="table" width="100%" cellPadding={0} cellSpacing={0}>
                                <tbody><tr>
                                    <td className="blck" rowSpan={2}>Start</td>
                                    <td>TIME</td>
                                    <td>9:00am</td>
                                </tr>
                                    <tr>
                                        <td>Odometer</td>
                                        <td>125687</td>
                                    </tr>
                                    <tr>
                                        <td className="blck" rowSpan={2}>End</td>
                                        <td>TIME</td>
                                        <td>5.00pm</td>
                                    </tr>
                                    <tr>
                                        <td>Odometer</td>
                                        <td>13569</td>
                                    </tr>
                                </tbody></table>
                        </div>
                        <div>
                            <img src="logo.jpg" className="Logo" />
                            <p>  Operations: 0418 990 736</p>
                        </div>
                    </div>
                    <br />
                    <h5 style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>Job Details</h5>
                    <table className="table" width="100%" cellPadding={0} cellSpacing={0}>
                        <tbody><tr>
                            <td className="blck" colSpan={2}>Location</td>
                            <td className="blck" colSpan={2}>Time</td>
                            <td className="blck" colSpan={3}>Activity</td>
                            <td className="blck">Details</td>
                            <td className="blck" colSpan={7}>Pallets</td>
                            <td className="blck" rowSpan={3}>Weight</td>
                        </tr>
                            <tr>
                                <td rowSpan={2}>Name of Customer</td>
                                <td rowSpan={2}>Suburb</td>
                                <td rowSpan={2}>Arrive</td>
                                <td rowSpan={2}>Depart</td>
                                <td rowSpan={2}>Rest</td>
                                <td rowSpan={2}>Pickup</td>
                                <td rowSpan={2}>Delivery</td>
                                <td rowSpan={2}>Reference Number/Load Description</td>
                                <td rowSpan={2}>Temp</td>
                                <td colSpan={3}>Delivered</td>
                                <td colSpan={3}>Picked Up</td>
                            </tr>
                            <tr>
                                <td>C</td>
                                <td>L</td>
                                <td>P</td>
                                <td>C</td>
                                <td>L</td>
                                <td>P</td>
                            </tr>
                            <tr>
                                <td>MC Herd</td>
                                <td>Corio</td>
                                <td>9:00am</td>
                                <td>9:00pm</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>832589</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>20225.6</td>
                            </tr>
                            <tr>
                                <td>Pacific</td>
                                <td>Ttown</td>
                                <td>10.45am</td>
                                <td>10.45pm</td>
                                <td>30m</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>832589</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>20225.6</td>
                            </tr>
                        </tbody></table>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <p>I certify that I am presenting myself as fit for duty and understand my obligations in relation to fatugue management. </p>
                    <p>Signature:  _________________________________</p>
                </div>
            </div>




        </>
    );
}

export default TimesheetPDFView;
