
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REACT_APP_API_URL } from '../../config'
import {listData} from "../../platform-admin/controllers/CompanyController"
// const STATUSES = Object.freeze({
//      IDLE:"Idle",
//      LOADING:"Loading...",
//      ERROR:"Error"
// })

const initialState = {}
export const getCompanyThunk =createAsyncThunk("get-data",listData())
export const companyThunk = createAsyncThunk('company-add', async(body) => {
    const baseAPIUrl = REACT_APP_API_URL

    const res = await fetch(baseAPIUrl + "company/addcompany", {
             method:"POST",
             headers:{
                 "Content-Type":"application/json"
             },
             body:JSON.stringify(body)
    })
    return await res.json(); 
})


const CompanyAddReducer = createSlice({
    name:'company',
    initialState,
    reducers:{
        addCompany(state, action){
            state = action.payload
        }
      
    },
    extraReducers: (builder) => {
            builder
                  .addCase(companyThunk.pending, (state, action) => {
                     console.log('pending')
                  })
                  .addCase(companyThunk.fulfilled, (state, action) => {
                    console.log('fulfilled')
                    state = action.payload
                  })
                  .addCase(companyThunk.rejected, (state, action) => {
                    console.log('rejected')
                  }) 
     
 
    } 
})

const CompanyGetReducer = createSlice({
    name:'company',
    initialState,
    reducers:{
      getCompanyList(state, action){
            state = action.payload
        }
      
    },
    extraReducers: (builder) => {
            builder
                  .getCase(getCompanyThunk.pending, (state, action) => {
                     console.log('pending')
                  })
                  .getCase(getCompanyThunk.fulfilled, (state, action) => {
                    console.log('fulfilled')
                    state = action.payload
                  })
                  .getCase(getCompanyThunk.rejected, (state, action) => {
                    console.log('rejected')
                  }) 
     
 
    } 
})


export const {getCompanyList} =CompanyGetReducer.actions
//export default CompanyGetReducer.reducer
export const {addCompany} = CompanyAddReducer.actions
export default CompanyAddReducer.reducer 