import React,{useRef,useState,useEffect} from 'react';
import {InputText,Toast,confirmDialog,Dropdown as Select} from '../../PrimeReactComponents'
import {Link,useNavigate,useParams} from 'react-router-dom';
import {useFormik, Formik, Form, Field, FieldArray, ErrorMessage} from 'formik';
import {TruckValidation,initialState} from '../Validations/TruckValidation'
import Button from 'react-bootstrap/Button';
import { addData,editData,singleData } from '../controllers/TruckTypeController';
import Loader from '../../features/loader/Loader';


function AddTruckType() {
    const toast = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setloader] = useState();
  const [initStateTruck, setInitStateTruck] = useState(initialState)
  useEffect(() => {
    if (id) {
      const response = singleData(id);
      response.then(({ status, message, type, error, data }) => {
        if (status) {
          setInitStateTruck({
            truck_type:data.data.truck_type,
           truck_detail:data.data.truck_details,
          });
          setloader(false)
        } else {
          toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          setloader(false)
        }
      })
    }
  }, [id])

  const vichelTypeOption=[
    {  value :"1", label:"Truck"},
    { value :"2", label:"Trailer"}
 ]
    const postData = (values, { resetForm }) => {
        confirmDialog({
          message: 'Are you sure you want to proceed?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            setloader(true)
            if (id) {
              editData(values, id).then(({ status, type, message }) => {
                if (status) {
                  setloader(false)
                  resetForm({ values: '' })
                  toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });   
                  setTimeout(() => {
                    navigate('/platform/setup') 
                  }, 3000)                      
                } else {
                  setloader(false)
                  toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                }
              })
            } else {
              addData(values).then(({ status, type, message }) => {
                if (status) {
                  setloader(false)
                  resetForm({ values: '' })
                  toast.current.show({ severity:  'success', summary: 'Info Message', detail: message, life: 3000, });
                  setTimeout(() => {
                    navigate('/platform/setup') 
                  }, 3000) 
                } else {
                  setloader(false)
                  toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                }
              })
            }
          }
        });
      }


  return (
    <>
     <Loader show={loader} />
            <Toast ref={toast} position="top-center" />
          
    <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
                    <div className="container d-flex flex-column h-100 ">
                    <Formik enableReinitialize initialValues={initStateTruck} validationSchema={TruckValidation} onSubmit={postData} render={({ values, setFieldValue }) => (<>
                    <Form >
                        <div className="px-4 mt-3">
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                                    <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">{(id ==="" || id ===undefined) ? "Add New Vehicle Type" :"Edit Vehicle Type"}</h2>
                                </div>
                                <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                                    <Link to="../setup" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link>
                                    <Button type='submit' variant='' className="bg-green text-white btn fz14 "><i className="fa-regular fa-save"></i> Save</Button>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-auto flex-grow-1 mt-5 px-4">
                          
                            <div className="card p-4">
                                <div className="card-body">
                                <div className="row gx-5">
                        <div className="col-sm-6 mb-4">
                          <InputText className="form-control px-3" name="company_code" hidden />

                          <label htmlFor="" className="mb-2">Vehicle Type <span className="text-danger">*</span></label>
                          <Field name="truck_type" render={({ field }) =>
                           <InputText className="form-control px-3"{...field}
                         //  options={vichelTypeOption}
                          //onChange={(e) => getState(e, setFieldValue)}
                         // placeholder="Please select a type"
                           />} />
                          <ErrorMessage name="truck_type" component="span" className="from-error-span" />

                        </div>

                        <div className="col-sm-6 mb-4">
                          <InputText className="form-control px-3" name="company_code" hidden />
                          <label htmlFor="" className="mb-2">Vehicle Type Details <span className="text-danger">*</span></label>
                          <Field name="truck_detail" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="truck_detail" component="span" className="from-error-span" />
                        </div>
                      </div>
                                </div>
                            </div>
                            
                        </div>
                        </Form>
                        </>)} />
                    </div>
                </div>

            </div>
    </>
  )
}

export default AddTruckType