import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import Logo1 from '../../assets/img/logo-black.png'
import LogoIcon from '../../assets/img/logo-icon.png'
import { Toast } from 'primereact/toast';
import SidebarToggleButton from "../components/SidebarToggleButton";
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../store/userInfo'
import { getUserInfo, getAllRoleData, getAllUserRolePermissionData } from '../../services/commonController';
import { loginApiUrl, transportRefreshlogin } from "../../config/keyClockConfig";
import { getLoginData } from '../controllers/UserController'
import { setTransportLoginDetails } from "../../store/transportLoginSlice"
import SidebarComp from './sidebarComponent';
import { setRoleInfo } from "../../store/roleSlice";

function Sidebar() {


  
  const dispatch = useDispatch();
  // const userInfoSelector = useSelector(state => state.userInfo);
  const { userInfo, transportUserInfo, rolePermissionInfo } = useSelector(state => state);
  const { userRoleInfo } = useSelector(state => state);
  const toast = useRef(null);
  const navigate = useNavigate();
  const { transId, userId } = useParams();
  const location = useLocation();
  const [loader, setloader] = useState(false);
  let userModiFyedId = ''
  let transModiFyedId = ''
  let customerModifiedId = ''
  let tokenData = ''
  let tokenDataModifyed = ''
  let MenuArr = []
  let PermissionArr = []
  let RoleArr = []

  tokenData = localStorage.getItem('transportData');
  if(!localStorage.getItem('userId') || !localStorage.getItem('transId')||!localStorage.getItem('userId1') || tokenData ===null){
   // window.location.href = '/transport'
    navigate('/transport');
    } 
  
 // console.log("tokenDatatokenData..........",tokenData, typeof tokenData);
  tokenDataModifyed = tokenData !== "undefined" ? JSON.parse(tokenData) : {}
  let newTimeWithsession = localStorage.getItem("newTimeTransport")
  let newRefreshTime = localStorage.getItem("newRefreshTimeTransport")
  let tokenDataKey = localStorage.getItem('keyCloakrefreshData');
  var now = new Date();
  var isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
  var currentDate = now.getTime()

  // if(transId){
  // transModiFyedId = transId.split("=").pop()
  localStorage.setItem('transId', tokenDataModifyed?.company_id);

  // userModiFyedId = userId.split("=").pop()
  localStorage.setItem('userId1', tokenDataModifyed?.user_id);
  // }
  localStorage.setItem('userId', tokenDataModifyed?.relation_id);

  if(localStorage.getItem('userId1')){
  userModiFyedId = localStorage.getItem('userId1');
  }else{
    navigate('/transport');
  }
  if(localStorage.getItem('transId')){
  transModiFyedId = localStorage.getItem('transId');
  }else{
    navigate('/transport');
  }

   if(localStorage.getItem('userId')){
  customerModifiedId = localStorage.getItem('userId');
  }else{
    navigate('/transport');
  }

  const getUserInfoData = async () => {
    const results = await getUserInfo(transportUserInfo?.user_id, transportUserInfo?.company_id);
    localStorage.setItem('userId', results?.data?.resultData.relation_id);
    dispatch(setUserInfo(results?.data));
  }


  // Role & Permission Set
  const getUserRolePermissionData = async (company_id, role_id, user_id, user_type_id) => {

    let results = await getAllUserRolePermissionData({ company_id, role_id, user_id, user_type_id });
    if (results?.data) {
      localStorage.setItem("rolePermissionTransportInfo", JSON.stringify(results?.data))
    }
  }


  useEffect(() => {
    getUserInfoData()
    getUserRolePermissionData(tokenDataModifyed?.company_id, tokenDataModifyed?.roleId, tokenDataModifyed?.user_id, tokenDataModifyed?.user_typeId)
  }, [])
  useEffect(() => {
    refreshTokencheck()
  }, [location])

  let refreshTokencheck = async () => {
    //console.log("ascces token expait",currentDate > newTimeWithsession,"",parseInt(newRefreshTime) > currentDate ,"",newRefreshTime < currentDate);
    try {
      if (currentDate > newTimeWithsession) {
        // await toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Your session has been expired!!", life: 10000, });
        // localStorage.removeItem('transportData');
        // localStorage.removeItem('newTimeTransport');
        // localStorage.removeItem('newRefreshTimeTransport');
        // window.location.href = "/transport"
        if (parseInt(newRefreshTime) > currentDate) {

          var details = { ...transportRefreshlogin, refresh_token: tokenDataKey };
          var formBody = [];
          for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }

          formBody = formBody.join("&");
          try {
            setloader(true)
            let responsData = await fetch(loginApiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: formBody
            })

            responsData = await responsData.json()

            if (responsData?.error === "invalid_grant") {
              setloader(false)
              let now = new Date();
              let isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
              let newDateTransport = now.setSeconds(now.getSeconds() + responsData?.expires_in);
              let newrefresh = new Date();
              let isoDateRefresh = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
              let newRefreshDateTransport = newrefresh.setSeconds(now.getSeconds() + responsData?.refresh_expires_in);
              localStorage.setItem("keyCloakaccesstokenData", responsData?.access_token);
              localStorage.setItem("keyCloakrefreshData", responsData?.refresh_token);
              localStorage.setItem("newTimeTransport", newDateTransport);
              localStorage.setItem("newRefreshTimeTransport", newRefreshDateTransport);
              localStorage.setItem("keyCloakaccesstokenTransport", responsData?.access_token);
              getLoginData(responsData?.access_token, 1).then(data => {
                localStorage.setItem('tokenData', JSON.stringify(data?.data));
                dispatch(setTransportLoginDetails(data?.data))
                //console.log("this is olo0g....",data?.data);
                if (typeof data.data === 'object' && data.data !== null) {
                  //toast.current.show({severity:"success", summary: 'Info Message', detail: "Login Success" , life: 3000,});
                  // navigate('/transport/dashboard')
                  //  window.location.href='/transport/dashboard'  
                } else if (data?.data === 'Invalid User') {
                  toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Invalid User", life: 3000, });
                  window.location.href='/transport' 
                }
                else {
                  toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Some thing went worng", life: 3000, });
                  window.location.href='/transport'
                }

                setloader(false);
              })
            }
            if (responsData?.access_token) {
              let now = new Date();
              let isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
              let newDateTransport = now.setSeconds(now.getSeconds() + responsData?.expires_in);
              let newrefresh = new Date();
              let isoDateRefresh = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
              let newRefreshDateTransport = newrefresh.setSeconds(now.getSeconds() + responsData?.refresh_expires_in);
              localStorage.setItem("keyCloakaccesstokenData", responsData?.access_token);
              localStorage.setItem("keyCloakrefreshData", responsData?.refresh_token);
              localStorage.setItem("newTimeTransport", newDateTransport);
              localStorage.setItem("newRefreshTimeTransport", newRefreshDateTransport);
              localStorage.setItem("keyCloakaccesstokenTransport", responsData?.access_token);
              getLoginData(responsData?.access_token, 1).then(data => {
                localStorage.setItem('tokenData', JSON.stringify(data?.data));
                dispatch(setTransportLoginDetails(data?.data))
                //console.log("this is olo0g....",data?.data);
                if (typeof data.data === 'object' && data.data !== null) {
                  //toast.current.show({severity:"success", summary: 'Info Message', detail: "Login Success" , life: 3000,});
                  // navigate('/transport/dashboard')
                  //  window.location.href='/transport/dashboard'  
                } else if (data?.data === 'Invalid User') {
                  toast.current.show({ severity: "warn", summary: 'Info Message', detail:"Your session has been expired!!", life: 3000, });
                  window.location.href='/transport' 
                }
                else {
                  toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Your session has been expired!!", life: 3000, });
                  window.location.href='/transport'
                }

                setloader(false);
              })


            } else {
              setloader(false)
            }
          } catch (error) {
            setloader(false);
            console.log("this is error", error)
          }

        } else if (newRefreshTime < currentDate) {
          window.location.href = "/transport"
          localStorage.removeItem('transportData');
          localStorage.removeItem('newTimeTransport');
          localStorage.removeItem('newRefreshTimeTransport');
          localStorage.removeItem("keyCloakaccesstokenTransport");
          toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Your session has been expired!!", life: 3000, });
        }
      }
    } catch (error) {
      if (error) {
        console.log("error", error);
      }
    }
  }


  let rolePermissionInfoData = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))
  return (
    <>
      <Toast ref={toast} />
      <aside className="aside-bar main-nav" id="menuBar-toggle">
        <div className="position-relative sidebar-logo">

          <NavLink to="dashboard">
            <div className="logo py-sm-2 px-4 d-flex justify-content-between align-items-center">
              <img src={Logo1} className="lg-logo img-fluid" alt="" style={{ maxWidth: "156px" }} />
              <img src={LogoIcon} className="sm-logo img-fluid m-0" alt="" />
              <SidebarToggleButton />
            </div>
          </NavLink>
        </div>

        <SidebarComp allUserRolePermissionData={rolePermissionInfoData} />

        <ul className="h-100">
          <li>
            <NavLink to="fault-reports" activeclassname="active">
              <i className="fa-light fa-briefcase"></i> <span> Fault Report </span>
            </NavLink>
          </li>
        </ul>

        {/*   <ul className="h-100">
          <li>
            <NavLink to="dashboard" activeclassname="active">
              <i className="fa-light fa-gauge-high"></i> <span> Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="customers" activeclassname="active">
              <i className="fa-light fa-circle-user"></i> <span> Customers</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="consignments" activeclassname="active">
              <i className="fa-light fa-briefcase"></i> <span> Consignments</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="usermanagment" activeclassname="active">
              <i className="fa-light fa-users"></i> <span> User Management</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="drivers" activeclassname="active">
              <i className="fa-light fa-steering-wheel"></i> <span> Drivers</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="timesheet" activeclassname="active">
              <i className="fa-light fa-user-clock"></i> <span> Timesheet</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="doc-manager" activeclassname="active">
              <i className="fa-light fa-folders"></i> <span> Doc Manager</span>
            </NavLink>
          </li> */}
        {/* <li>
            <NavLink to="invoicing" activeclassname="active">
              <i className="fa-light fa-file-invoice"></i> <span> Invoicing</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="payroll" activeclassname="active">
              <i className="fa-light fa-credit-card"></i> <span> Payroll</span>
            </NavLink>
          </li> */}
        {/*   <li>
            <NavLink to="vehicle" activeclassname="active">
              <i className="fa-light fa-credit-card"></i> <span> Vehicle</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="setup" activeclassname="active">
              <i className="fa-light fa-cog"></i> <span> Setup</span>
            </NavLink>

          </li>
        </ul>  */}
      </aside>
    </>
  );
}

export default Sidebar;
