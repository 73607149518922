import * as yup from 'yup'

const initialState={
    document_type: "" 
}

const documentTypeValidation = yup.object().shape({
    document_type:yup.string().required('This Field is required')
})


const conFilterInitialState = {
    document_type: '' 
}

const conFilterSchema = yup.object().shape({
  //  document_type: yup.string().min(2, 'This Field must be at least 2 characters').max(30, 'This Field must be at most 30 characters').required('This Field is required'),
})


export {documentTypeValidation,initialState,conFilterInitialState,conFilterSchema };