import {createSlice} from '@reduxjs/toolkit';



const commonSlice = createSlice({
    name:'commonSlice',
    initialState: {
        customerAddressBookModal : false,
    },
    reducers:{       
        customerAddressBookModalToggleHandler(state, action){           
            state.customerAddressBookModal = !state.customerAddressBookModal;
        },
    }
})

export  const {customerAddressBookModalToggleHandler} = commonSlice.actions;
export default commonSlice.reducer;