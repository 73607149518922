import React, { useEffect, useState, useRef } from "react";
import { Link,useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { Dropdown, Button,Modal } from "../../BootstrapComponents";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import FilterOffset from "./components/OffsetRight";
import dataTableConfig from "../../config/dataTableOptions";
import { listDataManifest, activeInactiveMinifestBulk, removeDataMenifestBulk } from "../controllers/ConsignmentController";
import Loader from "../../features/loader/Loader";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
 import PrintListDetail from "./PrintListDetail";

function List(props) {
  const toast = useRef(null);
  const [tableHeight, setTableHeight] = React.useState("0px");
  const navigate = useNavigate();
  const reciveLocalData = localStorage.getItem('transId')
  const [selectedUser, setSelectedUser] = useState();
  const [initialState, setInitialState] = useState({});
  const [palletsData,setpalletsData]=useState();
  const [spacesData,setspacesData]=useState();
  const [dispatchDate,setdispatchDate]=useState();
  const [customers, setCustomers] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loader, setloader] = useState(false);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [viewPage, setViewPage]=useState(false)
  const [newModalShowEmail, setNewModalShowEmail] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [newModalShow, setNewModalShow] = useState(false);
  const [reciveId,setReciveId] =useState()
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    globalFilterValue: "",
    manifest_number: "",
    company_id: reciveLocalData,
    filters: {
      'company_code': { value: '', matchMode: 'contains' },
      'company_name': { value: '', matchMode: 'contains' },
      'company': { value: '', matchMode: 'contains' },
      'representative.name': { value: '', matchMode: 'contains' },
    }
  });


  const customerService = listDataManifest();

  let loadLazyTimeout = null;
  useEffect(() => {
    
    loadLazyData();
  }, [lazyParams])
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      listDataManifest({ lazyEvent: (lazyParams) }).then(data => {
        if (data?.data !== undefined) {
          setTotalRecords(data.data.data.count);
          setCustomers(data.data.data.rows);

        }
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 50 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    // console.log("this is params log......!!!",lazyParams);
    setLazyParams({
      ...lazyParams,
      //page:event.pageCount,
      pageCount: event.pageCount,
      first: event.first,
      // page:event.page===0 ?1:event.page ===1?2:event.page,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
      sortOrder: lazyParams.sortOrder,
      // sortOrder: event.sortOrder === "desc" ? "asc" : event.sortOrder === "asc" ? "desc":"desc",
    }, []);
  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
      listDataManifest({ lazyEvent: (lazyParams) }).then(data => {
        // if (data?.data !== undefined) {
          setTotalRecords(data?.count);
          setCustomers(data?.rows);
      })
       // }
     
    } else if (value.length === 0) {
      loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });

    }
  }

  const advanceSearch = (value) => {

    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.title, manifest_number: value.manifestNo });
      setoffsetFilter(true);
      listDataManifest({ lazyEvent: (lazyParams) }).then(data => {
        // if (data?.data !== undefined) {
          setTotalRecords(data?.count);
          setCustomers(data?.rows);
      })
    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2">
          <Link to={`./view/${rowData.manifest_id}`} className="btn p-0 fz16"><i className="fa-light fa-eye"></i></Link>
          <Button variant="" className="btn fz16 p-0" onClick={()=>newUserShowPopup(rowData.manifest_id)} ><i className="fa-light fa-print" title="Print"></i></Button>
          {/* <Button variant="" className="btn fz16 p-0"><i className="fa-light fa-envelope" title="Send Email"></i></Button> */}
          {/* { viewPage === true && <PrintListDetail downloadPdf={downloadPdf(rowData?.manifest_id)}/> } */}
        </div>
      </>
    );
  };

  const qlityTemplate = (rowData) => {

    const pallets = rowData.manifest_detail.reduce((acc, curre) => {
      return Number(acc) + Number(curre.pallets)
    }, 0);

    const spaces = rowData.manifest_detail.reduce((acc, curre) => {
      return Number(acc) + Number(curre.spaces)
    }, 0);

    return pallets + ' Pallets/' + spaces + ' spaces';

  };




  const dateBodyTemplate = (rowData) => {
    const now = new Date(rowData?.createdAt);
    return dateFormat(now, "dd mmmm, yyyy");
  }
  const disapatchTemplate = (rowData) => {
    const now = new Date(rowData?.dispatch_date);
    return dateFormat(now, "dd mmmm, yyyy");
  }

  const activeInactiveStatusBulk = (status, id) => {
    setloader(true)
    activeInactiveMinifestBulk(status, id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedUser([])
      loadLazyData();
      setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }


  const bulkActivate = () => {
    let selectedId = [];
    selectedUser && selectedUser.length > 0 && selectedUser.map((item, index) => {
      return selectedId.push(item.user_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }
  const bulkDeActivate = () => {
    let selectedId = [];
    selectedUser && selectedUser.length > 0 && selectedUser.map((item, index) => {
      return selectedId.push(item.user_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Deactivate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk('0', selectedId);
      }
    });
  }

  const DeleteBulk = (id) => {
    setloader(true)
    removeDataMenifestBulk(id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedUser([])
     // loadLazyData();
      //setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
  //  })
    loadLazyData();
    setloader(false)
    setTimeout(() => {
      navigate('/transport/consignments/manifest')
    }, 3000)
  })

  }
  const bulkDelete = () => {
    let selectedId = [];
    selectedUser && selectedUser.length > 0 && selectedUser.map((item, index) => {
      return selectedId.push(item.manifest_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }


  function newUserShowPopup(id) {
    setNewModalShowEmail(true);
   setReciveId(id)
  }

  const downloadPdf = (rowData) => {

    const input =  document.getElementById("pdfpdfpdf")
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.setFontSize(30);
        pdf.addImage(imgData, "PNG", 15, 30, 180, 200);
        pdf.save(`downloaded.pdf`);
      })
  }
  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0"><i className="fa-light fa-briefcase"></i> Manifest List</h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to="add" className="bg-green text-white btn fz14 me-3"><i className="fa-light fa-plus-circle"></i> Create  Manifest</Link>
                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul>
                      <li>
                        <Button className="dropdown-item" to="#" onClick={() => bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Button>
                      </li>
                     
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>


              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={customers} selectionMode="checkbox" lazy dataKey="manifest_id"
              scrollable=" true" responsiveLayout="scroll"
              {...{ scrollHeight: tableHeight }}
              scrollDirection="both" {...dataTableConfig}
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedUser} onSelectionChange={e => setSelectedUser(e.value)}
            >
              <Column headerStyle={{ width: "3em" }} selectionMode="multiple"></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ width: "100px" }}></Column>
              <Column field="title" header="Title" sortable style={{ flexGrow: 1, width: "100px" }}></Column>
              <Column field="manifest_number" header="Manifest No." sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="carrier" header="Carrier" sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="phone" header="Phone" sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="qty" header="Qty." body={qlityTemplate} style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="pallet_dkt" header="Pallet DKT" style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="dispatch_date" header="Dispatch Date" body={disapatchTemplate} sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="createdAt" header="Created On" body={dateBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
            </DataTable>
          </div>
          <Modal scrollable show={newModalShowEmail} onHide={() => setNewModalShow(false)} size="xl" centered>
              <Modal.Header closeButton={false}>
                {/* <Modal.Title>New User </Modal.Title> */}
              
                <Button className="btn-close ms-21 "  onClick={() => setNewModalShowEmail(false)} variant=""></Button>
              </Modal.Header>
              <Modal.Body>
                {/* <Formik  onSubmit={(value) => {
                  confirmDialog({
                    message: 'Are you sure you want to proceed?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                      //addNewUser(value)  
                      console.log("this is log...");
                    }
                  });
                }} > */}
<PrintListDetail reciveId={reciveId} setNewModalShowEmail={setNewModalShowEmail}  />
                  
                {/* </Formik> */}
              </Modal.Body>
            </Modal>
        </div>
      </div>
    </>
  );
}

export default List;
