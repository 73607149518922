import React from "react";
import ReactDOM from "react-dom/client";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/saga-blue/theme.css";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fontawesome-pro-6/css/all.min.css";
import App from "./App";
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from "react-redux";
import store from './store/store'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <Provider store={store}>
         <App />
      </Provider>
   </React.StrictMode>
);



