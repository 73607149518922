
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REACT_APP_API_URL } from '../../../config'


export const consignmentAllThunk = createAsyncThunk('all-consignment', async () => {
  const baseAPIUrl = REACT_APP_API_URL

  const res = await fetch(baseAPIUrl + "consignments/getAllConsignments")
  return await res.json();
})

const ConsignmentAllReducer = createSlice({
  name: 'fetchConsignment',
  initialState:{
    consignments:[]
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(consignmentAllThunk.pending, (state, action) => {
        console.log('pending')
      })
      .addCase(consignmentAllThunk.fulfilled, (state, { payload: { data } }) => {
        console.log('fulfilled', data?.rows)
        if (data?.rows?.length) {
          // state.push(data.rows[0])

          // state.consignments.push(data.rows[0])

           state.consignments = [data.rows[0]]
        } 

      })
      .addCase(consignmentAllThunk.rejected, (state, action) => {
        console.log('rejected')
      })
  }
})

export default ConsignmentAllReducer.reducer