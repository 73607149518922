import React, { useRef, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { InputText, Dropdown as Select, InputMask, InputNumber, InputTextarea } from "../../PrimeReactComponents";
import { singleData } from "../controllers/customerController";
import { getAllCountryState } from '../../services/commonController';
import Loader from "../../features/loader/Loader";
import { REACT_APP_BASE_BACKEND_URL } from '../../config'

let BASE_BACKEND_URL = REACT_APP_BASE_BACKEND_URL


function View() {
    const { transportUserInfo } = useSelector(state => state);
    let company_id = transportUserInfo.company_id
    const toast = useRef(null);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [tableHeight, setTableHeight] = React.useState("0px");

    const [customersData, setCustomersData] = useState()
    const [country, setCountry] = useState();
    const [states, setStates] = useState();
    const [countrystate, setCountrystate] = useState();
    const pathname = window.location.pathname

    const { id } = useParams();

    let customerArr = []
    let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

    transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Customers')?.map(val => {
        if (!customerArr.includes(val?.menuActionData?.action_name)) {
            customerArr.push(val?.menuActionData?.action_name)
        }
    })

    if (pathname.split('/')[3] === 'view') {
        if (!customerArr.includes('View')) {
            navigate('/transport/404');
        }
    }


    const getAllCountries = async () => {
        const results = await getAllCountryState();
        const result_data = await results?.data?.map(val => ({ value: val.id, label: val.country_name }))
        setCountrystate(results)
        setCountry(result_data)
    }


    const getSingleData = async () => {
        setLoader(true)
        const list = await singleData(id);
        if (list) {
            setCustomersData(list.data?.data);
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        getSingleData()
        getAllCountries()
    }, [id]);

    const getCountryName = (id) => {
        const country_result = country && country.filter((e) => (e.value === id))
        return country_result?.[0]?.label
    }

    const getStateName = (country_id, state_id) => {
        const country_data = countrystate
        const country_result = country_data?.data && country_data?.data.filter((e) => (e.id === country_id))
        const state_result = country_result?.[0]?.states && country_result?.[0]?.states.filter((e) => (e.id === state_id))
        return state_result?.[0]?.state_name
    }

    let loadImage = function (val) {

        var image = new Image();
        var url_image = BASE_BACKEND_URL + val?.document_name;
        image.src = url_image;

        if (image.width == 0) {
            if (val?.document_name?.split('.')?.pop() === 'pdf') {
                return val?.document_name !== null && val?.document_name !== undefined && (((val?.document_name?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${val?.document_name}`} target="_blank">View <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a> : <a href={`${BASE_BACKEND_URL}${val?.document_name}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${val?.document_name}`} width="100" /></a>)
            } else {
                return <img src={`${BASE_BACKEND_URL}` + 'default_image.jpg'} width="100" />;
            }
        } else {
            return val?.document_name !== null && val?.document_name !== undefined && (((val?.document_name?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${val?.document_name}`} target="_blank">View <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a> : <a href={`${BASE_BACKEND_URL}${val?.document_name}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${val?.document_name}`} width="100" /></a>)

        }
    }

    return (
        <>
            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
                    <div className="container d-flex flex-column h-100 ">
                        <div className="px-4 mt-3">
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                                    <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">View Customer</h2> 
                                </div>
                            </div>
                        </div>
                        <span className="text-end"><Link to="../" className="btn btn-gradient"><i className="fa-light fa-times"></i> Cancel</Link></span>
                        <div className="overflow-auto flex-grow-1 mt-5 px-4">
                            <div className="card p-4">
                                <div className="card-body">
                                    <div className="row gx-5">
                                        <input type="text" className="form-control px-3" name="company_code" hidden />
                                        <div className="col-12 mb-3 justify-content-between d-flex">
                                            <h4 className=" fw600 fz16 text-dark">Customer</h4>
                                            <Link to={`../address-book/${id}/${company_id}`} className="btn text-dark btn-gradient shadow-none ms-3" ><i className="fa-regular fa-map"></i> Address Book</Link>
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Customer Number </label>
                                            <InputNumber format={false} defaultValue="1154987" disabled className="w-100" placeholder="Your Customer Number" inputClassName="form-control px-3" value={customersData?.customer_number} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Company Name </label>
                                            <InputText className="form-control px-3" name="customer_company_name" disabled placeholder="Company Name" value={customersData?.customer_company_name} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Contact Person First Name</label>
                                            <InputText className="form-control px-3" disabled name="contact_person_first_name" placeholder="First Name" value={customersData?.first_name} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Contact Person Last Name</label>
                                            <InputText className="form-control px-3" name="contact_person_last_name" disabled placeholder="Last Name" value={customersData?.last_name} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Email Address </label>
                                            <InputText className="form-control px-3" name="email" disabled placeholder="Username / Email" value={customersData?.email_id} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Phone</label>
                                            <InputText value={customersData?.phone} className="form-control px-3" disabled ></InputText>
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Mobile</label>
                                            <InputText className="form-control px-3" disabled value={customersData?.mobile} />
                                        </div>
                                        <div className="col-12 mt-4 mb-3">
                                            <h4 className=" fw600 fz16 text-dark"> Billing Address Details </h4>
                                            <div className="overflow-auto mt-3 ">
                                                <table className="table  multiple-feilds-table" style={{ minWidth: "1200px" }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="fw500 fz14" width="30px">#</th>
                                                            <th className="fw500 fz14">Customer Name</th>
                                                            <th className="fw500 fz14">Address</th>
                                                            <th className="fw500 fz14">Suburb</th>
                                                            <th className="fw500 fz14">Zip Code</th>
                                                            <th className="fw500 fz14">Country</th>
                                                            <th className="fw500 fz14" width="190px">State</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            customersData?.customer_billing_address_details && customersData.customer_billing_address_details.map((val, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className="dynamicText">{index + 1}</td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.customer_name} />  </td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.address} />  </td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.suburb} />  </td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.zip_code} />      </td>
                                                                        <td>
                                                                            <InputText name="name" className="form-control px-3" disabled value={getCountryName(val?.country_id)} />   </td>
                                                                        <td>
                                                                            <InputText name="name" className="form-control px-3" disabled value={getStateName(val?.country_id, val?.state_id)} />
                                                                        </td>

                                                                    </tr>

                                                                </>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4 mb-3">
                                            <h4 className=" fw600 fz16 text-dark"> Documents</h4>
                                            <div className="overflow-auto mt-3 ">
                                                <table className="table  multiple-feilds-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw500 fz14" width="30px">#</th>
                                                            <th className="fw500 fz14">Upload</th>
                                                            <th className="fw500 fz14" width="30px"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            customersData?.customer_document_details && customersData.customer_document_details.map((val, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className="dynamicText">{index + 1}</td>
                                                                        <td>
                                                                            {/* {(val?.document_name?.split('.')?.pop() === 'pdf') && <a href={`${BASE_BACKEND_URL}${val?.document_name}`} target="_blank">View <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a>}

                                                                            {(val?.document_name?.split('.')?.pop() !== 'pdf') && <img src={`${BASE_BACKEND_URL}${val?.document_name}`} width="100" />} */}

                                                                            {loadImage(val)}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4 mb-3">
                                            <h4 className=" fw600 fz16 text-dark"> Multiple Notes</h4>
                                            <div className="overflow-auto mt-3 ">
                                                <table className="table  multiple-feilds-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw500 fz14" width="30px">#</th>
                                                            <th className="fw500 fz14">Notes</th>
                                                            <th className="fw500 fz14" width="30px"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            customersData?.customer_notes_details && customersData.customer_notes_details.map((val, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className="dynamicText">{index + 1}</td>
                                                                        <td><InputTextarea autoResize className="form-control px-3" rows={5} disabled value={val?.notes_content} /></td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default View;
