import React,{useState, useEffect,useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "../assets/css/style.css";
import "../assets/css/media.css";
import Sidebar from "./includes/Sidebar";
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import { useSelector } from "react-redux";
import {ConfirmDialog, ConfirmPopup } from '../PrimeReactComponents';
//import KeycloakAuthentication from "../middleware/keycloakauthentication";
import Loader from "../features/loader/Loader";
//import {getLoginData} from "./../customer-portal/controllers/UserController";

function Layout() {
  const navToggleRedux = useSelector((state) => state.sidebar_toggle);
  const userInfo = useSelector((state) => state.userInfo);
  // console.log("userInfo",userInfo);
let tokenData =null
let tokenDataModifyed=null

//  useEffect(()=>{
   
  
//  },[])
 tokenData = localStorage.getItem('keyCloakaccesstokenData');
try {
  let decoded = jwt_decode(tokenData);
 
} catch (error) {
  if(error.message){
    window.location.href="../customer"
    return;
  }
}

  return (
    <>  
     <ConfirmDialog />
     <ConfirmPopup />   
      <div className={`template-default customer-admin  ${navToggleRedux ? "menu-closed" : ""}`} id="template-default-page">
        <Sidebar />
        <div className="fixed-header-content">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </div>
       
    </>
 
  );
 
}

export default Layout;
