import {createSlice} from '@reduxjs/toolkit';

let initial = {}
if(localStorage.getItem("customerData") !== 'null'){
    if(localStorage.getItem("customerData") !== 'undefined'){
        initial = JSON.parse(localStorage.getItem("customerData"))
    }
}

const customerloginSlice = createSlice({
    name:'sidebar_toggle',
    initialState:initial,
    reducers:{       
        setCustomerLoginDetails(state, action){    
            localStorage.setItem('customerData',JSON.stringify(action.payload))       
            return  state = action.payload;
        }

    }
})

export  const {setCustomerLoginDetails} = customerloginSlice.actions;
export default customerloginSlice.reducer;