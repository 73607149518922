import {createSlice} from '@reduxjs/toolkit';

const roleSlice = createSlice({
    name:'roleSlice',
    initialState: {},
    reducers:{       
        setRoleInfo(state, {payload}){                 
            return  state = payload;
        }

    }
})

export  const {setRoleInfo} = roleSlice.actions;
export default roleSlice.reducer;
 