import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputText, Dropdown as Select, Calendar, confirmDialog, Toast } from "../../PrimeReactComponents";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Button } from "../../BootstrapComponents";
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileInitialState, UserProfileValidateSchema } from './Validatation/ProfileValidation'
import { updateProfileData } from './Controllers/ProfileController';
import Loader from "../../features/loader/Loader";
// import { getUserInfo, uploadDocument } from '../../services/commonController';
import { getUserInfo, uploadDocument } from '../controllers/ConsignmentController';
import { setUserInfo } from '../../store/userInfo'
import { REACT_APP_BASE_BACKEND_URL } from '../../config'
let BASE_BACKEND_URL = REACT_APP_BASE_BACKEND_URL



const Profile = () => {
    const { customerUserInfo } = useSelector(state => state);

    const navigate = useNavigate();
    const toast = useRef(null);
    const [loader, setLoader] = useState();
    const [checkStatus, setCheckStatus] = useState(false)

    const companyId = customerUserInfo.company_id;
    const userId = customerUserInfo.user_id;
    const [filename, setFilename] = useState();

    const [initialState, setInitState] = useState(UserProfileInitialState)
    const dispatch = useDispatch();


    const getUserInfoData = async () => {
        const results = await getUserInfo(userId, companyId);

        setInitState({
            first_name: results?.data?.resultData?.userData?.first_name,
            last_name: results?.data?.resultData?.userData?.last_name,
            email_id: results?.data?.resultData?.userData?.email_id,
            user_image: results?.data?.resultData?.userData?.user_image
        })
        dispatch(setUserInfo(results.data));
    }

    useEffect(() => {
        getUserInfoData();
    }, [userId, companyId, checkStatus])


    const customerUserSubmit = async (values, { resetForm }) => {
        setCheckStatus(false); 

        const headers = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };

       const formData = new FormData();
        formData.append('first_name', values?.first_name);
        formData.append('last_name', values?.last_name);
        formData.append('email_id', values?.email_id);
        formData.append('user_id', userId); 
        formData.append('user_image', filename); 

        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setLoader(true)
                updateProfileData(formData, headers).then(({ status, type, message }) => {
                    if (status) {
                        setLoader(false)
                        resetForm({ all_values: '' })
                        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                        setCheckStatus(true);
                        setTimeout(() => {
                            navigate('/customer/profile')
                        }, 2000)


                    } else {
                        setLoader(false)
                        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                    }
                })
            }
        });
    }

    const handleOnChange = async (event) => {

        if (event.target.files[0].name !== null) {
            let image_name = event.target.files[0]

            if (image_name && (image_name.type === 'image/jpg' || image_name.type === 'image/jpeg' || image_name.type === 'image/png')) {
                if (image_name !== undefined) {
                    if (image_name !== undefined) {
                        const formData = new FormData();
                        formData.append(`user_image`, image_name)
              
                        setFilename(image_name)
                    }
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'Please select a valid JPG, JPEG or PNG image.', life: 3000, });
            }
        }
    };

    let loadImage = function (values) {
 
        var image = new Image();
        var url_image = BASE_BACKEND_URL  + values?.user_image;
        image.src = url_image;  
        
        if (image.width == 0) {
          return <img src={`${BASE_BACKEND_URL}`+'default_image.jpg'} width="100" />;
        } else { 
          return values?.user_image && <img src={`${BASE_BACKEND_URL}${values?.user_image}`} width="100" />
        }
      }

    return (
        <>
            <Loader show={loader} />
            <Toast ref={toast} position="top-center" />
            <Formik enableReinitialize initialValues={initialState} validationSchema={UserProfileValidateSchema} onSubmit={customerUserSubmit}>
                {
                    ({ values, setFieldValue }) => (
                        <Form className="d-flex flex-column overflow-auto" encType="multipart/form-data">
                            <div>
                                <div className="tab-pane fade active show pt-5" id="tab-Profile">
                                    <div className="card p-4 mt-5">
                                        <div className="card-body">
                                            <div className="row gx-5">


                                                <div className="col-sm-6 mb-4">
                                                    <label htmlFor="" className="mb-2">First Name <span className="text-danger">*</span></label>
                                                    <Field name="first_name" render={({ field }) => <InputText className="form-control px-3"   {...field} />} maxLength="50" />
                                                    <ErrorMessage name="first_name" component="span" className="from-error-span" />
                                                </div>

                                                <div className="col-sm-6 mb-4">
                                                    <label htmlFor="" className="mb-2">Last Name <span className="text-danger">*</span></label>
                                                    <Field name="last_name" render={({ field }) => <InputText className="form-control px-3"   {...field} />} maxLength="50" />
                                                    <ErrorMessage name="last_name" component="span" className="from-error-span" />
                                                </div>

                                                <div className="col-sm-6 mb-4">
                                                    <label htmlFor="" className="mb-2">Email <span className="text-danger">*</span></label>
                                                    <Field name="email_id" render={({ field }) => <InputText className="form-control px-3"   {...field} disabled />} maxLength="50" />
                                                    <ErrorMessage name="email_id" component="span" className="from-error-span" />
                                                </div>

                                                <div className="col-sm-6 mb-4">
                                                    <label htmlFor="" className="mb-2">User Image </label>
                                                    <Field name="user_image" render={({ field }) => <InputText type="file" className="form-control px-3" onChange={(e) => { handleOnChange(e) }} />} />

                                                    {/* {values?.user_image && <img src={`${BASE_BACKEND_URL}${values?.user_image}`} width="100" />} */}
                                                    {loadImage(values)}
                                                </div>

                                            </div>
                                            <div className="text-end">
                                                <Button type="submit" variant="" className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save Changes</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    )
                }
            </Formik>
        </>
    )
}

export default Profile
