import * as yup from 'yup';

const UserProfileInitialState = {
    first_name: '',
    last_name: '',
    email_id: '',
    user_image:''
};

const UserProfileValidateSchema = yup.object().shape({
    first_name: yup.string().required('This Field is required'),
    last_name: yup.string().required('This Field is required'),
    email_id: yup.string().email('This Field must be a valid email').required('This Field is required'),
})

export { UserProfileValidateSchema, UserProfileInitialState }


