import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Table } from "../../BootstrapComponents";
import { singleData } from "../controllers/ConsignmentController";
import { useSelector } from 'react-redux';
import Logo1 from '../../assets/img/logo-black.png'
import LogoIcon from '../../assets/img/logo-icon.png'
//import View from "./List";
import List from "./List";
import Moment from 'react-moment';
import { getCountry, getState, getAllCustomer, getAllDriver, getAllCustomerAddress, getAllTruckType, getSingleCustomerAddressBook, getAllGeoLocationData } from '../../services/commonController';

function View(props) {
    const [initialState, setInitialState] = useState({});
    const [loader, setloader] = useState();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [printData, setprintData] = useState(false)
    const [country, setCountry] = useState();
    const [deliveryCountry, setDeliveryCountry] = useState();
    const [allCustomer, setAllCustomer] = useState();
    const [pickupCustomerAddress, setPickupCustomerAddress] = useState();
    const [deliveryCustomerAddress, setDeliveryCustomerAddress] = useState();
    const [allGeoLocationData, setAllGeoLocationData] = useState();
    const pathname = window.location.pathname

    const userInfoSelector = useSelector(state => state.userInfo);

    //Role & Permission Set 
    let customerConsignmentArr = []
    let customerPermission = JSON.parse(localStorage.getItem('rolePermissionCustomerInfo'))

    customerPermission.length > 0 && customerPermission.filter(e => e.menuData?.menu_name === 'Consignments')?.map(val => {
        if (!customerConsignmentArr.includes(val?.menuActionData?.action_name)) {
            customerConsignmentArr.push(val?.menuActionData?.action_name)
        }
    })


    if (pathname.split('/')[3] === 'view') {
        if (!customerConsignmentArr.includes('View')) {
            navigate('/customer/404');
        }
    }


    const getAllCountries = async () => {
        const results = await getCountry();
        setCountry(results.data)
    }

    const getDeliveryAllCountries = async () => {
        const results = await getCountry();
        setDeliveryCountry(results.data)
    }

    const getAllCustomerData = async () => {
        const results = await getAllCustomer();
        setAllCustomer(results.data)
    }

    const getPickupAddressBook = async (companyId, Id) => {
        const results = await getSingleCustomerAddressBook(companyId, Id);
        setPickupCustomerAddress(results.data)
    }

    const getDeliveryAddressBook = async (companyId, Id) => {
        const results = await getSingleCustomerAddressBook(companyId, Id);
        setDeliveryCustomerAddress(results.data)
    }

    const getGeoLocation = async (consignmentId, driverId) => {
        let data = { consignment_id:consignmentId, driver_id:driverId }
        const results = await getAllGeoLocationData(data);
        setAllGeoLocationData(results.data)
    }

    const { id } = useParams();
    useEffect(() => {
        if (id) {
            const response = singleData(id);
            response.then(({ status, message, type, error, data }) => {
                if (status) {
                    setInitialState({
                        ...data.data
                    })

                    getAllCountries()
                    getDeliveryAllCountries()
                    getAllCustomerData()

                    getPickupAddressBook(data?.data?.company_id, data?.data?.pickup_customer_id)
                    getDeliveryAddressBook(data?.data?.company_id, data?.data?.delivery_name)
                    getGeoLocation(data?.data?.consignment_id, data?.data?.driver_id)

                    setloader(false)

                } else {
                    toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                    setloader(false)
                }
            })
        }

    }, [id])

    const downloadPdf = () => {
        const input = document.getElementById("downloadPdf");
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.setFontSize(40);
                pdf.addImage(imgData, "PNG", 15, 30, 180, 200);
                pdf.save(`consignment.pdf`);
            })
    }

    let country_name = country && country.filter(id => id.id === initialState?.country_id)[0]?.country_name
    let delivery_country_name = deliveryCountry && deliveryCountry.filter(id => id.id === initialState?.delivery_country_id)[0]?.country_name

    let pickup_customer_name = pickupCustomerAddress?.customer_company_name
    let delivery_customer_name = deliveryCustomerAddress?.customer_company_name

    return (
        <>
            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-auto flex-grow-1  px-5">
                    <div id="editor"></div>
                    <div className="d-flex justify-content-end gap-3 my-5">
                        <Link to="../" className="btn btn-gradient"><i className="fa-light fa-times"></i> Cancel</Link>
                        <button className="btn bg-green text-white" id="exportForm" onClick={() => { downloadPdf() }}><i className="fa-light fa-print"></i> Print</button>
                        <button className="btn bg-green text-white" id="exportForm"><i className="fa-light fa-envelope"></i> Send To Email</button>
                    </div>

                    <div className="invoice-wrapper shadow bg-white mt-0 mb-5 py-5" >
                        <span id="downloadPdf" >
                            <div>
                                <div className="mb-5 border p-4" style={{ maxWidth: "200px" }}>
                                    <img src={Logo1} className="lg-logo img-fluid" alt="" style={{ maxWidth: "156px" }} />
                                    <img src={LogoIcon} className="sm-logo img-fluid m-0" alt="" />

                                </div>
                                <div className="row pb-lg-5 mt-4">
                                    <div className="col-6">
                                        <div className="from-invoice d-flex justify-content-between">
                                            <h4 style={{ width: "80px" }} className="fz20">From</h4>
                                            <div className="flex-grow-1 ps-5 border-start">
                                                <div><h3>{pickup_customer_name}</h3></div>
                                                <div>{initialState?.customer_address}</div>
                                                <div>{initialState?.suburb}, {country_name}, {initialState?.zip_code} </div>
                                            </div>
                                        </div>
                                        <div className="from-invoice d-flex justify-content-between align-items-center mt-5">
                                            <h4 style={{ width: "80px" }} className="fz20">To</h4>
                                            <div className="flex-grow-1 ps-5 border-start">
                                                <div><h3>{delivery_customer_name}</h3></div>
                                                <div>{initialState?.delivery_address}</div>
                                                <div>{initialState?.delivery_suburb}, {delivery_country_name}, {initialState?.delivery_zip_code}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 ">
                                        <div className="row  align-items-center">
                                            <div className="col-8  offset-2">
                                                <h3 className="fz18 text-end mb-0"> {userInfoSelector?.companyData?.company_name?.charAt(0)?.toUpperCase() + userInfoSelector?.companyData?.company_name?.slice(1)}  </h3>
                                            </div>

                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-8 text-end">Booked Date</div>
                                            <div className="col-4">
                                                <Moment format="D MMM YYYY"  >{initialState?.booked_date}</Moment>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-8 text-end">Pickup Date</div>
                                            <div className="col-4"> <Moment format="D MMM YYYY"  >{initialState?.pickup_date}</Moment></div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-8 text-end">Manifest #</div>
                                            <div className="col-4">{initialState?.manifest_number}</div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-8 text-end">Consignment #</div>
                                            <div className="col-4"> {initialState?.job_number}</div>
                                        </div>
                                    </div>
                                </div>

                                <Table className="table mt-5" >
                                    <thead className="border-bottom">
                                        <tr>
                                            <th width="150px">Senders Reference</th>
                                            <th className="text-center">Items</th>
                                            <th>Freight Description</th>
                                            <th className="text-center"> Pallets</th>
                                            <th className="text-center">Spaces</th>
                                            <th className="text-center">Weight(KG)</th>
                                            <th className="text-center" width="100px">Job Temp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {initialState?.consignment_details && initialState?.consignment_details.map((val, index) => (
                                            <tr>
                                                <td>{val?.senders_no}</td>
                                                <td className="text-center">{val?.no_of_items}</td>
                                                <td>{val?.freight_desc}</td>
                                                <td className="text-center">{val?.pallets}</td>
                                                <td className="text-center">{val?.spaces}</td>
                                                <td className="text-center">{val?.weight}</td>
                                                <td className="text-center">{val?.job_temp}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <th>Totals</th>
                                            <th className="text-center">{initialState?.total_items}</th>
                                            <th></th>
                                            <th className="text-center">{initialState?.total_pallets}</th>
                                            <th className="text-center">{initialState?.total_spaces}</th>
                                            <th className="text-center">{initialState?.total_weight}</th>
                                            <th className="text-center"></th>
                                        </tr>
                                    </tbody>
                                </Table>

                                <Table className="ms-auto mt-5" bordered style={{ maxWidth: "400px" }} >
                                    <tbody>
                                        <tr>
                                            <td colSpan="4" className="text-center"><strong>Equipment</strong></td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Chep</strong></td>
                                            <td>{initialState?.chep}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Loscom</strong></td>
                                            <td>{initialState?.loscom}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Other</strong></td>
                                            <td>{initialState?.plain}</td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <Table className="ms-auto mt-4" bordered style={{ maxWidth: "400px" }}>
                                    <tbody>

                                        <tr><td colSpan="4"><strong>RECIEVED BY</strong>: {delivery_customer_name}</td></tr>
                                        <tr>
                                            <td><strong>Date</strong>:{(initialState?.signature_date !== null) ? <Moment format="D MMM, YYYY">{initialState?.signature_date}</Moment> : ''}</td>
                                            <td><strong>Signature</strong>: {initialState.signature ? <img width='100' height='100' src={`data:image/png;base64,${initialState.signature}`} /> :
                                                ''}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <h4 className=" fw600 fz16 text-dark mt-5"> Geo Location Log</h4>
                            <div className="overflow-auto mt-5 ">
                                <Table className="w-100 geoLocationLog" bordered>
                                    <tbody>
                                        <tr>
                                            <td className=" pb-3 fw-bold border-bottom">Geo Location Started</td>
                                            <td className=" pb-3 fw-bold border-bottom">Geo Location Delivered</td>
                                            <td className=" pb-3 fw-bold border-bottom">Date & Time</td>
                                            <td className="text-center pb-3 fw-bold border-bottom">View In Map</td>
                                        </tr>
                                        {allGeoLocationData?.rows && allGeoLocationData?.rows.map((val, index) => (
                                            <tr>
                                                <td>{val?.started_latitude} &nbsp; &nbsp;{val?.started_longitude}</td>
                                                <td>{val?.delivered_latitude} &nbsp; &nbsp;{val?.delivered_longitude}</td>
                                                <td>
                                                    {(val?.geo_date !== null) ? <Moment format="D MMM, YYYY - hh:mm:s A ">{val?.geo_date}</Moment> : ''}</td>
                                                <td className="text-center"><Link target="_blank" to={`https://www.google.com/maps/place/TUKZAR+AUTO/@${val?.started_latitude},${val?.started_longitude},666m/data=!3m1!1e3!4m6!3m5!1s0x0:0x1abcdcf546e032a5!4b1!8m2!3d-32.0433541!4d115.982511?hl=en-US" className="btn px-0 fz16" title="View in Google Map`}><i className="fa-light fa-map-location"></i></Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default View;
