import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { InputText, Dropdown as Select, Calendar, confirmDialog, Toast, ConfirmDialog } from "../PrimeReactComponents";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Button } from "../BootstrapComponents";
import Loader from "./../features/loader/Loader";
  import Logo1 from '../assets/img/logo-black.png'

export default function ThankYouRest() {
    const [loader, setLoader] = useState();
    const toast = useRef(null);


    return (
        <>

            <Loader show={loader} />
            <ConfirmDialog />
            <Toast ref={toast} position="top-center" />
            <div className="main-login-page">
                <div className="loginWrap">
                    <div className="login_container d-flex flex-column justify-content-center h-100">
                        <header className="text-center text-md-start">
                            <div className="login_container">
                            </div>
                        </header>
                        <div className="login-Announcement">
                            <div className="row w-100 mw-100">
                                <div className="col-md-10 offset-md-2">
                                    <div className="login-content w-100 mw-100 box-remove-mobile">
                                        <img src={Logo1} className="img-fluid" alt="" />
                                        <br></br>
                                        <div className="jumbotron text-center">
                                            <h1 className="display-3"> Your Password has been changed Successfully !</h1>
                                            <hr />
                                            <p className="lead">
                                                <a className="btn btn-primary btn-sm" href="/" role="button">Continue to homepage</a>
                                            </p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="login_footer py-3 border-top">
                    <div className="container-fluid d-md-flex justify-content-between text-center text-md-start">
                        <ul className="mb-0">
                            <li><a href="">Terms of Service</a></li>
                            <li><a href="">Contact</a></li>
                            <li><a href="">Support</a></li>
                        </ul>
                        <p className="mb-0"> Copyright © 2023 LEOPOLD TRANSPORT PTY LTD. All rights reserved</p>
                    </div>
                </footer>
            </div>
        </>
    )
}
