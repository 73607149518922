import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '../../../BootstrapComponents'
import { InputText, Dropdown as Select, confirmDialog, Toast, InputTextarea } from "../../../PrimeReactComponents";
import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik';
import { rejectValidateSchema, rejectValidateInit } from '../../Validations/rejectChecklistValidation'
import { getCountry, getState } from '../../../services/commonController';
import { addCustomerAddress } from '../../controllers/customerController';
import { rejectReasonComment } from "../../controllers/timeSheet"

function RejectModal(props) { 
 
    const { rejectModalShow, handleRejectModalClose } = props;
    const { transportUserInfo } = useSelector(state => state);
    const [initialState, setInitialState] = useState(rejectValidateInit);
    const [loader, setLoader] = useState();
    const navigate = useNavigate();
    const toast = useRef(null);
    const reciveLocalData = transportUserInfo.company_id;

    const rejectChecklistSubmit = async (values, { resetForm }) => {
        const all_values = { ...values, id:props.checklistId,company_id: reciveLocalData }; 
 
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setLoader(true)
                rejectReasonComment(all_values).then(({ status, type, message }) => {
                   if (status) {
                     setLoader(false)
                     resetForm({ all_values: '' })
                     toast.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                     handleRejectModalClose();   
                     props.reload(props.id)   
                   } else {
                     setLoader(false)
                     toast.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                     props.reload(props.id)   
                   }
                 }) 
            }
        });

    }


    const handleClose = () => {
        handleRejectModalClose();
    };

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <Formik initialValues={rejectValidateInit} validationSchema={rejectValidateSchema} onSubmit={rejectChecklistSubmit}
                render={({ values }) => (
                    <>
                        <Modal show={rejectModalShow} onHide={handleClose} size="xl" centered modal-lg >
                            <Form className='d-flex flex-column overflow-auto'>
                                <Modal.Header closeButton={false} className="p-4">
                                    <Modal.Title>Reason for Rejection</Modal.Title>

                                    <Button className="btn-close ms-2 " onClick={handleClose} variant=""></Button>
                                </Modal.Header>

                                <Modal.Body className='overflow-auto' style={{ width: '1130px', height: '480px' }}>
                                    <div className="modal-body p-0">
                                        <div className="px-5 pb-4">
                                            <p className="mt-4"> </p>
                                            <div className="row px-0 py-4" >
                                                <Field name="comment"  render={({ field }) => (
                                                    <>
                                                        <InputTextarea {...field} className="form-control px-3"  style={{ height: '250px' }}/>
                                                        <ErrorMessage name="comment" component="span" className="from-error-span" />
                                                    </>
                                                )} /> 

                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>

                                <div className="modal-footer py-3">
                                    <div className="btn-group">
                                        <button type="button" className="btn fz14 btn-gradient" onClick={handleClose} >Cancel</button>
                                        <button type="submit" className="btn fz14 bg-green text-white">
                                            Save</button>
                                    </div>
                                </div>
                            </Form>
                        </Modal>
                    </>
                )}


            />
        </>
    )
}

export default RejectModal

