import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Dropdown, InputText } from "../../../PrimeReactComponents";
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {companyFilterInitialState, companyFilterSchema} from '../../Validations/companyValidation'
import { useEffect } from "react";

const FilterOffset = (props) => {
  const status = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  const [show, setShow] = useState(false);
 
  useEffect(()=>{
    if(props.offsetFilter){
      setShow(false);
    }
  },[props.offsetFilter])
  return (
    <>
      <Button variant="" onClick={()=>setShow(true)} className="me-3 btn btn-gradient blurDropdownBg fz14 shadow-none border">
        <i className="fa-regular fa-filter"></i>
      </Button>
      <Offcanvas show={show} onHide={()=>setShow(false)} placement="end" className="offcanvas sm w-100 ">
        <Formik initialValues={companyFilterInitialState}  onSubmit={(value)=> {
      props.filterHandle(value)  }} render={()=> (
          <Form className="d-flex flex-column h-100 overflow-auto">
              <Offcanvas.Header closeButton className="border-bottom px-4">
                  <Offcanvas.Title>
                    <span className="fz20 title">
                      <i className="fa-light fa-filter"></i> Filter
                    </span>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0 pt-4">
                  <div className="filterBox">
                    <div className="filter-form-wrap  h-100">
                      <div className="form-group mb-3">
                        <label className="mb-1 fz14 fw400" htmlFor="">
                         Company Name
                        </label>                        
                        <Field name="companyname" render={({field})=>(
                          <>
                            <InputText id="username2" {...field} className="form-control mt-2 px-3"  />
                            <ErrorMessage className="from-error-span" component="span" name="companyname" />
                          </>
                        )} />
                      </div>                 
                      <div className="form-group mb-3">
                        <label className="mb-1 fz14 fw400" htmlFor="">
                          Status
                        </label>
                        <Field name="status" render={({field})=>(
                            <>                          
                              <Dropdown  {...field}  className="w-100  mt-2 px-3" options={status} placeholder="Select a Status" />
                              {/* <ErrorMessage className="from-error-span" component="span" name="status" /> */}
                            </>
                        )} />
                     
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
                <div className="offset-footer border-top py-4 px-4">
                  <div className="btn-group d-block text-end">
                
                     <Button variant=""  onClick={()=>setShow(false)} className="me-3 btn btn-gradient blurDropdownBg fz14 shadow-none border">
                     Cancel
                    </Button>
                    
                    <Button type="submit"  className="btn bg-green text-white rounded ms-3 px-3 text-white">Apply</Button>
                  </div>
                </div>
          </Form>
        )} />
        
      </Offcanvas>
    </>
  );
};

export default FilterOffset ;
