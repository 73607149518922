import React, { useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button } from "../../BootstrapComponents";
import { InputText, Calendar, confirmDialog,Toast } from "../../PrimeReactComponents";
import { manifestValidateSchema, manifestInitialState } from '../Validations/ManifestValidation';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { addDataManifest, searchConsigmentNumber } from "../controllers/ConsignmentController";
import { AutoComplete } from "primereact/autocomplete";
function Add() {
  const toast = useRef(null);
  const {customerUserInfo} = useSelector(state => state);
  const reciveLocalData =  customerUserInfo.company_id
  const [loader, setloader] = useState();
  const { id } = useParams();
  const formikRef = useRef();
  const navigate = useNavigate();
  const [consigmentValue, setConsigmentValue] = useState('');
  const [allConsingment, setAllConsingment] = useState([]);


  const postData = (values, { resetForm }) => {

    let consignment_number = [];
    //let sender_ref = [];
    let from_company_name = [];
    let to_company_name = [];
    let address = [];
    let city = [];
    let postcode = [];
    let pallets = [];
    let spaces = [];
    let weight = [];
    let job_temp = [];
    let consignmentArr = [];


    /*  allConsingment && allConsingment.map((data, index) => {
       console.log('data', data)
       consignmentArr.push(data.job_number);
     })   */

    const multipalLoopData = values.manifests.map((data, index) => {
      //consignment_number.push(data.consignment_no ? data.consignment_no : "");  

      consignment_number.push(data.consignment_no.job_number ? data.consignment_no.job_number : "")

      from_company_name.push(data.from_company_name ? data.from_company_name : "");
      to_company_name.push(data.to_company_name ? data.to_company_name : "");
      address.push(data.to_address ? data.to_address : "");
      city.push(data.to_city ? data.to_city : "");
      postcode.push(data.to_postcode ? data.to_postcode : "");
      pallets.push(data.total_pallets ? data.total_pallets : "");
      spaces.push(data.total_spaces ? data.total_spaces : "");
      weight.push(data.weight ? data.weight : "");
      job_temp.push(data.job_temp ? data.job_temp : "");
    })

    let modifyedData = {
      ...values, company_id: reciveLocalData, manifest_details: {
        consignment_number: consignment_number,
        from_company_name: from_company_name,
        to_company_name: to_company_name,
        address: address, city: city, postcode: postcode, pallets: pallets, spaces: spaces, weight: weight, job_temp: job_temp
      }
    }
    const { manifests, ...InputData } = modifyedData
    console.log(modifyedData, "this is log......", modifyedData);

    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        if (id) {
          //   editData(modifyedData1,id).then(({ status, type, message }) => {
          //     if (status) {
          //         setloader(false)
          //         resetForm({modifyedData:''})
          //         toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          //         // history.push("company/companylist")                        
          //     } else {
          //         setloader(false)
          //         toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          //     }
          // })
          
        } else {
          addDataManifest(InputData).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ InputData: '' })
              toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000 });

              setTimeout(() => {
                navigate('/consignments/manifest')
              }, 3000)

            } else {
              setloader(false)
              toast.current.show({ severity: 'error', summary: 'Info Message', detail: message, life: 3000, });
            }
          })
        }
      }
    });
  }

  const search = async (event) => {
    let keyword = event.query
    if (keyword !== null && keyword !== 'undefined' && keyword.length > 2) {
      const resultData = await searchConsigmentNumber(reciveLocalData, keyword);
      if (resultData?.data?.data.length > 0) {
        setAllConsingment(resultData?.data?.data)
      }
      else {
        setAllConsingment([{ consignment_id: '', job_number: 'Not Found' }])
      }
    }
  }
  const onSelectedConsigment = (value, idx) => {
    formikRef.current.setFieldValue('manifests[' + idx + '].weight', value.total_weight)
    formikRef.current.setFieldValue('manifests[' + idx + '].total_spaces', value.total_spaces)
    formikRef.current.setFieldValue('manifests[' + idx + '].total_pallets', value.total_pallets)

    formikRef.current.setFieldValue('manifests[' + idx + '].from_company_name', value.customer_pickup_details.customer_company_name)
    formikRef.current.setFieldValue('manifests[' + idx + '].to_company_name', value.customer_delivery_details.customer_company_name)
    formikRef.current.setFieldValue('manifests[' + idx + '].to_city', value.delivery_suburb)

    formikRef.current.setFieldValue('manifests[' + idx + '].to_postcode', value.delivery_zip_code)
    formikRef.current.setFieldValue('manifests[' + idx + '].to_address', value.delivery_address)
    formikRef.current.setFieldValue('manifests[' + idx + '].job_temp', value.consignment_details[0].job_temp)
  }

  return (

    <>
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
          <Formik initialValues={manifestInitialState} validationSchema={manifestValidateSchema} innerRef={formikRef} onSubmit={postData} render={({ values, setFieldValue }) => (
            <Form className="d-flex flex-column overflow-auto">
              <div className="container d-flex flex-column h-100 ">

                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                      <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Create New Manifest</h2>
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      <Link to="../" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link>
                      <Button type="submit" variant="" className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save</Button>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">
                  <div className="card p-4">
                    <div className="card-body">
                      <div className="row gx-5">
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Title <span className="text-danger">*</span></label>
                          <Field name="title" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="title" component="span" className="from-error-span" />
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Carrier <span className="text-danger">*</span></label>
                          <Field name="carrier" render={({ field }) => <InputText className="form-control px-3 " {...field} />} />
                          <ErrorMessage name="carrier" component="span" className="from-error-span" />
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Phone <span className="text-danger">*</span></label>
                          <Field name="phone" render={({ field }) => <InputText className="form-control px-3 " maxLength="10" keyfilter="int" {...field} />} />
                          <ErrorMessage name="phone" component="span" className="from-error-span" />
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Dispatch Date <span className="text-danger">*</span></label>
                          <Field name="dispatch_date" render={({ field }) => <Calendar className="w-100" readOnlyInput inputClassName="form-control px-3 calender-icon-bg" {...field} dateFormat="dd M, yy" />} />
                          <ErrorMessage name="dispatch_date" component="span" className="from-error-span" />
                        </div>

                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Manifest Number <span className="text-danger">*</span></label>
                          <Field name="manifest_number" render={({ field }) => <InputText className="form-control px-3" keyfilter="int"  {...field} />} />
                          <ErrorMessage name="manifest_number" component="span" className="from-error-span" />
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Pallet DKT <span className="text-danger">*</span></label>
                          <Field name="pallet_dkt" render={({ field }) => <InputText className="form-control px-3" keyfilter="int"  {...field} />} />
                          <ErrorMessage name="pallet_dkt" component="span" className="from-error-span" />
                        </div>

                        <div className="col-12 mt-4 mb-3">
                          <div className="overflow-auto">
                            <table className="table" style={{ minWidth: "1400px" }}>
                              <thead>
                                <tr>
                                  <th className="fw500 fz14">#</th>
                                  <th width="140px" className="fw500 fz14">Consignment No  <span className="text-danger">*</span></th>

                                  <th width="190px" className="fw500 fz14">From Company Name  <span className="text-danger">*</span></th>
                                  <th width="180px" className="fw500 fz14">To Company Name  <span className="text-danger">*</span></th>
                                  <th width="180px" className="fw500 fz14">To Address  <span className="text-danger">*</span></th>
                                  <th width="180px" className="fw500 fz14">To City  <span className="text-danger">*</span></th>
                                  <th width="120px" className="fw500 fz14">To Postcode  <span className="text-danger">*</span></th>
                                  <th width="110px" className="fw500 fz14">Total Pallets <span className="text-danger">*</span></th>
                                  <th width="130px" className="fw500 fz14">Pallet Spaces <span className="text-danger">*</span></th>
                                  <th width="120px" className="fw500 fz14">Weight (KG) <span className="text-danger">*</span></th>
                                  <th width="10px" className="fw500 fz14">Job Temp <span className="text-danger">*</span></th>
                                  <th className="fw500 fz14" width="30px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name="manifests" render={({ push, remove }) => (
                                  values?.manifests && values?.manifests.map((val, idx) => (

                                    <tr key={idx}>
                                      <td>{idx + 1}</td>
                                      <td>

                                        <Field name={`manifests[${idx}].consignment_no`}
                                          render={({ field }) => (
                                            <AutoComplete className="w-100" inputClassName="form-control"
                                              {...field} suggestions={allConsingment} field="job_number"
                                              completeMethod={search}
                                              onChange={(e) => { setFieldValue(`manifests[${idx}].consignment_no`, e.value) }}
                                              onSelect={(e) => onSelectedConsigment(e.value, idx)}
                                            />)}
                                        />

                                      </td>

                                      <td><Field name={`manifests[${idx}].from_company_name`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" keyfilter="alphanum" disabled />
                                          <ErrorMessage name={`manifests[${idx}].from_company_name`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].to_company_name`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" keyfilter="alphanum" disabled />
                                          <ErrorMessage name={`manifests[${idx}].to_company_name`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].to_address`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" keyfilter="alphanum" disabled />
                                          <ErrorMessage name={`manifests[${idx}].to_address`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].to_city`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" disabled />
                                          <ErrorMessage name={`manifests[${idx}].to_city`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].to_postcode`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" keyfilter="int" disabled />
                                          <ErrorMessage name={`manifests[${idx}].to_postcode`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].total_pallets`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" keyfilter="int" disabled />
                                          <ErrorMessage name={`manifests[${idx}].total_pallets`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].total_spaces`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" disabled />
                                          <ErrorMessage name={`manifests[${idx}].total_spaces`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].weight`} render={({ field }) => (
                                        <>
                                          <InputText {...field} className="form-control px-3" keyfilter="int" disabled />
                                          <ErrorMessage name={`manifests[${idx}].weight`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      <td><Field name={`manifests[${idx}].job_temp`} render={({ field }) => (
                                        <>
                                          <InputText  {...field} className="form-control px-3" disabled />
                                          <ErrorMessage name={`manifests[${idx}].job_temp`} component="span" className="from-error-span" />
                                        </>
                                      )} />
                                      </td>
                                      {idx < 1 && (
                                        <td>
                                          <button type="button" onClick={() => push(manifestInitialState.manifests[0])} className="btn fw500 fz14 ms-auto text-success shadow-none px-0">
                                            <i className="fa-regular fa-plus-circle "></i>
                                          </button>
                                        </td>
                                      )}
                                      {idx > 0 && (
                                        <td>
                                          <button type="button" onClick={() => remove(idx)} className="btn fw500 fz14 ms-auto text-danger shadow-none px-0">
                                            <i className="fa-regular fa-minus-circle "></i>
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  )))} />


                              </tbody>
                            </table>




                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )} />
        </div>
      </div>
    </>
  );
}

export default Add;
