import React,{useRef} from "react";
import { Link,useNavigate } from "react-router-dom";
import { Dropdown as Select,Menu } from "../../PrimeReactComponents";
import Dropdown from "react-bootstrap/Dropdown";
import { Toast } from 'primereact/toast';
import SidebarToggleButton from "../components/SidebarToggleButton";
import { useSelector} from 'react-redux';
import {logoutApiUrl,plateformLogoutDetails} from "../../config/keyClockConfig"

function Header() {
  const userInfoSelector = useSelector(state => state.userInfo);
  const menuRight = useRef(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const logOut= async()=>{
  let access_token=localStorage.getItem('keyCloakaccesstokenData');
  let refresh_token=localStorage.getItem('keyCloakrefreshData');
    let details = {...plateformLogoutDetails,"refresh_token":refresh_token};
  var formBody = [];
for (var property in details) {
  var encodedKey = encodeURIComponent(property);
  var encodedValue = encodeURIComponent(details[property]);
  formBody.push(encodedKey + "=" + encodedValue);
}
formBody = formBody.join("&"); 
  await fetch(logoutApiUrl, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${access_token}`,
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: formBody
  },()=>{ toast.current.show({severity:"warn", summary: 'Info Message', detail: "Logout Successfully !!" , life: 3000,});})
    localStorage.removeItem('plateFromData');
    //sessionStorage.clear();
    //window.location.href='../../platform'
    setTimeout(() => {navigate('/platform')},500) 
  }
  const items = [
    {
      label: 'User Profile',
      icon: 'fa-light fa-user',
      command: () => {
        navigate('/platform/profile');
      }
    },
    {
      label: 'Change Password',
      icon: 'fa-light fa-key',
      command: () => {
        navigate('/platform/profile/change-password');
      }
    },
    { separator: true},
    {
      label: 'Logout',
      icon: 'fa-light fa-right-from-bracket',
      command: () => {
        logOut()
      }
    },
  ];

  return (
    <>
    <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" style={{minWidth:170}} />
     <Toast ref={toast} />
      <div className="top-strip">
        <div className="container-fluid">
          <div className="d-flex gap-3 align-items-center">
              <SidebarToggleButton/>
            <div className="dropdown coles-supermarket">
              <button type="button" className="w-100 d-block btn shadow-none fw600 p-0">
              Welcome to Leopold Transport Pty Ltd.
              </button>
            </div>
            <div className="d-flex align-items-center ms-auto">
              <ul className="header-right-links d-flex align-items-center">
                <li className="ms-4 ">
                  {/* <Dropdown className="flex-shrink-0 profileDropDownWrap" autoClose="inside">
                    <Dropdown.Toggle variant="" to="#" className="d-block blurDropdownBg fz14 p-0">
                      <span width="30" height="30" className="profile-icon rounded-circle">
                      {(userInfoSelector?.resultData?.userData?.first_name)?.charAt(0)} {(userInfoSelector?.resultData?.userData?.last_name)?.charAt(0)} 
                      </span>
                      <span className="d-none d-lg-inline ms-2">{userInfoSelector?.resultData?.userData?.first_name} {userInfoSelector?.resultData?.userData?.last_name}  </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu shadow dropdown-menu-md-start profileDropDown">
                      <div className="px-3 profile-name-head border-bottom d-xl-none">
                        <div className="d-flex link-dark fz14">
                          <span width="30" height="30" className="profile-icon rounded-circle">
                          {(userInfoSelector?.resultData?.userData?.first_name)?.charAt(0)} {(userInfoSelector?.resultData?.userData?.last_name)?.charAt(0)}
                          </span>
                          <div className="ms-2">
                            <h5 className="fz16 mb-0">  {userInfoSelector?.resultData?.userData?.first_name} {userInfoSelector?.resultData?.userData?.last_name}</h5>
                           </div>
                        </div>
                      </div>
                      <Dropdown.Item className="p-0"><Link className="dropdown-item"  to="/platform/profile"> <i className="fa-light fa-user"></i> User Profile  </Link></Dropdown.Item>
                      <Dropdown.Item className="p-0"><Link className="dropdown-item"  to="/platform/profile/change-password"> <i className="fa-light fa-user"></i> Change Password </Link></Dropdown.Item>
                      <hr className="dropdown-divider d-none d-xl-block" />
                      <Dropdown.Item className="p-0"><Link className="dropdown-item"to="/" onClick={()=>logOut()}> <i className="fa-light fa-right-from-bracket"></i> Sign out </Link></Dropdown.Item>  
                    </Dropdown.Menu>
                  </Dropdown> */}
                     <button className="btn p-0" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup>
                    <span width="30" height="30" className="profile-icon rounded-circle">
                        {(userInfoSelector?.resultData?.userData?.first_name)?.charAt(0)} {(userInfoSelector?.resultData?.userData?.last_name)?.charAt(0)}
                      </span>
                      <span className="d-none d-lg-inline ms-4">
                        {userInfoSelector?.resultData?.userData?.first_name} {userInfoSelector?.resultData?.userData?.last_name}
                      </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
