import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../BootstrapComponents";
import { InputText,  Calendar } from "../../PrimeReactComponents";
import {manifestValidateSchema, manifestInitialState} from '../Validations/ManifestValidation';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";

function Edit() {


  return (
    <>    
      
      <div className="page-wrapper-without-aisde">
          <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
            <Formik initialValues={manifestInitialState} validationSchema={manifestValidateSchema} onSubmit={(values, { setSubmitting })=> {
                console.log(values)
            }} 
            render={(props)=> (
              <Form className="d-flex flex-column overflow-auto">     
                <div className="container d-flex flex-column h-100 ">
                              
                  <div className="px-4 mt-3">
                      <div className="d-flex align-items-center flex-wrap">
                          <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                              <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Create New Manifest</h2>
                          </div>
                          <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                              <Link to="../" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link>
                              <Button  type="submit" variant=""  className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save</Button>
                          </div>
                      </div>                                                                               
                  </div>                
                  <div className="overflow-auto flex-grow-1 mt-5 px-4">                  
                      <div className="card p-4">
                          <div className="card-body">                          
                              <div className="row gx-5">                                                                 
                                   <div className="col-sm-6 mb-4">
                                      <label htmlFor="" className="mb-2">Title</label>    
                                      <Field name="title" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                                      <ErrorMessage name="title" component="span" className="from-error-span" />                                                                                            
                                  </div>
                                  <div className="col-sm-6 mb-4">
                                      <label htmlFor="" className="mb-2">Carrier </label>                      
                                      <Field name="carrier" render={({ field }) => <InputText  className="form-control px-3 " {...field}  /> } />
                                      <ErrorMessage name="carrier" component="span" className="from-error-span" />                
                                  </div>
                                  <div className="col-sm-6 mb-4">
                                      <label htmlFor="" className="mb-2">Phone</label>                      
                                      <Field name="phone" render={({ field }) => <InputText  className="form-control px-3 " keyfilter="int" {...field}  /> } />
                                      <ErrorMessage name="phone" component="span" className="from-error-span" />                
                                  </div>
                                  <div className="col-sm-6 mb-4">
                                      <label htmlFor="" className="mb-2">Dispatch Date</label>                      
                                      <Field name="dispatch_date" render={({ field }) => <Calendar className="w-100" readOnlyInput inputClassName="form-control px-3 calender-icon-bg" {...field}  dateFormat="dd M, yy" /> } />
                                      <ErrorMessage name="dispatch_date" component="span" className="from-error-span" />                
                                  </div>                                 
                                                                 
                                  <div className="col-sm-6 mb-4">
                                      <label htmlFor="" className="mb-2">Manifest Number</label>    
                                      <Field name="manifest_number" render={({ field }) => <InputText className="form-control px-3" keyfilter="int"  {...field} />} />
                                      <ErrorMessage name="manifest_number" component="span" className="from-error-span" />                                                                                            
                                  </div>
                                  <div className="col-sm-6 mb-4">
                                      <label htmlFor="" className="mb-2">Pallet DKT</label>    
                                      <Field name="pallet" render={({ field }) => <InputText className="form-control px-3" keyfilter="int"  {...field} />} />
                                      <ErrorMessage name="pallet" component="span" className="from-error-span" />                                                                                            
                                  </div>
                                                                
                                <div className="col-12 mt-4 mb-3">                                     
                                      <div className="overflow-auto">
                                      <FieldArray name="manifests" render={({push, remove, }) => (
                                            <table className="table" style={{minWidth:"1400px"}}>
                                            <thead>
                                              <tr>
                                                    <th className="fw500 fz14">#</th>
                                                    <th width="140px" className="fw500 fz14">Consignment No</th>
                                                    <th width="110px" className="fw500 fz14">Sender Ref</th>
                                                    <th width="190px"  className="fw500 fz14">From Company Name</th>
                                                    <th width="180px" className="fw500 fz14">To Company Name</th>
                                                    <th width="180px" className="fw500 fz14">To Address</th>
                                                    <th width="180px" className="fw500 fz14">To City</th>
                                                    <th width="120px" className="fw500 fz14">To Postcode</th>
                                                    <th width="110px" className="fw500 fz14">Total Pallets</th>
                                                    <th width="130px" className="fw500 fz14">Pallet Spaces</th>
                                                    <th width="120px" className="fw500 fz14">Weight (KG)</th>
                                                    <th width="90px" className="fw500 fz14">Job Temp</th>
                                                    <th className="fw500 fz14" width="30px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>                                             
                                              { props.values.manifests.map((val, idx)=> (
                                                    <tr key={idx}>
                                                      <td>{idx+1}</td>
                                                      <td><Field name={`manifests[${idx}].consignment_no`} render={({field})=> (
                                                          <>
                                                            <InputText {...field}  className="form-control px-3" keyfilter="int" />
                                                            <ErrorMessage name={`manifests[${idx}].consignment_no`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td> 
                                                      {/* <td><Field name={`manifests[${idx}].sender_ref`} render={({field})=> (
                                                          <>
                                                              <InputText {...field}  className="form-control px-3"  />
                                                            <ErrorMessage name={`manifests[${idx}].sender_ref`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>   */}
                                                      <td><Field name={`manifests[${idx}].from_company_name`} render={({field})=> (
                                                          <>
                                                              <InputText {...field}  className="form-control px-3" keyfilter="alphanum"  />
                                                            <ErrorMessage name={`manifests[${idx}].from_company_name`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                                  
                                                      <td><Field name={`manifests[${idx}].to_company_name`} render={({field})=> (
                                                          <>
                                                               <InputText {...field}  className="form-control px-3"  keyfilter="alphanum"  />
                                                            <ErrorMessage name={`manifests[${idx}].to_company_name`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                                  
                                                      <td><Field name={`manifests[${idx}].to_address`} render={({field})=> (
                                                          <>
                                                              <InputText {...field}  className="form-control px-3" keyfilter="alphanum"  />
                                                            <ErrorMessage name={`manifests[${idx}].to_address`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                                  
                                                      <td><Field name={`manifests[${idx}].to_city`} render={({field})=> (
                                                          <>
                                                               <InputText {...field}  className="form-control px-3"  />
                                                            <ErrorMessage name={`manifests[${idx}].to_city`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                                  
                                                      <td><Field name={`manifests[${idx}].to_postcode`} render={({field})=> (
                                                          <>
                                                               <InputText {...field}  className="form-control px-3"   keyfilter="int"  />
                                                            <ErrorMessage name={`manifests[${idx}].to_postcode`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                                                                                                                                       
                                                      <td><Field name={`manifests[${idx}].total_pallets`} render={({field})=> (
                                                          <>
                                                              <InputText {...field}  className="form-control px-3"  keyfilter="int" />
                                                            <ErrorMessage name={`manifests[${idx}].total_pallets`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>    
                                                      <td><Field name={`manifests[${idx}].total_spaces`} render={({field})=> (
                                                          <>
                                                               <InputText {...field}  className="form-control px-3"  />
                                                            <ErrorMessage name={`manifests[${idx}].total_spaces`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                               
                                                      <td><Field name={`manifests[${idx}].weight`} render={({field})=> (
                                                          <>
                                                            <InputText {...field}  className="form-control px-3" keyfilter="int" />
                                                            <ErrorMessage name={`manifests[${idx}].weight`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                                  
                                                      <td><Field name={`manifests[${idx}].job_temp`} render={({field})=> (
                                                          <>
                                                            <InputText  {...field}  className="form-control px-3"  />
                                                            <ErrorMessage name={`manifests[${idx}].job_temp`} component="span" className="from-error-span" />  
                                                          </>                                                      
                                                        )} />
                                                      </td>                                                                                                                                                       
                                                      {idx < 1 && (
                                                        <td>
                                                          <button type="button" onClick={() => push({ ...manifestInitialState.manifests[0]})} className="btn fw500 fz14 ms-auto text-success shadow-none px-0">
                                                            <i className="fa-regular fa-plus-circle "></i>
                                                          </button>
                                                        </td>
                                                      )}
                                                      {idx > 0 && (
                                                        <td>
                                                          <button type="button" onClick={() => remove(idx)} className="btn fw500 fz14 ms-auto text-danger shadow-none px-0">
                                                            <i className="fa-regular fa-minus-circle "></i>
                                                          </button>
                                                        </td>
                                                      )}      
                                                   </tr>
                                                  ))}                                                      
                                            </tbody>                                           
                                          </table>
                                          )}>
                                        
                                        </FieldArray>
                                        
                                      </div>
                                  </div> 
                                 
                              </div>
                          </div>
                      </div>                    
                  </div>              
              </div>
            </Form>
            )} />
          </div>
        </div>
    </>
  );
}

export default Edit;
