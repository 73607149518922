import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Dropdown, Button } from "../../../BootstrapComponents";
import { FilterOffset } from "../../components/OffsetRight";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../../PrimeReactComponents";
import dataTableConfig from "../config/DatatableConfig";
import { Link, useNavigate } from 'react-router-dom'
import { documentTypeListData, documentTypeDeleteData, activeInactive, activeInactiveBulk, removeDataBulk } from "../../controllers/SetUpController";
import useDataTableFilter from '../../../features/customUseHook/useDataTableFilter'
import Loader from "../../../features/loader/Loader";

function DocumentType() {
  const { transportUserInfo } = useSelector(state => state);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState("0px");
  const reciveLocalData = transportUserInfo.company_id;
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [list, setList] = useState([])
  const [filters, globalFilterValue, onGlobalFilterChange] = useDataTableFilter();
  const [loader, setLoader] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [documentTypeData, setDocumentTypeData] = useState(null);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);

  let documentTypeListArr = []
  let documentTypeArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Setup')?.map(val => {
    if (!documentTypeListArr.includes(val?.menuData?.menu_name)) {
      documentTypeListArr.push(val?.menuData?.menu_name)
    }

    if (!documentTypeArr.includes(val?.menuActionData?.action_name)) {
      documentTypeArr.push(val?.menuActionData?.action_name)
    }
  })

  if (documentTypeListArr.length == 0) {
    navigate('/transport/404');
  }

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    status: "",
    filters: "",
    globalFilterValue: "",
    company_id: reciveLocalData,
    filters: {
      'document_type': { value: '', matchMode: 'contains' },
    }
  });

  let loadLazyTimeout = null;
  useEffect(() => {
    loadLazyData();

  }, [lazyParams])
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      documentTypeListData({ lazyEvent: (lazyParams) }).then(data => {
        setTotalRecords(data?.data?.data?.length);
        setDocumentTypeData(data?.data?.data);
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);

      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
      sortOrder: lazyParams.sortOrder,
    }, []);
  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
    } else if (value.length === 0) {
      setLazyParams({ ...lazyParams, globalFilterValue: "" });
    }
  }

  const advanceSearch = (value) => {
    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.document_type });
      setoffsetFilter(true);

    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }



  // Delete Entry Function Start Here =====
  const deleteEntry = (id) => {
    setLoader(true);
    documentTypeDeleteData(id).then(({ type, message }) => {
      let updatedList = list.filter(val => val.document_id !== id);
      loadLazyData();
      setLoader(false);
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const deleteConfirm = ({ document_id }) => {
    //console.log("this is template row...",document_id);
    setIsListUpdated(false)
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(document_id)
      }
    });

  }
  // Delete Entry Function end Here =====

  const activeInactiveStatus = (status, id) => {
    setLoader(true)
    activeInactive(status, id).then(({ type, message }) => {
      setIsListUpdated(true)
      setLoader(false)
      loadLazyData();
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {
    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data.status === '1' ? 'Deactivate' : 'Activate'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data.status, data.document_id);
      }
    });
  }

  const statusBodyTemplate = (rowData) => {
    return <button onClick={(e) => activeInactiveHandler(e, rowData)} className={`fw600 border-0 badge py-2 fs-5 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Active" : "Inactive"}</button>;
  };


  const actionButton = (rowData) => (
    <div className="d-flex gap-2">
      <Link variant='' to={`./edit-document-type/${rowData.document_id}`} className={`btn fz16 p-0 edit-btn ${documentTypeArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`}><i className="fa-light fa-edit"></i></Link>
      <Button variant='' className={`deleteRow btn fz16 p-0 delete-btn ${documentTypeArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} onClick={() => deleteConfirm(rowData)} ><i className="fa-light fa-trash"></i></Button>
    </div>
  )

  //Bulk
  const activeInactiveStatusBulk = (status, id) => {
    setLoader(true)
    activeInactiveBulk(status, id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }

  const bulkActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.document_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }


  const bulkDeActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.document_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Deactivate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("0", selectedId);
      }
    });
    setSelectedCustomers();
  }

  const DeleteBulk = (id) => {
    setLoader(true)
    removeDataBulk(id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }

  const bulkDelete = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.document_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }
  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
        <Link to="./add-document-type" className={`bg-green text-white btn fz14 me-3 ${documentTypeArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}><i className="fa-light fa-plus-circle"></i> Add</Link>
        <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />
        <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
          <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500 p-2" />
          <Button variant='' className="insideSubmitBtn me-2 fz14 bg-transparent border-0 position-absolute" ><i className="fa-light fa-search"></i></Button>
        </div>
        <Dropdown className="order-4">
          <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
            <i className="fa-light fa-ellipsis"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <ul>
              <li>
                <Button className={`dropdown-item  ${documentTypeArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} to="#" onClick={() => bulkDelete()}>
                  <i className="fa-light me-2 fa-trash"></i>
                  Delete Selected
                </Button>
              </li>
              <li>
                <Button className="dropdown-item" onClick={() => bulkActivate()} >
                  <i className="fa-light me-2 fa-check"></i>
                  Active
                </Button>
              </li>
              <li>
                <Button className="dropdown-item" onClick={() => bulkDeActivate()}>
                  <i className="fa-light me-2 fa-ban"></i>
                  Inactive
                </Button>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="table-wrapper flex-grow-1  mt-5">
        <DataTable value={documentTypeData} selectionMode="checkbox" dataKey="document_type" paginator rows={12} totalRecords={totalRecords} onPage={onPage}    {...dataTableConfig} scrollDirection="both"
          selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)} {...dataTableConfig}
        >
          <Column headerStyle={{ width: "3em" }} selectionMode="multiple" />
          <Column header="Action" body={actionButton} />
          <Column sortable field='document_type' header="Document Type" style={{ flexGrow: 1, flexBasis: "120px" }} />
          <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
        </DataTable>
      </div>
    </>
  )
}

export default DocumentType

