import * as yup from 'yup';

const TransportChangePasswordInitialState = { 
    newpassword: '',
    confirmpassword: ''
};

const TransportChangePasswordValidateSchema = yup.object().shape({ 
    newpassword: yup.string().required('Please Enter your password').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,12})/,
    "Must Contain 6 Characters and Maximum 12 Characters, One Uppercase, One Lowercase, One Number and One Special Character"),
    confirmpassword: yup.string().oneOf([yup.ref('newpassword'), null], 'Passwords must match').required('Confirm Password is required'),
})

export { TransportChangePasswordValidateSchema, TransportChangePasswordInitialState }


