import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from 'react-router-dom';
import { Toast } from "../PrimeReactComponents";
 import {rowCount} from './controllers/common/Query'
import { useDispatch, useSelector } from 'react-redux';
import { listData} from "./controllers/ConsignmentController";
import {listData as costomerListData  } from "./controllers/customerController";
import {listData as driverListData  } from "./controllers/DriverControllers/controllers";
import { REACT_APP_BASE_BACKEND_URL } from '../config';
import Loader from "../features/loader/Loader";
let BASE_BACKEND_URL = REACT_APP_BASE_BACKEND_URL

function Dashboard() {
  const dispatch = useDispatch();
  const userInfoSelector = useSelector(state => state.userInfo);
  const reciveLocalData= localStorage.getItem('transId')
  const reciveLocalUserIdData= localStorage.getItem('userId')
  const toast = useRef(null);
  const [consignmentCount, setConsignmentCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalCustomerRecords, setCustomerTotalRecords] = useState(0);
  const [totalDriverRecords, setDriverTotalRecords] = useState(0);
  const [loader, setLoading] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",
    sortOrder:false,
    filters: "",
    status:"",
    company_id:reciveLocalData,
    companyLocalId:reciveLocalData,
    globalFilterValue: "",
    last_name:"",
    first_name:"",
    pickup_date:"",
    delivery_date:"",
    job_number:"",
    filters: {
      'job_number': { value: '', matchMode: 'contains' },
      'manifest_number': { value: '', matchMode: 'contains' },
      'customer_address': { value: '', matchMode: 'contains' },
      'delivery_address': { value: '', matchMode: 'contains' },
      'pickup_date': { value: '', matchMode: 'contains' },
      'delivery_date': { value: '', matchMode: 'contains' },
      'driver_id': { value: '', matchMode: 'contains' },
    }
  });
  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();

  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

  loadLazyTimeout = setTimeout(() => {
    listData({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {  
      setTotalRecords(data?.data?.data?.count);
      setLoading(false);
    });
    costomerListData({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {
      setCustomerTotalRecords(data?.data?.count);
      setLoading(false);
    });
    driverListData({ lazyEvent: (lazyParams) }).then(data => {
      setDriverTotalRecords(data?.data?.data?.count);
      setLoading(false);
    });
  }, Math.random() * 1000 + 250);
  
}
  // React.useEffect(()=> {
  //   rowCount('consignments/getAllConsignments/'+reciveLocalData+'/'+reciveLocalUserIdData).then(({status, message, count})=> {    
  //     setConsignmentCount(count)
  //   }).catch((err)=> {    
  //     toast.current.show({severity:'info', summary: 'Info Message', detail: err.message , life: 3000,});    
  //   })
  // }, []) 

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto">
          <div className="d-flex container align-items-center py-5 pb-2">
            <div className="card w-100 bg-transparent shadow-none">
              <div className="card-body d-flex align-items-center">
                <figure style={{ width: "100px" , height:100}} className="rounded-circle overflow-hidden">
                {userInfoSelector?.resultData?.userData?.user_image && <img src={`${BASE_BACKEND_URL}${userInfoSelector?.resultData?.userData?.user_image}`} style={{"object-fit": "cover", "width": "100%", "height": "100%"}} alt="" />} 
                </figure>
                <div className="ms-4">
                  <h2 className="fz40">Welcome {userInfoSelector?.resultData?.userData?.first_name} {userInfoSelector?.resultData?.userData?.last_name}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex-grow-1  p-4 p-lg-5 dashboard_Page pt-lg-0">
            <div className="container d-flex flex-column h-100 ">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row g-5">
                    <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Customers</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../customers">
                                    <i className="fa-light me-2 fa-eye"></i> View Customers List
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="../../customers/add">
                                    <i className="fa-light me-2 fa-plus"></i> Add New Customers
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-danger m-auto">
                          <h5 className="fw-bold  mb-0">{totalCustomerRecords?totalCustomerRecords:0}</h5>
                          <p className="fw500 mb-0">Total Customers</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Consignments</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../consignments">
                                    <i className="fa-light me-2 fa-eye"></i> View Consignment List
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="../../consignments/add">
                                    <i className="fa-light me-2 fa-plus"></i> Add New Consignment
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-success m-auto">
                          <h5 className="fw-bold  mb-0">{totalRecords?totalRecords:0}</h5>
                          <p className="fw500 mb-0">Total Consignment</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Users</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../user">
                                    <i className="fa-light me-2 fa-eye"></i> View Users List
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="../user/add">
                                    <i className="fa-light me-2 fa-plus"></i> Add New User
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-info m-auto">
                          <h5 className="fw-bold  mb-0">950</h5>
                          <p className="fw500 mb-0">Total Active Users</p>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Drivers</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../drivers">
                                    <i className="fa-light me-2 fa-eye"></i> View Drivers List
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="../drivers/add">
                                    <i className="fa-light me-2 fa-plus"></i> Add New Driver
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-warning m-auto">
                          <h5 className="fw-bold  mb-0">{totalDriverRecords?totalDriverRecords:0}</h5>
                          <p className="fw500 mb-0">Total Drivers</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Invoice</h4>
                        </div>
                        <div className="text-center text-dark m-auto">
                          <h5 className="fw-bold  mb-0"></h5>
                          <p className="fw500 mb-0">Total Active Invoicing</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
