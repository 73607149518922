
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REACT_APP_API_URL } from '../../../config'

export const consignmentEditThunk = createAsyncThunk('consignment-edit', async (id) => {
  const baseAPIUrl = REACT_APP_API_URL

  const res = await fetch(baseAPIUrl + `consignments/getConsignmentById/${id}`)
  return await res.json();
})

export const consignmentUpdateThunk = createAsyncThunk('consignment-update', async (id, body) => {
  const baseAPIUrl = REACT_APP_API_URL 
 
  const res = await fetch(baseAPIUrl + `consignments/updateConsignment/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
  return await res.json();

})


const ConsignmentEditReducer = createSlice({
  name: 'consignment',
  initialState: { consignment: {} },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(consignmentEditThunk.pending, (state, action) => {
        console.log('pending')
      })
      .addCase(consignmentEditThunk.fulfilled, (state, { payload: { data } }) => {
        //  console.log('fulfilled', data)
        state.consignment = data
      })
      .addCase(consignmentEditThunk.rejected, (state, action) => {
        console.log('rejected')
      })

      .addCase(consignmentUpdateThunk.pending, (state, action) => {
        console.log('pending')
      })
      .addCase(consignmentUpdateThunk.fulfilled, (state, { payload: { data } }) => {
        //  console.log('fulfilled', data)
        state.consignment = data
      })
      .addCase(consignmentUpdateThunk.rejected, (state, action) => {
        console.log('rejected')
      })
  }
})

export default ConsignmentEditReducer.reducer