import axios from "axios";
let access_token=localStorage.getItem('keyCloakaccesstokenData');
//console.log("access_token",access_token);
const app = axios.create({
    headers: {
        Authorization: "Bearer "  + access_token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
    withCredentials: false
})

export default app;