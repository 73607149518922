import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Dropdown, Button } from "../../BootstrapComponents";
import { InputText, DataTable, Column, ConfirmDialog, confirmDialog, Toast, confirmPopup } from "../../PrimeReactComponents";
import FilterOffset from "./components/OffsetRight";
import RequestEmail from "./components/RequestEmail";
import dataTableConfig from "./config/DatatableConfig";
import { listData, deleteData, title, activeInactive, activeInactiveBulk, removeDataBulk } from "../controllers/customerController";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter'
import Moment from 'react-moment';
import Loader from "../../features/loader/Loader";
import { useResizeListener } from 'primereact/hooks';


function List() {
  const { transportUserInfo } = useSelector(state => state);
  const toast = useRef(null);
  const navigate = useNavigate();
  const reciveLocalData = transportUserInfo.company_id;
  const [tableHeight, setTableHeight] = React.useState("0px");
  const [selectedCheckbox, setSelectedCheckbox] = React.useState(null);
  const [customersData, setCustomersData] = useState()
  const [filters, globalFilterValue, onGlobalFilterChange] = useDataTableFilter();
  const [loader, setLoader] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  //const [loader, setloader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const [offsetFilter, setoffsetFilter] = useState(false);
  // const [urlId,seturlId] =useState("44eef57e-8d41-4cae-a9fa-876983a6ea2c")


  let customerListArr = []
  let customerArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Customers')?.map(val => {
    if (!customerListArr.includes(val?.menuData?.menu_name)) {
      customerListArr.push(val?.menuData?.menu_name)
    }

    if (!customerArr.includes(val?.menuActionData?.action_name)) {
      customerArr.push(val?.menuActionData?.action_name)
    }
  })

  if (customerListArr.length == 0) {
    navigate('/transport/404');
  }


  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
});

useEffect(() => {
  bindWindowResizeListener();

  return () => {
      unbindWindowResizeListener();
  };
}, [bindWindowResizeListener, unbindWindowResizeListener]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    status: "",
    globalFilterValue: "",
    companyLocalId: reciveLocalData,
    filters: {
      'customer_number': { value: '', matchMode: 'contains' },
      'company_name': { value: '', matchMode: 'contains' },
      'first_name': { value: '', matchMode: 'contains' },
    }
  });

  const { id } = useParams();
  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();

  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {

        setTotalRecords(data?.data?.count);
        setCustomers(data?.data?.rows);
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
    }, []);

  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }

  const onSelectionChange = (event) => {
    const value = event.value;
    setSelectedCustomers(value);
    setSelectAll(value.length === totalRecords);
  }

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      listData.then(data => {
        setSelectAll(true);
        // console.log('kkkkkkkkk', data)
        setSelectedCustomers(data.rows);
      });
    }
    else {
      setSelectAll(false);
      setSelectedCustomers([]);
    }
  }
  const activeInactiveStatus = (status, id) => {
    setLoader(true)
    activeInactive(status, id).then(({ type, message }) => {
      setIsListUpdated(true)
      setLoader(false)
      loadLazyData();
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {
    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data.status === '1' ? 'Inactivate' : 'Activate'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data.status, data.customer_id);
      }
    });
  }
  const advanceSearch = (value) => {
    //console.log("value.....",value);
    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.customer_number, status: value.status });
      setoffsetFilter(true);

    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }
  const onGlobalFilterChange1 = (event) => {

    const value = event.target.value;
    if (value.length > 2) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
    } else if (value.length === 0) {
      // loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });
    }

  }

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2">
          <Link to={"edit/" + rowData?.customer_id} className={`btn fz16 p-0 edit-btn  ${customerArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`}>
            <i className="fa-light fa-edit"></i>
          </Link>
          <Button className={`deleteRow btn fz16 p-0 delete-btn  ${customerArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} variant="" onClick={() => deleteConfirm(rowData.customer_id)}>
            <i className="fa-light fa-trash"></i>
          </Button>
          <Link to={"view/" + rowData?.customer_id} className={`btn p-0 fz16  ${customerArr.includes('View') ? '' : 'border-0 shadow-none disabled'}`}>
            <i className="fa-light fa-eye"></i>
          </Link>
        </div>
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return <span onClick={(e) => activeInactiveHandler(e, rowData)} className={`fw600 border-0 badge py-2 fs-5 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Active" : "Inactive"}</span>;
  };
  const userBodyTemplate = (rowData) => {
    return <RequestEmail customer_company_name={rowData.customer_company_name} company_id={rowData.company_id} relationid={rowData.customer_id} />;
  };

  const customerDetailsTemplate = (rowData) => {
    return <span>{`${rowData.customer_company_name} P: +${rowData.phone}  E: ${rowData.email_id}  `}</span>;
  };

  const contactTemplate = (rowData) => {
    return <span>{`${rowData.first_name} ${rowData.last_name} `}</span>;
  };


  const createdDateTemplate = (rowData) => {
    return <span>{rowData?.createdAt !== '' ? <Moment date={rowData?.createdAt} format="D MMM YYYY" /> : ''}</span>
  }

  const createdByTemplate = (rowData) => {
    return <span>{rowData?.userData?.first_name} {rowData?.userData?.last_name} </span>
  }



  const deleteConfirm = (data) => {
    confirmDialog({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteData(data).then(res => {
          toast?.current?.show({ severity: 'success', detail: 'Deleted Successfully', life: 3000, });
          loadLazyData();
          setLoader(false)
          setTimeout(() => {
            navigate('/transport/customers')
          }, 3000)

        })

      },
      reject: () => {
        console.log('Not delete')
      }
    });
  }


  // BULK CHANGES

  const activeInactiveStatusBulk = (status, id) => {
    setLoader(true)
    activeInactiveBulk(status, id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail:id.length> 0 ? message:"No customer seleted ", life: 3000, });
    })
  }

  const bulkActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.customer_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }

  const bulkDeActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.customer_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Inactivate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("0", selectedId);
      }
    });
    setSelectedCustomers();
  }

  const DeleteBulk = (id) => {
    setLoader(true)
    removeDataBulk(id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail:id.length > 0? message:"No customer selected to delete", life: 3000, });
    })
  }
  const bulkDelete = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.customer_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }

  
  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-user"></i> &nbsp; Customers Management
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to="./add" className={`bg-green text-white btn fz14 me-3 ${customerArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}>
                  <i className="fa-light fa-plus-circle"></i> Add New Customer
                </Link>
                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <ul>
                      <li>
                        <Button className={`dropdown-item ${customerArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} to="#" onClick={() => bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={() => bulkActivate()} >
                          <i className="fa-light me-2 fa-check"></i>
                          Active
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={() => bulkDeActivate()}>
                          <i className="fa-light me-2 fa-ban"></i>
                          Inactive
                        </Button>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={customers} lazy selectionMode="checkbox" dataKey="customer_number" {...{ scrollHeight: tableHeight }} scrollDirection="both"
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)} {...dataTableConfig}
            >
              <Column headerStyle={{ width: "3em" }} selectionMode="multiple" frozen></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }}></Column>
              <Column field="customer_number" header="Customer ID" sortable style={{ flexGrow: 1, width: "200px" }}></Column>
              <Column field="customer_company_name" body={customerDetailsTemplate} header="Customer Details" sortable style={{ flexGrow: 1, flexBasis: "330px" }}></Column>
              <Column field="first_name" body={contactTemplate} header="Contact Person" sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="createdAt" body={createdDateTemplate} header="Created On" sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="created_by" body={createdByTemplate} header="Created By" sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column header="Users" body={userBodyTemplate} style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
