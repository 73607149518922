import * as yup from 'yup';

const manifestInitialState = {
    company_id: "",
    title: "",
    carrier: "",
    phone: "", 
    dispatch_date: "",     
    manifest_number: "",     
    pallet_dkt: "",     
    manifests: [{
      consignment_no: "",  
      from_company_name: "",
      to_company_name: "",
      to_address: "",
      to_city: "",
      to_postcode: "",
      total_pallets: "",
      total_spaces: "",
      weight: "",
      job_temp: ""      
    }]
   
  };

const manifestValidateSchema = yup.object().shape({
        title:yup.string().min(4, 'This Field must be at least 4 characters').max(8 , 'This Field must be at most 8 characters').required('This Field is required'),
        carrier:yup.string().min(2 , 'This Field must be at least 2 characters').max(30,  'This Field must be at most 30 characters').required('This Field is required'),
        phone: yup.number().typeError("That doesn't look like Phone Number").integer("This Field can't include a decimal point").min(10, 'This Field must be at least 10 characters').required('This Field is required'),                    
        dispatch_date:yup.string().required('This Field is required'),
        manifest_number: yup.string().required('This Field is required'), 
        pallet_dkt:yup.number().typeError("That doesn't look like Pallet Number").required('This Field is required').integer("This Field can't include a decimal point").min(6, 'This Field must be at least 6 characters'),
        
        manifests :yup.array().of( yup.object().shape({                      
            from_company_name:yup.string().min(2 , 'This Field must be at least 2 characters').required('This Field is required'),
            to_company_name:yup.string().required('This Field is required'),
            to_address:yup.string().min(2 , 'This Field must be at least 2 characters').required('This Field is required'),
            to_city:yup.string().min(2 , 'This Field must be at least 2 characters').required('This Field is required'),
            to_postcode:yup.number().typeError("That doesn't look like Postal Code").required('This Field is required').integer("This Field can't include a decimal point").min(6, 'This Field must be at least 6 characters'),
            total_pallets:yup.number().typeError("That doesn't look like Total Pallets").required('This Field is required').integer("This Field can't include a decimal point").min(1, 'This Field must be at least 1 characters'),
            total_spaces:yup.number().typeError("That doesn't look like Total Space").required('This Field is required').integer("This Field can't include a decimal point").min(1, 'This Field must be at least 1 characters'),
            weight:yup.number().typeError("That doesn't look like weight Number").required('This Field is required').integer("This Field can't include a decimal point").min(1, 'This Field must be at least 1 characters'),
            job_temp:yup.string().min(1 , 'This Field must be at least 2 characters').required('This Field is required')
        })      
    )
})


const maniFilterInitialState = {
  title: '',
  manifestNo: ''
}

const maniFilterSchema = yup.object().shape({
  title: yup.string().min(2, 'This Field must be at least 2 characters').max(30, 'This Field must be at most 30 characters').required('This Field is required'),
  manifestNo: yup.string().required('This Field is required')
})

export {manifestValidateSchema , manifestInitialState,maniFilterInitialState,maniFilterSchema}