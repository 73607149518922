import {createSlice} from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
    name:'user_info',
    initialState: {},
    reducers:{       
        setUserInfo(state, {payload}){                 
            return  state = payload;
        }

    }
})

export  const {setUserInfo} = userInfoSlice.actions;
export default userInfoSlice.reducer;