import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { InputText, Dropdown as Select, confirmDialog, Toast, Calendar } from "../../PrimeReactComponents";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { driverValidateSchema, driverInitialState } from '../Validations/driversValidation';
import { getCountry, getState, uploadDocument } from '../../services/commonController';
import { addData, singleData, editData, removeDriverDocument, removeDriverNote, toValidateEmailIdExistOrNot } from '../controllers/DriverControllers/controllers';

import states from '../json_files/states.json';
import Loader from "../../features/loader/Loader";
import { companyThunk } from "../../redux/company/companyAddSlice";
import { useEffect } from "react";

import { REACT_APP_BASE_BACKEND_URL } from '../../config';
import { documentTypeDropDawnData } from "../controllers/SetUpController";
import { getRandomNumber } from '../../utility/helper';

function Add(props) {
  const { transportUserInfo } = useSelector(state => state);
  const reciveLocalData = transportUserInfo.company_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [allDocument, setAllDocument] = useState();
  const toast = useRef(null);
  const [loader, setloader] = useState();
  const [initialState, setInitialState] = useState(driverInitialState);
  const { id } = useParams();
  const [country, setCountry] = useState();
  const [states, setStates] = useState();

  const [userExistReadOnly, setUserExistReadOnly] = useState(false);
  const [userName, setUserName] = useState({ username: '' });
  const pathname = window.location.pathname


  let driversArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Drivers')?.map(val => {
    if (!driversArr.includes(val?.menuActionData?.action_name)) {
      driversArr.push(val?.menuActionData?.action_name)
    }
  })

  if (pathname.split('/')[3] === 'add') {
    if (!driversArr.includes('Add')) {
      navigate('/transport/404');
    }
  }

  if (pathname.split('/')[3] === 'edit') {
    if (!driversArr.includes('Edit')) {
      navigate('/transport/404');
    }
  }


  let reciveResponse = [];
  let dataToUpdate = []
  const getAllCountries = async () => {
    const results = await getCountry();
    setCountry(results.data)
  }

  const getAllStates = async () => {
    const results = await getState();
    setStates(results.data)
  }
  useEffect(() => {
    getAllCountries()
    getAllStates()
    getAllTruckTypeData(reciveLocalData)
    setInitialState(prev => ({ ...prev, drivercode: getRandomNumber('DRI') }))
    getSingleData(id)

  }, [id])

  const getSingleData = (id) => {
    if (id) {
      const response = singleData(id);
      setloader(true)
      response.then(({ status, message, type, error, data }) => {

        

        if (status) {
          setloader(false)
          setInitialState({
            drivercode: data?.data?.driver_number,
            title: data?.data?.title,
            company_id: data?.data?.company_id,
            fname: data?.data?.first_name,
            lname: data?.data?.last_name,
            username: data?.data?.user_name,
            driverphone: data?.data?.phone,
            drivermobile: data?.data?.mobile,
            emergencycontactname: data?.data?.emergency_contact_name,
            emergencyphone: data?.data?.emergency_phone,
            contactrelation: data?.data?.contact_relation,
            address: data?.data?.address,
            suburb: data?.data?.suburb,
            zipcode: data?.data?.zip_code,
            state_id: data?.data?.state_id,
            country_id: data?.data?.country_id,
            // multiple_documents:{...data?.data?.driver_document,license_expiry_date:new Date(data?.data?.driver_document?.license_expiry_date)},  
            multiple_documents: data?.data?.driver_document.map((item, index) => {
              item['license_expiry_date'] = new Date(item.license_expiry_date);
              item['issue_date'] = new Date(item.issue_date);
              item['driverdocument'] = item.document;
              return item;
            }),
            notes: data?.data?.driver_notes
          })

          setloader(false)
          // console.log("this is log data",initialState);
        } else {
          toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          setloader(false)
        }
      })
    }
  }


  const handleOnChange = async (event, index) => {
    if (event.target.files[0].name !== null) {
      let image_name = event.target.files[0]
      if (image_name !== undefined) {
        const formData = new FormData();
        formData.append(`driver_doc`, image_name)
        const headers = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        const res = await uploadDocument(formData, headers)
        reciveResponse.push(res.data);
        if (id) {
          dataToUpdate.push({ [index]: res.data });
        }

      }
    }

  };
  // const licensTypeOption=[
  //    {  value :"1", label:"Type1"},
  //    { value :"2", label:"Type2"}
  // ]
  const forkitTypeOption = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" }
  ]
  const getAllTruckTypeData = async (reciveLocalData) => {
    const results = await documentTypeDropDawnData(reciveLocalData);
    setAllDocument(results?.data?.data)
  }
  const licensTypeOption = () => {
    const option = [];
    allDocument?.map((ele) => {
      option.push({ value: ele.document_id, label: ele.document_type })
    })
    return option;
  }
  const postData = (values, { resetForm }) => {


    let license_type = [];
    let license_expiry_date = [];
    let issue_date = [];
    let forklift_license = [];
    let document_id = [];
    let modifyedDocument = [];

    /* 
        const multipalLoopData = values.multiple_documents.map((data, index) => { 
          license_type.push(data.license_type ? data.license_type : "");
          license_expiry_date.push(data.license_expiry_date ? new Date(data.license_expiry_date) : "");
          issue_date.push(data.issue_date ? data.issue_date : "");
          forklift_license.push(data.forklift_license ? data.forklift_license : "");
          document_id.push(data.document_id ? data.document_id : "");
          modifyedDocument.push(data.document_id && "");
    
        })
    
    
        let concatedData = modifyedDocument.concat(reciveResponse);
    
        let filtedConcatedData = concatedData.filter((item, index) => item !== undefined)
        let modifyedData = { ...values, company_id: reciveLocalData, role_type: '1', user_type_id: '4', multiple_documents: { driverdocument: reciveResponse, license_type: license_type, license_expiry_date: license_expiry_date, issue_date: issue_date, forklift_license: forklift_license } }
     */

    let modifyedData = { ...values, company_id: reciveLocalData, role_type: '1', user_type_id: '4' }

    let modifyedData1 = {
      ...values, company_id: reciveLocalData, multiple_documents: {
        driverdocument: dataToUpdate,
        licensetype: license_type, licenseexpiry: license_expiry_date,
        issuedate: issue_date, forkliftlicense: forklift_license, document_id: document_id
      }
    }


    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        if (id) {
          editData(modifyedData1, id).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ modifyedData: '' })
              toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
            } else {
              setloader(false)
              toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
            }

            setTimeout(() => {
              navigate('/transport/drivers')
            }, 3000)

            setloader(false)
          })
        } else {

          addData(modifyedData).then(({ status, type, message }) => {

            if (status) {
              setloader(false)
              resetForm({ modifyedData: '' })
              toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
            } else {
              setloader(false)
              toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
            }
            setTimeout(() => {
              navigate('/transport/drivers')
            }, 3000)

          })
        }
      }
    });
  }
  const getStateOption = (CoutryId) => {
    const option = [];
    states?.filter((ele) => {
      if (ele.country_id == CoutryId) {
        option.push({ value: ele.id, label: ele.state_name })
      }
    })
    return option;
  }
  const getCountryOption = () => {
    const option = [];
    country?.map((ele) => {
      option.push({ value: ele.id, label: ele.country_name })
    })
    return option;
  }
  const removeDriverDoc = (id) => {
    removeDriverDocument(id)
  }

  const toCheckEmailIdExistOrNot = async (emailId, setFieldValue) => {
    setUserExistReadOnly(false)

    if (emailId !== '') {

      // setUserName({username:emailId}) 

      const response = await toValidateEmailIdExistOrNot(emailId);

      if (response?.data !== null && response?.data !== '') {

        setInitialState({
          drivercode: response?.data.driver_number,
          title: response?.data.title,
          company_id: response?.data.company_id,
          fname: response?.data.first_name,
          lname: response?.data.last_name,
          username: response?.data.user_name,
          driverphone: response?.data.phone,
          drivermobile: response?.data.mobile,
          emergencycontactname: response?.data.emergency_contact_name,
          emergencyphone: response?.data.emergency_phone,
          contactrelation: response?.data.contact_relation,
          address: response?.data.address,
          suburb: response?.data.suburb,
          zipcode: response?.data.zip_code,
          state_id: response?.data.state_id,
          country_id: response?.data.country_id,
          multiple_documents: response?.data.driver_document.map((item, index) => {
            item['license_expiry_date'] = new Date(item.license_expiry_date);
            item['issue_date'] = new Date(item.issue_date);
            item['driverdocument'] = item.document;
            return item;
          }),
          notes: response.data.driver_notes
        });

        setUserExistReadOnly(true)
      }

      else {

        // setInitialState(({...driverInitialState, username:userName?.username}))  

        // setInitialState(prev => ({ ...prev, drivercode: getRandomNumber('DRI') }))

      }
    }
  }

  const deleteEntry = (driver_id_note) => {
    setloader(true);
    removeDriverNote(driver_id_note).then(({ type, message }) => {
      setloader(false);
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const deleteConfirm = (driver_id_note) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(driver_id_note);
        getSingleData(id);
      }
    });

  }

  let loadImage = function (val) {


    var image = new Image();
    var url_image = REACT_APP_BASE_BACKEND_URL + val?.document;
    image.src = url_image;

 
    if (image.width === 0) { 
      if (val?.document?.split('.')?.pop() === 'pdf') { 
        return val?.document !== null && val?.document !== undefined && (((val?.document?.split('.')?.pop() === 'pdf')) ? <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> : <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank"> <img src={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} width="100" /></a>)
      } else { 
        return <img src={`${REACT_APP_BASE_BACKEND_URL}` + 'default_image.jpg'} width="100" />;
      } 
     
    } else {   
      return val?.document !== null && val?.document !== undefined && (((val?.document?.split('.')?.pop() === 'pdf')) ? <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> : <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank"> <img src={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} width="100" /></a>)
    }
  }



  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <Formik enableReinitialize initialValues={initialState} validationSchema={driverValidateSchema} onSubmit={postData} render={({ values, handleChange, setFieldValue }) => (

        <div className="page-wrapper-without-aisde">
          <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
            <div className="container d-flex flex-column h-100 ">
              <Form className="d-flex flex-column  h-100" encType="multipart/form-data">
                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                      <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">{(id === "" || id === undefined) ? "Add New Driver" : "Update Driver"}</h2>
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      <Link to="../" className="btn-gradient btn fz14 me-3">
                        <i className="fa-regular fa-times"></i> Cancel
                      </Link>
                      <button type="submit" className="bg-green text-white btn fz14 ">
                        <i className="fa-regular fa-save"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">
                  <div className="card p-4">
                    <div className="card-body">
                      <div className="row gx-5">
                        <input type="text" className="form-control px-3" hidden />
                        <div className="col-12 mb-3">
                          <h4 className="fw600 fz16 text-dark">Driver</h4>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Username / Email Address  <span className="text-danger">*</span></label>
                          <Field name="username" render={({ field }) => (
                            <>
                              <InputText className="form-control px-3" {...field} onBlur={(e) => { toCheckEmailIdExistOrNot(e.target.value, setFieldValue) }} disabled={(userExistReadOnly == false && id)} />
                              <ErrorMessage name="username" component="span" className="from-error-span" />
                            </>
                          )} />
                        </div>
                        {id && <>
                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">Title  <span className="text-danger">*</span></label>
                            <Field name="title" render={({ field }) => (
                              <>
                                <InputText className="form-control px-3" {...field} disabled={(userExistReadOnly || id)} />
                                <ErrorMessage name="title" component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>
                        </>}

                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2 d-block">First Name  <span className="text-danger">*</span></label>
                          <Field name="fname" render={({ field }) => (
                            <>
                              <InputText className="form-control px-3" {...field} disabled={(userExistReadOnly == false && id)} />
                              <ErrorMessage name="fname" component="span" className="from-error-span" />
                            </>
                          )} />

                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Last Name  <span className="text-danger">*</span></label>
                          <Field name="lname" render={({ field }) => (
                            <>
                              <InputText className="form-control px-3" {...field} disabled={(userExistReadOnly == false && id)} />
                              <ErrorMessage name="lname" component="span" className="from-error-span" />
                            </>
                          )} />

                        </div>
                        {id && <>
                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2 d-block">Driver Number  <span className="text-danger">*</span></label>
                            <Field name="drivercode" render={({ field }) => (
                              <>
                                <InputText className="form-control px-3" {...field} disabled />
                                {/* <ErrorMessage name="drivercode" component="span" className="from-error-span" /> */}
                              </>
                            )} />

                          </div>


                          {/*     <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2 d-block">First Name  <span className="text-danger">*</span></label>
                            <Field name="fname" render={({ field }) => (
                              <>
                                <InputText className="form-control px-3" {...field} disabled={userExistReadOnly || id} />
                                <ErrorMessage name="fname" component="span" className="from-error-span" />
                              </>
                            )} />

                          </div>
                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2 d-block">Last Name  <span className="text-danger">*</span></label>
                            <Field name="lname" render={({ field }) => (
                              <>
                                <InputText className="form-control px-3" {...field} disabled={userExistReadOnly || id} />
                                <ErrorMessage name="lname" component="span" className="from-error-span" />
                              </>
                            )} />

                          </div> */}

                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">Phone  <span className="text-danger">*</span></label>
                            <Field name="driverphone" render={({ field }) => (
                              <>
                                <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int" disabled={userExistReadOnly || id} />
                                <ErrorMessage name="driverphone" component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>
                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">Mobile  <span className="text-danger">*</span></label>
                            <Field name="drivermobile" render={({ field }) => (
                              <>
                                <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int" disabled={userExistReadOnly || id} />
                                <ErrorMessage name="drivermobile" component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>

                          <div className="col-12 mt-4 mb-3">
                            <h4 className=" fw600 fz16 text-dark">Emergency Contact Details  </h4>
                          </div>
                          <div className="overflow-auto">
                            <table className="table multiple-feilds-table">

                              <tbody>

                                <tr>
                                  <td className="col-sm-4 mb-4">
                                    <label htmlFor="" className="mb-2">Name  </label>
                                    <Field name="emergencycontactname" render={({ field }) => (
                                      <>
                                        <InputText {...field} className="form-control px-3" disabled={userExistReadOnly || id} />
                                        <ErrorMessage name="emergencycontactname" component="span" className="from-error-span" />
                                      </>
                                    )} />

                                  </td>
                                  <td className="col-sm-4 mb-4">

                                    <label htmlFor="" className="mb-2">Phone Number  </label>
                                    <Field name="emergencyphone" render={({ field }) => (
                                      <>
                                        <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int" disabled={userExistReadOnly || id} />
                                        <ErrorMessage name="emergencyphone" component="span" className="from-error-span" />
                                      </>
                                    )} />

                                  </td>
                                  <td className="col-sm-4 mb-4">

                                    <label htmlFor="" className="mb-2">Relationship </label>
                                    <Field name="contactrelation" render={({ field }) => (
                                      <>
                                        <InputText {...field} className="form-control px-3" disabled={userExistReadOnly || id} />
                                        <ErrorMessage name="contactrelation" component="span" className="from-error-span" />
                                      </>
                                    )} />

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="col-12 mt-4 mb-3">
                            <h4 className=" fw600 fz16 text-dark">Address Details   </h4>
                          </div>

                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">Address  <span className="text-danger">*</span></label>
                            <Field name="address" render={({ field }) => (
                              <>
                                <InputText {...field} className="form-control px-3" disabled={userExistReadOnly || id} />
                                <ErrorMessage name="address" component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>
                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">Suburb  <span className="text-danger">*</span></label>
                            <Field name="suburb" render={({ field }) => (
                              <>
                                <InputText {...field} className="form-control px-3" disabled={userExistReadOnly || id} />
                                <ErrorMessage name="suburb" component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>
                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">Zip Code  <span className="text-danger">*</span></label>
                            <Field name="zipcode" render={({ field }) => (
                              <>
                                <InputText {...field} className="form-control px-3" keyfilter="int" maxLength={4} minLength={4} disabled={userExistReadOnly || id} />
                                <ErrorMessage name="zipcode" component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>

                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">Country  <span className="text-danger">*</span></label>
                            <Field name={`country_id`} render={({ field }) => (
                              <>
                                <Select className="w-100 px-3"  {...field} options={getCountryOption()} placeholder="Please select a country" disabled={userExistReadOnly || id} />
                                <ErrorMessage name={`country_id`} component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>
                          <div className="col-sm-6 mb-4">
                            <label htmlFor="" className="mb-2">State  <span className="text-danger">*</span></label>
                            <Field name={`state_id`} render={({ field }) => (
                              <>
                                <Select className="w-100 px-3"  {...field} options={getStateOption(values.country_id)} placeholder="Please select a state" disabled={userExistReadOnly || id} />
                                <ErrorMessage name={`state_id`} component="span" className="from-error-span" />
                              </>
                            )} />
                          </div>
                          <div className="col-12 mt-4 mb-3">
                            <h4 className=" fw600 fz16 text-dark">Multiple Documents</h4>
                            <div className="overflow-auto mt-3 ">
                              <table className="table  multiple-feilds-table">
                                <thead>
                                  <tr>
                                    <th className="fw500 fz14" width="30px">
                                      #
                                    </th>
                                    <th className="fw500 fz14" width="120px">
                                      License Types
                                    </th>
                                    <th className="fw500 fz14">Upload Licence (jpg,jpeg,png,pdf)</th>

                                    <th className="fw500 fz14" width="180px">
                                      Issue date
                                    </th>
                                    <th className="fw500 fz14" width="160px">
                                      License Expiry date
                                    </th>
                                    <th className="fw500 fz14" width="130px">
                                      Forklift License
                                    </th>
                                    <th className="fw500 fz14" width="30px"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <FieldArray name="multiple_documents" render={({ push, remove }) => (
                                    (values.multiple_documents.length > 0 ? values.multiple_documents : driverInitialState.multiple_documents).map((val, idx) => (
                                      <tr key={idx}>

                                        <td>{idx + 1}</td>
                                        <td>
                                          <Field name={`multiple_documents[${idx}].license_type`} render={({ field }) => (
                                            <>
                                              <Select className="w-100 px-3"  {...field}
                                                options={licensTypeOption()}
                                                //onChange={(e) => getState(e, setFieldValue)}
                                                placeholder="Please select a type" disabled={userExistReadOnly || id} />
                                              {/* <ErrorMessage name={`multiple_documents[${idx}].license_type`} component="span" className="from-error-span" /> */}
                                            </>
                                          )} />

                                        </td>
                                        <td>

                                          {/*  <Field name={`multiple_documents[${idx}].driverdocument`} render={({ field }) => (
                                          <>
                                            <input type="file" value={values.multiple_documents[idx].driverdocument?.target?.files[0].name} className="form-control px-3"  {...field} onChange={(e) => handleOnChange(e, idx)} />

                                            {id !== undefined && (((val?.document?.split('.')?.pop() === 'pdf')) ? <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> : <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank"> <img src={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} width="100" /></a>)}

                                            <ErrorMessage name={`multiple_documents[${idx}].driverdocument`} component="span" className="from-error-span" />

                                          </>
                                        )}
                                        /> */}

                                          <Field name="multiple_documents[${idx}].driverdocument" render={({ field }) => <input type="file" className="form-control px-3" onChange={(e) => { handleOnChange(e, idx); setFieldValue(`multiple_documents[${idx}].driverdocument`, e.target.files[0].name) }} disabled={userExistReadOnly || id} />} />

                                          {/* {val?.document !== null && val?.document !== undefined && (((val?.document?.split('.')?.pop() === 'pdf')) ? <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank">View <i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> : <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} target="_blank"> <img src={`${REACT_APP_BASE_BACKEND_URL}${val?.document}`} width="100" /></a>)} */}

                                          {/* <ErrorMessage name={`multiple_documents[${idx}].driverdocument`} component="span" className="from-error-span" /> */}

                                          {loadImage(val)}

                                        </td>
                                        <td>
                                          <Field name={`multiple_documents[${idx}].issue_date`} render={({ field }) => (
                                            <>
                                              <Calendar dateFormat="dd/mm/yy" maxDate={val.license_expiry_date} inputClassName="form-control px-3" className="w-100" {...field} disabled={userExistReadOnly || id} />
                                              {/* <ErrorMessage name={`multiple_documents[${idx}].issue_date`} component="span" className="from-error-span" /> */}
                                            </>
                                          )} />

                                        </td>
                                        <td>
                                          <Field name={`multiple_documents[${idx}].license_expiry_date`} render={({ field }) => (
                                            <>
                                              <Calendar dateFormat="dd/mm/yy" minDate={val.issue_date} inputClassName="form-control px-3" className="w-100" {...field} disabled={userExistReadOnly || id} />
                                              {/* <ErrorMessage name={`multiple_documents[${idx}].license_expiry_date`} component="span" className="from-error-span" /> */}
                                            </>
                                          )} />

                                        </td>

                                        <td>
                                          <Field name={`multiple_documents[${idx}].forklift_license`} render={({ field }) => (
                                            <>
                                              <Select className="w-100 px-3"  {...field}
                                                options={forkitTypeOption}
                                                //onChange={(e) => getState(e, setFieldValue)}
                                                placeholder="Select a forklift license"
                                                disabled={userExistReadOnly || id}
                                              />
                                              {/* <ErrorMessage name={`multiple_documents[${idx}].forklift_license`} component="span" className="from-error-span" /> */}
                                            </>
                                          )} />

                                        </td>
                                        <td>
                                          {idx > 0 ? (
                                            <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" onClick={() => {
                                              remove(idx)
                                              reciveResponse.splice(idx, 1)
                                              removeDriverDocument(val.document_id)
                                            }} >
                                              <i className="fa-regular fa-minus-circle "></i>
                                            </button>
                                          ) : (
                                            <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0" onClick={() => push(driverInitialState.multiple_documents[0])} disabled={userExistReadOnly || id}>
                                              <i className="fa-regular fa-plus-circle "></i>
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                    ))} />
                                </tbody>
                              </table>

                            </div>
                          </div>
                        </>}

                      </div>
                      {id && <>
                        <div className="col-12 mt-4 mb-3">
                          <h4 className=" fw600 fz16 text-dark"> Multiple Notes</h4>
                          <div className="overflow-auto mt-3 ">
                            <table className="table  multiple-feilds-table">
                              <thead>
                                <tr>
                                  <th className="fw500 fz14" width="30px">#</th>
                                  <th className="fw500 fz14">Notes </th>
                                  <th className="fw500 fz14" width="30px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name="notes" render={({ push, remove }) => (
                                  values.notes && values.notes.map((val, idx) => (
                                    <tr key={idx}>
                                      {/* <td>{idx + 1}</td> */}

                                      <td className="dynamicText">{idx + 1}</td>
                                      <td>
                                        <Field name={`notes[${idx}].notes_content`} render={({ field }) => (
                                          <>
                                            <textarea className="form-control px-3" {...field} />
                                            {/* <ErrorMessage name={`notes[${idx}].notes_content`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} /></td>
                                      <td>
                                        {values.notes.length - 1 === idx ? (
                                          <>
                                            {values.notes.length > 1 &&
                                              <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0"
                                                onClick={() => {
                                                  val?.notes_id ? deleteConfirm(val?.notes_id) : remove(idx)
                                                  // remove(idx)
                                                }}
                                              >
                                                <i className="fa-regular fa-minus-circle "></i>
                                              </button>}
                                            <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0"
                                              onClick={() => push(driverInitialState.notes[0])} >
                                              <i className="fa-regular fa-plus-circle "></i>
                                            </button>
                                          </>
                                        ) : (
                                          <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0"
                                            onClick={() => {
                                              val?.notes_id ? deleteConfirm(val?.notes_id) : remove(idx)
                                              // remove(idx)
                                            }}
                                          >
                                            <i className="fa-regular fa-minus-circle "></i>
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                  ))} />
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>}

                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )} />
    </>
  );
}

export default Add;
