import * as yup from 'yup';


const docManagerInitialState = {
    document_type: '',
    document_number: '',
    document_file: '',
    document_file_image: '',
    doc_manager_id: ''
};

const docManagerValidateSchema = yup.object().shape({
    document_type: yup.string().required('This Field is required'),
    document_number: yup.string().required('This Field is required'),
    // document_file: yup.string().required('This Field is required'),
})

export { docManagerValidateSchema, docManagerInitialState }