import * as yup from 'yup';



const connsignmentValidateSchema = yup.object().shape({

    job_number: yup.string().required('This Field is required'),
    booked_date: yup.string().required('This Field is required'),
    pickup_date: yup.string().required('This Field is required'),
    delivery_date: yup.string().required('This Field is required'),
    // special_instruction: yup.string().required('This Field is required'), 
    billing_customer: yup.string().required('This Field is required'), 
    billing_address_id: yup.string().required('This Field is required'),  
    pickup_customer_id: yup.string().required('This Field is required'),
    customer_address: yup.string().required('This Field is required'),
    suburb: yup.string().required('This Field is required'),
    zip_code: yup.string().min(4, 'This Field must be at least 4 characters').max(4, 'This Field must be maxium 4 characters').required('This Field is required'),
    state_id: yup.string().required('This Field is required'),
    country_id: yup.string().required('This Field is required'),
    delivery_name: yup.string().required('This Field is required'),
    delivery_address: yup.string().required('This Field is required'),
    delivery_suburb: yup.string().required('This Field is required'),
    delivery_zip_code: yup.string().min(4, 'This Field must be at least 4 characters').max(4, 'This Field must be maxium 4 characters').required('This Field is required'),
    delivery_state_id: yup.string().required('This Field is required'),
    delivery_country_id: yup.string().required('This Field is required'), 
    // driver_id: yup.string().required('This Field is required'),
    // driver_mobile_number: yup.number().min(10, 'This Field must be at least 10 characters').required('This Field is required'),
    /* chep: yup.string().required('This Field is required'),
    loscom: yup.string().required('This Field is required'),
    plain: yup.string().required('This Field is required'), */

    consignment_details: yup.array().of(
        yup.object().shape({
           /*  no_of_items: yup.number().typeError('This Field must be a number').required('This Field is required'),
            freight_desc: yup.string().required('This Field is required'),
            pallets: yup.number().typeError('This Field must be a number').required('This Field is required'),
            spaces: yup.number().typeError('This Field must be a number').required('This Field is required'),
            weight: yup.number().typeError('This Field must be a number').required('This Field is required'),
            job_temp: yup.string().typeError('This Field must be a number').required('This Field is required'),
            recipient_no: yup.string().required('This Field is required'),
            senders_no: yup.string().required('This Field is required')*/ 
        })
    ),
    truck_details: yup.array().of( 
        yup.object().shape({
          /*   document: yup.string().required('This Field is required'),
            truck_details: yup.string().required('This Field is required'),
            truck_number: yup.string().required('This Field is required') */
        })
    )

    
})

const consignmentInitialState = {
    //company_id:'4b78417f-bd5a-4fca-8928-f959140dcc56',
    job_number:  '',
    booked_date: '',
    pickup_date: '',
    delivery_date: '',
    special_instruction: '',
    manifest_number: '',
    billing_customer: '',
    billing_address_id: '', 
    pickup_customer_id: '',
    customer_id: '',
    customer_address: '',
    suburb: '',
    zip_code: '',
    state_id: '',
    country_id: '',
    delivery_name: '',
    delivery_address: '',
    delivery_suburb: '',
    delivery_zip_code: '',
    delivery_state_id: '',
    delivery_country_id: '',  
    driver_mobile_number: '',
    chep:'',
    loscom:'',
    plain:'',
    driver_id:'',
    consignment_details: [{
        no_of_items: '',
        freight_desc: '',
        pallets: '',
        spaces: '',
        weight: '',
        job_temp: '',
        recipient_no: '',
        senders_no: '' 
    }],
    truck_details: [{
        document: '',
        truck_details:'',
        truck_number:'',
        truck_document_id:'',
    }]
};
const conFilterInitialState = {
    job_number: ''
}

const conFilterSchema = yup.object().shape({
   // job_number: yup.string().min(2, 'This Field must be at least 2 characters').max(30, 'This Field must be at most 30 characters').required('This Field is required'),
    //status: yup.string().required('This Field is required')
})


export { connsignmentValidateSchema, consignmentInitialState, conFilterInitialState, conFilterSchema }