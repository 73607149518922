import { REACT_APP_API_URL } from '../../../config'
import axios from './../AxiosFile'; 

export async function updateProfileData(data, headers) {
    try {
        const res = await axios.put(`${REACT_APP_API_URL}profile/updateProfile`, data, headers);
        const result = await res.data; 
        
        if (result.status) {
            return {
                status: result.status,
                message: "Data Successfully Updated.",
                type: 'success',
                error: null,
                data: result.data.data,
            };
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
                data: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}

export async function ChangePasswordDataAPI(data) {
    try {
        const res = await axios.post(`${REACT_APP_API_URL}company/changepassword`, data);
        const result = await res.data; 
         
        if (result.status) {
            return {
                status: result.status,
                message: "Data Successfully Updated.",
                type: 'success',
                error: null,
                data: result?.data,
            };
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
                data: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}
  

