import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { InputText, confirmDialog, Toast } from "../../PrimeReactComponents";
import html2canvas from "html2canvas";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { jsPDF } from "jspdf";
import { Table, Modal, Button } from "../../BootstrapComponents";
import { singleData } from "../controllers/ConsignmentController";
import { consignmentMailInitState, connsignmentMailValidation } from '../Validations/ConsignmentMailValidation';
import { useSelector } from 'react-redux';
import Logo1 from '../../assets/img/logo-black.png'
import LogoIcon from '../../assets/img/logo-icon.png'
//import View from "./List";
import List from "./List";
import Moment from 'react-moment';
import { getCountry, getState, consignmentMailSend, generateConsignmentPDF, getFileName, getAllCustomer, getAllDriver, getAllCustomerAddress, getAllTruckType, getSingleCustomerAddressBook, getAllGeoLocationData } from '../../services/commonController';
import { Editor } from '@tinymce/tinymce-react';
import Loader from "../../features/loader/Loader";

function View(props) {
    const [initialState, setInitialState] = useState({});
    const [consignmentMailInitialState, setConsignmentMailInitialState] = useState(consignmentMailInitState);
    const [loader, setLoader] = useState();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [country, setCountry] = useState();
    const [deliveryCountry, setDeliveryCountry] = useState();
    const [allCustomer, setAllCustomer] = useState();
    const [pickupCustomerAddress, setPickupCustomerAddress] = useState();
    const [deliveryCustomerAddress, setDeliveryCustomerAddress] = useState();
    const [allGeoLocationData, setAllGeoLocationData] = useState();
    const pathname = window.location.pathname
    const [modalShow, setModalShow] = React.useState(false);
    const [body, setBody] = React.useState();
    const editorRef = useRef(null);
    const [imagename, setImagename] = useState(null);
    const [file, setFile] = useState(null);


    const userInfoSelector = useSelector(state => state.userInfo);

    // To set Role & Permissions 
    let consignmentArr = []
    let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

    transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Consignments')?.map(val => {
        if (!consignmentArr.includes(val?.menuActionData?.action_name)) {
            consignmentArr.push(val?.menuActionData?.action_name)
        }
    })

    if (pathname.split('/')[3] === 'view') {
        if (!consignmentArr.includes('View')) {
            navigate('/transport/404');
        }
    }


    const getAllCountries = async () => {
        const results = await getCountry();
        setCountry(results.data)
    }

    const getDeliveryAllCountries = async () => {
        const results = await getCountry();
        setDeliveryCountry(results.data)
    }

    const getAllCustomerData = async () => {
        const results = await getAllCustomer();
        setAllCustomer(results.data)
    }

    const getPickupAddressBook = async (companyId, Id) => {
        const results = await getSingleCustomerAddressBook(companyId, Id);
        setPickupCustomerAddress(results.data)
    }

    const getDeliveryAddressBook = async (companyId, Id) => {
        const results = await getSingleCustomerAddressBook(companyId, Id);
        setDeliveryCustomerAddress(results.data)
    }

    const getGeoLocation = async (consignmentId, driverId) => {
        let data = { consignment_id: consignmentId, driver_id: driverId }
        const results = await getAllGeoLocationData(data);
        setAllGeoLocationData(results.data)
    }

    const { id } = useParams();
    useEffect(() => {
        if (id) {
            const response = singleData(id);
            response.then(({ status, message, type, error, data }) => {
                if (status) {
                    setInitialState({
                        ...data.data
                    })

                    getAllCountries()
                    getDeliveryAllCountries()
                    getAllCustomerData()
                    getPickupAddressBook(data?.data?.company_id, data?.data?.pickup_customer_id)
                    getDeliveryAddressBook(data?.data?.company_id, data?.data?.delivery_name)
                    getGeoLocation(data?.data?.consignment_id, data?.data?.driver_id)

                    setLoader(false)

                } else {
                    toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                    setLoader(false)
                }
            })
        }

    }, [id])



    const downloadPdf = () => {
         let jobNumber = initialState?.job_number
        const input = document.getElementById("downloadPdf");
        html2canvas(input, {
            scale: 3,
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg');
                const pdf = new jsPDF();
                pdf.addImage(imgData, "JPEG", 15, 30, 0, 170);
                pdf.save(`${jobNumber}.pdf`);
            })
    }

    let country_name = country && country.filter(id => id.id === initialState?.country_id)[0]?.country_name
    let delivery_country_name = deliveryCountry && deliveryCountry.filter(id => id.id === initialState?.delivery_country_id)[0]?.country_name

    let pickup_customer_name = pickupCustomerAddress?.customer_company_name
    let delivery_customer_name = deliveryCustomerAddress?.customer_company_name

    allGeoLocationData?.rows && allGeoLocationData?.rows.map(val => {

    })

    function closeModal() {
        setModalShow(false);
    }

    let generatePdfData = async () => {
        let resData = await generateConsignmentPDF(id)
        if (resData) {
            let res = await getFileName(id) 
             setImagename(res.data.imagename)
             setFile(res.data.filename);
        }

    }

    function openModal(e) {
        generatePdfData()
        setTimeout(() => {
            setModalShow(true);
        },1000) 
        
    }

    let handleEditorChange = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            setBody(content);
        }
    }


    const saveFile = async (values, { resetForm }) => {
        let allValues = { ...values, body: body } 

        const formData = new FormData();

        formData.append('email_id', allValues.email_id);
        formData.append('subject', allValues.subject);
        formData.append('body', allValues.body);
        formData.append('imagename', imagename);
        formData.append('file', file);

        setLoader(true)
        consignmentMailSend(formData).then(({ status, type, message }) => {
            if (status) {
                setLoader(false)
                resetForm({ allValues: '' })
                toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                closeModal()
            } else {
                setLoader(false)
                toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                closeModal()

            }
        })

    };

    let countryNameDetails =  (country_name !== undefined) ? `, ${country_name}`:'' 
    let pickupZipCodeDetails = (initialState?.zip_code !== undefined) ? `, ${initialState?.zip_code}`:'' 

    let countryDeliveryDetails = (delivery_country_name !== undefined) ? `, ${delivery_country_name}`:'' 
    let deliverZipCodeDetails = (initialState?.delivery_zip_code !== undefined) ? `, ${initialState?.delivery_zip_code}`:''


    const base64ToBlob = (base64) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
    
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
    
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
    
        return new Blob(byteArrays, { type: 'application/octet-stream' });
      };
    
      const handleDownload = (base64String, fileName) => {
        const blob = base64ToBlob(base64String);
        const url = URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.pdf` || 'download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };

    return (

        <>
            <Loader show={loader} />
            <Toast ref={toast} position="top-center" />
            {/* {  console.log("selcted date.....",new Date("2023-08-31T21:15:29Z"))} */}
            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-auto flex-grow-1  px-5">
                    <div id="editor"></div>
                    <div className="d-flex justify-content-end gap-3 my-5">
                        <Link to="../" className="btn btn-gradient"><i className="fa-light fa-times"></i> Cancel</Link>
                        <button className="btn bg-green text-white" id="exportForm" onClick={() => { downloadPdf() }}><i className="fa-light fa-print"></i> Print</button>
                        <button className="btn bg-green text-white" id="exportForm" data-toggle="modal" onClick={() => openModal()}><i className="fa-light fa-envelope"></i> Send To Email</button>


                    </div>

                    <div className="invoice-wrapper shadow bg-white mt-0 mb-5 py-5">
                        <span id="downloadPdf">
                            <div id="section-to-print">
                                <div className="mb-5 border p-4" style={{ maxWidth: "200px" }}>
                                    <img src={Logo1} className="lg-logo img-fluid" alt="" style={{ maxWidth: "156px" }} />
                                    <img src={LogoIcon} className="sm-logo img-fluid m-0" alt="" />
                                </div>
                                <div className="row pb-lg-5 mt-4">
                                    <div className="col-6">
                                        <div className="from-invoice d-flex justify-content-between">
                                            <h4 style={{ width: "80px" }} className="fz20">From</h4>
                                            <div className="flex-grow-1 ps-5 border-start">
                                                <div><h3>{pickup_customer_name}</h3></div>
                                                <div>{initialState?.customer_address}</div>
                                                <div>{initialState?.suburb}{countryNameDetails}{pickupZipCodeDetails} </div>
                                            </div>
                                        </div>
                                        <div className="from-invoice d-flex justify-content-between align-items-center mt-5">
                                            <h4 style={{ width: "80px" }} className="fz20">To</h4>
                                            <div className="flex-grow-1 ps-5 border-start">
                                                <div><h3>{delivery_customer_name}</h3></div>
                                                <div>{initialState?.delivery_address}</div>
                                                <div>{initialState?.delivery_suburb}{countryDeliveryDetails}{deliverZipCodeDetails}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 ">
                                        <div className="row">
                                            <div className="col-8">
                                                <h3 className="fz18 mb-0">{userInfoSelector?.companyData?.company_name?.charAt(0)?.toUpperCase() + userInfoSelector?.companyData?.company_name?.slice(1)}  </h3>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-3">Booked Date:</div>
                                            <div className="col-6">
                                                <Moment format="D MMM YYYY"  >{initialState?.booked_date}</Moment>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-3">Pickup Date:</div>
                                            <div className="col-6"> <Moment format="D MMM YYYY"  >{initialState?.pickup_date}</Moment></div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-3">Manifest#:</div>
                                            <div className="col-6">{initialState?.manifest_number}</div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-3">Consignment#:</div>
                                            <div className="col-6"> <strong>{initialState?.job_number}</strong></div>
                                        </div>
                                    </div>
                                </div>

                                <Table className="table mt-5 table-bordered" >
                                    <thead className="border-bottom table-bordered">
                                        <tr>
                                            <th width="150px">Senders Reference</th>
                                            <th className="text-center">Items</th>
                                            <th>Freight Description</th>
                                            <th className="text-center"> Pallets</th>
                                            <th className="text-center">Spaces</th>
                                            <th className="text-center">Weight(KG)</th>
                                            <th className="text-center" width="100px">Job Temp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {initialState?.consignment_details && initialState?.consignment_details.map((val, index) => (
                                            <tr>
                                                <td>{val?.senders_no}</td>
                                                <td className="text-center">{val?.no_of_items}</td>
                                                <td>{val?.freight_desc}</td>
                                                <td className="text-center">{val?.pallets}</td>
                                                <td className="text-center">{val?.spaces}</td>
                                                <td className="text-center">{val?.weight}</td>
                                                <td className="text-center">{val?.job_temp}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <th>Totals</th>
                                            <th className="text-center">{initialState?.total_items}</th>
                                            <th></th>
                                            <th className="text-center">{initialState?.total_pallets}</th>
                                            <th className="text-center">{initialState?.total_spaces}</th>
                                            <th className="text-center">{initialState?.total_weight}</th>
                                            <th className="text-center"></th>
                                        </tr>
                                    </tbody>
                                </Table>

                                <Table className="ms-auto mt-5" bordered style={{ maxWidth: "400px" }} >
                                    <tbody>
                                        <tr>
                                            <td colSpan="4" className="text-center"><strong>Equipment</strong></td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Chep</strong></td>
                                            <td>{initialState?.chep}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Loscom</strong></td>
                                            <td>{initialState?.loscom}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Other</strong></td>
                                            <td>{initialState?.plain}</td>
                                        </tr>
                                    </tbody>
                                </Table>



                                <Table className="ms-auto mt-4" bordered style={{ maxWidth: "400px" }}>
                                    <tbody>

                                        <tr><td colSpan="4"><strong>RECEIVED BY</strong>: {delivery_customer_name}</td></tr>
                                        <tr>
                                            <td><strong>Date</strong>: {(initialState?.signature_date !== null) ? <Moment format="D MMM, YYYY">{initialState?.signature_date}</Moment> : ''}</td>
                                            <td><strong>Signature</strong>:
                                                {initialState.signature ? <img width='100' height='100' src={`data:image/png;base64,${initialState.signature}`} /> :
                                                    ''}</td>
                                        </tr>
                                        <tr><td colSpan="4"><strong>Receiver Name</strong>: {initialState.receiver_name} </td></tr>
                                        <tr><td colSpan="4"><strong>Receiver Attachment </strong>: 
                                        
                                        {initialState.receiver_attachment_type !== 'pdf' ? (<img width='100' height='100' src={`data:image/png;base64,${initialState.receiver_attachment}`} />) : (<a style={{'cursor':'pointer'}} onClick={(e) => handleDownload(initialState.receiver_attachment,initialState?.job_number)} target="_blank"> <i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a>) }
                                         </td></tr>
                                        <tr><td colSpan="4"><strong>Latitude / Longitude </strong>: {initialState.end_latitude} &nbsp;  &nbsp; &nbsp; {initialState.end_longitude}</td></tr>
                                    </tbody>
                                </Table>
                            </div>
                            <h4 className=" fw600 fz16 text-dark mt-5"> Geo Location Log</h4>
                            <div className="overflow-auto mt-5 ">
                                <Table className="w-100 geoLocationLog" bordered>
                                    <tbody>
                                        <tr>
                                            <td className=" pb-3 fw-bold border-bottom">Geo Location Started</td>
                                            <td className=" pb-3 fw-bold border-bottom">Geo Location Delivered</td>
                                            <td className=" pb-3 fw-bold border-bottom">Date & Time</td>
                                            <td className="text-center pb-3 fw-bold border-bottom">View In Map</td>
                                        </tr>
                                        {allGeoLocationData?.rows && allGeoLocationData?.rows.map((val, index) => (
                                            <tr>
                                                <td>{val?.started_latitude} &nbsp; &nbsp;{val?.started_longitude}</td>
                                                <td>{val?.delivered_latitude} &nbsp; &nbsp;{val?.delivered_longitude}</td>
                                                <td>
                                                    {(val?.geo_date !== null) ? <Moment format="D MMM, YYYY - hh:mm:s A ">{val?.geo_date}</Moment> : ''} </td>
                                                <td className="text-center"><Link target="_blank" to={`https://www.google.com/maps/place/TUKZAR+AUTO/@${val?.started_latitude},${val?.started_longitude},666m/data=!3m1!1e3!4m6!3m5!1s0x0:0x1abcdcf546e032a5!4b1!8m2!3d-32.0433541!4d115.982511?hl=en-US" className="btn px-0 fz16" title="View in Google Map`}><i className="fa-light fa-map-location"></i></Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            {/* Modal   */}

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title> Send Mail </Modal.Title>
                    <Button className="btn-close ms-2 " onClick={() => setModalShow(false)} variant=""></Button>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
                    <div className="px-0 table-wrapper">
                        <Formik initialValues={consignmentMailInitialState} enableReinitialize validationSchema={connsignmentMailValidation} onSubmit={saveFile}>
                            {
                                ({ values, setFieldValue }) => (
                                    <div className="row">
                                        <div className="col-md-10 offset-1">
                                            <Form className="d-flex flex-column overflow-auto" encType="multipart/form-data">
                                                <div className="form-group">
                                                    <label htmlFor="emailTo">To</label>
                                                    <Field type="email" name="email_id" render={({ field }) => <InputText type="text" name="email_id" className="form-control px-3"   {...field} maxLength="50" />} />
                                                    <ErrorMessage name="email_id" component="span" className="from-error-span" />
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label htmlFor="Subject">Subject</label>
                                                    <Field name="subject" render={({ field }) => <InputText type="text" name="subject" className="form-control px-3"   {...field} maxLength="50" />} />
                                                    <ErrorMessage name="subject" component="span" className="from-error-span" />
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label htmlFor="attachment">Attachment: </label>&nbsp;
                                                    <Link to={file} target="_blank"> <i className='fas fa-file-pdf' style={{ fontSize: '22px', color: 'red' }}></i> </Link>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <label htmlFor="body">Body</label>
                                                    <Editor
                                                        name="body"
                                                        onChange={(e) => handleEditorChange(e)}
                                                        apiKey='mogoc5ex2lkvawdmkttdbg8q32i8pe3t0snf99q4gazbgjrw'
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        wrapperStyle={{ width: 770, minHeight: 100, border: "1px solid black" }}
                                                        init={{
                                                            selector: '#tinymce',
                                                            branding: false,
                                                            statusbar: false,
                                                            plugins: 'code lists link image paste help wordcount',
                                                            toolbar: 'code | undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
                                                        }}



                                                        onInit={(evt, editor) => {
                                                            // Save the editor instance to the ref
                                                            editorRef.current = editor;
                                                        }}
                                                    />
                                                    {/* <ErrorMessage name="body" component="span" className="from-error-span" /> */}
                                                </div>


                                                <div className="form-group">
                                                    <div className="text-center btn-block p-3">
                                                        <Button type="submit" variant="" className="bg-green text-white btn fz14 "><i className="fa fa-envelope" aria-hidden="true"></i> Send</Button>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>

                                )
                            }
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default View;
