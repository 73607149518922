import React, { useState, useEffect, useRef } from 'react'
import { Dropdown, Button } from "../../../BootstrapComponents";
import { FilterOffset } from "./OffsetRight";
import { InputText, DataTable, Column, Toast, confirmDialog, confirmPopup } from "../../../PrimeReactComponents";
import dataTableConfig from "../config/DatatableConfig";
import trucktData from '../../json_files/truck.json'
import { Link } from 'react-router-dom'
//import { singleData,removeData } from '../../../transport-portal/controllers/SetUpController';
import { listData, removeData, activeInactive, activeInactiveBulk, removeDataBulk } from '../../../platform-admin/controllers/TruckTypeController'
import Loader from '../../../features/loader/Loader';
function TrucksType() {
  const toast = useRef(null);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectedTruck, setSelectedTruck] = useState();
  const [list, setList] = useState([]);
  const [loader, setloader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [trucktData, setCustomers] = useState(null);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState("0px");


  let platformSetupArr = []
  let companyPermission = JSON.parse(localStorage.getItem('rolePermissionPlatformInfo'))

  companyPermission.length > 0 && companyPermission.filter(e => e.menuData?.menu_name === 'Setup')?.map(val => {
    if (!platformSetupArr.includes(val?.menuActionData?.action_name)) {
      platformSetupArr.push(val?.menuActionData?.action_name)
    }
  })


  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    globalFilterValue: "",
    status: "",
    filters: {
      'truck_type': { value: '', matchMode: 'contains' },
      'truck_detail': { value: '', matchMode: 'contains' },
      'status': { value: '', matchMode: 'contains' }


    }
  });
  let loadLazyTimeout = null;
  useEffect(() => {
    loadLazyData();

  }, [lazyParams])
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: (lazyParams) }).then(data => {
        if (data?.data !== undefined) {
          setTotalRecords(data?.data?.count);
          setCustomers(data?.data?.rows);

        }
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 50 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",

    }, []);
  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if (value.length > 3) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
    } else if (value.length === 0) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
      loadLazyData();
    }
  }

  const advanceSearch = (value) => {

    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.truckType, status: value.status });
      setoffsetFilter(true);

    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }



  // Delete Entry Function Start Here =====
  const deleteEntry = (id) => {
    setloader(true);
    removeData(id).then(({ type, message }) => {
      loadLazyData();
      setloader(false);
      toast.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const deleteConfirm = (rowData) => {
    setIsListUpdated(false)
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(rowData.truck_id)
      }
    });

  }
  // Delete Entry Function end Here =====

  const actionButton = (rowData) => (
    <div className="d-flex gap-2">
      <Link variant='' to={`./edit-truck/${rowData.truck_id}`} className={`btn fz16 p-0 edit-btn`}><i className="fa-light fa-edit"></i></Link>
      <Button variant='' className={`deleteRow btn fz16 p-0 delete-btn`} onClick={() => deleteConfirm(rowData)}><i className="fa-light fa-trash"></i></Button>
    </div>
  )
  
  const vechileType = (rowData) => {
    return <span>{rowData.truck_type === "1" ? "Truck" : "Trailer"} </span>
  }

  const activeInactiveStatus = (status, id) => {
    setloader(true)
    activeInactive(status, id).then(({ type, message }) => {
      setIsListUpdated(true)
      setloader(false)
      loadLazyData();
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {
    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data.status === '1' ? 'Deactivate' : 'Activate'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data.status, data.truck_id);
      }
    });

  }
  const statusBodyTemplate = (rowData) => {
    return <button onClick={(e) => activeInactiveHandler(e, rowData)} icon="pi pi-check" label="Confirm" className={`fw600 border-0 badge py-2 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Active" : "Inactive"} <i className="fa-light fa-angle-down"></i></button>;
  };



  const activeInactiveStatusBulk = (status, id) => {
    setloader(true)
    activeInactiveBulk(status, id).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedTruck([])
      loadLazyData();
      setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }


  const bulkActivate = () => {
    let selectedId = [];
    selectedTruck && selectedTruck.length > 0 && selectedTruck.map((item, index) => {
      return selectedId.push(item.truck_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }




  const bulkDeActivate = () => {
    let selectedId = [];
    selectedTruck && selectedTruck.length > 0 && selectedTruck.map((item, index) => {
      return selectedId.push(item.truck_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Deactivate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("0", selectedId);
      }
    });
  }

  const DeleteBulk = (id) => {
    setloader(true)
    removeDataBulk(id).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedTruck([])
      loadLazyData();
      setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const bulkDelete = () => {
    let selectedId = [];
    selectedTruck && selectedTruck.length > 0 && selectedTruck.map((item, index) => {
      return selectedId.push(item.truck_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} />

      <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
        <Link to="./add-truck" className="bg-green text-white btn fz14 me-3"><i className="fa-light fa-plus-circle"></i> Add</Link>
        <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />
        <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
          <InputText placeholder="Search" className="form-control fw500 p-2" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} />
          <Button variant='' className="insideSubmitBtn me-2 fz14 bg-transparent border-0 position-absolute"><i className="fa-light fa-search"></i></Button>
        </div>
        <Dropdown className="order-4">
          <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
            <i className="fa-light fa-ellipsis"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ul>
              <li>
                <Button className={`dropdown-item`} to="#" onClick={() => bulkDelete()}>
                  <i className="fa-light me-2 fa-trash"></i>
                  Delete Selected
                </Button>
              </li>
              <li>
                <Button className="dropdown-item" onClick={() => bulkActivate()} >
                  <i className="fa-light me-2 fa-check"></i>
                  Active
                </Button>
              </li>
              <li>
                <Button className="dropdown-item" onClick={() => bulkDeActivate()}>
                  <i className="fa-light me-2 fa-ban"></i>
                  Inactive
                </Button>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="table-wrapper flex-grow-1  mt-5">
        <DataTable value={trucktData} selectionMode="checkbox"
          paginator rows={12} totalRecords={totalRecords} onPage={onPage}
          onSelectionChange={(e) => setSelectedTruck(e.value)}
          selection={selectedTruck} {...dataTableConfig}
          onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
          onFilter={onFilter} filters={lazyParams.filters} loading={loading}
          scrollDirection="both">
          <Column headerStyle={{ width: "3em" }} selectionMode="multiple" />
          <Column header="Action" body={actionButton} />
          <Column sortable field='truck_type' header="Vehicle Type" body={vechileType} style={{ flexGrow: 1, flexBasis: "120px" }} />
          <Column sortable field='truck_details' header="Vehicle Type Detail" style={{ flexGrow: 1, flexBasis: "150px" }} />
          <Column sortable field="status" header="Status" body={statusBodyTemplate} style={{ flexGrow: 1, flexBasis: "60px" }}></Column>
        </DataTable>
      </div>
    </>
  )
}

export default TrucksType

