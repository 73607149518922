// const dataTableConfig = {
//     scrollable: true,
//     responsiveLayout: "scroll",
//     paginator: true,
//     paginatorTemplate: "CurrentPageReport  FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
//     currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
//    // rows: 5,
//     //rowsPerPageOptions: [5, 10, 15],
//     filterDisplay:"menu",
   
//     // showGridlines:true
//   };
  
// export default dataTableConfig;
const dataTableConfig = {
  scrollable: true,
  responsiveLayout: "scroll",
  paginator: true,
  paginatorTemplate: "CurrentPageReport  FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
  currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
 // rows: 15,
 // rowsPerPageOptions: [15, 30, 50],
};

export default dataTableConfig;
  