import * as yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const companyValidateSchema = yup.object().shape({
     // companycode:yup.string().min(4 , 'This Field must be at least 4 characters').max(8 , 'This Field must be at most 8 characters').required('This Field is required'),
      companyname:yup.string().min(2 , 'This Field must be at least 2 characters').max(30 , 'This Field must be at most 30 characters').required('This Field is required'),            
     // companyphone: yup.number().typeError("That doesn't look like Phone Number").integer("This Field can't include a decimal point").min(9, 'This Field must be at least 9 characters').max(12, 'This Field must be at least 12 characters').required('This Field is required'),
      companyphone:yup.number().required('This Field is required'),
      companymobile: yup.number().required('This Field is required'),
      companyaddress:yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
      companysuburb:yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
      companyzipcode:yup.number().required('This Field is required'),
      companystate:yup.string().required('This Field is required'),
      companycountry:yup.string().required('This Field is required')          
      
      
      
  })

 const companyInitialState ={
    //companycode:"LEOP" + Math.floor((Math.random() * 1000000000000000000) + 100000),
    companycode:'',
    companyname:'',      
    companyphone:'',
    companymobile:'',
    companyaddress:'',
    companysuburb:'',
    companyzipcode:'',
    companystate:'',
    companycountry:1
 }

const companyFilterInitialState = {
  companyname:'',
  status:''
}

const companyFilterSchema = yup.object().shape({
  companyname: yup.string().min(2 , 'This Field must be at least 2 characters').max(30 , 'This Field must be at most 30 characters').required('This Field is required'),
 // last_name: yup.string().required('This Field is required'),
  status: yup.string().required('This Field is required')
})

export {companyValidateSchema, companyInitialState, companyFilterInitialState, companyFilterSchema}