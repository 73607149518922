import { configureStore } from "@reduxjs/toolkit";
import navToggleReducer from './navToggleSlice'
import loaderReducer from './loaderSlice';
import userInfo from "./userInfo";
import commonSlice from './commonSlice';
import platformLoginSlice from "./platformLoginSlice";
import customerLoginSlice from "./customerLoginSlice";
import transportloginSlice from "./transportLoginSlice";
import ConsignmentReducer from "../redux/transport/consignment/ConsignmentSlice";
import ConsignmentAllReducer from "../redux/transport/consignment/ConsignmentAllSlice";
import ConsignmentAddSlice from "../redux/transport/consignment/ConsignmentAddSlice";
import ConsignmentEditReducer from "../redux/transport/consignment/ConsignmentEditSlice";
import companyAddSlice from "../redux/company/companyAddSlice";
import roleSlice from "./roleSlice";
import company from './CompanySlice'
import rolePermissionSlice from "./rolePermissionSlice";


const store = configureStore({
    reducer: {
        userRoleInfo:roleSlice,
        company,
        commonSlice,
        userInfo:userInfo,
        sidebar_toggle: navToggleReducer,
        loader:loaderReducer, 
        consignmentResultData:ConsignmentReducer,
        consignmentAddData:ConsignmentAddSlice,
        consignmentResultData:ConsignmentAllReducer,
        consignmentEditData:ConsignmentEditReducer,
        companyAddData:companyAddSlice,
        platformUserInfo:platformLoginSlice,
        customerUserInfo:customerLoginSlice,
        transportUserInfo:transportloginSlice,
        rolePermissionInfo:rolePermissionSlice,

    }
})

export default store;