import * as yup from 'yup'

const initialState={
    
        company_id: "",
        feet_number: "",
        truck_registration: "",
        truck_model: "",
        truck_year: "",
        truck_type: "",
        last_service_detail: "",
        next_service_due: ""
      
}

const TruckValidation = yup.object().shape({
    feet_number:yup.string().required('This Field is required'),
    truck_registration:yup.string().required('This Field is required'),
    truck_model:yup.string().required('This Field is required'),
    // truck_year:yup.string().required('This Field is required'),
    truck_type:yup.string().required('This Field is required'),
    last_service_detail:yup.string().required('This Field is required'),
    next_service_due:yup.string().required('This Field is required'),
})

const conFilterInitialState = {
    feet_number: '', 
    //status: '', 
}

const conFilterSchema = yup.object().shape({
    //feet_number: yup.string().required('This Field is required'),
   // status: yup.string().required('This Field is required')
})


export { TruckValidation, initialState, conFilterInitialState, conFilterSchema } 