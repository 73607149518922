import {appAxios} from './common/AxiosFile';
import {REACT_APP_API_URL} from '../../config'

export  async function  addData(data){  

    try {
        const res = await appAxios().post(`${REACT_APP_API_URL}company/senduserrequest/`, data);
        const result = await res.data;    
         
        if(result.status){                  
            if(result.data.success===1){
                return {                      
                    status: 1,
                    message:result.data?.msg,
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };     
            }else{
                return {                      
                    status: 1,
                    message:result.data.data,
                    type:'info',
                    error:null,
                    data:result.data.data,                                           
                };                         
            }                    
        }else{
            return {                      
                status: 0,
                message:'Something went wrong.',
                type:'info',
                error:null,                                           
            };                    
        } 
    } catch (error) {                            
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,    
            data: null,                                       
        };                  
    }
}

export async function listData(lazyEvent){ 

   try {
    const {rows,page,id,sortOrder,sortField,globalFilterValue,status,relationid}=lazyEvent;
    let reciveSortField = sortField === "userData.email_id" ? "email_id":sortField;
        const res = await appAxios().get(`${REACT_APP_API_URL}company/userlist/${id}/${relationid}/1?keyword=${globalFilterValue}&status=${status}&sortkey=${reciveSortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
         const result = await res.data;        

        if(result.status ===1){
            if(result.data?.rows.length > 0){  
                return {
                    status: 1,
                    message:"User Successfully fetched.",
                    data: result.data,
                    type:'success',                   
                    error:null
                }                  
            }else{ 
                return {
                    status: 0,
                    message:"Data not found!",
                    data: null,
                    type:'info',
                    error:null
                }
            } 
        }        
   } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,    
            data: null,                                       
        };    
   }
}

export async function activeInactive(id, status){ 
    try {
      const res = await appAxios().put(`${REACT_APP_API_URL}company/userstatusupdate/${id}/${status === '1'? 0:1}`);
      const result = await res.data;                
      if(result.status){   
          return {               
              type:'success',
              error:null,                                    
              status: 1,
              message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
              data: res.data
          }                  
      }else{
          return {                      
              status: 0,
              message:"Data not found!",         
              type:'info',
              error:null,                                         
          };   
      } 
    } catch (error) {
          return {                      
              status: 0,
              message:error.message,         
              type:'info',
              error:error,                                         
          };   
    }
  }
  export async function removeData(id){        
    try {
        const res = await appAxios().delete(`${REACT_APP_API_URL}company/userroledelete/${id}`);
        const result = await res.data;                        
        if(result.status){   
            return {
                status: result.status,
                message:"Data Successfully Deleted.",
                type:'success',
                error:null,                                                              
            }                  
        }else{
            return {
                status: 0,                    
                message:"Data not found!" ,
                type:'info',   
                error:null                                    
            }
        } 
    } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,                                         
        };   
    }
}

export async function userRoleUpdate(data){  

    let company_id = data?.company_id
    let user_id = data?.user_id
  
      try {
        const res = await appAxios().put(`${REACT_APP_API_URL}company/user-role-update/${company_id}/${user_id}`, data);
        const result = await res.data;                
        if(result.status){   
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:`Data Successfully Updated`,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:"Data not found!",         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
      }
    }

    export async function activeInactiveBulk(status,reciveLocalData,ids){
          
        try {
          const res = await appAxios().put(`${REACT_APP_API_URL}company/userBulkChangeStatus/${reciveLocalData}/1`,{user_id:ids,status});
          const result = await res.data;                
          if(result.status){
         
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:res.data.data.msg,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:res.data.data.msg,         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.msg,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }
    
    
      export async function removeDataBulk(ids,reciveLocalData){        
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}company/userBulkDelete/${reciveLocalData}/1`,{user_id:ids});
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function getLoginData(key, type) {
        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/tokenverification`, { access_token: key, user_type: type });
            const result = await res.data; 
            if (result.status) {
                if (result.status === 1) {
                    return {
                        status: 1,
                        message: "",
                        type: 'success',
                        error: null,
                        data: result.data,
                    };
    
                } else {
                    return {
                        status: 1,
                        message: "",
                        type: 'info',
                        error: null,
                        data: result.data,
                    };
                }
    
            } else {
                return {
                    status: 0,
                    message: 'Something went wrong.',
                    type: 'info',
                    error: null,
                };
            }
    
        } catch (error) {
            return {
                status: 0,
                message: error.message,
                type: 'info',
                error: error,
                data: null,
    
            };
    
        }
    
    }
    

    export  async function  getTransportOnUser(data){  

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/getcompanydropdawn`, data);
            const result = await res.data;    
             //console.log("thois is good all",result);
            if(result.status){                  
                if(result.data.success===1){
                    return {                      
                        status: 1,
                        message:result.data?.msg,
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: 1,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                            
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,    
                data: null,                                       
            };                  
        }
    }

    export async function getUserType(){ 

        try {
      
             const res = await appAxios().get(`${REACT_APP_API_URL}permissions/getRoles`);
              const result = await res.data;        
     
             if(result.status ===1){
                 if(result.data){  
                     return {
                         status: 1,
                         message:"User Successfully fetched.",
                         data: result.data,
                         type:'success',                   
                         error:null
                     }                  
                 }else{ 
                     return {
                         status: 0,
                         message:"Data not found!",
                         data: null,
                         type:'info',
                         error:null
                     }
                 } 
             }        
        } catch (error) {
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,    
                 data: null,                                       
             };    
        }
     }

     export  async function resendInvit(data){  

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/resenduserrequest`, data);
            const result = await res.data;    
             
            if(result.status){                  
                if(result.data.success===1){
                    return {                      
                        status: 1,
                        message:result.data?.msg,
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: 1,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                            
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,    
                data: null,                                       
            };                  
        }
    }