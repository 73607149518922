import {useState, useEffect} from "react";
import { FilterMatchMode } from 'primereact/api'

function useDataTableFilter() {

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    useEffect(()=> {
        initFilters1();
    }, [])

    const onGlobalFilterChange1 = (e) => {
        console.log(e.target.value,"change pagination");
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;
    
        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },          
        });
        setGlobalFilterValue1('');
      }

    return [filters1 , globalFilterValue1, onGlobalFilterChange1];
  
}

export default useDataTableFilter