import React, { useRef, useState, useEffect } from "react";
import {useSelector } from 'react-redux';
import * as yup from 'yup'
import { InputText, Calendar, confirmDialog,Toast } from "../../../PrimeReactComponents";
import { Link, useParams,useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { documentTypeValidation, initialState } from '../../Validations/documentTypeValidation';
import Button from 'react-bootstrap/Button';
import { addDocumentTypeData, documentTypeByIdData, documentTypeUpdateData,
  faultreportUpdateData,faultReportByIdData,addFaultReportData,faultReportListData } from "../../controllers/SetUpController"
import Loader from "../../../features/loader/Loader";

function Setting() {
  const {transportUserInfo} = useSelector(state => state); 
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loader, setloader] = useState();
  const { id } = useParams();  
  const reciveLocalData= transportUserInfo.company_id;

  //const [initFaultReport, setInitFaultReport] = useState()
  const pathname = window.location.pathname 

  let documentTypeArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Setup')?.map(val => {
    if (!documentTypeArr.includes(val?.menuActionData?.action_name)) {
      documentTypeArr.push(val?.menuActionData?.action_name)
    }
  })
 const initialState={
    descriptions: "" ,
    title:"Fault Report Email",
    company_id:reciveLocalData,
}
const [initFaultReport, setInitFaultReport] = useState(initialState)

const faultTypeValidation = yup.object().shape({
  descriptions:yup.string().email('This Field must be a valid email').required('This Field is required'),
})
const [lazyParams, setLazyParams] = useState({
  first: 0,
  rows: 12,
  page: 1,
  sortField: "",
  sortOrder: false,
  filters: "",
  status: "",
  company_id: reciveLocalData,
  globalFilterValue: "",
 
});
  useEffect(() => {
   
const listData =faultReportListData(lazyParams)
console.log("this is log...",listData);
  //  if (id) {
      const response = faultReportListData(lazyParams);
      response.then(({ status, message, type, error, data }) => {
//console.log("datadatadata",status, message, type, error, data);
        // if (status) {
        //   setInitFaultReport({
        //     descriptions: data.data.document_type,
        //     title:"" 
        //   }); 
        //   setloader(false)
        // } else {
        //   toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
        //   setloader(false)
        // }
      })
  //  }
  }, [id])


  let loadLazyTimeout = null;
  useEffect(() => {
      loadLazyData();

  }, [lazyParams])
  const loadLazyData = () => {
      //setLoading(true);
      if (loadLazyTimeout) {
          clearTimeout(loadLazyTimeout);
      }
      loadLazyTimeout = setTimeout(() => {
        faultReportListData({ lazyEvent: (lazyParams) }).then(data => {
          // console.log("this is log is mhtr...",data?.data[0]);
              // setTotalRecords(data?.data?.data?.rows?.length);
               setInitFaultReport(data?.data[0]);
              // setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
              //setLoading(false);

          });
      }, Math.random() * 1000 + 250);
  }



  const postData = (values, { resetForm }) => { 
  // console.log("this is log....",initFaultReport);
     let modifiesValues = {...values,title:"Fault Report Email", company_id:reciveLocalData} 
   // console.log("this is modifyrd daat",modifiesValues);
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        if (initFaultReport.id) {
          faultreportUpdateData(modifiesValues, initFaultReport.id).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ values: '' })
              loadLazyData();
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });            
              setTimeout(() => {
                //navigate('/transport/setup') 
              }, 3000)              
            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

              
            }
          })
        } else {
           
           addFaultReportData(modifiesValues).then(({ status, type, message }) => { 
            if (status) {
              setloader(false)
              resetForm({ values: '' })
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

              setTimeout(() => { 
                //navigate('/transport/setup')
              }, 3000)

            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            }
          }) 
        }
      }
    });
  }


  return (
    <>
     <Loader show={loader} />
            <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
          <div className="container d-flex flex-column h-100 ">
            <Formik enableReinitialize initialValues={initFaultReport}
             validationSchema={faultTypeValidation} onSubmit={postData} render={({ values, setFieldValue }) => (<>
              <Form className="d-flex flex-column overflow-auto">
                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                    
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      {/* <Link to="/transport/setup" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link> */}

                   <button type="submit" className="bg-green text-white btn fz14 ">
                              <i className="fa-regular fa-save"></i> Save
                            </button>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">

                  <div className="card p-4">
                    <div className="card-body">
                    <div className="row gx-1">
                        <div className="col-sm-8 ">
                          
                      <label htmlFor="" className="mb-4"> Fault Report Email <span className="text-danger">*</span></label>             
                         
                        </div>
                      </div> 
                      <div className="row gx-1">
                        <div className="col-sm-6 "> 
                          <Field name="descriptions" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="descriptions" component="span" className="from-error-span" />
                        </div>
                      </div> 
                    </div>
                  </div>

                </div>
              </Form>
            </>)} />
          </div>

        </div>

      </div>
    </>
  )
}

export default Setting