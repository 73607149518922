import {createSlice} from '@reduxjs/toolkit';



const navToggleSlice = createSlice({
    name:'sidebar_toggle',
    initialState: Math.abs(window.localStorage.getItem("toggleNav")) ? 1 : 0,
    reducers:{       
        toggleSidebar(state, action){           
            return  state = !action.payload;
        }

    }
})

export  const {toggleSidebar} = navToggleSlice.actions;
export default navToggleSlice.reducer;