import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { InputText, Calendar, confirmDialog, Toast, Dropdown as Select } from "../../PrimeReactComponents";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { TruckValidation, initialState } from '../Validations/Setupvalidation';
import { getAllTruckType } from '../../services/commonController';
import Button from 'react-bootstrap/Button';
import { addData, singleData, editData } from "../controllers/SetUpController";

import moment from 'moment'


import Loader from "../../features/loader/Loader";
function AddTruck() {
  const { transportUserInfo } = useSelector(state => state);
  const toast = useRef(null);
  const reciveLocalData = transportUserInfo.company_id;
  const navigate = useNavigate();
  const [loader, setloader] = useState();
  const { id } = useParams();
  const [_truckYear, settruckYear] = useState(null);
  const [allTruck, setAllTruck] = useState();
  const [initStateTruck, setInitStateTruck] = useState(initialState)
  const pathname = window.location.pathname

  let vehicleArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Vehicle')?.map(val => {

    if (!vehicleArr.includes(val?.menuActionData?.action_name)) {
      vehicleArr.push(val?.menuActionData?.action_name)
    }
  }) 
 

  if (pathname.split('/')[3] === 'add-truck') {
    if (!vehicleArr.includes('Add')) {
      navigate('/transport/404');
    }
  }

  if (pathname.split('/')[3] === 'edit') {
    if (!vehicleArr.includes('Edit')) {
      navigate('/transport/404');
    }
  }



  useEffect(() => {
    getAllTruckTypeData()
    if (id) {
      const response = singleData(id, reciveLocalData);
      setloader(true);
      response.then(({ status, message, type, error, data }) => {

        if (status) {

          setInitStateTruck({
            feet_number: data.data.feet_number,
            truck_registration: data.data.truck_registration,
            truck_model: data.data.truck_model,
            truck_year: new Date(new Date().setFullYear(data.data.truck_year)),
            truck_type: data.data.truck_type,
            last_service_detail: data.data.last_service_detail,
            next_service_due: data.data.next_service_due,
          });
          settruckYear(new Date(new Date().setFullYear(data.data.truck_year)))


          setloader(false)
        } else {
          toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          setloader(false)
        }
      })
    }
  }, [id])

  const postData = (values, { resetForm }) => {
    const modifyed = { ...values, company_id: reciveLocalData }
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        if (id) {
          const {...InputData } = values

          let modifyTruckYear = moment(InputData.truck_year).format('YYYY')
          let modifyInputData = { ...InputData, truck_year: modifyTruckYear }

          editData(modifyInputData, reciveLocalData, id).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ InputData: '' })
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });


              setTimeout(() => {
                navigate('/transport/vehicle')
              }, 3000)

            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            }
          })
        } else {
          addData(modifyed).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ values: '' })
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

              setTimeout(() => {
                navigate('/transport/vehicle')
              }, 3000)
            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            }
          })
        }
      }
    });
  }
  const getAllTruckTypeData = async () => {
    const results = await getAllTruckType();
    setAllTruck(results.data)
  }
  const getAllTruckOption = () => {
    const option = [];
    allTruck?.map((ele) => {
      option.push({ value: ele.truck_id, label: ele.truck_type })
    })
    return option;
  }
  const vechileTypeOption = [
    { value: "1", label: "Truck" },
    { value: "2", label: "Trailer" }
  ]

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
          <div className="container d-flex flex-column h-100 ">
            <Formik enableReinitialize initialValues={initStateTruck} validationSchema={TruckValidation} onSubmit={postData} render={({ values, setFieldValue }) => (<>
              <Form className="d-flex flex-column overflow-auto">
                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                      <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">{(id === "" || id === undefined) ? "Add New Vehicle" : "Update Vehicle"}</h2>
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      <Link to="../vehicle" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link>
                      <Button type='submit' variant='' className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save</Button>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">

                  <div className="card p-4">
                    <div className="card-body">
                      <div className="row gx-5">
                        <div className="col-sm-6 mb-4">
                          <InputText className="form-control px-3" name="company_code" hidden />

                          <label htmlFor="" className="mb-2">Fleet Number <span className="text-danger">*</span></label>
                          <Field name="feet_number" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="feet_number" component="span" className="from-error-span" />

                        </div>

                        <div className="col-sm-6 mb-4">
                          <InputText className="form-control px-3" name="company_code" hidden />
                          <label htmlFor="" className="mb-2">Vehicle Registration <span className="text-danger">*</span></label>
                          <Field name="truck_registration" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="truck_registration" component="span" className="from-error-span" />
                        </div>
                      </div>
                      <div className="row gx-5">

                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Make/Model <span className="text-danger">*</span></label>
                          <Field name="truck_model" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="truck_model" component="span" className="from-error-span" />

                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Year <span className="text-danger">*</span></label>
                          <Calendar defaultValue={values.truck_year} readOnlyInput value={_truckYear} className="w-100" onChange={(e) => {
                            settruckYear(e.target.value)
                            setFieldValue('truck_year', new Date(e.target.value).getFullYear().toString())
                          }} inputClassName="form-control px-3" view="year" dateFormat="yy" />
                          <ErrorMessage name="truck_year" component="span" className="from-error-span" />
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Type <span className="text-danger">*</span></label>
                          <Field name="truck_type" render={({ field }) =>
                            <Select
                              options={vechileTypeOption}
                              placeholder="Please select a vehicle type"
                              {...field} className="w-100 px-3" />} />

                          <ErrorMessage name="truck_type" component="span" className="from-error-span" />

                        </div>
                        <div className="col-sm-6 mb-4">

                          <label htmlFor="" className="mb-2">Last Serviced (date and KMS) <span className="text-danger">*</span></label>
                          <Field name="last_service_detail" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="last_service_detail" component="span" className="from-error-span" />
                        </div>
                        <div className="col-sm-12 mb-4">
                          <label htmlFor="" className="mb-2">Next Service due (kms) <span className="text-danger">*</span></label>
                          <Field name="next_service_due" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="next_service_due" component="span" className="from-error-span" />

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </Form>
            </>)} />
          </div>

        </div>

      </div>
    </>
  )
}

export default AddTruck