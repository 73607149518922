 import axios from "axios";
// let access_token=localStorage.getItem('keyCloakaccesstokenTransport');
// const app = axios.create({
//     headers: {
//         Authorization: "Bearer "  + access_token,
//         'Access-Control-Allow-Origin': '*',
//         'Content-Type': 'application/json',
//     },
//     withCredentials: false
// })

// export default app;



export const appAxios = ()=>  {
    let access_token=localStorage.getItem('keyCloakaccesstokenTransport');
    //  console.log("access_token...12",access_token);
    return  axios.create({
      headers: {
          Authorization: "Bearer "  + access_token,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
      },
      withCredentials: false
  })}