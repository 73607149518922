import {REACT_APP_API_URL} from '../config'
import axios from './AxiosFile'; 

    export async function getCountry(){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/countries`);
            const result = await res.data;    
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getAllTimesheetTemp(){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/timesheettemp`);
            const result = await res.data;    
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function uploadDriverDocument(data, headers){ 

        try {
            const res = await axios.post(`${REACT_APP_API_URL}imageUpload/driverImage`, data, headers);
            const result = await res.data; 

            console.log('result', result)

            if(result.status){                  
                if(result.status){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }


    export async function getAllCountryState(){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/get-country-states`);
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getState(){        
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/states`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    } 

    export async function getStateById(id){        
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/states/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

	
	export async function uploadDocument(data, headers){

        try {
            const res = await axios.post(`${REACT_APP_API_URL}transport/driverdocumentupload`, data, headers);
            const result = await res.data; 

            if(result.status){                  
                if(result.status){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }
    
    export async function getAllDriver(company_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/driverlist/${company_id}`);
            const result = await res.data;   

            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getAllCustomerAddress(company_id,customer_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}customer/get-all-customer-address/${customer_id}/${company_id}`);
            const result = await res.data;    
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data?.rows[0]
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getAllCustomer(company_id, customer_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/customerlist/${company_id}/${customer_id}?keyword=`);
            const result = await res.data;    
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getCustomerAddressBook(company_id, customer_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getCutomerAddressBook/${company_id}/${customer_id}`);
            const result = await res.data;    
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getSingleCustomerAddressBook(company_id, addressId){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getCutomerAddressBookById/${company_id}/${addressId}`);
            const result = await res.data;    
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getSingleCustomer(customer_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getcustomername/${customer_id}`);
            const result = await res.data;    
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getAllTruckType(){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/truck-type`);
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getCustomerAddress (customerId, companyId){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}customer/getdefaultaddressbycustomerid/${customerId}/${companyId}`);
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
    }

    export async function getCustomerAddressKeyword (customerId, companyId, keyword){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}customer/getaddressbykeyword/${customerId}/${companyId}?keyword=${keyword}`);
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
    }

    export async function getAllCustomerCompany(company_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getCustomerCompany/${company_id}`);
            const result = await res.data;   

            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getCustomerCompanyAddress(customer_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getCustomerCompanyAddress/${customer_id}`);
            const result = await res.data;   

            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getAllDocType(company_id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/get-doc-types/${company_id}`);
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getUserInfo(id,company_id="",customerId=""){

        try {
            const res = await axios.get(`${REACT_APP_API_URL}company/userinfo/${id}/${company_id}/${customerId}`); 
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }


    export async function getUserInfoPlateform(id,platform_id){

        try {
            const res = await axios.get(`${REACT_APP_API_URL}company/userinfo/${id}/${platform_id}`); 
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }


    export async function getAllTruckInfo(companyId){

        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getvehiclelist/${companyId}/1`); 
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getAllTrailerInfo(companyId){

        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getvehiclelist/${companyId}/2`); 
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function documentTypeDropDawnData(company_id){
       
        try {
            // const res = await axios.get(`${REACT_APP_API_URL}documentType/allDocumentTypes/${company_id}`);
            const res = await axios.get(`${REACT_APP_API_URL}common/get-doc-types/${company_id}`); 
    
            const result = await res.data;   
    
            if(result.data.length){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result,                  
                } 
                               
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getInvitationKey(id){ 

        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getInvitesStatusByInviteKey/${id}`); 
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getAllRoleData() {

        try {
    
            const res = await axios.get(`${REACT_APP_API_URL}permissions/getRoles`);
            const result = await res.data;
            if (result) {
                return {
                    status: 1,
                    message: "List Successfully fetched.",
                    type: 'success',
                    error: null,
                    data: res?.data?.data,
                }
    
            } else {
                return {
                    status: 0,
                    message: "Data not found!",
                    type: 'info',
                    error: null,
                }
            }
        } catch (error) {
            return {
                status: 0,
                message: error.message,
                type: 'info',
                error: error
            }
        }
    
    }

    export async function getAllGeoLocationData(data) {

        try {
    
            const res = await axios.post(`${REACT_APP_API_URL}geo/geoLocation`, data);
            const result = await res.data;
            console.log('result', result)
            if (result) {
                return {
                    status: 1,
                    message: "List Successfully fetched.",
                    type: 'success',
                    error: null,
                    data: res?.data?.data,
                }
    
            } else {
                return {
                    status: 0,
                    message: "Data not found!",
                    type: 'info',
                    error: null,
                }
            }
        } catch (error) {
            return {
                status: 0,
                message: error.message,
                type: 'info',
                error: error
            }
        }
    
    }

    export async function getAllUserRolePermissionData(data){  
        try {
            const res = await axios.post(`${REACT_APP_API_URL}permissions/getUserRoleByPermissions`, data);
            const result = await res.data; 
            
            if(result){    
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result?.data
                }                  
            }else{ 
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getSingleDriverDocument(driverId){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/getDriverInfoByDriverId/${driverId}`);
            const result = await res.data;     
            
            if(result){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getUniquData(params){
        const {type}= params
        try {
         
            
            const res = await axios.get(`${REACT_APP_API_URL}custom/generate-random-number/${type}`);
            const result = await res.data;  
;
            if(result.status===1){   
               // console.log("this is log.....2323",result)
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result.data.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function consignmentMailSend(data){
        try {
            const res = await axios.post(`${REACT_APP_API_URL}common/consignment-mail`,data);
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"Mail Send Successfully",                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getFileName(id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/get-consignment-pdf`); 
           
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"success",                    
                    type:'success',
                    error:null,
                    data: result.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }
    
    export async function generateConsignmentPDF(id){
        try {
            const res = await axios.get(`${REACT_APP_API_URL}common/generate-consignment-pdf/${id}`); 
           
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,
                    message:"success",                    
                    type:'success',
                    error:null,
                    data: result
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }
 
 
 