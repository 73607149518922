import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Button } from "../../BootstrapComponents";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import dataTableConfig from "./config/DatatableConfig";
import FilterOffset from "./components/OffsetRight";
import { Link, useNavigate } from 'react-router-dom'
import { listData, removeData, vehicleBulkDelete } from "../controllers/SetUpController";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter'
import Loader from "../../features/loader/Loader";
import { useResizeListener } from 'primereact/hooks';

function Vehicle() {
  const { transportUserInfo } = useSelector(state => state);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState("0px");
  const reciveLocalData = transportUserInfo.company_id;
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [list, setList] = useState([])
  const [filters, globalFilterValue, onGlobalFilterChange] = useDataTableFilter();
  const [loader, setLoader] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [trucktData, setCustomers] = useState(null);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);

  let vehicleListArr = []
  let vehicleArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Vehicle')?.map(val => {
    if (!vehicleListArr.includes(val?.menuData?.menu_name)) {
      vehicleListArr.push(val?.menuData?.menu_name)
    }

    if (!vehicleArr.includes(val?.menuActionData?.action_name)) {
      vehicleArr.push(val?.menuActionData?.action_name)
    }
  })

  if (vehicleListArr.length == 0) {
    navigate('/transport/404');
  }
  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
});

useEffect(() => {
  bindWindowResizeListener();

  return () => {
      unbindWindowResizeListener();
  };
}, [bindWindowResizeListener, unbindWindowResizeListener]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: "desc",
    filters: "",
    globalFilterValue: "",
    company_id: reciveLocalData,
    filters: {
      'feet_number': { value: '', matchMode: 'contains' },
      'truck_registration': { value: '', matchMode: 'contains' },
      'truck_model': { value: '', matchMode: 'contains' },
      'truck_year': { value: '', matchMode: 'contains' },
      'last_service_detail': { value: '', matchMode: 'contains' },
      'next_service_due': { value: '', matchMode: 'contains' },
    }
  });

  let loadLazyTimeout = null;
  useEffect(() => {
    loadLazyData();

  }, [lazyParams])
  const loadLazyData = () => {
    setLoader(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: (lazyParams) }).then(data => {

        if (data?.data !== undefined) {
          setTotalRecords(data.data.data.count);
          setCustomers(data.data.data.rows);
        }

        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoader(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      // page:event.page===0 ?1:event.page ===1?2:event.page,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
      sortOrder: lazyParams.sortOrder,
      // sortOrder: event.sortOrder === "desc" ? "asc" : event.sortOrder === "asc" ? "desc":"desc",
    }, []);
  }

  const onSort = (event) => {
    setLazyParams({
      ...lazyParams, sortField: event.sortField, sortOrder: event.sortOrder === 1 ? "asc" : "desc"
    });
    //console.log(lazyParams,"this is sorting",event);
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }

  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;

    if (value.length > 2) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
      listData({ lazyEvent: (lazyParams) }).then(data => {
        //if (data?.data !== undefined) {
          setTotalRecords(data?.count);
          setCustomers(data?.rows);
        })
    } else if (value.length === 0) {
      // loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });
    }
  }

  const advanceSearch = (value) => {
    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.feet_number });
      setoffsetFilter(true);
      listData({ lazyEvent: (lazyParams) }).then(data => {
        //if (data?.data !== undefined) {
          setTotalRecords(data?.count);
          setCustomers(data?.rows);
        })
    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }

  let istyping = false
  const throttleFunction=(func, delay)=>{

  clearTimeout(istyping);
  istyping = setTimeout(() => {
    func();
  }, delay);
  }

  // Delete Entry Function Start Here =====
  const deleteEntry = (id) => {
    setLoader(true);
    removeData(id, reciveLocalData).then(({ type, message }) => {

      toast?.current?.show({ severity: 'success', detail: 'Deleted Successfully', life: 3000, });
      setLoader(false);
      loadLazyData();

      setTimeout(() => {
        navigate('/transport/vehicle')
      }, 3000)

    })
  }
  const deleteConfirm = (truck_setup_id) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(truck_setup_id)
      }
    });

  }
  // Delete Entry Function end Here =====




  const actionButton = (rowData) => (
    <div className="d-flex gap-2">
      <Link variant='' to={`./edit/${rowData.truck_setup_id}`} className={`btn fz16 p-0 edit-btn ${vehicleArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`}><i className="fa-light fa-edit"></i></Link>
      <Button variant='' className={`deleteRow btn fz16 p-0 delete-btn ${vehicleArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} onClick={() => deleteConfirm(rowData.truck_setup_id)} ><i className="fa-light fa-trash"></i></Button>
    </div>
  )

  const vehicleActionButton = (rowData) => (
    rowData?.truck_type === '1' ? <div className="d-flex gap-2">
      <Link variant='' to={`./checklist/${rowData.truck_setup_id}`} className="btn fz16 p-0 edit-btn"><i className="fa-light fa-truck"></i></Link>
    </div>:''
  )
  
  const vechileType = (rowData) => {
    return <span>{rowData.truck_type === "1" ? "Truck" : "Trailer"} </span>
  }

  const DeleteBulk = (id) => {
    setLoader(true)
    vehicleBulkDelete(id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      setLoader(false)
      loadLazyData();
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000 });

      setTimeout(() => {
        navigate('/transport/vehicle')
      }, 3000)

    })
  }

  const bulkDelete = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.truck_setup_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-truck"></i>&nbsp;
                  Vehicle
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to="./add-truck" className={`bg-green text-white btn fz14 me-3 ${vehicleArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}>
                  <i className="fa-light fa-plus-circle"></i>&nbsp; 
                  Add New
                </Link>
                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => throttleFunction(()=> onGlobalFilterChange1(event), 1000)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                {/* <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <ul>
                    <li>
                        <Button className="dropdown-item" to="#" onClick={() => bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Button>
                      </li> 
                    </ul>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1  mt-5">
            <DataTable value={trucktData} selectionMode="checkbox" dataKey="feet_number" paginator rows={12} totalRecords={totalRecords} onPage={onPage} {...dataTableConfig} scrollDirection="both"
              selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)} {...dataTableConfig}
            >
              {/* <Column headerStyle={{ width: "3em" }} selectionMode="multiple" /> */}
              <Column header="Action" body={actionButton} />
              <Column sortable field='feet_number' header="Fleet Number" style={{ flexGrow: 1, flexBasis: "120px" }} />
              <Column sortable field='truck_registration' header="Vehicle Registration" style={{ flexGrow: 1, flexBasis: "150px" }} />
              <Column sortable field='truck_model' header="Make/Model" style={{ flexGrow: 1, flexBasis: "100px" }} />
              <Column sortable field='truck_year' header="Year" style={{ flexGrow: 1, flexBasis: "100px" }} />
              <Column sortable field='truck_type' header="Type" body={vechileType} style={{ flexGrow: 1, flexBasis: "100px" }} />
              <Column sortable field='last_service_detail' header="Last Serviced (date and KMS)" style={{ flexGrow: 1, flexBasis: "250px" }} />
              <Column sortable field='next_service_due' header="Next Service due (kms)" style={{ flexGrow: 1, flexBasis: "200px" }} />
              <Column field='' header="Vehicle check list" body={vehicleActionButton} style={{ flexGrow: 1, flexBasis: "200px" }} />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default Vehicle

