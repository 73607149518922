
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REACT_APP_API_URL } from '../../../config'

const STATUSES = Object.freeze({
     IDLE:"Idle",
     LOADING:"Loading...",
     ERROR:"Error"
})

const initialState = {}

export const consignmentThunk = createAsyncThunk('consignment-add', async(body) => {
    const baseAPIUrl = REACT_APP_API_URL

    const res = await fetch(baseAPIUrl + "consignments/addConsignment", {
             method:"POST",
             headers:{
                 "Content-Type":"application/json"
             },
             body:JSON.stringify(body)
    })
    return await res.json(); 
})


const ConsignmentAddReducer = createSlice({
    name:'consignment',
    initialState,
    reducers:{
        addConsignment(state, action){
            state = action.payload
        }
    },
    extraReducers: (builder) => {
            builder
                  .addCase(consignmentThunk.pending, (state, action) => {
                     console.log('pending')
                  })
                  .addCase(consignmentThunk.fulfilled, (state, action) => {
                    console.log('fulfilled')
                    state = action.payload
                  })
                  .addCase(consignmentThunk.rejected, (state, action) => {
                    console.log('rejected')
                  }) 

 
    } 
})



export const {addConsignment} = ConsignmentAddReducer.actions
export default ConsignmentAddReducer.reducer