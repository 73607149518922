import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputText, Dropdown as Select, Calendar, confirmDialog, Toast } from "../../PrimeReactComponents";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Button } from "../../BootstrapComponents";
import { useDispatch, useSelector } from 'react-redux';
import { TransportChangePasswordInitialState, TransportChangePasswordValidateSchema } from './Validatation/TransportChangePasswordValidation'
import { ChangePasswordDataAPI } from './Controllers/ProfileController';
import Loader from "../../features/loader/Loader";
import { getUserInfo } from '../../services/commonController';
import { setUserInfo } from '../../store/userInfo'  

const ChangePassword = () => { 
  const { transportUserInfo } = useSelector(state => state);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loader, setLoader] = useState(); 
  const [initialState, setInitState] = useState(TransportChangePasswordInitialState)
  const [userEmailId, setUserEmailId] = useState()
  const dispatch = useDispatch();

  const companyId = transportUserInfo.company_id;
  const userId = transportUserInfo.user_id;


  const getUserInfoData = async () => {
    const results = await getUserInfo(userId, companyId);  
    setUserEmailId(results?.data?.resultData?.userData?.email_id) 
    dispatch(setUserInfo(results.data));
}

useEffect(() => {
    getUserInfoData();
}, [userId, companyId])


  const changeTransportPasswordSubmit = async (values, { resetForm }) => {

    let modifyData = {...values, email:userEmailId} 

    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => { 
        setLoader(true)
        ChangePasswordDataAPI(modifyData).then(({ status, type, message }) => {
               if (status) {
                   setLoader(false)
                   resetForm({ all_values: '' })
                   toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                       navigate('/transport/profile') 
               } else {
                   setLoader(false)
                   toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
               }
           }) 
      }
    });
  }

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <Formik enableReinitialize initialValues={initialState} validationSchema={TransportChangePasswordValidateSchema} onSubmit={changeTransportPasswordSubmit}>
        {
          ({ values, setFieldValue }) => (
            <Form className="d-flex flex-column overflow-auto" encType="multipart/form-data">
              <div className="tab-pane fade active show pt-5" id="change_pass">
                <div className="card p-4 mt-5">
                  <div className="card-body">
                    <div className="row gx-5"> 
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">New Password <span className="text-danger">*</span></label>
                        <Field name="newpassword" render={({ field }) => <InputText type="password" className="form-control px-3"   {...field} />} maxLength="20" />
                        <ErrorMessage name="newpassword" component="span" className="from-error-span" />
                      </div>
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">Confirm Password <span className="text-danger">*</span></label>
                        <Field name="confirmpassword" render={({ field }) => <InputText type="password" className="form-control px-3"   {...field} />} maxLength="20" />
                        <ErrorMessage name="confirmpassword" component="span" className="from-error-span" />
                      </div>
                    </div>
                    <div className="text-end">
                      <Button type="submit" variant="" className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save Changes</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </>
  )
}

export default ChangePassword
