import {appAxios} from './common/AxiosFile';
import {REACT_APP_API_URL} from '../../config'

export  async function  addData(data){  

    try {
        const res = await appAxios().post(`${REACT_APP_API_URL}platformsetup/addtrucktype`, data);
        const result = await res.data;    
        if(result.status){                  
            if(result.data.success===1){
                return {                      
                    status: 1,
                    message:result.data.msg,
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };     
            }else{
                return {                      
                    status: 1,
                    message:result.data.msg,
                    type:'info',
                    error:null,
                    data:result.data.data,                                           
                };                         
            }                    
        }else{
            return {                      
                status: 0,
                message:result?.message,
                type:'info',
                error:null,                                           
            };                    
        } 
    } catch (error) {                            
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,    
            data: null,                                       
        };                  
    }
}
export async function listData(lazyEvent){ 
   
   try {
    const {rows,page,id,sortOrder,sortField,globalFilterValue,status}=lazyEvent?.lazyEvent;
        const res = await appAxios().get(`${REACT_APP_API_URL}platformsetup/trucktypelist?keyword=${globalFilterValue}&sortkey=${sortField}&status=${status}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);    
        const result = await res.data;                      
        if(result.status ===1){
            if(result.data?.rows.length > 0){  
                return {
                    status: 1,
                    message:"User Successfully fetched.",
                    data: result.data,
                    type:'success',                   
                    error:null
                }                  
            }else{
                
                return {
                    status: 0,
                    message:"Data not found!",
                    data: null,
                    type:'info',
                    error:null
                }
            } 
        }        
   } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,    
            data: null,                                       
        };    
   }
}
export async function editData(data,id){
    try {
         const res = await appAxios().put(`${REACT_APP_API_URL}platformsetup/updatetrucktype/${id}`, data);
         const result = await res.data;                      
         if(result.status){          
             return {                      
                 status: result.status,
                 message:"Data Successfully Updated.",
                 type:'success',
                 error:null,                       
                 data:result.data.data,
             };               
         }else{
             return {
                 status:0,
                 message:"Data not found!",
                 type:'info',
                 error:null,                       
                 data:null,                    
             }
         } 
    } catch (error) {       
         return {                      
             status: 0,
             message:error.message,         
             type:'info',
             error:error,                                         
         };   
    }
 }
 export async function removeData(id){        
    try {
        const res = await appAxios().put(`${REACT_APP_API_URL}platformsetup/trucktypedelete`,{id:[id]});
        const result = await res.data;                        
        if(result.status){   
            return {
                status: result.status,
                message:"Data Successfully Deleted.",
                type:'success',
                data:id,
                error:null,                                                              
            }                  
        }else{
            return {
                status: 0,                    
                message:"Data not found!" ,
                type:'info',   
                error:null                                    
            }
        } 
    } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,                                         
        };   
    }
}

export async function activeInactive(status,id){
    console.log("status,idstatus,id",status,id);
    try {
      const res = await appAxios().put(`${REACT_APP_API_URL}platformsetup/updatetrucktype/${id}/${status === '1'? 0:1}`);
      const result = await res.data;                
      if(result.status){   
          return {               
              type:'success',
              error:null,                                    
              status: 1,
              message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
              data: res.data
          }                  
      }else{
          return {                      
              status: 0,
              message:"Data not found!",         
              type:'info',
              error:null,                                         
          };   
      } 
    } catch (error) {
          return {                      
              status: 0,
              message:error.message,         
              type:'info',
              error:error,                                         
          };   
    }
  }

  export async function singleData(id){      
    try {
        const res = await appAxios().get(`${REACT_APP_API_URL}platformsetup/edittrucktype/${id}`);  
        const result = await res.data;               
        if(result.status){   
            return {
                status: 1,
                message:"Data Successfully fetched.",                    
                type:'success',
                error:null,
                data: res.data
            }                  
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {     
       
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}




  export async function activeInactiveBulk(status,ids){
      
      try {
        const res = await appAxios().post(`${REACT_APP_API_URL}platformsetup/platformTruckBulkChangeStatus`,{truck_id:ids,status});
        const result = await res.data;                
        if(result.status){
       
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:res.data.data.msg,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:res.data.data.msg,         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.msg,         
                type:'info',
                error:error,                                         
            };   
      }
    }


    export async function removeDataBulk(ids){        
      try {
          const res = await appAxios().put(`${REACT_APP_API_URL}platformsetup/platformTruckBulkDelete`,{truck_id:ids});
          const result = await res.data;                        
          if(result.status){   
              return {
                  status: result.status,
                  message:"Data Successfully Deleted.",
                  type:'success',
                  error:null,                                                              
              }                  
          }else{
              return {
                  status: 0,                    
                  message:"Data not found!" ,
                  type:'info',   
                  error:null                                    
              }
          } 
      } catch (error) {
          return {                      
              status: 0,
              message:error.message,         
              type:'info',
              error:error,                                         
          };   
      }
  }