import * as yup from 'yup';

const faultReportInitialState = {
    status: '',
    comments: '' 
};

const faultReportValidateSchema = yup.object().shape({
    status: yup.string().required('This Field is required'),
    comments: yup.string().required('This Field is required').nullable() 
})

export { faultReportValidateSchema, faultReportInitialState }


