import {REACT_APP_API_URL} from '../../../config'
import {appAxios} from './../common/AxiosFile';

 export const title = "Driver Management"

    export async function listData(params){
       
        try {
            const {rows,page,sortOrder,sortField,globalFilterValue,status,company_id,last_name,first_name}=params?.lazyEvent;    
            
            const res = await appAxios().get(`${REACT_APP_API_URL}transport/driverlist/${company_id}?keyword=${globalFilterValue}&first_name=${first_name}&last_name=${last_name}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
            const result = await res.data;  
            if(result.data.count){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data,                  
                } 
                               
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function singleData(id){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}transport/editdriver/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function addData(data, header){ 
        try {

            const res = await appAxios().post(`${REACT_APP_API_URL}transport/adddriver`, data, header);
            const result = await res.data;  

            if(result?.status){                  
                if(result?.data?.success){ 
                    return {                      
                        status: result.data.success,
                        message:"Driver added successfully.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function editData(data,id){
       try {
            const res = await appAxios().put(`${REACT_APP_API_URL}transport/updatedriver/${id}`, data);
            const result = await res.data;                      
            if(result.status){          
                return {                      
                    status: result.status,
                    message:"Data Successfully Updated.",
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };               
            }else{
                return {
                    status:0,
                    message:"Data not found!",
                    type:'info',
                    error:null,                       
                    data:null,                    
                }
            } 
       } catch (error) {       
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
       }
    }

    export async function removeData(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}transport/companydriverdelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function activeInactive(status,id){
      try {
        const res = await appAxios().put(`${REACT_APP_API_URL}transport/companydriverstatusupdate/${id}/${status === '1'? 0:1}`);
        const result = await res.data;                
        if(result.status){   
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:"Data not found!",         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
      }
    }

    export async function removeDriverDocument(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}transport/driverdocdelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!",
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function removeDriverNote(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}transport/drivernotesdelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!",
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function toValidateEmailIdExistOrNot(emailId){
        try {
             const res = await appAxios().get(`${REACT_APP_API_URL}common/getEmailExistOrNot/${emailId}`); 
             const result = await res?.data;   
             if(result?.status){        
                 return {                      
                     status: result.status,
                     message: result?.data,
                     type:'success',
                     error:null,                       
                     data:result?.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }

     // Active Driver

    
    export async function activeInactiveBulk(status,ids, companyId){ 
        try {
          const res = await appAxios().put(`${REACT_APP_API_URL}transport/driverBulkChangeStatus/${companyId}`,{driver_id:ids,status});
          const result = await res.data;                
          if(result.status){
         
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:res.data.data.msg,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:res.data.data.msg,         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.msg,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }
    
      export async function removeDataBulk(ids,companyId,selectedDriverName){      
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}transport/driverBulkDelete/${companyId}`,{driver_id:ids,driver_number:selectedDriverName});
            const result = await res.data; 
            const messageData =
                <p>{result.data?.data.successMessage ?result.data?.data.successMessage:""} <br></br>             
                    <span>{result.data?.data.errorMessage}</span><br></br>
                    <b><span>{result.data?.data.driverList}</span> </b>
                    </p> ;                    
            if(result.status){   
                return {
                    status: result.status,
                    message:messageData,
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:messageData,
                    type:'error',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'error',
                error:error,                                         
            };   
        }
    }



