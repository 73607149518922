import {appAxios} from './common/AxiosFile';
import {REACT_APP_API_URL} from '../../config'

export  async function  addData(data){  

    try {
        const res = await appAxios().post(`${REACT_APP_API_URL}company/senduserrequest/`, data);
        const result = await res.data;    
        if(result.status){                  
            if(result.data.success===1){              
                return {                      
                    status: 1,
                    message:result.data.msg,
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };     
            }else{
                return {                      
                    status: 1,
                    message:result.data.msg,
                    type:'success',
                    error:null,
                    data:result.data.data,                                           
                };                         
            }                    
        }else{
            return {                      
                status: 0,
                message:'Something went wrong.',
                type:'info',
                error:null,                                           
            };                    
        } 
    } catch (error) {                            
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,    
            data: null,                                       
        };                  
    }
}


export async function companyUserListData(lazyEvent){ 

    try {
     const {rows,page,id,sortOrder,sortField,globalFilterValue,status}=lazyEvent;
     let reciveSortField = sortField === "userData.email_id" ? "email_id":sortField;
         const res = await appAxios().get(`${REACT_APP_API_URL}company/userlist/${id}/${id}/1?keyword=${globalFilterValue}&status=${status}&sortkey=${reciveSortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
         const result = await res.data;        
         if(result.status ===1){
             if(result.data?.rows.length > 0){  
                 return {
                     status: 1,
                     message:"User Successfully fetched.",
                     data: result.data,
                     type:'success',                   
                     error:null
                 }                  
             }else{ 
                 return {
                     status: 0,
                     message:"Data not found!",
                     data: null,
                     type:'info',
                     error:null
                 }
             } 
         }        
    } catch (error) {
         return {                      
             status: 0,
             message:error.message,         
             type:'info',
             error:error,    
             data: null,                                       
         };    
    }
 }

export async function listData(lazyEvent){ 

   try {
    const {rows,page,id,sortOrder,sortField,globalFilterValue,status}=lazyEvent;
    let reciveSortField = sortField === "userData.email_id" ? "email_id":sortField;
        const res = await appAxios().get(`${REACT_APP_API_URL}company/userlist/${id}/${id}/3?keyword=${globalFilterValue}&status=${status}&sortkey=${reciveSortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
        const result = await res.data;        
        if(result.status ===1){
            if(result.data?.rows.length > 0){  
                return {
                    status: 1,
                    message:"User Successfully fetched.",
                    data: result.data,
                    type:'success',                   
                    error:null
                }                  
            }else{ 
                return {
                    status: 0,
                    message:"Data not found!",
                    data: null,
                    type:'info',
                    error:null
                }
            } 
        }        
   } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,    
            data: null,                                       
        };    
   }
}

export async function activeInactive(id, status){ 
    try {
      const res = await appAxios().put(`${REACT_APP_API_URL}company/userstatusupdate/${id}/${status === '1'? 0:1}`);
      const result = await res.data;                
      if(result.status){   
          return {               
              type:'success',
              error:null,                                    
              status: 1,
              message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
              data: res.data
          }                  
      }else{
          return {                      
              status: 0,
              message:"Data not found!",         
              type:'info',
              error:null,                                         
          };   
      } 
    } catch (error) {
          return {                      
              status: 0,
              message:error.message,         
              type:'info',
              error:error,                                         
          };   
    }
  }
  export async function removeData(id){        
    try {
        const res = await appAxios().delete(`${REACT_APP_API_URL}company/userroledelete/${id}`);
        const result = await res.data;                        
        if(result.status){   
            return {
                status: result.status,
                message:"Data Successfully Deleted.",
                type:'success',
                error:null,                                                              
            }                  
        }else{
            return {
                status: 0,                    
                message:"Data not found!" ,
                type:'info',   
                error:null                                    
            }
        } 
    } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,                                         
        };   
    }
}

export async function userRoleUpdate(data){  

    let company_id = data?.company_id
    let user_id = data?.user_id
  
      try {
        const res = await appAxios().put(`${REACT_APP_API_URL}company/user-role-update/${company_id}/${user_id}`, data);
        const result = await res.data;                
        if(result.status){   
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:`Data Successfully Updated`,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:"Data not found!",         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
      }
    }


  
  
      export async function activeInactiveBulk(status,ids){
          
          try {
            const res = await appAxios().put(`${REACT_APP_API_URL}company/userBulkChangeStatus/7a67614e-823a-459b-a5cc-6e6ce33d96bg/3`,{user_id:ids,status});
            const result = await res.data;                
            if(result.status){
           
                return {               
                    type:'success',
                    error:null,                                    
                    status: 1,
                    message:res.data.data.msg,
                    data: res.data
                }                  
            }else{
                return {                      
                    status: 0,
                    message:res.data.data.msg,         
                    type:'info',
                    error:null,                                         
                };   
            } 
          } catch (error) {
                return {                      
                    status: 0,
                    message:error.msg,         
                    type:'info',
                    error:error,                                         
                };   
          }
        }
    
  
        export async function removeDataBulk(ids){        
          try {
              const res = await appAxios().put(`${REACT_APP_API_URL}company/userBulkDelete/7a67614e-823a-459b-a5cc-6e6ce33d96bg/3`,{user_id:ids});
              const result = await res.data;                        
              if(result.status){   
                  return {
                      status: result.status,
                      message:"Data Successfully Deleted.",
                      type:'success',
                      error:null,                                                              
                  }                  
              }else{
                  return {
                      status: 0,                    
                      message:"Data not found!" ,
                      type:'info',   
                      error:null                                    
                  }
              } 
          } catch (error) {
              return {                      
                  status: 0,
                  message:error.message,         
                  type:'info',
                  error:error,                                         
              };   
          }
      }
  
      export async function getLoginData(key, type) {
        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/tokenverification`, { access_token: key, user_type: type });
            const result = await res.data;
            if (result.status) {
                if (result.status === 1) {
                    return {
                        status: 1,
                        message: "",
                        type: 'success',
                        error: null,
                        data: result.data,
                    };
    
                } else {
                    return {
                        status: 1,
                        message: "",
                        type: 'info',
                        error: null,
                        data: result.data,
                    };
                }
    
            } else {
                return {
                    status: 0,
                    message: 'Something went wrong.',
                    type: 'info',
                    error: null,
                };
            }
    
        } catch (error) {
            return {
                status: 0,
                message: error.message,
                type: 'info',
                error: error,
                data: null,
    
            };
    
        }
    
    }

    export  async function  forgetPassword(data){  
        
        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/resetpassword`, data);
            console.log("result................",res);   
            const result = await res.data; 
           
            if(result.status){                  
                if(result.status===1){              
                    return {                      
                        status: 1,
                        message:"Link send on your email to change password",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else if(result.status===1){
                    return {                      
                        status: 1,
                        message:"Link send on your email to change password",
                        type:'success',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else if(result.status===0){
                return {                      
                    status: 0,
                    message:'Please enter valid mail',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                            
            return {                      
                status: 0,
                message:'Something went wrong.',         
                type:'info',
                error:error,    
                data: null,                                       
            };                  
        }
    }


    export async function getUserType(){ 

        try {
      
             const res = await appAxios().get(`${REACT_APP_API_URL}permissions/getRoles`);
              const result = await res.data;        
     
             if(result.status ===1){
                 if(result.data){  
                     return {
                         status: 1,
                         message:"User Successfully fetched.",
                         data: result.data,
                         type:'success',                   
                         error:null
                     }                  
                 }else{ 
                     return {
                         status: 0,
                         message:"Data not found!",
                         data: null,
                         type:'info',
                         error:null
                     }
                 } 
             }        
        } catch (error) {
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,    
                 data: null,                                       
             };    
        }
     }

     export  async function resendInvit(data){  

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/resenduserrequest`, data);
            const result = await res.data;    
             
            if(result.status){                  
                if(result.data.success===1){
                    return {                      
                        status: 1,
                        message:result.data?.msg,
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: 1,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                            
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,    
                data: null,                                       
            };                  
        }
    }