// Login , Logout API
let URL = "https://idlttech.broadwayinfotech.net.au/auth";
let GRAND_TYPE = "password";

// LTTECH LIVE Credentials

let REALM = "LTTECH";
let CLIENT_ID_PLATEFORM = "lttech-platform";
let CLIENT_SECRET_PLATEFORM = "gdcEpaWOTaB5Ep96VGQp7cv8lQMU0Ns6";
let CUSTOMER_CLIENT_ID = "lttech-customer";
let CUSTOMER_CLIENT_SECRET = "mdjcobgtRvieUzpRZLpNnCwsfXjzp4W6";
let TRANSPORTER_CLIENT_ID = "lttech-transporter";
let TRANSPORTER_CLIENT_SECRET = "VOfVPUoPUoAOgoUtTbdTiVu4TjQYZx88";     


// LTTECHDEMO  Credentials
   
/* let REALM = "LttechLocal"; 
let CLIENT_ID_PLATEFORM = "lttech-platformDemo";
let CLIENT_SECRET_PLATEFORM = "30BvVQ76paCKZP7n1DgTi36jlQW4O6JX";
let CUSTOMER_CLIENT_ID = "lttech-customerDemo";
let CUSTOMER_CLIENT_SECRET = "g5HKd9VFnhKeIywxKSVfTeGglzTzqsz5";
let TRANSPORTER_CLIENT_ID = "lttech-transporterDemo";
let TRANSPORTER_CLIENT_SECRET = "NTNs6DOGroC785LD8Uohi3oKhYofVen9";    */ 


// LTTECH Beta Credentials

/* let REALM = "LTTECHBETA";
let CLIENT_ID_PLATEFORM = "lttech-platform-beta";
let CLIENT_SECRET_PLATEFORM = "JBM0dgs0YkXGmSkzEPRaIzp2OMUgWaPE";
let CUSTOMER_CLIENT_ID = "lttech-customer-beta";
let CUSTOMER_CLIENT_SECRET = "c8F6nTFdBZSgIVfLcsiJkDsjGM9x6p1j";
let TRANSPORTER_CLIENT_ID = "lttech-transporter-beta";
let TRANSPORTER_CLIENT_SECRET = "bENoli9EpZd8LX1hWFAtAnLmgE1z5Ycn";   */


export const loginApiUrl = `${URL}/realms/${REALM}/protocol/openid-connect/token`;
export const logoutApiUrl = `${URL}/realms/${REALM}/protocol/openid-connect/logout`;


export let plateformLogoutDetails = {
    "client_id": CLIENT_ID_PLATEFORM,
    "client_secret":CLIENT_SECRET_PLATEFORM, 
    //"refresh_token":refresh_token
 };

 export let platformRefreshlogin ={
   "client_id": CLIENT_ID_PLATEFORM,
   "client_secret":CLIENT_SECRET_PLATEFORM,
   "grant_type":"refresh_token",

 }

 export let plateformLoginDetails = {
    "grant_type": GRAND_TYPE,
   "client_id": CLIENT_ID_PLATEFORM,
   "client_secret":CLIENT_SECRET_PLATEFORM,
   //"username":values.username,
   // "password": values.password,
};

export let customerLogouDetails = {
    "client_id": CUSTOMER_CLIENT_ID,
    "client_secret":CUSTOMER_CLIENT_SECRET, 
    //"refresh_token":{refresh_token}
 };

 export let customerLoginDetails = {
    "grant_type":GRAND_TYPE,
   "client_id":CUSTOMER_CLIENT_ID,
   "client_secret":CUSTOMER_CLIENT_SECRET,
   //"username":values.username,
    //"password": values.password, 
};

export let customerRefreshlogin ={
   "client_id": CUSTOMER_CLIENT_ID,
   "client_secret":CUSTOMER_CLIENT_SECRET,
   "grant_type":"refresh_token",

 }

export let transportLogoutDetails = {
    "client_id": TRANSPORTER_CLIENT_ID,
    "client_secret":TRANSPORTER_CLIENT_SECRET, 
   // "refresh_token":{refresh_token}
 };

 export let TransportLoginDetails = {
    "grant_type":GRAND_TYPE,
   "client_id": TRANSPORTER_CLIENT_ID,
   "client_secret":TRANSPORTER_CLIENT_SECRET,
   //"username":values.username,
   // "password": values.password,
};
export let transportRefreshlogin ={
   "client_id": TRANSPORTER_CLIENT_ID,
   "client_secret":TRANSPORTER_CLIENT_SECRET,
   "grant_type":"refresh_token",

 }