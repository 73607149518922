import * as yup from 'yup';


const userRegistrationInviteInitialState = { 
      first_name: '',
      last_name:'', 
      email_id:'',
      password:'',
};

const userRegistrationInviteInitialStateValidateSchema = yup.object().shape({ 
    first_name: yup.string().required('This Field is required'),
    last_name: yup.string().required('This Field is required'),
    email_id: yup.string().trim().email('Please enter valid email').required('This Field is required'),
    password: yup.string().required('Please Enter your password').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,12})/,
    "Must Contain 6 Characters and Maximum 12 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
  )
})
 

export { userRegistrationInviteInitialStateValidateSchema, userRegistrationInviteInitialState }


