import * as yup from 'yup';

const customerInitialState = {
  customer_number: '',
  customer_company_name: '',
  first_name: '',
  last_name: '',
  email_id: '',
  phone: '',
  mobile: ''
  , 
  customer_billing_address_details: [{
    customer_name: '',
    address: '',
    suburb: '',
    zip_code: '',
    state_id: '',
    country_id: '',
    customer_billing_id:'',
  }],
  customer_document_details: [{ 
    document_name: '',
    customer_document_id:''
  }],
  customer_notes_details: [{
    notes_content: '',
    notes_id:''
  }],
  customer_document_id:[], 
  customer_document_name:[], 
}

const customerValidateSchema = yup.object().shape({
  customer_number: yup.string().required('This Field is required'),
  customer_company_name: yup.string().min(2, 'This Field must be at least 2 characters').max(30, 'This Field must be at most 30 characters').required('This Field is required'),
  first_name: yup.string().min(2, 'This Field must be at least 2 characters').max(50, 'This Field must be at most 50 characters').required('This Field is required'),
  last_name: yup.string().min(2, 'This Field must be at least 2 characters').max(50, 'This Field must be at most 50 characters').required('This Field is required'),
  email_id: yup.string().email('This Field must be a valid email').required('This Field is required'),
  phone: yup.string().min(10, 'This Field must be at least 10 characters').max(10, 'This Field must be maxium 10 characters').required('This Field is required'),
  mobile: yup.string().min(10, 'This Field must be at least 10 characters').max(10, 'This Field must be maxium 10 characters').required('This Field is required'),
  customer_billing_address_details: yup.array().of(
    yup.object().shape({
     /*  customer_name: yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
      address: yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
      suburb: yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
      suburb: yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
      zip_code: yup.string().min(4, 'This Field must be at least 4 characters').max(4, 'This Field must be maxium 4 characters').required('This Field is required'),
      state_id: yup.string().required('This Field is required'),
      country_id: yup.string().required('This Field is required'), */
    })
  ),
  customer_document_details: yup.array().of(
    yup.object().shape({
     // document_name: yup.string().required('This Field is required')
    })
  ),
  customer_notes_details: yup.array().of(
    yup.object().shape({
      //notes_content: yup.string().required('This Field is required')
    })
  )
})


const cusFilterInitialState = {
  customer_number: '', 
  status: ''
}

const cusFilterSchema = yup.object().shape({
  customer_number: yup.string().min(2, 'This Field must be at least 2 characters').max(30, 'This Field must be at most 30 characters').required('This Field is required'),
  status: yup.string().required('This Field is required')
})

export { customerValidateSchema, customerInitialState, cusFilterInitialState, cusFilterSchema }