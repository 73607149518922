import * as yup from 'yup';

const userinitialState = {
    users: {
      user_id: "",    
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      mobile: "",
    },
  
    address: {
      address: "",
      suburb: "",
      zip_code: "",
      state: "",
      country: "Australia",
    }
  };
  const initUserFilter={
    userId:"",
    status:""
  }
const userValidateSchema = yup.object().shape({
        users: yup.object().shape({
            user_id:yup.string().min(4, 'This Field must be at least 4 characters').max(8 , 'This Field must be at most 8 characters').required('This Field is required'),       
            first_name:yup.string().min(2 , 'This Field must be at least 2 characters').max(10 ,  'This Field must be at most 10 characters').required('This Field is required'),
            last_name:yup.string().min(2 , 'This Field must be at least 2 characters').max(10 ,  'This Field must be at most 10 characters').required('This Field is required'),
            email:yup.string().email().required('This Field is required'),
            phone: yup.number().typeError("That doesn't look like Phone Number").integer("This Field can't include a decimal point").min(6, 'This Field must be at least 6 characters').required('This Field is required'),
            mobile: yup.number().typeError("That doesn't look like Mobile Number").integer("This Field can't include a decimal point").min(8, 'This Field must be at least 8 characters').required('This Field is required')
        }),        
     
        address:  yup.object().shape({          
            address:yup.string().min(2 , 'This Field must be at least 2 characters').required('This Field is required'),
            suburb:yup.string().min(2 , 'This Field must be at least 2 characters').required('This Field is required'),
            zip_code:yup.string().min(2 , 'This Field must be at least 2 characters').required('This Field is required'),
            state:yup.string().required('This Field is required'),
            country:yup.string().min(2 , 'This Field must be at least 2 characters').required('This Field is required')
        }
    )        
})
const userValidateSchemaFilter = yup.object().shape({
  userId:yup.string().required('This Field is required'),
  status:yup.string().required('This Field is required'),

})

export {userValidateSchema , userinitialState,initUserFilter,userValidateSchemaFilter}