import {REACT_APP_API_URL} from '../../config'
import {appAxios} from './common/AxiosFile';

    export const title = "TimeSheet"
   export async function listData(params){
       
    try {
        const {rows,page,sortOrder,sortField,globalFilterValue,company_id,status}=params?.lazyEvent;
           
        const res = await appAxios().get(`${REACT_APP_API_URL}transport/timesheetlist/${company_id}?keyword=${globalFilterValue}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"} &status=${status}&page=${page}&size=${rows}`);
        const result = await res.data;  
        if(result.data.count){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: res.data,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}

   
    export async function singleData(id,reciveLocalData){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}transport/gettimesheet/${reciveLocalData}/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function downloadPDF(fileName){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}common/download/${fileName}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }
       

    export async function addData(data){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}transport/addtimesheet`, data);
            const result = await res.data;            
            if(result.status){                  
                if(result.data.success){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }
 
    
     export async function removeData(company_id,id){     
      
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}transport/deletetimesheet/${company_id}`,{id:[id]});     
            const result = await res.data;                     
            if(result.status){        
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null, 
                    id:[id]                                                             
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function getDriverJobDetail(data){     
          
        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}transport/getdriverjobs`,data);   
            const result = await res?.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function editData(data,id,company_id){
       
      
        try {
             const res = await appAxios().put(`${REACT_APP_API_URL}transport/updatetimesheet/${company_id}/${id}`, data); 
             const result = await res.data;  
           

             if(result.status){          
                 return {                      
                     status: result.status,
                     message:"Data Successfully Updated.",
                     type:'success',
                     error:null,                       
                     data:result.data.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }
 
     export async function removeTimeSheetJob(id, companyId){      
      
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}transport/deletetimesheetjob/${companyId}`,{id:[id]});         
            const result = await res.id;                       
            if(result.status){        
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null, 
                    id:[id]                                                             
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }


    export async function removeTimeSheetJobRest(id, companyId){      
      
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}transport/deletetimesheetrestdetail/${companyId}`,{id:[id]});         
            const result = await res.id;                       
            if(result.status){        
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null, 
                    id:[id]                                                             
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }


    export async function activeInactive(status,id, companyId){
        try {
          const res = await appAxios().put(`${REACT_APP_API_URL}transport/timesheetstatusupdate/${companyId}/${status === '1'? 0:1}`,{id:[id]});
          const result = await res.data;                
          if(result.status){   
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:`Data Successfully ${status === '1'? 'Rejected' : 'Approved'}.`,
                  data: [id]
              }                  
          }else{
              return {                      
                  status: 0,
                  message:"Data not found!",         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.message,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }


      export async function acceptChecklist(data){
       
      
        try {
             const res = await appAxios().put(`${REACT_APP_API_URL}checklist/driverchecklistapprove`, {id:data}); 
             const result = await res.data;            
             if(result.status){          
                 return {                      
                     status: result.status,
                     message:"Approved Successfully Updated.",
                     type:'success',
                     error:null,                       
                     data:result.data.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }


     export async function getUniquData(params){
        const {type}= params
        try {
         
            
            const res = await appAxios().get(`${REACT_APP_API_URL}custom/generate-random-number/${type}`);
            const result = await res.data;  
;
            if(result.status===1){   
               // console.log("this is log.....2323",result)
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result.data.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getTotalRestTime(id, companyId){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}common/timesheet-total-resttime/${companyId}/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    
    export async function rejectReasonComment(data){
      
        try {
             const res = await appAxios().put(`${REACT_APP_API_URL}checklist/reject-reason-comment`, data); 
             const result = await res.data;            
             if(result.status){          
                 return {                      
                     status: result.status,
                     message:"Reason for comment successfully updated.",
                     type:'success',
                     error:null,                       
                     data:result.data.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }
   