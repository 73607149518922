
import axios from "axios";
export const appAxios = ()=>  {
    let access_token=localStorage.getItem('keyCloakaccesstokenPlatfrom');
    //  console.log("access_token...12",access_token);
    return  axios.create({
      headers: {
          Authorization: "Bearer "  + access_token,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
      },
      withCredentials: false
  })}