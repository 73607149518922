import React from 'react';
import { useDispatch } from "react-redux";
import {toggleSidebar}  from '../../store/navToggleSlice'
import {useSelector} from 'react-redux';

function SidebarToggleButton() {

    const sidebarToggle = useSelector((state)=> state.sidebar_toggle) // Get  Data from store
    const dispatch = useDispatch()  // Set  Data in store
  
  
    function toggleNav(toggleVal) {
      dispatch(toggleSidebar(toggleVal))
      window.localStorage.setItem("toggleNav", +!toggleVal);
    }
  return (
    <>            
        <button className="hamburger  fz18 border-0 h-auto" onClick={()=> toggleNav(sidebarToggle) }><i className="fas fa-bars-sort"></i></button>
        <button className="d-xl-none mobileSidebarToggle btn fz24"  onClick={()=> toggleNav(sidebarToggle)}><i className="fa-regular fa-times"></i></button>
    </>
  )
}

export default SidebarToggleButton;




