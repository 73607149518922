import {REACT_APP_API_URL} from '../../config'
import {appAxios} from './common/AxiosFile';

    export const title = "Email Log"
  


export async function faultReportFetchData(params){
       
    try {
        const {rows,page,sortOrder,sortField,globalFilterValue,company_id}=JSON.parse(params?.lazyEvent) ;  

        const res = await appAxios().get(`${REACT_APP_API_URL}reports/faultReports/${company_id}?keyword=${globalFilterValue}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);

        const result = await res.data;   
       
        if(result.data.count){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: result,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}
  



export async function faultReportFetchDataById(params){
       
    try {
        const {company_id,reportId}=params ;  

        const res = await appAxios().get(`${REACT_APP_API_URL}reports/getFaultReportById/${company_id}/${reportId}`);

        const result = await res.data;   
      // console.log("log in action....123",result);
        if(result.data){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: result.data,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}

export async function faultReportUpdateById(data, params){
       
    try {
        const {company_id,id}=params ;  

        const res = await appAxios().put(`${REACT_APP_API_URL}reports/update-fault-report/${company_id}/${id}`,data);
        const result = await res.data;  

        if(result.data){   
            return {
                status: 1,
                message:"Updated Successfully.",                    
                type:'success',
                error:null,
                data: result.data,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}