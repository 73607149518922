import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputText, Dropdown as Select, Calendar, confirmDialog, Toast } from "../../PrimeReactComponents";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Button } from "../../BootstrapComponents";
import { useDispatch, useSelector } from 'react-redux';
import { getAllDocType } from '../../services/commonController';
import { docManagerInitialState, docManagerValidateSchema } from '../Validations/docManagerEditValidation';
import { addData, uploadDocument, singleData, updateData } from '../controllers/DocManagerController';
import Loader from "../../features/loader/Loader";

import { REACT_APP_BASE_BACKEND_URL } from '../../config'

function DocManagerEdit() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loader, setLoader] = useState();
  const [filename, setFilename] = useState();
  const [docType, setDocType] = useState();
  const { id } = useParams();
  const imageArr = []
  const updateImageArr = []
  const [initStateDocManager, setInitStateDocManager] = useState(docManagerInitialState)
  const reciveLocalData = localStorage.getItem('transId');
  const [selectedFile, setSelectedFile] = useState();

  const pathname = window.location.pathname


  let docManagerArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Doc Manager')?.map(val => {
    if (!docManagerArr.includes(val?.menuActionData?.action_name)) {
      docManagerArr.push(val?.menuActionData?.action_name)
    }
  })

  if (pathname.split('/')[3] === 'edit') {
    if (!docManagerArr.includes('Edit')) {
      navigate('/transport/404');
    }
  }

  useEffect(() => {
    if (id) {
      const response = singleData(id);

      response.then(({ status, message, type, error, data }) => {

        if (status) {

          setInitStateDocManager({
            document_type: data?.data?.document_type,
            document_number: data?.data?.document_number,
            doc_manager_id: data?.data?.doc_manager_id,
            document_file: data?.data?.document_file,
            document_file_image: data?.data?.document_file
          });

          setLoader(false)
        } else {
          toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          setLoader(false)
        }
      })
    }
  }, [id])


  const getAllDocTypes = async (reciveLocalData) => {
    const results = await getAllDocType(reciveLocalData);

    const option = [];

    if (results?.data !== '') {
      results?.data.map((ele) => {
        option.push({ value: ele.document_id, label: ele.document_type })
      })
    }
    setDocType(option)
  }

  const handleOnChange = async (event) => {

    if (event.target.files[0].name !== null) {
      let image_name = event.target.files[0]

      if (image_name && (image_name.type === 'image/jpg' || image_name.type === 'image/jpeg' || image_name.type === 'image/png' || image_name.type === 'application/pdf')) {
        if (image_name !== undefined) { 

          setSelectedFile(image_name)
        }
      } else {
        toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'Please select a valid JPG, JPEG, PNG, PDF file Only.', life: 3000, });
      }

    }

  };

  useEffect(() => {
    getAllDocTypes(reciveLocalData)
  }, [])


  const docManagerUpdate = async (values, { resetForm }) => {


    if (selectedFile !== null) {

      const headers = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      const formData = new FormData();
      formData.append('document_file', selectedFile);
      formData.append('document_type', values?.document_type);
      formData.append('document_number', values?.document_number);
      formData.append('doc_manager_id', values?.doc_manager_id);
      formData.append('company_id', reciveLocalData);

      confirmDialog({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          setLoader(true)
          if (id) {
            updateData(formData, id, headers).then(({ status, type, message }) => {
              if (status) {
                setLoader(false)
                resetForm({ InputData: '' })
                toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                setTimeout(() => {
                  navigate('/transport/doc-manager')
                }, 3000)
              } else {
                setLoader(false)
                toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'Error: File size exceeds maximum limit 2 MB.', life: 3000, });
              }
            })
          }
        }
      });
    }

  }


  // validationSchema={docManagerValidateSchema}

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
          <Formik enableReinitialize initialValues={initStateDocManager} validationSchema={docManagerValidateSchema} onSubmit={docManagerUpdate}>
            {
              ({ values, setFieldValue }) => (
                <Form className="d-flex flex-column overflow-auto" encType="multipart/form-data">
                  <div className="container d-flex flex-column h-100 ">

                    <div className="px-4 mt-3">
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                          <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Edit Document</h2>
                        </div>
                        <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                          <Link to="../" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link>
                          <Button type="submit" variant="" className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save</Button>
                        </div>
                      </div>
                    </div>
                    <div className="overflow-auto flex-grow-1 mt-5 px-4">
                      <div className="card p-4">
                        <div className="card-body">
                          <div className="row gx-5">
                            <input className="form-control px-3" name="company_code" hidden />
                            <div className="col-12 mb-3">
                              <h4 className=" fw600 fz16 text-dark">Document Details</h4>
                            </div>

                            <div className="col-12 mt-4 mb-3">
                              <div className="overflow-auto mt-3 ">
                                <FieldArray name="doc_manager_details" render={({ push, remove, }) => (
                                  <table className="table" style={{ minWidth: "1200px" }}>
                                    <thead>
                                      <tr>
                                        <th className="fw500 fz14" width="530px">Document Type <span className="text-danger">*</span></th>
                                        <th className="fw500 fz14" width="530px">Document Number  <span className="text-danger">*</span></th>
                                        <th className="fw500 fz14" width="530px">Document Upload (jpg,png,jpeg,pdf 2 MB Only)  <span className="text-danger">*</span></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <>
                                            <Field name={`document_type`} render={({ field }) => <Select options={docType} placeholder="Please select document type" {...field} className="w-100 px-3" />} />
                                            <ErrorMessage name={`document_type`} component="span" className="from-error-span" />
                                          </>
                                        </td>

                                        <td>
                                          <Field name={`document_number`} render={({ field }) => (
                                            <>
                                              <InputText {...field} className="form-control px-3" />
                                              <ErrorMessage name={`document_number`} component="span" className="from-error-span" />
                                            </>
                                          )} />
                                        </td>

                                        <td>
                                          <Field name="document_file" render={({ field }) => <input type="file" className="form-control px-3" onChange={(e) => { handleOnChange(e, setFieldValue); setFieldValue('document_file', e.target.files[0]) }} />} /> 

                                          {values?.document_file_image !== '' && values?.document_file_image !== undefined && (values?.document_file_image?.split('.')?.pop() === 'pdf') ? <a href={`${REACT_APP_BASE_BACKEND_URL}${values?.document_file_image}`} target="_blank">View <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a>: <a href={`${REACT_APP_BASE_BACKEND_URL}${values?.document_file_image}`} target="_blank"> <img src={`${REACT_APP_BASE_BACKEND_URL}${values?.document_file_image}`} width="100" /></a>}  


                                          <ErrorMessage name={`document_file`} component="span" className="from-error-span" />

                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}>

                                </FieldArray>

                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </>
  );
}

export default DocManagerEdit;
