import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Dropdown, Button } from "../../BootstrapComponents";
// import { FilterOffset } from "../../components/OffsetRight";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import dataTableConfig from "./config/DatatableConfig";
import { Link, useNavigate } from 'react-router-dom'
import { faultReportFetchData } from "./FaultReportController";
import Loader from "../../features/loader/Loader";
import Moment from 'react-moment';
import { REACT_APP_BASE_BACKEND_URL } from '../../config'

function FaultReports() {
    const { transportUserInfo } = useSelector(state => state);
    const toast = useRef(null);
    const navigate = useNavigate();
    const [tableHeight, setTableHeight] = useState("0px");
    const reciveLocalData = transportUserInfo.company_id;
    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [faultReportData, setFaultReportData] = useState(null);
    const [offsetFilter, setoffsetFilter] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);


    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 12,
        page: 1,
        sortField: "",
        sortOrder: "",
        filters: "",
        globalFilterValue: "",
        company_id: reciveLocalData,
        filters: {  
            'driver_id': { value: '', matchMode: 'contains' },
            'report_date': { value: '', matchMode: 'contains' },
            'report_time': { value: '', matchMode: 'contains' },
            'vehicle_registration_number': { value: '', matchMode: 'contains' },
            'vehicle_type': { value: '', matchMode: 'contains' },
            'report_content': { value: '', matchMode: 'contains' },
            'fault_level': { value: '', matchMode: 'contains' },
            'attachment': { value: '', matchMode: 'contains' },
            'attachment': { value: '', matchMode: 'contains' }
        }
    });

    let loadLazyTimeout = null;
    useEffect(() => {
        loadLazyData();

    }, [lazyParams])
    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            faultReportFetchData({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {
                setTotalRecords(data?.data?.data?.count);
                setFaultReportData(data?.data?.data?.rows);
                setTableHeight(document?.querySelector(".table-wrapper").scrollHeight - 50 + "px");
                setLoading(false);

            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        const { page } = lazyParams
        setLazyParams({
            ...lazyParams,
            pageCount: event.pageCount,
            first: event.first,
            page: event.page === 0 ? 1 : event.page + 1,
            rows: 12,
            multiSortMeta: event.multiSortMeta,
            filters: event.filters,
            sortField: event.sortField,
            globalFilterValue: "",
            sortOrder: lazyParams.sortOrder,
        }, []);
    }

    const onSort = (event) => {
        setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }
    const onGlobalFilterChange1 = (event) => {
        const value = event.target.value;
        if (value.length > 2) {
            setLazyParams({ ...lazyParams, globalFilterValue: value });
        } else if (value.length === 0) {
            setLazyParams({ ...lazyParams, globalFilterValue: "" });
        }
    }

    const advanceSearch = (value) => {
        if (value) {
            setLazyParams({ ...lazyParams, globalFilterValue: value.document_type });
            setoffsetFilter(true);

        } else {
            setoffsetFilter(false);
            setLazyParams({ ...lazyParams });
        }
    }


    const getReportDataTemplate = (rowData) => {
        return <span>{rowData?.report_date !== '' ? <Moment date={rowData?.report_date} format="DD-MM-YYYY" /> : ''}</span>
    }
 

    const getDriverTemplate = (rowData) => {
        return <span>{rowData?.driverData?.first_name} {rowData?.driverData?.last_name}</span>
    }

    const getVehicleTypeTemplate = (rowData) => {
        return <span>{rowData?.truckType?.truck_details}</span>
    }

    const getReportContentTemplate = (rowData) => {
        return <span>{rowData?.report_content.length >= 30 ? rowData?.report_content.substring(0, 30).concat('...') : rowData?.report_content}</span>
    }

    const getAttchmentTemplate = (rowData) => {
        return <span><a href={`${REACT_APP_BASE_BACKEND_URL}${rowData?.attachment}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> </span>
    }

    const getStatusTemplate = (rowData) => {
        let status = ''
        if (rowData?.status === '1') {
            status = <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-yellow`}>Pending</span>
        } else if (rowData?.status === '2') {
            status = <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-green`}>In Progress</span>
        } else {
            status = <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-red`}>Closed</span>
        }
        return status
    }


    const actionBodyTemplate = (rowData) => {
//console.log("this is log",rowData);
        return (
          <>
            <div className="d-flex gap-2">         
              <Link to={"view/" + rowData?.id} className='btn p-0 fz16' >
                <i className="fa-light fa-eye"></i>
              </Link>
            </div>
          </>
        );
      };
    
    return (
        <>
            <Loader show={loader} />
            <Toast ref={toast} position="top-center" />

            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
                    <div className="body-content-top">
                        <div className="d-sm-flex align-items-center flex-wrap">
                            <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0"><i className="fa-light fa-briefcase"></i>&nbsp; Fault Reports </h2>
                            </div>
                            <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">

                                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                                    <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                                    <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                                        <i className="fa-light fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-wrapper flex-grow-1">  
                        <DataTable value={faultReportData} selectionMode="checkbox" lazy dataKey="vehicle_registration_number"
                            scrollable=" true" responsiveLayout="scroll"
                            {...{ scrollHeight: tableHeight }}
                            paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
                            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                            onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                            selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)} {...dataTableConfig}
                        >
                            <Column resizeable style={{ flexGrow: 1, flexBasis: "80px"  }}   header="Action" body={actionBodyTemplate}/>
                            <Column resizeable style={{  flexGrow: 1, flexBasis: "250px" }} sortable field='driver_id' header="Driver Name" body={getDriverTemplate} />
                            <Column resizeable style={{  flexGrow: 1, flexBasis: "250px" }} sortable field='report_date' header="Report Date" body={getReportDataTemplate} />
                            <Column resizeable style={{ flexGrow: 1, flexBasis: "250px"  }} sortable field='report_time' header="Report Time" />
                            <Column resizeable style={{  flexGrow: 1, flexBasis: "250px"  }} sortable field='vehicle_registration_number' header="Vehicle Registration Number" />
                            {/* <Column resizeable style={{ minWidth: 250 }} sortable field='vehicle_type' header="Vehicle Type" body={getVehicleTypeTemplate} />
                            <Column resizeable style={{ minWidth: 250 }} sortable field='report_content' header="Report Content" body={getReportContentTemplate} />
                            <Column resizeable style={{ minWidth: 250 }} sortable field='fault_level' header="Fault Level" />
                            <Column resizeable style={{ minWidth: 250 }} sortable field='attachment' header="File" body={getAttchmentTemplate} /> */}
                            <Column resizeable style={{ minWidth: 250 }} sortable field='status' header="Status" body={getStatusTemplate} />
                        </DataTable>
                    </div>

                </div>
            </div>
        </>
    )
}

export default FaultReports

