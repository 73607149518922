import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
export  const downloadPdf=(html)=>{
    console.log("proint page......");
       // const input = document.getElementById(id);
        html2canvas(html,{
            scale: 3,  
          })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.setFontSize(30); 
                pdf.addImage(imgData, "JPEG", 15, 30, 0, 110);
                pdf.save(`downloaded.pdf`); 
            })
    }