import React, { useRef, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { InputText, InputTextarea, Dropdown as Select, confirmDialog, Toast } from "../../PrimeReactComponents";
import { faultReportFetchDataById, faultReportUpdateById } from "./FaultReportController"
import { REACT_APP_BASE_BACKEND_URL } from '../../config'
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Loader from "../../features/loader/Loader";
import { faultReportValidateSchema, faultReportInitialState } from '../Validations/faultReportValidation';

let BASE_BACKEND_URL = REACT_APP_BASE_BACKEND_URL


function View() {
    const { transportUserInfo } = useSelector(state => state);
    let company_id = transportUserInfo.company_id
    const toast = useRef(null);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [customersData, setCustomersData] = useState()
    const pathname = window.location.pathname
    const { id } = useParams();
    const [driverFullname, setDriverFullname] = useState()
    const [initFaultReport, setInitFaultReport] = useState(faultReportInitialState)
    //let viewstataus;
    let customerArr = []

    let statusArr = [
        { value: 1, label: "Pending" },
        { value: 2, label: "In Progress" },
        { value: 3, label: "Closed" }
    ]


    let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

    transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Customers')?.map(val => {
        if (!customerArr.includes(val?.menuActionData?.action_name)) {
            customerArr.push(val?.menuActionData?.action_name)
        }
    })

    if (pathname.split('/')[3] === 'view') {
        if (!customerArr.includes('View')) {
            navigate('/transport/404');
        }
    }

    const getSingleData = async () => {
        const params = { company_id: company_id, reportId: id }
        setLoader(true)
        const list = await faultReportFetchDataById(params);

        if (list) {
            setCustomersData(list?.data);
            setLoader(false)
            getStatusTemplate(list?.data);
            getDriverTemplate(list?.data)
            setInitFaultReport(list?.data)
        } else {
            setLoader(false)
        }

    }

    useEffect(() => {
        getSingleData()
    }, [id]);


    let loadImage = function (val) {

        var image = new Image();
        var url_image = BASE_BACKEND_URL + val?.attachment;
        image.src = url_image;

        if (image.width === 0) {
            if (val?.attachment?.split('.')?.pop() === 'pdf') {
                return val?.attachment !== null && val?.attachment !== undefined && (((val?.attachment?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${val?.attachment}`} target="_blank"> View<i className="fa-solid fa-file-pdf-o" aria-hidden="true"></i></a> : <a href={`${BASE_BACKEND_URL}${val?.attachment}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${val?.attachment}`} width="100" /></a>)
            } else {
                return <img src={`${BASE_BACKEND_URL}` + 'default_image.jpg'} width="100" />;
            }
        } else {
            return val?.attachment !== null && val?.attachment !== undefined && (((val?.attachment?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${val?.attachment}`} target="_blank">View <i className="fa-solid fa-file-pdf-o" aria-hidden="true"></i></a> : <a href={`${BASE_BACKEND_URL}${val?.attachment}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${val?.attachment}`} width="100" /></a>)

        }
    }

    const getStatusTemplate = (rowData) => {
        let viewstataus;
        if (rowData?.status === '1') {
            viewstataus = <span className={`fw600 border-0 badge py-2 badge-bg-yellow`}>Pending</span>
        } else if (rowData?.status === '2') {
            viewstataus = <span className={`fw600 border-0 badge py-2 badge-bg-green`}>In Progress</span>
        } else {
            viewstataus = <span className={`fw600 border-0 badge py-2 badge-bg-red`}>Closed</span>
        }
        return viewstataus
    }

    const getDriverTemplate = (rowData) => {
        setDriverFullname(rowData?.driverData?.first_name + " " + rowData?.driverData?.last_name)
    }


    const faultReportSave = async (values, { resetForm }) => {

        const params = { company_id: company_id, id: id }

        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setLoader(true)
                faultReportUpdateById(values, params).then(({ status, type, message }) => {
                    if (status) {
                        setLoader(false)
                        resetForm({ all_values: '' })
                        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                        setTimeout(() => {
                            navigate('/transport/fault-reports')
                        }, 3000)
                    } else {
                        setLoader(false)
                        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                    }
                })
            }
        });

    }

    return (
        <>
            <Loader show={loader} />
            <Toast ref={toast} position="top-center" />
            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
                    <div className="container d-flex flex-column h-100 ">
                        <div className="px-4 mt-3">
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                                    <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">View Fault Report</h2>
                                </div>
                            </div>
                        </div>
                        <span className="text-end"><Link to="/transport/fault-reports" className="btn btn-gradient"><i className="fa-light fa-times"></i> Cancel</Link></span>
                        <div className="overflow-auto flex-grow-1 mt-5 px-4">
                            <div className="card p-4">
                                <div className="card-body">
                                    <div className="row gx-5">
                                        <input type="text" className="form-control px-3" name="company_code" hidden />
                                        {/* <div className="col-12 mb-3 justify-content-between d-flex">
                                            <h4 className=" fw600 fz16 text-dark"></h4>
                                            <Link to={`../address-book/${id}/${company_id}`} className="btn text-dark btn-gradient shadow-none ms-3" ><i className="fa-regular fa-map"></i> Address Book</Link>
                                        </div> */}
                                        {/* <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Company Number </label>
                                            <InputNumber format={false} defaultValue="1154987" disabled className="w-100" placeholder="Your Customer Number" inputClassName="form-control px-3" value={customersData?.customer_number} />
                                        </div> */}
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Company Name </label>
                                            <InputText className="form-control px-3" disabled placeholder="Company Name" value={customersData?.companyData?.company_name} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Driver Name</label>
                                            <InputText className="form-control px-3" disabled name="contact_person_first_name" placeholder="Driver Name" value={driverFullname} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Vehicle Type </label>
                                            <InputText className="form-control px-3" name="contact_person_last_name" disabled placeholder="Type" value={customersData?.truckType?.truck_details} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Vehicle Registration Number</label>
                                            <InputText className="form-control px-3" name="email" disabled placeholder="Registration Number" value={customersData?.vehicle_registration_number} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Fault Level</label>
                                            <InputText value={customersData?.fault_level} className="form-control px-3" disabled ></InputText>
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Report Date</label>
                                            <InputText className="form-control px-3" disabled value={customersData?.report_date} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Report Time</label>
                                            <InputText className="form-control px-3" disabled value={customersData?.report_time} />
                                        </div>

                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Status</label>
                                            {/* <InputText className="form-control px-3" disabled value={viewstataus} /> */}
                                            <div>{getStatusTemplate(customersData)}</div>
                                        </div>

                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="" className="mb-2">Attachment</label>
                                            {/* <InputText className="form-control px-3" disabled value={loadImage(customersData)} /> */}
                                            {/*  <div>{loadImage(customersData)}</div> */}

                                            <div>
                                                {customersData?.attachment !== null && customersData?.attachment !== undefined && (((customersData?.attachment?.split('.')?.pop() === 'pdf')) ? <a href={`${BASE_BACKEND_URL}${customersData?.attachment}`} target="_blank">View <i className="fa-solid fa-file-pdf-o" aria-hidden="true"></i></a> : <a href={`${BASE_BACKEND_URL}${customersData?.attachment}`} target="_blank"> <img src={`${BASE_BACKEND_URL}${customersData?.attachment}`} width="100" /></a>)}
                                            </div>

                                        </div>

                                        <div className="col-sm-12 mb-8">
                                            <label htmlFor="" className="mb-2 mt-4">Report Content</label>
                                            <InputTextarea autoResize className="form-control px-3" rows={5} disabled value={customersData?.report_content} />
                                        </div>

                                        <Formik enableReinitialize initialValues={initFaultReport} validationSchema={faultReportValidateSchema} onSubmit={faultReportSave}>
                                            {
                                                ({ setFieldValue, values }) => (
                                                    <Form className="d-flex flex-column overflow-auto" encType="multipart/form-data">
                                                        <div className="col-sm-12 mb-8">
                                                            <label htmlFor="" className="mb-2 mt-4">Transport Comment <span style={{ 'color': 'red' }}>*</span></label>

                                                            <Field name={`comments`} render={({ field }) => (
                                                                <>
                                                                    <InputTextarea {...field} className="form-control px-3" />
                                                                    <ErrorMessage name={`comments`} component="span" className="from-error-span" />
                                                                </>
                                                            )} />
                                                            <ErrorMessage name={`document_type`} component="span" className="from-error-span" />
                                                        </div>

                                                        <div className="col-sm-4 mb-4 mt-4">
                                                            <label htmlFor="" className="mb-2 mt-4">Status <span style={{ 'color': 'red' }}>*</span></label>

                                                            <Field as="select" name="status" id="status" className="form-control" >
                                                                {statusArr.map((status) => (
                                                                    <option
                                                                        key={status.value}
                                                                        value={status.value}
                                                                        selected={values.status === status.value}
                                                                    >
                                                                        {status.label}
                                                                    </option>
                                                                ))}
                                                            </Field>

                                                            <ErrorMessage name={`status`} component="span" className="from-error-span" />
                                                        </div>


                                                        <div className="mt-4 mb-4 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                                                            <button type="submit" variant="" className="bg-green text-white btn fz14 mt-4 "><i className="fa-light fa-save"></i> Save</button>
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        </Formik>

                                        {/* <div className="col-12 mt-4 mb-3">
                                            <h4 className=" fw600 fz16 text-dark"> Billing Address Details </h4>
                                            <div className="overflow-auto mt-3 ">
                                                <table className="table  multiple-feilds-table" style={{ minWidth: "1200px" }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="fw500 fz14" width="30px">#</th>
                                                            <th className="fw500 fz14">Customer Name</th>
                                                            <th className="fw500 fz14">Address</th>
                                                            <th className="fw500 fz14">Suburb</th>
                                                            <th className="fw500 fz14">Zip Code</th>
                                                            <th className="fw500 fz14">Country</th>
                                                            <th className="fw500 fz14" width="190px">State</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            customersData?.customer_billing_address_details && customersData.customer_billing_address_details.map((val, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className="dynamicText">{index + 1}</td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.customer_name} />  </td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.address} />  </td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.suburb} />  </td>
                                                                        <td><InputText name="name" className="form-control px-3" disabled value={val?.zip_code} />      </td>
                                                                        <td>
                                                                            <InputText name="name" className="form-control px-3" disabled value={getCountryName(val?.country_id)} />   </td>
                                                                        <td>
                                                                            <InputText name="name" className="form-control px-3" disabled value={getStateName(val?.country_id, val?.state_id)} />
                                                                        </td>

                                                                    </tr>

                                                                </>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4 mb-3">
                                            <h4 className=" fw600 fz16 text-dark"> Documents</h4>
                                            <div className="overflow-auto mt-3 ">
                                                <table className="table  multiple-feilds-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw500 fz14" width="30px">#</th>
                                                            <th className="fw500 fz14">Upload</th>
                                                            <th className="fw500 fz14" width="30px"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            customersData?.customer_document_details && customersData.customer_document_details.map((val, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className="dynamicText">{index + 1}</td>
                                                                        <td>
                                                                            {/* {(val?.document_name?.split('.')?.pop() === 'pdf') && <a href={`${BASE_BACKEND_URL}${val?.document_name}`} target="_blank">View <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a>}

                                                                            {(val?.document_name?.split('.')?.pop() !== 'pdf') && <img src={`${BASE_BACKEND_URL}${val?.document_name}`} width="100" />} 

                                                                            {loadImage(val)}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4 mb-3">
                                            <h4 className=" fw600 fz16 text-dark"> Multiple Notes</h4>
                                            <div className="overflow-auto mt-3 ">
                                                <table className="table  multiple-feilds-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw500 fz14" width="30px">#</th>
                                                            <th className="fw500 fz14">Notes</th>
                                                            <th className="fw500 fz14" width="30px"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            customersData?.customer_notes_details && customersData.customer_notes_details.map((val, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className="dynamicText">{index + 1}</td>
                                                                        <td><InputTextarea autoResize className="form-control px-3" rows={5} disabled value={val?.notes_content} /></td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default View;
