import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import {confirmDialog,InputText } from "../../PrimeReactComponents";
import { useParams } from "react-router-dom";
import { Dropdown, Button,Modal } from "../../BootstrapComponents";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { jsPDF } from "jspdf";
import { Table } from "../../BootstrapComponents";
import {singleDataManifest} from "../controllers/ConsignmentController";
import {downloadPdf} from "../../features/CommanDownloadpdf/downloadpdf"

import List from "./List";
import Logo1 from '../../assets/img/logo-black.png'
import LogoIcon from '../../assets/img/logo-icon.png'



function View(props) {
    const [initialState, setInitialState] = useState({});
    const [loader, setloader] = useState();
    const reciveLocalData = localStorage.getItem('transId');
    const toast = useRef(null);
    const [printData, setprintData]= useState(false);
    const [palletsData,setpalletsData]=useState();
    const [spacesData,setspacesData]=useState();
    const [dispatchDate,setdispatchDate]=useState()
    const [newModalShowEmail, setNewModalShowEmail] = useState(false);
    const [newModalShow, setNewModalShow] = useState(false);
    const {id} = useParams();
    useEffect(()=>{
        if(id){
            const response= singleDataManifest(id,reciveLocalData);
            response.then(({status,message,type,error, data})=> {           
                if(status){        
                  setInitialState({
                    ...data.data
                  })       
                  setloader(false)
                  qlityTemplate(data.data)
                  dateBodyTemplate(data.data)
                }else{
                  toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});    
                  setloader(false)    
                }      
              })
        }

    }, [id])


    const qlityTemplate = (rowData) => {
        const pallets = rowData.manifest_detail.reduce((acc, curre) => {
            return Number(acc) + Number(curre.pallets)
        }, 0);
        const spaces = rowData.manifest_detail.reduce((acc, curre) => {
            return Number(acc) + Number(curre.spaces)
        }, 0);
        setpalletsData(pallets);
        setspacesData(spaces);
        return palletsData
    };

    const dateBodyTemplate = (rowData) => {
        const now = new Date(rowData?.dispatch_date);
        // console.log("this is log.....", dateFormat(now, "dd mmmm, yyyy"));
        setdispatchDate(dateFormat(now, "dd mmmm, yyyy"))
    }

    // const downloadPdf=()=>{
    //     const input = document.getElementById(id);
    //     html2canvas(input)
    //         .then((canvas) => {
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new jsPDF();
    //             pdf.setFontSize(30); 
    //             pdf.addImage(imgData, "PNG", 15, 30, 180, 200); 
    //             pdf.save(`downloaded.pdf`); 
    //         })
    // }
    function newUserShowPopup(id) {
        setNewModalShowEmail(true);
     //  setReciveId(id)
      }
    return (
        <>
            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-auto flex-grow-1  px-5">
                    <div id="editor"></div>

                    <div className="d-flex justify-content-end gap-3 my-5">
                        <Link to="../" className="btn btn-gradient"><i className="fa-light fa-times"></i> Cancel</Link>
                        <button className="btn bg-green text-white" id="exportForm"  onClick={()=>{downloadPdf(document.getElementById(id))}}><i className="fa-light fa-print"></i> Print</button>
                        <button className="btn bg-green text-white"  data-bs-target="#sendMail" data-bs-toggle="modal"><i className="fa-light fa-envelope"></i> Send To Email</button>
                    </div>

                    <div className="invoice-wrapper shadow bg-white mt-0 mb-5 py-5" >
                        {/* <span  id="downloadPdf"> */}
                        <span id={id}>
                            <div id="section-to-print">
                                <div className="mb-5 fz26 fw600">
                                    Manifest
                                </div>
                                <div className="row pb-lg-5 mt-4">
                                    <div className="col-12">
                                        <div className="from-invoice">
                                            <div className="fw500">
                                                <div>
                                                    <img src={Logo1} className="lg-logo img-fluid" alt="" style={{ maxWidth: "156px" }} />
                                                    <img src={LogoIcon} className="sm-logo img-fluid m-0" alt="" />
                                                </div>
                                                {/* <div>10 WESTGATE DRIVE</div>
                                            <div>LAVERTON NORTH, VIC 3026</div> */}
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <Table className="table table-bordered">
                                                <tbody><tr>
                                                    <th>Phone:</th>
                                                    <td>{initialState?.phone}</td>
                                                    <th rowSpan="3" className="align-middle">Receiver Signature:</th>
                                                </tr>
                                                    <tr>
                                                        <th>Carrier:</th>
                                                        <td>{initialState?.carrier} </td>
                                                    </tr>
                                                    <tr>
                                                        <th>DISPATCH DATE:</th>


                                                        <td> {dispatchDate} </td>

                                                    </tr>
                                                    <tr>
                                                        <th>MANIFEST NO:</th>
                                                        <td>{initialState?.manifest_number}</td>
                                                        <th rowSpan="3" className="align-middle">Date Received:</th>
                                                    </tr>
                                                    <tr>
                                                        <th>QTY:</th>
                                                        <td>{palletsData}Pallets/ {spacesData} spaces</td>
                                                    </tr>
                                                    <tr>
                                                        <th>PALLET DKT: </th>
                                                        <td>{initialState?.pallet_dkt}</td>

                                                    </tr>
                                                </tbody></Table>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-auto">
                                    <Table className="table mt-5 table-bordered">
                                        <thead>
                                            <tr><th>Consignment No</th>
                                                {/* <th>Sender Ref</th> */}
                                                <th>From Company Name</th>
                                                <th>To Company Name</th>
                                                <th>To Address</th>
                                                <th>To City</th>
                                                <th>To Postcode</th>
                                                <th>Total Pallets</th>
                                                <th>Pallet Spaces</th>
                                                <th>Weight (KG)</th>
                                                <th>Job Temp</th>
                                            </tr></thead>
                                        <tbody>



                                            {initialState &&
                                                initialState.manifest_detail?.length > 0 &&
                                                initialState.manifest_detail.map((data, index) => {
                                                    return (<tr>
                                                        <td> {data.consignment_number}</td>
                                                        {/* <td>{data.sender_ref}</td> */}
                                                        <td>{data.from_company_name}</td>
                                                        <td>{data.to_company_name}</td>
                                                        <td>{data.address}</td>
                                                        <td>{data.city}</td>
                                                        <td>{data.postcode}</td>
                                                        <td>{data.pallets}</td>
                                                        <td>{data.spaces}</td>
                                                        <td>{data.weight}</td>
                                                        <td>{data.job_temp}</td>
                                                    </tr>)

                                                })}

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </span>
                     
          
                    </div>
                    <Modal scrollable show={newModalShowEmail} onHide={() => setNewModalShow(false)} size="lg" centered>
              <Modal.Header closeButton={false}>
                {/* <Modal.Title>New User </Modal.Title> */}
              
                <Button className="btn-close ms-21 "  onClick={() => setNewModalShowEmail(false)} variant=""></Button>
              </Modal.Header>
              <Modal.Body>
              <Formik  onSubmit={(value) => {
                  confirmDialog({
                    message: 'Are you sure you want to proceed?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                     // updateUserRole(value)
                     console.log("this is log");
                    }
                  });
                }}>
                 {({setFieldValue}) => (
                  <Form>
                    <div className="row align-items-center p-5 pt-0 gx-2">

                    <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">Email <span className="text-danger">*</span></label>
                          <Field name="" render={({ field }) => (
                            <>
                              <InputText {...field} className="form-control mt-2 px-3"  />
                              {/* <ErrorMessage name="firstname" component="span" className="from-error-span position-absolute" /> */}
                            </>
                          )} />

                        </div>
                      </div>
                     
                 
                      <div className="col-sm-2">
                        <button type="submit" className="btn fz14 bg-green text-white mt-5"><i className="fa-light fa-save"></i> Send</button>
                      </div>
                    </div>
                  </Form>
                 )}
                </Formik>
              </Modal.Body>
            </Modal>
                </div>


            </div>
        </>
    );
}

export default View;
