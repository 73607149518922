import * as yup from 'yup';

const driverInitialState = {
  drivercode: '',
  title: '',
  fname: '',
  lname: '',
  driverphone: '',
  drivermobile: '',
  email_id: '',
  password: '',
  emergencycontactname: '',
  emergencyphone: '',
  contactrelation: '',
  address: '',
  suburb: '',
  zipcode: '',
  state_id: '',
  country_id: 1,
  role_type: '',
  multiple_documents: [{
    driver_id:"",
    license_type: "",
    driverdocument: "",
    license_expiry_date: "",
    issue_date: "",
    forklift_license: ""
  }],
  notes: [{
    notes_content: "",
    driver_id: "",
    notes_id: "",

  }]
};

//const[multipalDocmentState,setmultipalDocmentState]=useState([])
const driverValidateSchema = yup.object().shape({
  drivercode: yup.string().required('This Field is required'),
  title: yup.string().required('This Field is required'),
  fname: yup.string().required('This Field is required'),
  lname: yup.string().required('This Field is required'),
  driverphone: yup.number().required('This Field is required'),
  drivermobile: yup.number().required('This Field is required'),
  // emergencycontactname: yup.string().required('This Field is required'),
  // emergencyphone: yup.number().required('This Field is required'),
  // contactrelation: yup.string().required('This Field is required'),
  suburb: yup.string().required('This Field is required'),
  address: yup.string().required('This Field is required'),
  zipcode: yup.number().required('This Field is required'),
  state_id: yup.string().required('This Field is required'),
  password: yup.string().required('Please Enter your password').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,12})/,
    "Must Contain 6 Characters and Maximum 12 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
  ),
  //email_id: yup.string().email('Invalid email format').required('This Field is required'),
  //  country_id: yup.string().required('This Field is required'),
  multiple_documents: yup.array().of(
    yup.object().shape({
      // license_type: yup.string().required('This Field is required'), 
     // driverdocument: yup.string().required('This Field is required'),
      //     // driverdocument: yup.mixed().test('fileType', "Unsupported File Format", value => ['jpeg', 'png', 'jpg', 'pdf'].includes(['image/*']) ) ,
      //     license_expiry_date: yup.string().required('This Field is required'),
      //     issue_date: yup.string().required('This Field is required'),
      //     forklift_license: yup.string().required('This Field is required'),

    })
  ),
  // notes: yup.array().of(
  //   yup.object().shape({
  //     notes_content: yup.string().required('This Field is required'),
  //   })
  // ),

})


export { driverInitialState, driverValidateSchema }