import * as yup from 'yup';

const rejectValidateInit = {  
            comment: '' 
        }  

const rejectValidateSchema = yup.object().shape({  
            comment: yup.string().required('This Field is required') 
})

export { rejectValidateSchema, rejectValidateInit }