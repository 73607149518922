import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '../../../BootstrapComponents'
import { InputText, Dropdown as Select, confirmDialog, Toast } from "../../../PrimeReactComponents";
import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik';
import { addressBookInitialState, addressBookValidateSchema } from '../../Validations/addressBookValidation'
import { getCountry, getState } from '../../../services/commonController';
import { addCustomerAddress } from '../../controllers/customerController';
import { acceptChecklist, singleData } from "../../controllers/timeSheet"
import RejectModal from './RejectChecklist';

function FitnessModal(props) {

    const { transportUserInfo } = useSelector(state => state);
    const [modalShow, setModalShow] = React.useState(false)
    const [loader, setLoader] = useState();
    const navigate = useNavigate();
    const toast = useRef(null);
    const rs = useParams();
    const { id } = useParams();
    const reciveLocalData = transportUserInfo.company_id;
    const [rejectModalShow, setRejectModalShow] = React.useState(false); // New state for the reject modal

    let modifyedData = props.initialState.checklistDetail[0]?.checklist_value
    let jsonpasrsConvertedData = JSON.parse(modifyedData)

    const customerAddressSubmit = () => {
        acceptChecklist(props.initialState.checklistDetail[0].id).then(({ status, type, message }) => {
            if (status) {
                setModalShow(false)
                singleData(id, reciveLocalData)
                toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                setLoader(false) 
                props.reload(props.id)
            }
        })
    }


    const handleRejectButtonClick = () => {
        setRejectModalShow(true);
    };

    const handleRejectModalClose = () => {
        setRejectModalShow(false);
        setModalShow(false)
        singleData(id, reciveLocalData)
    };

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <Formik initialValues={addressBookInitialState} onSubmit={customerAddressSubmit}
                render={({ values }) => (
                    <>
                        <span variant="" onClick={(e) => setModalShow(true)}
                            className="btn text-dark btn-gradient shadow-none">
                            <i className="fa-solid fa-heart-pulse"></i>Fitness Checklist</span>

                        <Modal show={modalShow} onHide={() => setModalShow(false)} size="xl" centered>
                            <Form className='d-flex flex-column overflow-auto'>
                                <Modal.Header closeButton={false} className="p-4">
                                    <Modal.Title>Fitness Checklist</Modal.Title>

                                    {(props.initialState.checklistDetail[0].status === '0') && (
                                        <Modal.Title>
                                            <strong>Status</strong>:
                                            <h3 className='btn fz14 text-danger'>
                                                <strong>Pending</strong>
                                            </h3>
                                        </Modal.Title>
                                    )}

                                    {(props.initialState.checklistDetail[0].status === '1') && (
                                        <Modal.Title>
                                            <strong>Status</strong>:
                                            <h3 className='btn fz14 text-green'>
                                                <strong>Approved</strong>
                                            </h3>
                                        </Modal.Title>
                                    )}

                                    {(props.initialState.checklistDetail[0].status === '2') && (
                                        <Modal.Title>
                                            <strong>Status</strong>:
                                            <h3 className='btn fz14 text-danger'>
                                                <strong>Rejected</strong>
                                            </h3>
                                        </Modal.Title>
                                    )}

                                    <Button className="btn-close ms-2 " onClick={() => setModalShow(false)} variant=""></Button>
                                </Modal.Header>
                                <Modal.Body className='overflow-auto'>
                                    <div className="modal-body p-0">
                                        <div className="px-5 pb-4">
                                            <p className="mt-4">All Employees need to be fit when reporting for duty. A simple method of ensuring this is to use the ‘I’m Safe’ method to check you are fit for work</p>

                                            <div className="row px-0 py-4">
                                                {jsonpasrsConvertedData && jsonpasrsConvertedData.length > 1 && jsonpasrsConvertedData.map((val, indx) =>
                                                    <div className="col-sm-6">
                                                        <div className="row g-5">


                                                            <div className="col-12">
                                                                {/* { console.log("this map log data.....",val,indx)} */}
                                                                <h4>
                                                                    {val.checklist_name}

                                                                </h4>
                                                                <div className="d-flex gap-3 mt-3">
                                                                    <div>
                                                                        <label className={`btn btn-outline-success ${val.Yes === 1 ? 'bg-success text-white' : ''}`}>Yes</label>
                                                                    </div>
                                                                    <div>
                                                                        <label className={`btn btn-outline-danger ${val.Yes === 0 ? 'bg-danger text-white' : ''}`}>No</label>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                        </div>
                                        {(props.initialState.checklistDetail[0].status === '2') &&
                                            (<><p className="mt-5"><strong>Reason for Rejection</strong></p>
                                                <p>{props.initialState.checklistDetail[0]?.comment}</p></>)}

                                    </div>
                                </Modal.Body>
                                <div className="modal-footer py-3">
                                    <div className="btn-group">
                                        <button type="button" className="btn fz14 btn-gradient" onClick={() => setModalShow(false)}>Cancel</button>

                                        {(props.initialState.checklistDetail[0].status === '0') && (<>
                                            <button type="button" className="btn fz14 bg-red text-white" onClick={handleRejectButtonClick}>Reject</button>
                                             </>)}


                                        {(props.initialState.checklistDetail[0].status !== '1') && (<> 
                                            <button type="submit" className="btn fz14 bg-green text-white">
                                                Approve</button></>)}
                                    </div>
                                </div>
                            </Form>
                        </Modal>

                        <RejectModal rejectModalShow={rejectModalShow} handleRejectModalClose={handleRejectModalClose}
                            initialState={props.initialState}
                            checklistId={props.initialState.checklistDetail[0].id}
                            reload={props.reload}
                            id={props.id}
                        />

                    </>
                )}


            />
        </>
    )
}

export default FitnessModal

