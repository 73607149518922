import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputText, Dropdown as Select, confirmDialog, Toast } from "../../PrimeReactComponents";
import states from '../json_files/states.json'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { roleActionValidateSchema, roleActionInitialState } from './validations/editRoleActionValidation';
import { singleData, updateData, getAllMenuActionData, checkRoleExist, getRoleById } from './controllers';
import { useParams } from "react-router-dom";
import Loader from "../../features/loader/Loader";

function Edit() {
  const dispatch = useDispatch();
  const { id, user_type_id } = useParams();
  const toast = useRef(null);
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true);
  const [initialState, setInitialState] = useState(roleActionInitialState);
  const [panelId, setPanelId] = useState(user_type_id) 
  const [roleId, setRoleId] = useState(parseInt(id))
  const [roleName, setRoleName] = useState()
  const [allData, setAllData] = useState([]);
  const [uniqueMenuId, setUniqueMenuId] = useState([]);
  const [uniqueMenuActionId, setUniqueMenuActionId] = useState([]);

  let menuArr = []
  let menuActionArr = []

  useEffect(() => {

    const response = singleData(id); 

    response.then(({ status, message, type, error, data }) => { 

      setAllData(data?.data)

      if (status) {
        setLoader(false)
      } else {
        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
        setLoader(false)
      }
    })
    getAllRolesById(roleId);

  }, [id])

  useEffect(() => { 
    getAllData()
  }, [panelId, allData])

  useEffect(() => {
    getAllMenuAction();
  }, [uniqueMenuId])


  if(allData !==''){
    allData.length > 0 && allData.forEach(val => {

      if (!menuArr?.includes(val.menu_id)) {
        menuArr.push(val.menu_id)
      }
  
      if (!menuActionArr?.includes(val?.menuActionData?.id)) {
        menuActionArr.push(val?.menuActionData?.id)
      }
  
    })
  }


  const getAllData = () => {
    setUniqueMenuId(menuArr)
    setUniqueMenuActionId(menuActionArr)
  }

  const panelArr = [
    { value: '1', label: 'Transporter User' },
    { value: '2', label: 'Customer User' },
    { value: '3', label: 'Platform User' }
  ]

  const getAllRolesById = async (roleId) => {
    let results = await getRoleById(roleId)

    if(results.status){
      setRoleName(results?.data?.role_name)
    }
  }

  const getAllMenuAction = async () => {
    let results = await getAllMenuActionData()
    const menus = results.data;


    let newArr = menus.map((val, idx) => {

      if (uniqueMenuId.includes(val?.id) === true) {
        val.checked_status = '1'
      } else {
        val.checked_status = '0'
      }


      let fulData = val?.menuActionData && val?.menuActionData.map((values, index) => {

        if (uniqueMenuActionId.includes(values?.id) === true) {
          values.checked_status = !Number(values.checked_status)
        }

        return values;

      })
      val['menuActionData'] = fulData
      return val;

    })

    setInitialState({ menuDetails: newArr })

  }

  const handleChange = (event) => {
    if (event.target.name === 'user_type_id') {
      setPanelId(event.target.value)
    }  
  };

  const handleCheckboxChange = (e, idx) => {
    let targetValue = e.target.value

    let newArrays = initialState && initialState?.menuDetails.map((val, idx) => {
      if (targetValue.includes(val?.id) === true) {
        val.checked_status = !Number(val.checked_status)
      }
      return val
    })

    setInitialState({ menuDetails: newArrays })
  }

  const handlePermissionCheckboxChange = (e, idx) => {
    let targetValue = e.target.value

    let newArrays = initialState && initialState?.menuDetails.map((val, idx) => {

      const newdata = val?.menuActionData && val?.menuActionData.map((v, index) => {

        if (targetValue.includes(v?.id) === true) {
          v.checked_status = !Number(v.checked_status)
        }
        return v
      })

      val['menuActionData'] = newdata
      return val;

    })

    setInitialState({ menuDetails: newArrays })
  }

  const checkRoleExitHandler = async (e, roleId, panelId, setFieldValue) => {
    let targetName = e.target.value
    setRoleName(targetName)

    let checkData = {
      role_id:roleId,
      rolename: targetName,
      usertypeid: panelId,
    }

    const data = await checkRoleExist(checkData);

    setLoader(false)
    if (data.status) {
      setFieldValue('role_name', targetName);
    } else {
      toast.current.show({ severity: 'error', summary: 'Info Message', detail: "Already Role is Exist", life: 3000, });
    }
  }

  // permissionDetails

  const updateFormData = async (values, { resetForm }) => {

    let modifyInit = { ...initialState, panel_id: panelId, role_name: roleName }

    console.log('initialState', modifyInit)

    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoader(true)
        updateData(modifyInit, roleId).then(({ status, type, message }) => {

          if (status) {
            setLoader(false)
            resetForm({ all_values: '' })
            toast.current.show({ severity: 'success', summary: 'Info Message', detail: "Updated successfully!", life: 3000, });

            setTimeout(() => {
              navigate('/platform/rolemanagement')
            }, 1000)

          } else {
            setLoader(false)
            toast.current.show({ severity: 'warning', summary: 'Info Message', detail: "Something error", life: 3000, });
          }
        })
      }
    });

  }
 
  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <Formik enableReinitialize initialValues={roleActionInitialState} validationSchema={roleActionValidateSchema} onSubmit={updateFormData} >
        {({ values, setFieldValue }) => (
          <div className="page-wrapper-without-aisde">
            <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
              <div className="container d-flex flex-column h-100 ">
                <Form className="d-flex flex-column  h-100">
                  <div className="px-4 mt-3">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                        <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Edit Roles & Permissions</h2>
                      </div>
                      <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                        <Link to="../" className="btn-gradient btn fz14 me-3">
                          <i className="fa-regular fa-times"></i> Cancel
                        </Link>
                        <button type="submit" className="bg-green text-white btn fz14 ">
                          <i className="fa-regular fa-save"></i> Save
                        </button>
                      </div>
                    </div>
                  </div>


                  <div className="overflow-auto flex-grow-1 mt-5 px-4">

                    <div className="row">
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">Panel Name <span className="text-danger">*</span></label>
                        <Field name="user_type_id">
                          {({ field }) => (
                            <>
                              <Select options={panelArr} placeholder="Please select Panel" {...field} onChange={(e) => { handleChange(e); setFieldValue('user_type_id', e.value) }} value={panelId} className="w-100 px-3" disabled />
                              <ErrorMessage name="user_type_id" component="span" className="from-error-span" />
                            </>
                          )
                          }
                        </Field>

                      </div>
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">Role Name <span className="text-danger">*</span></label>
                        <Field name="role_name" render={({ field }) => <InputText className="form-control px-3"   {...field} onChange={(e) => { checkRoleExitHandler(e,roleId, panelId, setFieldValue); setFieldValue('role_name', e.value) }} value={roleName} />} maxLength="100" />
                        <ErrorMessage name="role_name" component="span" className="from-error-span" />
                      </div>
                    </div>
                    <div className="card p-4">
                      <h3>Modules Name</h3>

                      <div className="card-body">
                        <table className="table table-bordered border">
                          <tr>
                            <th className="p-3">Transporter</th>
                            <th className="p-3">List</th>
                            <th className="p-3">Add</th>
                            <th className="p-3">Edit</th>
                            <th className="p-3">Delete</th>
                            <th className="p-3">View</th> 
                          </tr>


                          {initialState && initialState?.menuDetails.filter(e => e.user_type_id === panelId).map((val, idx) => (
                            <>                             
                              <tr>
                                <td className="p-3">{val?.menu_name} &nbsp; <Field name="menu_id" >{({ field }) => (<input type="checkbox" {...field} value={val?.id}
                                  checked={(val.checked_status == '1')}
                                  onChange={(e) => { handleCheckboxChange(e, idx); setFieldValue('menu_id', e) }}

                                />)}</Field></td>
                                {
                                  val.menuActionData && val.menuActionData.length ? val.menuActionData.map((ele, index) => (
                                    <td className="p-3">
                                      <Field name={`action_details.${idx}`}>{({ field }) => (<input type="checkbox" {...field} value={ele?.id}
                                        checked={(ele.checked_status == '1')}
                                        onChange={(e) => { handlePermissionCheckboxChange(e, idx); setFieldValue('action_details', e, index) }}
                                      />)}</Field>
 
                                    </td>)) : ''

                                }

                              </tr>
                            </>
                          ))}

                        </table>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )
        }
      </Formik>

    </>
  );
}

export default Edit;
