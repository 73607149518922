import React from "react";
import { Link } from "react-router-dom";
import { InputText, InputMask, InputNumber, InputTextarea } from "../../PrimeReactComponents";

function View() {
  return (
    <>    
     <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
            <div className="container d-flex flex-column h-100 ">
                <div className="px-4 mt-3">
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                            <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">View Customer</h2>
                        </div>
                    </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">
                    <div className="card p-4">
                        <div className="card-body">
                            <div className="row gx-5">
                                <input type="text" className="form-control px-3" name="company_code" hidden />
                                <div className="col-12 mb-3 justify-content-between d-flex">
                                    <h4 className=" fw600 fz16 text-dark">Customer</h4>
                                    <Link to="../address-book" className="btn text-dark btn-gradient shadow-none ms-3" ><i className="fa-regular fa-map"></i> Address Book</Link>
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label htmlFor="" className="mb-2">Customer Number </label>
                                    <InputNumber  format={false} defaultValue="1154987" disabled className="w-100" placeholder="Your Customer Number" inputClassName="form-control px-3" />                                                                  
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label htmlFor="" className="mb-2">Company Name </label>                                            
                                    <InputText className="form-control px-3" name="company_name" disabled placeholder="Company Name" value="Coastal Cold Storage"/>
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label htmlFor="" className="mb-2">Contact Person First Name</label>
                                    <InputText className="form-control px-3" disabled name="contact_person_first_name" placeholder="First Name" defaultValue="Atul" />                                            
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label htmlFor="" className="mb-2">Contact Person Last Name</label>                                            
                                    <InputText className="form-control px-3" name="contact_person_last_name" disabled placeholder="Last Name" defaultValue="Ilwadi" />
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label htmlFor="" className="mb-2">Username / Email Address </label>                                            
                                    <InputText className="form-control px-3" name="email" disabled placeholder="Username / Email" value="Atul@broadwayinfotech.com"/>
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label htmlFor="" className="mb-2">Phone</label>                                           
                                    <InputMask mask="(999) 999-9999? x99999" className="form-control px-3" disabled defaultValue="(999) 999-9999" placeholder="(999) 999-9999? 99999"></InputMask>
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label htmlFor="" className="mb-2">Mobile</label>
                                    <InputText className="form-control px-3" disabled />                                           
                                </div>
                                <div className="col-12 mt-4 mb-3">
                                    <h4 className=" fw600 fz16 text-dark"> Billing Address Details </h4>
                                    <div className="overflow-auto mt-3 ">
                                        <table className="table  multiple-feilds-table" style={{minWidth:"1200px"}}>
                                            <thead>
                                                <tr>
                                                    <th className="fw500 fz14" width="30px">#</th>
                                                    <th className="fw500 fz14">Customer Name</th>
                                                    <th className="fw500 fz14">Address</th>
                                                    <th className="fw500 fz14">Suburb</th>
                                                    <th className="fw500 fz14">Zip Code</th>
                                                    <th className="fw500 fz14" width="190px">State</th>
                                                    <th className="fw500 fz14">Country</th>                                                            
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="dynamicText">1</td>
                                                    <td><InputText name="name" className="form-control px-3" disabled defaultValue="Larry"  />  </td>
                                                    <td><InputText name="name" className="form-control px-3" disabled defaultValue="Level 14/70"  />  </td>
                                                    <td><InputText name="name" className="form-control px-3" disabled defaultValue="Pitt St, Sydney NSW"  />  </td>
                                                    <td><InputText name="name" className="form-control px-3" disabled defaultValue="2000"  />      </td>
                                                    <td><InputText name="name" className="form-control px-3" disabled defaultValue="New South Wales"  />      </td>                                                            
                                                    <td><InputText name="name" className="form-control px-3" disabled defaultValue="Australia"/> </td>                                                                                                                                                                              
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 mt-4 mb-3">
                                    <h4 className=" fw600 fz16 text-dark"> Documents</h4>
                                    <div className="overflow-auto mt-3 ">
                                        <table className="table  multiple-feilds-table">
                                            <thead>
                                                <tr>
                                                    <th className="fw500 fz14" width="30px">#</th>
                                                    <th className="fw500 fz14">Upload</th>
                                                    <th className="fw500 fz14" width="30px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="dynamicText">1</td>
                                                    <td><input type="file" className="form-control px-3" /></td>                                                          
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 mt-4 mb-3">
                                    <h4 className=" fw600 fz16 text-dark"> Multiple Notes</h4>
                                    <div className="overflow-auto mt-3 ">
                                        <table className="table  multiple-feilds-table">
                                            <thead>
                                                <tr>
                                                    <th className="fw500 fz14" width="30px">#</th>
                                                    <th className="fw500 fz14">Notes</th>
                                                    <th className="fw500 fz14" width="30px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="dynamicText">1</td>
                                                    <td><InputTextarea autoResize className="form-control px-3" rows={5} disabled  defaultValue="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid, dolorem! Molestiae sapiente voluptatibus similique nam reiciendis, aut nemo eum velit neque, ullam ipsam fugit quibusdam, mollitia animi omnis repudiandae libero?" /></td>                                                             
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default View;
