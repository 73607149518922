import {REACT_APP_API_URL} from '../../config'
import {appAxios} from './common/AxiosFile';

    export const title = "Customer Management"
    // const reciveLocalData= localStorage.getItem('transId')
    // console.log("this is controller",reciveLocalData);
    export async function listData(params){
        try {
             let {rows,page,desc,filters,sortOrder,sortField,globalFilterValue,status,companyLocalId}=JSON.parse(params?.lazyEvent)  
            
            const res = await appAxios().get(`${REACT_APP_API_URL}customer/get-all-customers/${companyLocalId}?keyword=${globalFilterValue}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
            const result = await res.data;  

            if(result.data.count){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function singleData(id){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}customer/get-customer/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function addData(data){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}customer/add-customer/`, data);
            const result = await res.data;   
            if(result.status){                  
                if(result.data.success){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function updateData(data,id){
       try {
            const res = await appAxios().put(`${REACT_APP_API_URL}customer/update-customer/${id}`, data);
            const result = await res.data;     
            if(result.status){          
                return {                      
                    status: result.status,
                    message:"Data Successfully Updated.",
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };               
            }else{
                return {
                    status:0,
                    message:"Data not found!",
                    type:'info',
                    error:null,                       
                    data:null,                    
                }
            } 
       } catch (error) {       
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
       }
    }

    export async function deleteData(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}customer/delete-customer/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function activeInactive(status,id){
      try {
        const res = await appAxios().get(`${REACT_APP_API_URL}customer/change-customer-status/${id}/${status === '1'? 0:1}`);
        const result = await res.data;                
        if(result.status){   
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:"Data not found!",         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
      }
    }

    export async function uploadDocument(data, headers){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}transport/driverdocumentupload`, data, headers);
            const result = await res.data; 

            if(result.status){                  
                if(result.status){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }
 

    export async function uploadCustomerDocument(data, headers){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}imageUpload/customerImage`, data, headers);
            const result = await res.data; 

            console.log('result', result)

            if(result.status){                  
                if(result.status){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function deletCustomerImage(data, headers){

        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}imageUpload/deleteCustomerImage`, data, headers);
            const result = await res.data;  

            if(result.status){                  
                if(result.status){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    // Customer Address Book
    export async function getCustomerAddressData(params){
        try {

             let {rows,page,desc,filters,sortOrder,sortField,globalFilterValue,customer_id,company_id,status}=JSON.parse(params?.lazyEvent)  
            
            const res = await appAxios().get(`${REACT_APP_API_URL}customer/get-all-customer-address/${customer_id}/${company_id}/?keyword=${globalFilterValue}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);  
             const result = await res.data;  

             console.log('result', result)
 
            if(result.data.count){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function addCustomerAddress(data){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}customer/add-customer-address/`, data);
            const result = await res.data;   
            if(result.status){                  
                if(result.data.success){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function changeCustomerAddressStatusData(data){ 
        try {
             const res = await appAxios().put(`${REACT_APP_API_URL}customer/change-customer-address-status`, data);
             const result = await res.data;     
             if(result.status){          
                 return {                      
                     status: result.status,
                     message:"Data Successfully Updated.",
                     type:'success',
                     error:null                      
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }
 

     // Customer User List

     export  async function  addCustomerUser(data){  

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/senduserrequest/`, data);
            const result = await res.data;    
            if(result.status){                  
                if(result.data.success===1){
                    return {                      
                        status: 1,
                        message:"User Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: 1,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                            
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,    
                data: null,                                       
            };                  
        }
    }
    
    export async function customerUserList(lazyEvent){
       try { 
        const {rows,page,id,sortOrder,sortField,relationid}=lazyEvent;
            const res = await appAxios().get(`${REACT_APP_API_URL}company/userlist/${id}/${relationid}/2?sortkey=${sortField}&sortvalue=${sortOrder}&page=${page}&size=${rows}`);
            const result = await res.data;                      
            if(result.status ===1){
                if(result.data?.rows.length > 0){ 
                    return {
                        status: 1,
                        message:"User Successfully fetched.",
                        data: result.data,
                        type:'success',                   
                        error:null
                    }                  
                }else{
                    return {
                        status: 0,
                        message:"Data not found!",
                        data: null,
                        type:'info',
                        error:null
                    }
                } 
            }        
       } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,    
                data: null,                                       
            };    
       }
    }


    export async function removeCompanyDocument(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}customer/customerdocdelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!",
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function removeCompanyNote(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}customer/customernotesdelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!",
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    
    export async function removeCompanyBilling(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}customer/customerbillingdetaildelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!",
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function activeInactiveBulk(status,ids,companyId){ 
        
        try {
          const res = await appAxios().put(`${REACT_APP_API_URL}customer/customerBulkChangeStatus/${companyId}`,{customer_id:ids,status});
          const result = await res.data;                
          if(result.status){
         
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:res.data.data.msg,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:res.data.data.msg,         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.msg,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }

      export async function removeDataBulk(ids, companyId){       
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}customer/customerBulkDelete/${companyId}`,{customer_id:ids});
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }


    export async function getSingleCustomerAddressData(id, customer_id, company_id){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}customer/getCustomerAddressBookById/${id}/${customer_id}/${company_id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    
    export async function updateCustomerAddressBookData(data,id){
        try {
             const res = await appAxios().put(`${REACT_APP_API_URL}customer/customerAddressBookUpdate/${id}`, data);
             const result = await res.data;     
             if(result.status){          
                 return {                      
                     status: result.status,
                     message:"Data Successfully Updated.",
                     type:'success',
                     error:null,                       
                     data:result.data.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }

     export async function deleteCustomerAddressBookData(id, data){   
         
         try {
            const res = await appAxios().put(`${REACT_APP_API_URL}customer/deleteCustomerAddressBook/${id}/${data.customer_id}/${data.company_id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        } 
    }




