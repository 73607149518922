import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { InputText, Dropdown as Select, confirmDialog, Toast, Calendar, MultiSelect } from "../../PrimeReactComponents";
import { Formik, Form, Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { driverValidateSchema, driverInitialState } from '../Validations/driversValidation';
import { timeSheetInitialState, timeSheetValidateSchema, initSelect } from '../Validations/timeSheetValidation';
import { removeDriverDocument, removeDriverNote } from '../controllers/DriverControllers/controllers';
import { addData, getDriverJobDetail, singleData, timesheetPrint, downloadPDF, editData, removeTimeSheetJob, removeTimeSheetJobRest, getTotalRestTime } from '../controllers/timeSheet';
import { uploadDocument } from '../../services/commonController';
import states from '../json_files/states.json';
import Loader from "../../features/loader/Loader";
import { companyThunk } from "../../redux/company/companyAddSlice";
import { useEffect } from "react";
import { REACT_APP_BASE_BACKEND_URL } from '../../config';
import { getCountry, getAllDriver, getState, getAllTruckInfo, getAllTrailerInfo, getAllTimesheetTemp } from '../../services/commonController';
import FitnessModal from "./components/FitnessChecklist"
import { Button } from "react-bootstrap";
import VehicleModal from "./components/VehicleChecklist";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { getRandomNumber } from '../../utility/helper';
import TimesheetPDFView from "./TimesheetPDFView";
import { REACT_APP_API_URL } from '../../config'
// import './timesheet.css';


function View(props) {
    const { transportUserInfo } = useSelector(state => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filename, setFilename] = useState([]);
    const toast = useRef(null);
    const formikRef = useRef();
    const [loader, setloader] = useState();
    const [initialState, setInitialState] = useState(timeSheetInitialState);
    const { id } = useParams();
    const [country, setCountry] = useState();
    const [states, setStates] = useState();
    const [allDriver, setAllDriver] = useState();
    const [driverData, setDriverData] = useState();
    const [driverMobile, setdriverMobile] = useState(null);
    const [dateId, setDateId] = useState("");
    const [driverId, setDriverId] = useState("");
    const [newJobOption, setNewJobOption] = useState([]);
    const [starMin, setStartMin] = useState()
    const [endMin, setEndMin] = useState()
    const [allTruck, setallTruck] = useState([]);
    const [allTrailer, setallTrailer] = useState([]);
    const reciveLocalData = transportUserInfo.company_id;
    const pathname = window.location.pathname
    const [getAllTimesheetData, setGetAllTimesheetData] = useState();
    const [timesheetId, setTimesheetId] = useState();
    const [totalRestTime, setTotalRestTime] = useState();
    const [allTimesheetTemp, setAllTimesheetTemp] = useState();

    let timesheetArr = []
    let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

    transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Timesheet')?.map(val => {
        if (!timesheetArr.includes(val?.menuActionData?.action_name)) {
            timesheetArr.push(val?.menuActionData?.action_name)
        }
    })

    if (pathname.split('/')[3] === 'view') {
        if (!timesheetArr.includes('View')) {
            navigate('/transport/404');
        }
    }


    let reciveResponse = [];
    let dataToUpdate = [];
    let recivedNewJob = [];
    const getAllCountries = async () => {
        const results = await getCountry();
        setCountry(results.data)
    }
    const getAllStates = async () => {
        const results = await getState();
        setStates(results.data)
    }
    const getAllDriverData = async (reciveLocalData) => {
        const results = await getAllDriver(reciveLocalData);
        setAllDriver(results.data)
    }

    const getAllTruckTypeData = async (reciveLocalData) => {
        const results = await getAllTruckInfo(reciveLocalData);
        setallTruck(results.data)
    }
    const getAllTruckTypeDataOption = () => {
        const option = [];
        allTruck?.map((ele) => {
            option.push({ value: ele.truck_setup_id, label: ele.truck_registration })
        })
        return option;
    }

    const getAllTrailerTypeData = async (reciveLocalData) => {
        const results = await getAllTrailerInfo(reciveLocalData);
        setallTrailer(results.data)
    }
    const getAllTrailerTypeDataOption = () => {
        const option = [];
        allTrailer?.map((ele) => {
            option.push({ value: ele?.truck_setup_id, label: ele?.truck_registration })
        })
        return option;
    }

    const getAllTimesheetTempData = async () => {
        const results = await getAllTimesheetTemp();
        setAllTimesheetTemp(results.data)
    }

    const getAllTimesheetOption = () => {
        const option = [];
        allTimesheetTemp?.map((ele) => {
            option.push({ value: ele?.id, label: ele?.name })
        })
        return option;
    }

    let getTimesheetData = async (id) => {
        if (id) {

            const { status, message, type, error, data } = await singleData(id, reciveLocalData);

            if (status) {

                setGetAllTimesheetData(data?.data)
                setTimesheetId(data.data.timesheet_id)

                setInitialState({
                    timesheetid: data.data.timesheet_id,
                    timesheetdate: new Date(data.data.timesheet_date),
                    starttime: data.data.start_time,
                    startodometer: data.data.start_odometer,
                    endtime: data.data.end_time,
                    endodometer: data.data.end_odometer,
                    driver_id: data.data.driver_id,
                    drivermobile: data.data.driver_mobile,
                    truck: data.data.truck,
                    trailer: data.data.trailer,
                    signature: data.data.signature,
                    job_details: data.data.timesheetjobs,
                    checklistDetail: data.data.checklist,
                    /*   job_details: data?.data?.timesheetjobs?.map((ele, i) => { 
                          if (ele.temp) {
                            ele['temp'] = parseInt(ele.temp);
                          }   
                          return ele;
                      }),  */
                    rest_details: data.data.restdetails.map((ele, i) => {
                        ele['start_time'] = ele.start_time;
                        ele['end_time'] = ele.end_time;
                        console.log(ele);
                        return ele;
                    })
                })

                setloader(false)
            } else {
                toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                setloader(false)
            }
        }
    }

    let getTotalRestTimeData = async (id, reciveLocalData) => {
        if (id) {

            const { data } = await getTotalRestTime(id, reciveLocalData);
            setTotalRestTime(data?.data)
        }
    }

    useEffect(() => {
        getAllCountries()
        getAllStates()
        getAllDriverData(reciveLocalData)
        getAllTruckTypeData(reciveLocalData);
        getAllTrailerTypeData(reciveLocalData);
        getTimesheetData(id)
        getAllTimesheetTempData();
    }, [id])

    useEffect(() => {
        getTotalRestTimeData(id, reciveLocalData)
    }, [id])

    useEffect(() => {
        (async () => {
            if (dateId && driverId) {
                if (formikRef.current) {
                    const paramsData = { driver_id: driverId, pickup_date: moment(dateId).format('YYYY-MM-DD') }
                    const { data } = await getDriverJobDetail(paramsData);
                    setDriverData(data)
                    data.data.data && data.data.data.length > 0 && data.data.data.forEach((element, i) => {
                        formikRef.current.setFieldValue('job_details[' + i + '].consignment_id', element.consignment_id);
                        formikRef.current.setFieldValue('drivermobile', element.driver_mobile_number);
                        formikRef.current.setFieldValue('job_details[' + i + '].job_name', element.job_number);
                        formikRef.current.setFieldValue('job_details[' + i + '].customer_name', element.customer_details.first_name);
                        formikRef.current.setFieldValue('job_details[' + i + '].address', element.customer_address);
                        formikRef.current.setFieldValue('job_details[' + i + '].suburb', element.delivery_suburb);
                    });
                }
            }
        })()
    }, [dateId, driverId])


    useEffect(() => {
        formikRef.current.setFieldValue('job_details', timeSheetInitialState.job_details)
    }, [dateId, driverId])


    const recivedNewJobAdd = () => {
        // initialState.job_details.splice(2,1)
        recivedNewJob.push(initSelect);


    }

    const getAllDriverOption = () => {
        const option = [];
        allDriver?.map((ele) => {
            option.push({ value: ele?.drivers?.driver_id, label: ele?.drivers?.first_name.concat(" ", ele?.drivers?.last_name) })
        })
        return option;
    }

    const postData = (values, { resetForm }) => {
        const modifyedData = {
            ...values, rest_details: values.rest_details.map((item) => (
                {
                    company_id: reciveLocalData,
                    description: item.description,
                    end_time: item.end_time,
                    rest_id: item.rest_id ? item.rest_id : "",
                    start_time: item.start_time,
                    //timesheet_id:id
                })
            )
        }

        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setloader(true)
                if (id) {
                    editData(modifyedData, id).then(({ status, type, message }) => {
                        if (status) {
                            setloader(false)
                            resetForm({ values: '' })
                            toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                            navigate('/transport/timesheet')
                        } else {
                            setloader(false)
                            toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                        }
                    })
                } else {
                    addData(values).then(({ status, type, message }) => {
                        if (status) {
                            setloader(false)
                            resetForm({ values: '' })
                            toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                            navigate('/transport/timesheet')
                        } else {
                            setloader(false)
                            toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                        }
                    })
                }
            }
        });
    }

    const selectedNewJob = async (push) => {
        const paramsData = { driver_id: initialState.driver_id, pickup_date: moment(initialState.timesheetdate).format('YYYY-MM-DD') }
        const { data } = await getDriverJobDetail(paramsData);
        setDriverData(data.data.data);
        const reciveResult = await singleData(id);
        var res = data.data.data.filter(n => !reciveResult.data.data.timesheetjobs.some(n2 => n.consignment_id == n2.consignment_id));
        setNewJobOption(res);
        if (res.length > 0) {
            push((timeSheetInitialState.job_details[0]));
        } else {
            toast?.current?.show({ severity: "1", summary: 'Info Message', detail: "There is no job, for selected driver on selected date", life: 3000, });
        }


    }

    const onJobSelect = (idx, values) => {
        let selectedJob = newJobOption && newJobOption.length > 0 && newJobOption.filter((element) => {
            return element.consignment_id === values
        })
        formikRef.current.setFieldValue('job_details[' + idx + '].timesheet_id', selectedJob[0].id)
        formikRef.current.setFieldValue('job_details[' + idx + '].timesheet_job_id', "")
        formikRef.current.setFieldValue('job_details[' + idx + '].consignment_id', selectedJob[0].consignment_id);
        formikRef.current.setFieldValue('job_details[' + idx + '].job_name', selectedJob[0].job_number);
        formikRef.current.setFieldValue('job_details[' + idx + '].customer_name', selectedJob[0].customer_details.first_name);
        formikRef.current.setFieldValue('job_details[' + idx + '].address', selectedJob[0].customer_address);
        formikRef.current.setFieldValue('job_details[' + idx + '].suburb', selectedJob[0].delivery_suburb);
    }

    const deleteConfirm = (val, idx, remove) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                removeTimeSheetJob(val.timesheet_job_id);
                remove(idx);
            }
        });

    }

    const deleteRestJobConfirm = (val, idx, remove) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                removeTimeSheetJobRest(val.rest_id);
                remove(idx);
            }
        });

    }


    const getJobOption = () => {
        const option = [];
        newJobOption?.map((ele) => {
            option.push({ value: ele.consignment_id, label: ele.job_number })
        })
        return option;
    }


    const getVehicleCheckList = () => {
        return (<div id="vehicleChecklist" className="modal">
            <div className="modal-content">
                <span className="close">&times;</span>
                <p>Some text in the Modal..</p>
            </div>

        </div>)
    }


    const downloadTimeSheetPdf = async () => {

        let url = `${REACT_APP_API_URL}common/pdf/${reciveLocalData}/${id}`

        fetch(url, { method: 'GET' })
            .then(response => response.blob())
            .then(blob => {

                //  const pdfUrl = URL.createObjectURL(blob);
                //window.open(pdfUrl);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${timesheetId}.pdf`;
                link.click();
                window.URL.revokeObjectURL(link.href);
            })
            .catch(error => {
                console.error('Error fetching and opening PDF:', error);
            });

    }


    let checklistyTypeVehicle = 1
    let checklistyTypeFitness = 2

    let filteredDataVehicle = ''
    let filteredDataFitness = ''

    if (initialState.checklistDetail && initialState.checklistDetail.length > 1) {
        filteredDataVehicle = initialState.checklistDetail.filter(item => item.checklist_type === checklistyTypeVehicle);
    }

    if (initialState.checklistDetail && initialState.checklistDetail.length > 1) {
        filteredDataFitness = initialState.checklistDetail.filter(item => item.checklist_type === checklistyTypeFitness);
    }


    return (
        <>

            <Loader show={loader} />
            <Toast ref={toast} position="top-center" />
            <Formik enableReinitialize initialValues={initialState} innerRef={formikRef} render={({ values, setFieldValue }) => (


                <div className="page-wrapper-without-aisde">
                    <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
                        <div className="container d-flex flex-column h-100 ">

                            <Form className="d-flex flex-column  h-100" encType="multipart/form-data">

                                <div className="px-4 mt-3">
                                    <div className="d-flex align-items-center flex-wrap">
                                        <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                                            <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">{(id === "" || id === undefined) ? "Add New TimeSheet" : "View Timesheet"}</h2>
                                        </div>

                                    </div>
                                </div>

                                <div className="d-flex justify-content-end gap-3 my-5">
                                    <Link to={`/transport/timesheet`} className="btn btn-gradient"><i className="fa-light fa-times"></i> Cancel</Link>
                                    <button className="btn bg-green text-white" id="exportForm" onClick={downloadTimeSheetPdf}><i className="fa-light fa-print"></i> Print</button>
                                </div>

                                <div className="overflow-auto flex-grow-1 mt-5 px-4">
                                    <div className="card p-4">
                                        <div className="card-body">
                                            <div className="row gx-5">
                                                <input type="text" className="form-control px-3" hidden />
                                                <div className="col-12 mb-3">
                                                    <h4 className="fw600 fz16 text-dark">Timesheet</h4>
                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2 d-block">Timesheet ID</label>
                                                    <Field name="timesheetid" render={({ field }) => (
                                                        <>
                                                            <InputText type="text" name="timesheetid" className="form-control px-3" {...field} readOnly disabled />
                                                            <ErrorMessage name="timesheetid" component="span" className="from-error-span" />
                                                        </>
                                                    )} />

                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2">Date</label>
                                                    <Field name="timesheetdate" render={({ field }) => (
                                                        <>
                                                            <Calendar
                                                                onSelect={(e) => {
                                                                    setDateId(e.value)
                                                                    setFieldValue('timesheetdate', e.value)
                                                                }}
                                                                dateFormat="dd-mm-yy"
                                                                inputClassName="form-control px-3"
                                                                className="w-100" {...field}
                                                                disabled
                                                            />
                                                            <ErrorMessage name="timesheetdate" component="span" className="from-error-span" />
                                                        </>
                                                    )} />
                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2 d-block">Start Time</label>
                                                    <Field name="starttime" render={({ field }) => (
                                                        <>
                                                            <InputText type="time" className="form-control px-3" {...field} readOnly disabled />
                                                            <ErrorMessage name="starttime" component="span" className="from-error-span" />
                                                        </>
                                                    )} />

                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2 d-block">Start Odometer</label>
                                                    <Field name="startodometer" render={({ field }) => (
                                                        <>
                                                            <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                                            <ErrorMessage name="startodometer" component="span" className="from-error-span" />
                                                        </>
                                                    )} />

                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2 d-block">End Time</label>
                                                    <Field name="endtime" render={({ field }) => (
                                                        <>
                                                            <InputText type="time" className="form-control px-3" {...field} readOnly disabled />
                                                            <ErrorMessage name="endtime" component="span" className="from-error-span" />
                                                        </>
                                                    )} />

                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2">End Odometer</label>
                                                    <Field name="endodometer" render={({ field }) => (
                                                        <>
                                                            <InputText {...field} type="text" className="form-control px-3" keyfilter="int" disabled />
                                                            <ErrorMessage name="endodometer" component="span" className="from-error-span" />
                                                        </>
                                                    )} />
                                                </div>


                                                <div className="col-12 mt-4 mb-3">
                                                    <h4 className=" fw600 fz16 text-dark">Driver </h4>
                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2 d-block">Driver Name</label>

                                                    <Field name="driver_id" render={({ field }) => <Select options={getAllDriverOption()}
                                                        placeholder="Please select a driver"
                                                        {...field} className="w-100 px-3" maxLength="100"
                                                        onChange={(event) => {
                                                            setDriverId(event.value)
                                                            setFieldValue('driver_id', event.value)

                                                        }}
                                                        readOnly
                                                        disabled
                                                    />} />
                                                    <ErrorMessage name="driver_id" component="span" className="from-error-span" />

                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2">Driver Mobile</label>
                                                    <Field name="drivermobile" render={({ field }) => (
                                                        <>
                                                            <InputText className="form-control px-3"  {...field} readOnly disabled />
                                                            <ErrorMessage name="drivermobile" component="span" className="from-error-span" />
                                                        </>
                                                    )} />
                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2 d-block">Vehicle</label>
                                                    {/*   <Field name="truck" render={({ field }) => (
                                                        <>
                                                            <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                                            <ErrorMessage name="truck" component="span" className="from-error-span" />
                                                        </>
                                                    )} /> */}

                                                    <Field name="truck" render={({ field }) => (
                                                        <>
                                                            <Select  {...field}
                                                                className="w-100 px-3"
                                                                options={getAllTruckTypeDataOption()}
                                                                placeholder="Please select"
                                                                maxLength="100"
                                                                readOnly disabled
                                                            />

                                                        </>
                                                    )} />

                                                </div>
                                                <div className="col-sm-6 col-md-3 mb-4">
                                                    <label htmlFor="" className="mb-2 d-block">Trailer</label>
                                                    {/* <Field name="trailer" render={({ field }) => (
                                                        <>
                                                            <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                                            <ErrorMessage name="trailer" component="span" className="from-error-span" />
                                                        </>
                                                    )} /> */}

                                                    <Field name="trailer" render={({ field }) => (
                                                        <>
                                                            <MultiSelect {...field}
                                                                options={getAllTrailerTypeDataOption()}
                                                                placeholder="Please select"
                                                                className="w-100 px-3"
                                                                maxLength="100"
                                                                readOnly disabled
                                                            />

                                                        </>
                                                    )} />

                                                </div>

                                                <div className="d-flex gap-4 mt-4">
                                                    <label htmlFor="" className="mb-2 d-block">Fitness Checklist</label><br />
                                                    <Button className="btn btn-gradient  position-relative" >
                                                        <i className="fa-solid fa-heart-pulse"></i>
                                                        {/*  {

                                                            initialState.checklistDetail && initialState.checklistDetail.length > 1 ?
                                                                <><FitnessModal initialState={initialState} />
                                                                    {initialState.checklistDetail[0].status === "0" ?
                                                                        <span className=" badge  bg-danger pt-2">Pending to approve</span>
                                                                        :
                                                                        <span className=" badge  bg-success pt-2">Approved</span>}  </> :
                                                                <span className=" badge  bg-danger pt-2">Pending</span>}  */}


                                                        {
                                                            initialState.checklistDetail && initialState.checklistDetail.length > 1 ?
                                                                <><FitnessModal initialState={initialState} reload={getTimesheetData} id={id} />
                                                                    {(filteredDataFitness[0].status === "0") && <span className=" badge  bg-danger pt-2">Pending to approve</span>}
                                                                    {(filteredDataFitness[0].status === "1") && <span className=" badge  bg-success pt-2">Approve</span>}
                                                                    {(filteredDataFitness[0].status === "2") && <span className=" badge  bg-danger pt-2">Rejected</span>}
                                                                </> :
                                                                <span className=" badge  bg-danger pt-2">Pending</span>
                                                        }


                                                    </Button>
                                                    <label htmlFor="" className="mb-2 d-block">Vehicle Checklist</label><br />
                                                    <Button className="btn btn-gradient  position-relative" >
                                                        <i className="fa-solid fa-truck"></i>
                                                        {

                                                            initialState.checklistDetail && initialState.checklistDetail.length > 1 ?
                                                                <><VehicleModal initialState={initialState} reload={getTimesheetData} id={id} />
                                                                    {(filteredDataVehicle[0].status === "0") && <span className=" badge  bg-danger pt-2">Pending to approve</span>}
                                                                    {(filteredDataVehicle[0].status === "1") && <span className=" badge  bg-success pt-2">Approve</span>}
                                                                    {(filteredDataVehicle[0].status === "2") && <span className=" badge  bg-danger pt-2">Rejected</span>}  </> :
                                                                <span className=" badge  bg-danger pt-2">Pending</span>}
                                                    </Button>
                                                </div>


                                                <div className="col-12 mt-4 mb-3">
                                                    <h4 className=" fw600 fz16 text-dark">Job Details</h4>
                                                    <div className="overflow-auto mt-3 ">
                                                        <table className="table  multiple-feilds-table" style={{ minWidth: '2220px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="fw500 fz14" width="30px">
                                                                        #
                                                                    </th>
                                                                    <th className="fw500 fz14" width="150px">
                                                                        Job Number
                                                                    </th>
                                                                    <th className="fw500 fz14">Name of Customer</th>
                                                                    <th className="fw500 fz14" width="150px">
                                                                        Address
                                                                    </th>
                                                                    <th className="fw500 fz14" width="150px">
                                                                        Suburb
                                                                    </th>
                                                                    <th className="fw500 fz14" width="180px">
                                                                        Pickup Arrive Time
                                                                    </th>
                                                                    <th className="fw500 fz14" width="200px">
                                                                        Pickup Depart Time
                                                                    </th>
                                                                    <th className="fw500 fz14" width="150px">Delivery Arrive Time</th>
                                                                    <th className="fw500 fz14" width="200px">
                                                                        Delivery Depart Time
                                                                    </th>
                                                                    <th className="fw500 fz14" width="150px">
                                                                        Load Description
                                                                    </th>
                                                                    <th className="fw500 fz14" width="180px">
                                                                        Delivery Temp
                                                                    </th>
                                                                    <th className="fw500 fz14" width="150px">
                                                                        Delivered ( C / L / P)

                                                                    </th>
                                                                    <th className="fw500 fz14" width="180px">
                                                                        Picked Up ( C / L / P)

                                                                    </th>
                                                                    <th className="fw500 fz14" width="180px">

                                                                        Weights
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray name="job_details" render={({ push, pop, remove }) => (
                                                                    values?.job_details && values?.job_details.map((val, idx) => (
                                                                        <tr key={idx}>
                                                                            <td>{idx + 1}</td>

                                                                            {val.job_name === "" && (
                                                                                <td>
                                                                                    <Field name="reciveLatestJob" render={({ field }) => <Select optionLabel="job_number"
                                                                                        optionValue="consignment_id" options={newJobOption}  {...field}
                                                                                        placeholder="Please select Consignment" className="w-100 px-3"
                                                                                        maxLength="100" onChange={(e) => onJobSelect(idx, e.value)}
                                                                                        hideSelectedOptions={false}
                                                                                        isMulti
                                                                                        readOnly
                                                                                        disabled
                                                                                    />} />
                                                                                </td>
                                                                            )}

                                                                            {val.job_name !== "" && (
                                                                                <td>
                                                                                    <Field name={`job_details[${idx}].job_name`} render={({ field }) => (
                                                                                        <>
                                                                                            <Link to={"../consignments/view/" + val?.consignment_id} target="_blank">
                                                                                                <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                                                                                <ErrorMessage name={`job_details[${idx}].job_name`} component="span" className="from-error-span" />
                                                                                            </Link>
                                                                                        </>
                                                                                    )} />

                                                                                </td>
                                                                            )}
                                                                            <td>


                                                                                <Field name={`job_details[${idx}].customer_name`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].customer_name`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )}
                                                                                />


                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].address`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].address`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].suburb`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly className="form-control px-3"  {...field} disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].suburb`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].arrival_time`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly type="time" className="form-control px-3" {...field} disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].arrival_time`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].depart_time`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly type="time" className="form-control px-3" {...field} disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].depart_time`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].delivery_arrive_time`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly type="time" className="form-control px-3" {...field} disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].delivery_arrive_time`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].delivery_depart_time`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly type="time" className="form-control px-3" {...field} disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].delivery_depart_time`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].reference_number`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly type="text" className="form-control px-3" {...field} disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].reference_number`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                             {/*    <Field name={`job_details[${idx}].temp`} render={({ field }) => <Select options={getAllTimesheetOption()} disabled
                                                                                    placeholder="Please select"
                                                                                    {...field} className="w-100 px-3" maxLength="100"

                                                                                />} /> */}

                                                                                <Field name={`job_details[${idx}].temp`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly type="text" className="form-control px-3" {...field} disabled />
                                                                                        <ErrorMessage name={`job_details[${idx}].temp`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex gap-2">


                                                                                    <Field name={`job_details[${idx}].delivered_chep`} render={({ field }) => (
                                                                                        <>
                                                                                            <InputText readOnly type="text" className="form-control px-3" {...field} disabled />

                                                                                            <ErrorMessage name={`job_details[${idx}].delivered_chep`} component="span" className="from-error-span" />
                                                                                        </>
                                                                                    )} />
                                                                                    <Field name={`job_details[${idx}].delivered_loscomp`} render={({ field }) => (
                                                                                        <>
                                                                                            <InputText readOnly type="text" className="form-control px-3" {...field} disabled />

                                                                                            <ErrorMessage name={`job_details[${idx}].delivered_loscomp`} component="span" className="from-error-span" />
                                                                                        </>
                                                                                    )} />

                                                                                    <Field name={`job_details[${idx}].delivered_plain`} render={({ field }) => (
                                                                                        <>
                                                                                            <InputText readOnly type="text" className="form-control px-3" {...field} disabled />

                                                                                            <ErrorMessage name={`job_details[${idx}].delivered_plain`} component="span" className="from-error-span" />
                                                                                        </>
                                                                                    )} />

                                                                                </div>

                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex gap-2">


                                                                                    <Field name={`job_details[${idx}].picked_up_chep`} render={({ field }) => (
                                                                                        <>
                                                                                            <InputText readOnly type="text" className="form-control px-3" {...field} disabled />

                                                                                            <ErrorMessage name={`job_details[${idx}].picked_up_chep`} component="span" className="from-error-span" />
                                                                                        </>
                                                                                    )} />
                                                                                    <Field name={`job_details[${idx}].picked_up_loscomp`} render={({ field }) => (
                                                                                        <>
                                                                                            <InputText readOnly type="text" className="form-control px-3" {...field} disabled />

                                                                                            <ErrorMessage name={`job_details[${idx}].picked_up_loscomp`} component="span" className="from-error-span" />
                                                                                        </>
                                                                                    )} />

                                                                                    <Field name={`job_details[${idx}].picked_up_plain`} render={({ field }) => (
                                                                                        <>
                                                                                            <InputText readOnly type="text" className="form-control px-3" {...field} disabled />

                                                                                            <ErrorMessage name={`job_details[${idx}].picked_up_plain`} component="span" className="from-error-span" />
                                                                                        </>
                                                                                    )} />

                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Field name={`job_details[${idx}].weight`} render={({ field }) => (
                                                                                    <>
                                                                                        <InputText readOnly type="text" className="form-control px-3" {...field} disabled />

                                                                                        <ErrorMessage name={`job_details[${idx}].weight`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />

                                                                            </td>

                                                                        </tr>
                                                                    )
                                                                    ))} />


                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-4 mb-3">
                                                <h4 className=" fw600 fz16 text-dark"> Rest details</h4>
                                                <div className="overflow-auto mt-3 ">
                                                    <table className="table  multiple-feilds-table">
                                                        <thead>
                                                            <tr>
                                                                <th className="fw500 fz14" width="30px">#</th>
                                                                <th className="fw500 fz14">Description</th>
                                                                <th className="fw500 fz14">Start Time</th>
                                                                <th className="fw500 fz14">End Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray name="rest_details" render={({ push, remove }) => (
                                                                values.rest_details && values.rest_details.map((val, idx) => (
                                                                    <tr key={idx}>
                                                                        {/* <td>{idx + 1}</td> */}

                                                                        <td className="dynamicText">{idx + 1}</td>
                                                                        <td>
                                                                            <Field name={`rest_details[${idx}].description`} render={({ field }) => (
                                                                                <>
                                                                                    <InputText readOnly type="text" className="form-control px-3" {...field} disabled />
                                                                                    <ErrorMessage name={`rest_details[${idx}].description`} component="span" className="from-error-span" />
                                                                                </>
                                                                            )} /></td>
                                                                        <td>
                                                                            <Field name={`rest_details[${idx}].start_time`} render={({ field }) => (
                                                                                <>

                                                                                    <InputText type="time" className="form-control px-3" readOnly disabled
                                                                                        onChange={(e) => {
                                                                                            console.log("val", val);
                                                                                            setFieldValue(`rest_details[${idx}].start_time`, e.value)
                                                                                        }} {...field} />
                                                                                    {/* <Calendar disabled min className="w-100" {...field} maxDate={starMin} onChange={(e) => { setEndMin(e.value); setFieldValue(`rest_details[${idx}].start_time`, e.value) }} inputClassName="form-control px-3" timeOnly /> */}
                                                                                    <ErrorMessage name={`rest_details[${idx}].start_time`} component="span" className="from-error-span" />
                                                                                </>
                                                                            )} /></td>
                                                                        <td>
                                                                            <Field name={`rest_details[${idx}].end_time`} render={({ field }) => (
                                                                                <>
                                                                                    <InputText type="time" className="form-control px-3" readOnly disabled
                                                                                        onChange={(e) => {
                                                                                            console.log("val", val);
                                                                                            setFieldValue(`rest_details[${idx}].end_time`, e.value)
                                                                                        }} {...field} />

                                                                                    {/* <Calendar disabled className="w-100" {...field} minDate={endMin} onChange={(e) => { setStartMin(e.value); setFieldValue(`rest_details[${idx}].end_time`, e.value) }} inputClassName="form-control px-3" timeOnly /> */}
                                                                                    <ErrorMessage name={`rest_details[${idx}].end_time`} component="span" className="from-error-span" />
                                                                                </>
                                                                            )} /></td>

                                                                    </tr>
                                                                )
                                                                ))} />

                                                        </tbody>
                                                    </table>
                                                    {/*   <div className="d-flex align-items-center mt-5">
                                                        <span>Signature</span>&nbsp;
                                                        <Field name="signature" render={({ field }) => (
                                                            <>
                                                                <textarea disabled name="signature" className="form-control px-3" {...field} />
                                                                <ErrorMessage name="signature" component="span" className="from-error-span" />
                                                            </>
                                                        )} />
                                                    </div> */}
                                                    <span style={{ "marginLeft": "930px" }}><strong>Total Rest Time : {totalRestTime?.hours}:{totalRestTime?.minutes} Hrs.</strong></span>
                                                </div>
                                            </div><br /><br />

                                            <div className="col-12">
                                                <span className="col-8 float-start">I certify that I am presenting myself as fit for duty and understand my obligations in relation to fatugue management.</span>
                                                <span className="col-4 float-end" style={{ "marginTop": "-40px" }}>Signature:
                                                    {initialState.signature ? <img width='100' height='100' src={`data:image/png;base64,${initialState.signature}`} /> :
                                                        ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>

                            <div id="downloadTimesheetPdf" style={{ "display": "none" }}>
                                <span>
                                    <TimesheetPDFView initialState={getAllTimesheetData} />


                                </span>

                            </div>
                        </div>
                    </div>
                </div >
            )
            } />
        </>
    );
}

export default View;
