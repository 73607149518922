import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputText, Dropdown as Select, confirmDialog, Toast } from "../../PrimeReactComponents";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { roleActionInitialState, roleActionValidateSchema } from './validations/roleActionValidations';
import { addData, getAllMenuActionData, checkRoleExist } from './controllers';
import Loader from "../../features/loader/Loader";

function Add() {
  const toast = useRef(null);
  const navigate = useNavigate()
  const [initialState, setInitialState] = useState([]);
  const [loader, setLoader] = useState(true);
  const [panelId, setPanelId] = useState(1)


  const panelArr = [
    { value: '1', label: 'Transporter User' },
    { value: '2', label: 'Customer User' },
    { value: '3', label: 'Platform User' }
  ]

  useEffect(() => {
    setLoader(false)
    getAllMenuAction();

  }, [panelId])


  const getAllMenuAction = async () => {
    let results = await getAllMenuActionData()
    const menus = results.data;
    setInitialState(menus)
  }


  const roleSubmit = async (values, { resetForm }) => {
    console.log('values', values)

    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoader(true)
        addData(values).then(({ status, type, message }) => {

          if (status) {
            setLoader(false)
            resetForm({ all_values: '' })
            toast?.current?.show({ severity: 'success', summary: 'Info Message', detail: "Added successfully!", life: 3000, });

            setTimeout(() => {
              navigate('/platform/rolemanagement')
            }, 3000)

          } else {
            setLoader(false)
            toast?.current?.show({ severity: 'warning', summary: 'Info Message', detail: "Something error", life: 3000, });
          }
        })
      }
    });
  }


  const handleChange = (event) => {

    if (event.target.name === 'user_type_id') {
      setPanelId(event.target.value)
    }
  };


  const checkRoleExitHandler = async (e, panelId, setFieldValue) => {
    let targetName = e.target.value

    let checkData = {
      rolename: targetName,
      usertypeid: panelId,
    }

    const data = await checkRoleExist(checkData);

    setLoader(false)
    if (data.status) {
      setFieldValue('role_name', targetName);
    } else {
      toast?.current?.show({ severity: 'error', summary: 'Info Message', detail: "Already Role is Exist", life: 3000, });
    }
  }



  // validationSchema={companyValidateSchema} 
  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />

      <Formik enableReinitialize initialValues={roleActionInitialState} validationSchema={roleActionValidateSchema} onSubmit={roleSubmit} >
        {({ values, setFieldValue }) => (
          <div className="page-wrapper-without-aisde">
            <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
              <div className="container d-flex flex-column h-100 ">
                <Form className="d-flex flex-column  h-100">
                  <div className="px-4 mt-3">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                        <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Add Roles & Permissions</h2>
                      </div>
                      <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                        <Link to="../" className="btn-gradient btn fz14 me-3">
                          <i className="fa-regular fa-times"></i> Cancel
                        </Link>
                        <button type="submit" className="bg-green text-white btn fz14 ">
                          <i className="fa-regular fa-save"></i> Save
                        </button>
                      </div>
                    </div>
                  </div>


                  <div className="overflow-auto flex-grow-1 mt-5 px-4">

                    <div className="row">
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">Panel Name <span className="text-danger">*</span></label>
                        <Field name="user_type_id">
                          {({ field }) => (
                            <>
                              <Select options={panelArr} placeholder="Please select Panel" {...field} onChange={(e) => { handleChange(e); setFieldValue('user_type_id', e.value) }} className="w-100 px-3" />
                              <ErrorMessage name="user_type_id" component="span" className="from-error-span" />
                            </>
                          )
                          }
                        </Field>

                      </div>
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">Role Name <span className="text-danger">*</span></label>

                        <Field name="role_name" render={({ field }) => <InputText className="form-control px-3"   {...field} onChange={(e) => { checkRoleExitHandler(e, panelId, setFieldValue); setFieldValue('role_name', e.value) }} />} maxLength="100" />
                        <ErrorMessage name="role_name" component="span" className="from-error-span" />

                      </div>
                    </div>
                    <div className="card p-4">
                      <h3>Modules Name</h3>

                      <div className="card-body">
                        <table className="table table-bordered border">
                          <thead>
                            <tr>
                              <th className="p-3">Transporter</th>
                              <th className="p-3">List</th>
                              <th className="p-3">Add</th>
                              <th className="p-3">Edit</th>
                              <th className="p-3">Delete</th>
                              <th className="p-3">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {initialState.length && initialState.filter(e => e.user_type_id == panelId).map((val, idx) => (
                              <tr>
                                <td className="p-3">
                                  <span>{val?.menu_name} &nbsp;</span>
                                  <Field name="menu_id">
                                    {({ field }) => (<input type="checkbox" {...field} value={val.id} />)}</Field>
                                </td>

                                {
                                  val?.menuActionData && val.menuActionData ? val.menuActionData.map((ele, index) => (
                                    <td className="p-3">
                                      <Field name={`action_details.${idx}`}>{({ field }) => (
                                        <input type="checkbox" {...field} value={ele.id} />
                                      )}
                                      </Field>

                                    </td>
                                  )) : ''
                                }
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )
        }
      </Formik>

    </>
  );
}

export default Add;
