import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from '../../../BootstrapComponents'
import { InputText, Dropdown as Select, confirmDialog, Toast } from "../../../PrimeReactComponents";
import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik';
import { addressBookInitialState, addressBookValidateSchema } from '../../Validations/updateAddressBookValidation'
import { getCountry, getState } from '../../../services/commonController';
import { updateCustomerAddressBookData, getSingleCustomerAddressData } from '../../controllers/customerController';
import {useDispatch, useSelector} from 'react-redux';
import {customerAddressBookModalToggleHandler} from '../../../store/commonSlice';

function EditAddressModal({ loadLazyData, customerAddressInfo, editInfo }) {
    const { customer_id, company_id } = editInfo
    const dispatch = useDispatch();
    const {customerAddressBookModal} = useSelector(state => state.commonSlice);
    const [loader, setLoader] = useState();
    const navigate = useNavigate();
    const toast = useRef(null);
    const [country, setCountry] = useState();
    const [states, setStates] = useState();
    const [companyAddresCheck, setCompanyAddresCheck] = useState(false);
    const [initialState, setInitialState] = useState(addressBookInitialState);
  
    useEffect(() => {
        getSingleData()
    }, [editInfo,customerAddressInfo ])

    const getSingleData = async () => {
        if (editInfo !== '') {
            setLoader(true)
          
            setInitialState({
                address: [
                    {
                        customer_company_name: customerAddressInfo?.customer_company_name,
                        address: customerAddressInfo?.address,
                        suburb: customerAddressInfo?.suburb,
                        zip_code: customerAddressInfo?.zip_code,
                        country_id: customerAddressInfo?.country_id,
                        state_id: customerAddressInfo?.state_id,
                        customer_address_id: customerAddressInfo?.customer_address_id,
                    }
                ]
            })

                setLoader(false)
            
        }else{
            setLoader(false)
        }
    }

 

    const getAllCountries = async () => {
        const results = await getCountry();
        setCountry(results.data)
    }

    const getAllStates = async () => {
        const results = await getState();
        setStates(results.data)
    }

    const getCountryOption = () => {
        const option = [];
        country?.map((ele) => {
            option.push({ value: ele.id, label: ele.country_name })
        })
        return option;
    }

    const getStateOption = (CoutryId) => {
        const option = [];
        states?.filter((ele) => {
            if (ele.country_id == CoutryId) {
                option.push({ value: ele.id, label: ele.state_name })
            }
        })
        return option;
    }

    useEffect(() => {
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        loadLazyData()
    }, [companyAddresCheck])


    const customerAddressUpdate = async (values, { resetForm }) => {

        const newArr = []
        values.address.forEach(element => {
            element['customer_id'] = customer_id
            element['company_id'] = company_id
            newArr.push(element)
        });

        let allData = { address: newArr }


        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setLoader(true)
                updateCustomerAddressBookData(allData, editInfo).then(({ status, type, message }) => {
                    if (status) {
                        setLoader(false)
                        resetForm({ allData: '' })
                        toast.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                        //  setModalShow(false)

                        dispatch(customerAddressBookModalToggleHandler())

                        setTimeout(() => {
                            setCompanyAddresCheck(true)
                            navigate(`/transport/customers/address-book/${customer_id}/${company_id}`)
                        }, 3000)

                    } else {
                        setLoader(false)
                        toast.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                    }
                })
            }
        });

    }

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <Formik enableReinitialize initialValues={initialState}
                validationSchema={addressBookValidateSchema} onSubmit={customerAddressUpdate}
                render={({ values }) => (
                    <>

                        <Modal show={customerAddressBookModal} size="xl" centered>
                            <Modal.Header closeButton={false}>
                                <Modal.Title>Edit New Address </Modal.Title>

                                <Button className="btn-close ms-2 " onClick={() => {dispatch(customerAddressBookModalToggleHandler())}} variant=""></Button>
                            </Modal.Header>

                            <Modal.Body>

                                <div className="overflow-auto">
                                    <Form className='d-flex flex-column overflow-auto'>
                                        <table className="table  multiple-feilds-table" style={{ minWidth: "700px" }}>
                                            <thead>
                                                <tr>
                                                    <th className="fw500 fz14" width="30px">#</th>
                                                    <th className="fw500 fz14" width="200px">Customer Name <span className="text-danger">*</span></th>
                                                    <th className="fw500 fz14">Address <span className="text-danger">*</span></th>
                                                    <th className="fw500 fz14" width="150px">Suburb <span className="text-danger">*</span></th>
                                                    <th className="fw500 fz14" width="150px">Zip Code <span className="text-danger">*</span></th>
                                                    <th className="fw500 fz14" width="150px">Country  <span className="text-danger">*</span></th>
                                                    <th className="fw500 fz14" width="180px">State <span className="text-danger">*</span></th>
                                                    <th className="fw500 fz14" width="30px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name='address' render={({ push, remove }) => (
                                                    values.address && values.address.map((val, idx) => (
                                                        <tr key={idx}>
                                                            <td>{idx + 1}</td>
                                                            <td>
                                                                <Field name={`address[${idx}].customer_company_name`} render={({ field }) => (
                                                                    <>
                                                                        <InputText className="form-control px-3" {...field} />
                                                                        <ErrorMessage component="span" name={`address[${idx}].customer_company_name`} className='from-error-span' />
                                                                    </>
                                                                )} />
                                                            </td>
                                                            <td>
                                                                <Field name={`address[${idx}].address`} render={({ field }) => (
                                                                    <>
                                                                        <InputText className="form-control px-3" {...field} />
                                                                        <ErrorMessage component="span" name={`address[${idx}].address`} className='from-error-span' />
                                                                    </>
                                                                )} />
                                                            </td>
                                                            <td>
                                                                <Field name={`address[${idx}].suburb`} render={({ field }) => (
                                                                    <>
                                                                        <InputText className="form-control px-3" {...field} />
                                                                        <ErrorMessage component="span" name={`address[${idx}].suburb`} className='from-error-span' />
                                                                    </>
                                                                )} />
                                                            </td>
                                                            <td>
                                                                <Field name={`address[${idx}].zip_code`} render={({ field }) => (
                                                                    <>
                                                                        <InputText keyfilter="num" className="form-control px-3" {...field} maxLength="4" />
                                                                        <ErrorMessage component="span" name={`address[${idx}].zip_code`} className='from-error-span' />
                                                                    </>
                                                                )} />
                                                            </td>

                                                            <td>
                                                                <Field name={`address[${idx}].country_id`} render={({ field }) => (
                                                                    <>
                                                                        <Select className="w-100 px-3"  {...field} options={getCountryOption()} placeholder="Please select a country" />
                                                                        <ErrorMessage component="span" name={`address[${idx}].country_id`} className='from-error-span' />
                                                                    </>
                                                                )} />
                                                            </td>

                                                            <td>
                                                                <Field name={`address[${idx}].state_id`} render={({ field }) => (
                                                                    <>
                                                                        <Select className="w-100 px-3"  {...field} options={getStateOption(val.country_id)} placeholder="Please select a state" />
                                                                        <ErrorMessage component="span" name={`address[${idx}].state_id`} className='from-error-span' />
                                                                    </>
                                                                )} />
                                                            </td>



                                                            {/* {
                                                                idx < 1 && <td><button type='button' className="btn fw500 fz14  ms-auto text-success shadow-none px-0" onClick={() => push(addressBookInitialState.address[0])}><i className="fa-regular fa-plus-circle "></i></button></td>
                                                            }
                                                            {
                                                                idx > 0 && <td><button type='button' className="btn fw500 fz14  ms-auto text-danger shadow-none px-0" onClick={() => remove(idx)}><i className="fa-regular fa-minus-circle "></i></button></td>
                                                            } */}
                                                        </tr>
                                                    )
                                                    )
                                                )} />

                                            </tbody>
                                        </table>

                                        <div className="modal-footer py-3">
                                            <div className="btn-group">
                                                <button type="button" className="btn fz14 btn-gradient"
                                                    onClick={() => {dispatch(customerAddressBookModalToggleHandler())}}>Cancel</button>
                                                <button type="submit" className="btn fz14 bg-green text-white">Submit</button>
                                            </div>
                                        </div>
                                    </Form>

                                </div>

                            </Modal.Body>

                        </Modal>
                    </>
                )}


            />
        </>
    )
}

export default EditAddressModal

