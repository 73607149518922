import {REACT_APP_API_URL} from '../../config'
import {appAxios }from './common/AxiosFile';

    export const title = "Consignment Management"

    export async function listData(params){
        
        try {
             let {rows,page,desc,filters,sortOrder,sortField,globalFilterValue,status,company_id,job_number,pickup_date,delivery_date}=JSON.parse(params?.lazyEvent)   

             let pickup_date_modify = (pickup_date !== '' && pickup_date !== 'undefined')?pickup_date:''
             let delivery_date_modify = (delivery_date !== '' && delivery_date !== 'undefined')?delivery_date:''
             let job_number_modify = (job_number !== '' && job_number !== 'undefined')?job_number:'' 
             
            const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getAllConsignments/${company_id}?keyword=${globalFilterValue}&job_number=${job_number_modify}&pickup_date=${pickup_date_modify}&delivery_date=${delivery_date_modify}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);   
            
            const result = await res.data;  
            if(result.data.count){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function singleData(id){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getConsignmentById/${id}`);  

            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function addData(data){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}consignments/addConsignment/`, data);
            const result = await res.data;            
            if(result.status){                  
                if(result.data.success){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function updateData(data,id){
       try {
            const res = await appAxios().put(`${REACT_APP_API_URL}consignments/updateConsignment/${id}`, data);
            const result = await res.data;     
            if(result.status){          
                return {                      
                    status: result.status,
                    message:"Data Successfully Updated.",
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };               
            }else{
                return {
                    status:0,
                    message:"Data not found!",
                    type:'info',
                    error:null,                       
                    data:null,                    
                }
            } 
       } catch (error) {       
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
       }
    }

    export async function deleteData(id){        
        try {
            const res = await appAxios().delete(`${REACT_APP_API_URL}consignments/deleteConsignment/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function activeInactive(status,id){
      try {
        const res = await appAxios().put(`${REACT_APP_API_URL}consignments/statusChangeConsignment/${id}/${status === '1'? 0:1}`);
        const result = await res.data;   

        if(result.status){   
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:"Data not found!",         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
      }
    }


    export async function uploadDocument(data, headers){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}transport/driverdocumentupload`, data, headers);
            const result = await res.data; 

            if(result.status){                  
                if(result.status){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function addDataManifest(data){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}consignments/addmanifest/`, data);
            const result = await res.data;            
            if(result.status){                  
                if(result.data.success){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function listDataManifest(params){
       
        try {
            const {rows,page,sortOrder,sortField,globalFilterValue,manifest_number,company_id}=params?.lazyEvent;
           
            const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getallmanifests/${company_id}?keyword=${globalFilterValue}&manifest_number=${manifest_number}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
            const result = await res.data;  
            if(result.data.count){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data,                  
                } 
                               
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function singleDataManifest(id, company_id){   
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getmanifestbyid/${company_id}/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function searchManifestNumber(company_id, keyword){   
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getmanifestbynumber/${company_id}?keyword=${keyword}`);    
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function searchConsigmentNumber(company_id, keyword){   
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getconsignmentbykeyword/${company_id}?keyword=${keyword}`);    
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function activeInactiveBulk(status,ids,companyId){
        
        try {
          const res = await appAxios().put(`${REACT_APP_API_URL}consignments/consignmentBulkChangeStatus/${companyId}`,{consignment_id:ids,status});
          const result = await res.data;                
          if(result.status){
         
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:res.data.data.msg,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:res.data.data.msg,         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.msg,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }

      export async function removeDataBulk(ids, companyId){        
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}consignments/consignmentBulkDelete/${companyId}`,{consignment_id:ids});
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }


    export async function activeInactiveMinifestBulk(status,ids,companyId){
        
        try {
          const res = await appAxios().put(`${REACT_APP_API_URL}consignments/manifestBulkChangeStatus/${companyId}`,{manifest_id:ids,status});
          const result = await res.data;                
          if(result.status){
         
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:res.data.data.msg,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:res.data.data.msg,         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.msg,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }

      export async function removeDataMenifestBulk(ids,companyId){  
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}consignments/manifestBulkDelete/${companyId}`,{manifest_id:ids});
            const result = await res.data;                        
            if(result.status === 1){   
                return {
                    status: result.status,
                    message:result.data.msg,
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:result.data.msg ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function uploadConsignmentDocument(data, headers){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}imageUpload/consignmentImage`, data, headers);
            const result = await res.data; 

            if(result.status){                  
                if(result.status){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    } 
 

    export async function getUniquData(params){
        const {type}= params
        try {
         
            
            const res = await appAxios().get(`${REACT_APP_API_URL}custom/generate-random-number/${type}`);
            const result = await res.data;  
;
            if(result.status===1){   
               // console.log("this is log.....2323",result)
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result.data.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function getUserInfo(id,company_id="",customerId=""){

        try {
            const res = await  appAxios().get(`${REACT_APP_API_URL}company/userinfo/${id}/${company_id}/${customerId}`); 
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }
