import React, { useRef, useState, useEffect } from "react";
import {useSelector } from 'react-redux';
import { Link,useNavigate } from "react-router-dom";
import * as yup from 'yup'
import { Dropdown } from 'react-bootstrap';
import { Dropdown as Dropdownas } from "../../PrimeReactComponents";
import { classNames } from 'primereact/utils';
import { listData, addData, userRoleUpdate, activeInactive, removeData,removeDataBulk,activeInactiveBulk,getUserType,resendInvit } from "../controllers/UserController";
import { Button, Modal } from "../../BootstrapComponents";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import RequestEmail from "../../platform-admin/companies/components/RequestEmail";
import dataTableConfig from "../../config/dataTableOptions";
import FilterOffset from "./components/OffsetRight";
import Loader from "../../features/loader/Loader";
import { useResizeListener } from 'primereact/hooks';
function List(props) {
  const {transportUserInfo} = useSelector(state => state); 
  const toast = useRef(null);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState("0px");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const reciveLocalData= transportUserInfo.company_id;
  const reciveLocalUserData= localStorage.getItem('userId')
  const [list, setList] = useState([]);
  const [loader, setloader] = useState(false);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState();
  const [getSelectedCompany, setSelectedComapny] = useState([])
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [newModalShowEmail, setNewModalShowEmail] = useState(false);
  const [newModalShow, setNewModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState({ company_id: '', user_id: '',userrelationid:'' });
  const [currentUserType, setCurrentUserType] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const[getUserDropDwan,setGetUserDropDwan ] =useState([])

  
  let userManagementListArr = []
  let userManagementArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'User Management')?.map(val => {

    if (!userManagementListArr.includes(val?.menuData?.menu_name)) {
      userManagementListArr.push(val?.menuData?.menu_name)
    }

    if (!userManagementArr.includes(val?.menuActionData?.action_name)) {
      userManagementArr.push(val?.menuActionData?.action_name)
    }
  }) 

  if (userManagementListArr.length == 0) {
    navigate('/transport/404');
  }

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
});

useEffect(() => {
  bindWindowResizeListener();

  return () => {
      unbindWindowResizeListener();
  };
}, [bindWindowResizeListener, unbindWindowResizeListener]);
  
  const [newUserInitialState,setInitialstate] = useState( {
    role_type: '',
    company_id: reciveLocalData,
    user_type_id: 3,
    user_id: '',
    firstname:'',
    lastname:'',
    userrelationid:'' 
  })

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder:false,
    filters: "",
    globalFilterValue: "",
    status:"",
    id: reciveLocalData,
    relationid: reciveLocalData,
    filters: {
      'first_name': { value: '', matchMode: 'contains' },
      'email_id': { value: '', matchMode: 'contains' },
      'user_typeId': { value: '', matchMode: 'contains' },
      'company_id': { value: '', matchMode: 'contains' }
    }
  });
  

  const initialState = {
    email_id: '',
    role_type: '',
    panel_name:'Transport',
    company_id: reciveLocalData,
    relationid:reciveLocalData,
    user_type_id: 1, 
    firstname:'',
    lastname:''
  }



  const userFilterSchema = yup.object().shape({
    firstname: yup.string().required('This Field is required'),
    lastname: yup.string().required('This Field is required'),
    email_id: yup.string().trim().email('Please enter valid email').required('This Field is required'),
    role_type: yup.string().required('This Field is required')
  })

const getUserDropdawan = async()=>{
 
 await getUserType().then(data=>{
  let option = [];
    data?.data.filter(data => data.user_type_id === 1).map( (ele) => {
    option.push({ value: ele.id, label: ele.role_name })
   })
    setGetUserDropDwan(option)
   });
  
}
  let loadLazyTimeout = null;

  useEffect(() => {
    getUserDropdawan();
    loadLazyData();
  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      listData((lazyParams)).then(data => {
        setTotalRecords(data?.data?.count);
        setCustomers(data?.data?.rows);
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);

      });
    }, Math.random() * 1000 + 250);

  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
     // sortOrder: event.sortOrder === 1 ? "asc" : "desc",
      globalFilterValue: ""
    }, []);

  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField,sortOrder: !lazyParams.sortOrder});
}

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if(value.length >3){
    setLazyParams({...lazyParams,globalFilterValue:value});
   
    }else if(value.length === 0){
      loadLazyData();
     setLazyParams({...lazyParams,globalFilterValue:""});
     
    }
}
  const updateUserRole = (data) => {
    setloader(true)

    let all_data = {
      ...data,
     // ...userData
    }
 

    userRoleUpdate(all_data).then(({ message, type }) => {
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
      setloader(false)
      setModalShowEmail(false)
      loadLazyData();
    })
  }
 const userResendMail =(id)=>{
  confirmDialog({
    message: 'Are you sure you want to proceed?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      setloader(true)
      resendInvit({id:id, panel_name:'Transport'}).then(({ status, type, message }) => {
        if (status) {
          setloader(false)
          // resetForm({ all_values: '' })
          toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
        } else {
          setloader(false)
          toast.current.show({ severity: 'error', summary: 'Info Message', detail: message, life: 3000, });
        }
      })
    }
  });
 }
  const advanceSearch=(value)=>{    
   
    if(value){
     setLazyParams({...lazyParams,globalFilterValue:value.first_name,status:value.status});
      setoffsetFilter(true);
    }else{
      setoffsetFilter(false);
      setLazyParams({...lazyParams});
    }
    }
  const deleteEntry = (id) => {
    setloader(true);
    removeData(id).then(({ type, message }) => {
      loadLazyData();
      setloader(false);
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const deleteConfirm = (user_id) => {
    setIsListUpdated(false)
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(user_id)
      }
    });

  } 

  // Active Inactive Entry Function Start Here =====
  const activeInactiveStatus = (id, status) => {
    setloader(true)
    activeInactive(id, status).then(({ type, message }) => {
      setIsListUpdated(true)
      loadLazyData();
      setloader(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {
    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data.status === '1' ? 'Inactivate' : 'Activate'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data.user_id, data.status);
      }
    });
  }
 
  const actionBodyTemplate = (rowData) => {
    return (<div className="d-flex gap-2"> 
      <Button className={`deleteRow btn fz16 p-0 delete-btn ${userManagementArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} variant="" onClick={() => deleteConfirm(rowData?.user_relation_id)}>
        <i className="fa-light fa-trash"></i>
      </Button> 
    </div>);
  };
  const statusBodyTemplate = (rowData) => {
    return <button onClick={(e) => activeInactiveHandler(e, rowData)} icon="pi pi-check" label="Confirm" className={`fw600 border-0 badge py-2 fs-5 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Active" : "Inactive"} <i className="fa-light fa-angle-down"></i></button>;
  };
  
  const userIdTemplate = (rowData) => { 
    return <span>{rowData?.tbl_role?.role_name}
      <span variant="" className={`btn p-0 ms-2 ${userManagementArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`} onClick={() => userShowPopup(rowData.company_id, rowData.user_id, rowData.roleId,rowData.userData.first_name,rowData.userData.last_name,rowData?.user_relation_id)}>
        <i className="fa-light fa-pen"></i>
      </span>
    </span>

  }

  const InvitStatusTemplate = (rowData) => {
    return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-${rowData.invited_status === '1' ? "green" : "red"}`}>{rowData.invited_status==="1" ? "Accepted " : "Pending "}
    {rowData.invited_status === "0" &&(
      <span variant="" className="btn btn-gradient ms-2" title="Resend Invite" onClick={() => userResendMail(rowData.user_relation_id)}>
        <i className="fa-light fa-send"></i>
      </span>)}
    </span>

  }

  const userNameTemplate = (rowData) => {
    return <span>{rowData.userData.first_name === null ? "" : rowData.userData?.first_name+ " "+rowData.userData?.last_name} </span>
  }

  function userShowPopup(company_id, user_id, role_id,firstname,lastname,user_relation_id) {
    setUserData({ company_id: company_id, user_id: user_id,firstname:firstname,lastname:lastname,userrelationid:user_relation_id });
    setInitialstate({company_id: company_id, user_id: user_id,firstname:firstname,lastname:lastname,userrelationid:user_relation_id,role_type:role_id });
    setCurrentUserType(parseInt(role_id)) 
    setModalShowEmail(true);
    
  }

  function newUserShowPopup() {
    setNewModalShowEmail(true);
  }


  const addNewUser = (data) => {
    setloader(true)
    addData(data).then(({ message, type }) => {
      toast?.current?.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
      setloader(false)
      setNewModalShowEmail(false)
      loadLazyData();
    })
  }


  const activeInactiveStatusBulk =(status, id)=> {
    setloader(true)
    activeInactiveBulk(status,reciveLocalData, id).then(({type, message})=> { 
      setIsListUpdated(true)  
      setSelectedUser([])   
      loadLazyData(); 
      setloader(false)
      toast.current.show({severity:type, summary: 'Info Message', detail:id.length > 0 ? message:"No user selected" , life: 3000,});     
    })
  }
  const bulkActivate =()=>{
    let selectedId=[];
    selectedUser && selectedUser.length > 0 && selectedUser.map((item,index)=>{
    return selectedId.push(item.user_relation_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {  
        activeInactiveStatusBulk("1",selectedId) ;     
      }
   });
   }
   const bulkDeActivate =()=>{
     let selectedId=[];
     selectedUser && selectedUser.length > 0 && selectedUser.map((item,index)=>{
     return selectedId.push(item.user_relation_id);
     })
     setIsListUpdated(false)
     confirmPopup({
       message: `Are you sure you want to 'Inactivate'?`,
       icon: 'pi pi-exclamation-triangle',
       accept: () => {  
        activeInactiveStatusBulk('0',selectedId) ;     
       }
    });
    }
   
    const DeleteBulk =( id)=> {
     setloader(true)
     removeDataBulk(id,reciveLocalData).then(({type, message})=> { 
       setIsListUpdated(true)  
       setSelectedUser([])    
       loadLazyData(); 
       setloader(false)
       toast.current.show({severity:type, summary: 'Info Message', detail:id.length > 0 ? message:"No user seleted to delete" , life: 3000,});     
     })
   }
    const bulkDelete =()=>{
     let selectedId=[];
     selectedUser && selectedUser.length > 0 && selectedUser.map((item,index)=>{
     return selectedId.push(item.user_relation_id);
     })
     setIsListUpdated(false)
     confirmPopup({
       message: `Are you sure you want to 'Delete'?`,
       icon: 'pi pi-exclamation-triangle',
       accept: () => {  
         DeleteBulk(selectedId) ;     
       }
    });
    }

   

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-users"></i>&nbsp;
                  User List
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Button variant=""  onClick={newUserShowPopup} className={`bg-green text-white btn fz14 me-3 ${userManagementArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}>
                  <i className="fa-light fa-plus-circle"></i>  New User
                </Button>
                
                
                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>
                <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul>
                      <li>
                        <Button className={`dropdown-item  ${userManagementArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} to="#"
                         onClick={()=>bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={()=>bulkActivate()} >
                          <i className="fa-light me-2 fa-check"></i>
                          Active
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={()=>bulkDeActivate()}>
                          <i className="fa-light me-2 fa-ban"></i>
                          Inactive
                        </Button>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={customers} selectionMode="checkbox" lazy  dataKey="user_id"
              scrollable=" true" responsiveLayout="scroll"
              {...{ scrollHeight: tableHeight }}
              scrollDirection="both"
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedUser} onSelectionChange={e => setSelectedUser(e.value)}
              {...dataTableConfig} 
            >
              <Column field="company_id" selectionMode="multiple" headerStyle={{ width: "3em" }} ></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "9rem" }}></Column>
              <Column field="first_name" header="Name" body={userNameTemplate} sortable style={{ flexGrow: 1, flexBasis: "120px"  }}></Column>
              <Column field="userData.email_id" header="Email" sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="roleId" header="User Type" body={userIdTemplate} sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="user_id" header="Invited Status" body={InvitStatusTemplate}  exportable={false} style={{ minWidth: "9rem" }} />
              <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "60px" }}></Column>
            </DataTable>   

            <Modal show={modalShowEmail} onHide={() => setModalShow(false)} size="lg" centered>
              <Modal.Header closeButton={false}>
                <Modal.Title>Update User </Modal.Title>              
                <Button className="btn-close ms-2 " onClick={() => setModalShowEmail(false)} variant=""></Button>
              </Modal.Header>
              <Modal.Body>
                <Formik enableReinitialize initialValues={newUserInitialState}  onSubmit={(value) => {
                  confirmDialog({
                    message: 'Are you sure you want to proceed?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                      updateUserRole(value)
                    }
                  });
                }}>
                 {({setFieldValue}) => (
                  <Form>
                    <div className="row align-items-center p-5 pt-0 gx-2">

                    <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">First Name  <span className="text-danger">*</span></label>
                          <Field name="firstname" render={({ field }) => (
                            <>
                              <InputText {...field} className="form-control mt-2 px-3" />
                              <ErrorMessage name="firstname" component="span" className="from-error-span position-absolute" />
                            </>
                          )} />

                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">Last Name  <span className="text-danger">*</span></label>
                          <Field name="lastname" render={({ field }) => (
                            <>
                              <InputText {...field} className="form-control mt-2 px-3" />
                              <ErrorMessage name="lastname" component="span" className="from-error-span position-absolute" />
                            </>
                          )} />

                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">Role <span className="text-danger">*</span></label>
                          <Field name="role_type" render={({ field }) => (
                            <> 
                              <Dropdownas className="w-100  mt-2 px-3" {...field} options={getUserDropDwan} placeholder="Select Role" value={currentUserType} onChange={ (e) => {setFieldValue('role_type',e.target.value); setCurrentUserType(e.target.value)}}/>
                              <ErrorMessage name="role_type" component="span" className="from-error-span position-absolute" />
                            </>
                          )} />

                        </div>
                      </div>
                      <div className="col-sm-2">
                        <button type="submit" className="btn fz14 bg-green text-white mt-5"><i className="fa-light fa-save"></i> Save</button>
                      </div>
                    </div>
                  </Form>
                 )}
                </Formik>
              </Modal.Body>
            </Modal>

            {/* Add New Role */}

            <Modal show={newModalShowEmail} onHide={() => setNewModalShow(false)} size="lg" centered>
              <Modal.Header closeButton={false}>
                <Modal.Title>New User </Modal.Title>
              
                <Button className="btn-close ms-21 " onClick={() => setNewModalShowEmail(false)} variant=""></Button>
              </Modal.Header>
              <Modal.Body>
                <Formik enableReinitialize initialValues={initialState} validationSchema={userFilterSchema} onSubmit={(value) => {
                  confirmDialog({
                    message: 'Are you sure you want to proceed?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                      addNewUser(value)  
                    }
                  });
                }} >

                  <Form>
                    <div className="row align-items-center p-5 pt-0 gx-2">
                    <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">First Name  <span className="text-danger">*</span></label>
                          <Field name="firstname" render={({ field }) => (
                            <>
                              <InputText {...field} className="form-control mt-2 px-3" />
                              <ErrorMessage name="firstname" component="span" className="from-error-span position-absolute" />
                            </>
                          )} />

                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">Last Name  <span className="text-danger">*</span></label>
                          <Field name="lastname" render={({ field }) => (
                            <>
                              <InputText {...field} className="form-control mt-2 px-3" />
                              <ErrorMessage name="lastname" component="span" className="from-error-span position-absolute" />
                            </>
                          )} />

                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">Email Address  <span className="text-danger">*</span></label>
                          <Field name="email_id" render={({ field }) => (
                            <>
                              <InputText {...field} className="form-control mt-2 px-3" />
                              <ErrorMessage name="email_id" component="span" className="from-error-span position-absolute" />
                            </>
                          )} />

                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="form-group flex-grow-1 mt-2">
                          <label htmlFor="">Role  <span className="text-danger">*</span></label>
                          <Field name="role_type" render={({ field }) => (
                            <>
                              <Dropdownas className="w-100  mt-2 px-3" {...field} options={getUserDropDwan} placeholder="Select Role" />
                              <ErrorMessage name="role_type" component="span" className="from-error-span position-absolute" />
                            </>
                          )} />

                        </div>
                      </div>
                      <div className="col-sm-2">
                        <button type="submit" className="btn fz14 bg-green text-white mt-5"><i className="fa-light fa-save"></i> Save</button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
