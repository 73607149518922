import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Dropdown, Button } from "../../BootstrapComponents";
import { FilterOffset } from "../components/OffsetRight";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import dataTableConfig from "./config/DatatableConfig";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { listData, removeData, vehicleCheckList,getTimesheetSingleData } from "../controllers/SetUpController";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter'
import Loader from "../../features/loader/Loader";
import Moment from 'react-moment';
import VehicleChecklistCustom from "./../timesheets/components/VehicleChecklistCustom";


function CheckList() {
  const { transportUserInfo } = useSelector(state => state);
  const toast = useRef(null);
  const [loader, setLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const reciveLocalData = transportUserInfo.company_id;
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [tableHeight, setTableHeight] = useState("0px");
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [checkList, setCheckList] = useState(null);
  const [vehicleData, setVehicleData] = useState(null);
  const { id } = useParams();
  const [vehicleId, setVehicleId] = useState();  
  const [initialState, setInitialState] = useState(); 

  let getTimesheetData = async (id) => {
    if (id) {

        const { status, message, type, error, data } = await getTimesheetSingleData(id, reciveLocalData);

        console.log('data.data.checklist', data.data)

        if (status) {

            setInitialState({    
                checklistDetail: data.data
            })
           
            setLoader(false)
        } else {
            toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            setLoader(false)
        }
    }
}

useEffect(() => { 
  getTimesheetData(id) 
}, [id])

 
 
 
  return (
    <>
      <Loader />
      <Toast position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-truck"></i>
                  Vehicle
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                
              {
                <>
                     <VehicleChecklistCustom initialState={initialState}  />
                </>
              }


                 
              </div>
            </div>
          </div>


          <div className="table-wrapper flex-grow-1  mt-5">

            

 


          </div>
        </div>
      </div>
    </>
  )
}

export default CheckList

