import {REACT_APP_API_URL} from '../../config'
import {appAxios} from './common/AxiosFile';

    export const title = "Email Log"
  


export async function emailLoggerListData(params){
       
    try {
        const {rows,page,sortOrder,sortField,globalFilterValue,company_id,user_type_id,panel_name}=params?.lazyEvent; 

        const res = await appAxios().get(`${REACT_APP_API_URL}emailLogger/loggerList/${company_id}?keyword=${globalFilterValue}&panel_name=${panel_name}&user_type_id=${user_type_id}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);  

        const result = await res.data;  
        if(result.data.count){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: result,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}
  