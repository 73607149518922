import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../BootstrapComponents";
import { DataTable, Column, confirmDialog, Toast } from "../../PrimeReactComponents";
import AddCustomerAddressModal from './components/AddCustomerAddressModal';
import EditCustomerAddressModal from './components/EditCustomerAddressModal';
import dataTableConfig from "./config/DatatableConfig";
import { getCustomerAddressData, changeCustomerAddressStatusData, deleteCustomerAddressBookData } from '../controllers/customerAddressBookController';
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter'
import { getCountry, getState } from '../../services/commonController';
import { useDispatch } from 'react-redux';
import { customerAddressBookModalToggleHandler } from '../../store/commonSlice';
import Loader from "../../features/loader/Loader";



function CustomerAddressBooks() {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const navigate = useNavigate();
  const { customer_id, company_id } = useParams()
  const [tableHeight, setTableHeight] = React.useState("0px");
  const [selectedCheckbox, setSelectedCheckbox] = React.useState(null);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [customersAddressData, setCustomersAddressData] = useState()
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [country, setCountry] = useState();
  const [states, setStates] = useState();
  const [editAddressInfo, setEditAddressInfo] = useState();
  const [loader, setLoader] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    status: "",
    globalFilterValue: "",
    customer_id: customer_id,
    company_id: company_id,
    filters: {
      'customer_company_name': { value: '', matchMode: 'contains' },
      'address': { value: '', matchMode: 'contains' },
      'suburb': { value: '', matchMode: 'contains' },
      'zip_code': { value: '', matchMode: 'contains' },
      'country_id': { value: '', matchMode: 'contains' },
      'state_id': { value: '', matchMode: 'contains' }
    }
  });


  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();

  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      getCustomerAddressData({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {


        setTotalRecords(data?.data?.data?.count);
        setCustomersAddressData(data?.data?.data?.rows);
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 10,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      globalFilterValue: "",
    }, []);

  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }


  const toChangeStatus = (customer_address_id, customer_id, company_id) => {

    let data = {
      customer_address_id,
      customer_id,
      company_id
    }

    setIsListUpdated(true)
    confirmDialog({
      message: 'Are you sure you want to change default status?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        changeCustomerAddressStatusData(data).then(({ status, message, type, error }) => {
          //toast?.current?.show({severity:type, summary: 'Info Message', detail: message , life: 5000,}); 
          setIsListUpdated(false)
          loadLazyData();

        })
      },
      reject: () => {
        console.log('Not delete')
      }
    });
  }


  const defaultStatusTemplate = (rowData) => {
    const regCheck = <i className="fa-regular text-green fa-circle-check"></i>
    return <span>{rowData.default_status === '1' ? regCheck : <span className="text-dark" onClick={() => toChangeStatus(rowData?.customer_address_id, rowData?.customer_id, rowData?.company_id)}>Set As Default</span>}</span>;
  };

  const getCountryTemplate = (rowData) => {
    let country_id = rowData?.country_id

    let country_data = country && country.filter(id => id?.id == country_id)[0]

    return <span className="fw700">{rowData?.country_id !== '' ? country_data?.country_name : ''}</span>
  }

  const getStateTemplate = (rowData) => {
    let state_id = rowData?.state_id

    let state_data = states && states.filter(id => id?.id == state_id)[0]

    return <span className="fw700">{rowData?.state_id !== '' ? state_data?.state_name : ''}</span>
  }


  const getAllCountries = async () => {
    const results = await getCountry();
    setCountry(results.data)
  }

  const getAllStates = async () => {
    const results = await getState();
    setStates(results.data)
  }

  useEffect(() => {
    getAllCountries();
    getAllStates();

  }, [])


  const deleteConfirm = (id) => {

    confirmDialog({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteCustomerAddressBookData(id, { customer_id: customer_id, company_id: company_id }).then(res => {
          toast?.current?.show({ severity: 'success', detail: 'Deleted Successfully', life: 3000, });
          loadLazyData();
          setLoader(false)

        })

      },
      reject: () => {
        console.log('Not delete')
      }
    });
  }


  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2">
          <Button className="editRow btn fz16 p-0 edit-btn" variant="" onClick={() => getEditInfo(rowData.customer_address_id)}>
            <i className="fa-light fa-edit"></i>
          </Button>
          <Button className="deleteRow btn fz16 p-0 delete-btn" variant="" onClick={() => deleteConfirm(rowData.customer_address_id)}>
            <i className="fa-light fa-trash"></i>
          </Button>
        </div>
      </>
    );
  };

  const getEditInfo = (id) => {

    let filterCustomerAddressData = customersAddressData.filter(item => item.customer_address_id == id);
    setEditAddressInfo(filterCustomerAddressData[0]);
    dispatch(customerAddressBookModalToggleHandler())
  }


  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
          <div className="container d-flex flex-column h-100 ">
            <div className="px-4 mt-3">
              <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                  <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Customer Address Book</h2>
                </div>
                <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                  <AddCustomerAddressModal loadLazyData={loadLazyData} />
                </div>
              </div>
            </div>
            <div className="overflow-auto flex-grow-1 mt-5 px-4">

              <div className="table-wrapper flex-grow-1">
                <DataTable value={customersAddressData} lazy selectionMode="checkbox" dataKey="customer_company_name" paginator rows={12} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} first={lazyParams.first} selection={selectedCheckbox} onSelectionChange={e => setSelectedCheckbox(e.value)} {...dataTableConfig} scrollDirection="both" onFilter={onFilter} filters={lazyParams.filters} loading={loading} >
                  <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }}></Column>
                  <Column field="customer_company_name" header="Customer Name" sortable style={{ flexGrow: 1, width: "150px" }}></Column>
                  <Column field="address" header="Address" sortable style={{ flexGrow: 1, width: "150px" }}></Column>
                  <Column field="suburb" header="Suburb" sortable style={{ flexGrow: 1, width: "150px" }}></Column>
                  <Column field="zip_code" header="Zip Code" sortable style={{ flexGrow: 1, width: "150px" }}></Column>
                  <Column field="country_id" body={getCountryTemplate} header="Country" sortable style={{ flexGrow: 1, width: "150px" }}></Column>
                  <Column field="state_id" body={getStateTemplate} header="State" sortable style={{ flexGrow: 1, width: "150px" }}></Column>
                  <Column field="default_status" body={defaultStatusTemplate} sortable style={{ flexGrow: 1, width: "150px" }}></Column>
                </DataTable>

                <EditCustomerAddressModal loadLazyData={loadLazyData} customerAddressInfo={editAddressInfo} editInfo={{ customer_id: customer_id, company_id: company_id }} />

              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}





export default CustomerAddressBooks  