import * as yup from 'yup';

const driverInitialState = {
  drivercode: '',
  title: '',
  // company_id:"194c390a-6ef7-11ed-bvee-00ff2902ee14",
  fname: '',
  lname: '',
  driverphone: '',
  drivermobile: '',
  username: '',
  emergencycontactname: '',
  emergencyphone: '',
  contactrelation: '',
  address: '',
  suburb: '',
  zipcode: '',
  state_id: '',
  country_id: 1,
  role_type:'',
  multiple_documents: [{
    license_type: "",
    driverdocument: "",
    license_expiry_date: "",
    issue_date: "",
    forklift_license: ""
  }],
  notes: [{
    notes_content: "",
    driver_id: "",
    notes_id: "",

  }]
};

//const[multipalDocmentState,setmultipalDocmentState]=useState([])
const driverValidateSchema = yup.object().shape({
  /* drivercode: yup.string().required('This Field is required'),
  title: yup.string().required('This Field is required'), 
  driverphone: yup.number().required('This Field is required'),
  drivermobile: yup.number().required('This Field is required'), */
  // emergencycontactname: yup.string().required('This Field is required'),
  // emergencyphone: yup.number().required('This Field is required'),
  // contactrelation: yup.string().required('This Field is required'),
  /*  suburb: yup.string().required('This Field is required'),  
   address: yup.string().required('This Field is required'),
   zipcode: yup.number().required('This Field is required'),
   state_id: yup.string().required('This Field is required'), */
   username: yup.string().email('Invalid email format').required('This Field is required'),
   fname: yup.string().required('This Field is required'),
   lname: yup.string().required('This Field is required'),

  //  country_id: yup.string().required('This Field is required'),
  // multiple_documents: yup.array().of(
  //   yup.object().shape({
  //     license_type: yup.string().required('This Field is required'),
  //     driverdocument: yup.string().required('This Field is required'),
  //     // driverdocument: yup.mixed().test('fileType', "Unsupported File Format", value => ['jpeg', 'png', 'jpg', 'pdf'].includes(['image/*']) ) ,
  //     license_expiry_date: yup.string().required('This Field is required'),
  //     issue_date: yup.string().required('This Field is required'),
  //     forklift_license: yup.string().required('This Field is required'),

  //   })
  // ),
  // notes: yup.array().of(
  //   yup.object().shape({
  //     notes_content: yup.string().required('This Field is required'),
  //   })
  // ),

})


const drivFilterInitialState = {
  fname: '',
  lname: '',
  status: ''
}

const drivFilterSchema = yup.object().shape({
  //fname: yup.string().required('This Field is required'),
 // status: yup.string().required('This Field is required')
})




export { driverInitialState, driverValidateSchema, drivFilterInitialState, drivFilterSchema }