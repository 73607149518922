import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link, NavLink, useNavigate, useSearchParams, Route } from "react-router-dom";
import { InputText, Dropdown as Select, Calendar, confirmDialog, Toast, ConfirmDialog } from "../PrimeReactComponents";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Button, Modal } from "../BootstrapComponents";
import Loader from "./../features/loader/Loader";
import { REACT_APP_BASE_BACKEND_URL } from './../config'
import { userRegistrationInviteInitialState, userRegistrationInviteInitialStateValidateSchema } from './Validations/userRegistrationInviteValidation';
import { driverInitialState, driverValidateSchema } from './Validations/driversValidation';
import { addUserRegistrationInvite, removeDriverDocument, removeDriverNote } from './controllers';
import { uploadDocument, getCountry, getState, documentTypeDropDawnData, getInvitationKey, uploadDriverDocument, getSingleDriverDocument,getUniquData } from '../services/commonController';
import { getRandomNumber } from '../utility/helper';
import Logo1 from '../assets/img/logo-black.png' 

export default function Registeration() {
    const navigate = useNavigate();
    const toast = useRef(null);
    const formikRef = useRef();
    const [loader, setLoader] = useState();
    const [allDocument, setAllDocument] = useState();
    const [country, setCountry] = useState();
    const [states, setStates] = useState();
    const [initialUserState, setInitialUserState] = useState(userRegistrationInviteInitialState);
    const [initialDriverState, setInitialDriverState] = useState(driverInitialState);
    const [searchParams] = useSearchParams();
    const [userInviteType, setUserInviteType] = useState(null);
    const [inviteStatus, setInviteStatus] = useState();
    const [userEmailId, setUserEmailId] = useState();
    const [reciveLocalData, setReciveLocalData] = useState();
    const [inviteKey, setInviteKey] = useState();
    const [driverId, setDriverId] = useState();
    const [acceptanceDate, setAcceptanceDate] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [checkStatus, setCheckStatus] = useState(false)
    const [totalInvite, setTotalInvite] = useState();
    const [keyClockId, setKeyClockId] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [modalShow, setModalShow] = React.useState(false);
    const [imagePos, setImagePos] = React.useState();


    let requestId = searchParams.get('accountactive');


    let reciveResponse = [];
    const getAllCountries = async () => {
        const results = await getCountry();
        setCountry(results.data)
    }
    const getAllStates = async () => {
        const results = await getState();
        setStates(results.data)
    }

    const forkitTypeOption = [
        { value: "1", label: "Yes" },
        { value: "2", label: "No" }
    ]
    const getAllTruckTypeData = async (reciveLocalData) => {
        const results = await documentTypeDropDawnData(reciveLocalData);
        setAllDocument(results?.data?.data)
    }

    const getDriverInfo = async (driver_id) => {
        const results = await getSingleDriverDocument(driver_id);

        formikRef.current.setFieldValue(`multiple_documents[${imagePos}].driverdocument`, results?.data?.driver_document[imagePos]?.driverdocument || '');

       // formikRef.current.setFieldValue('multiple_documents', results?.data?.driver_document);

        //   setInitialDriverState(prev => ({...prev, multiple_documents: results?.data?.driver_document , notes: notes }))  

    }


    const licensTypeOption = () => {
        const option = [];
        allDocument?.map((ele) => {
            option.push({ value: ele.document_id, label: ele.document_type })
        })
        return option;
    }

    const getStateOption = (CoutryId) => {
        const option = [];
        states?.filter((ele) => {
            if (ele.country_id == CoutryId) {
                option.push({ value: ele.id, label: ele.state_name })
            }
        })
        return option;
    }

    const getCountryOption = () => {
        const option = [];
        country?.map((ele) => {
            option.push({ value: ele.id, label: ele.country_name })
        })
        return option;
    }


    const userInviteSubmit = async (values, { resetForm }) => {
        setCheckStatus(false);
        let requestId = searchParams.get('accountactive');

        let modifyValues = [{ ...values, request_id: requestId }]

        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setLoader(true)
                addUserRegistrationInvite(modifyValues?.[0]).then(({ status, type, message }) => {

                    if (status) {
                        setLoader(false)
                        resetForm({ all_values: '' })

                        setLoader(false)
                        toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
                        setCheckStatus(true);

                        setTimeout(() => {
                            // window.location.href = '/thank-you';
                            navigate('/thank-you');
                        }, 3000)

                    } else {
                        // console.log('status2', status)
                        setLoader(false)
                        toast.current.show({ severity: 'info', summary: 'Info Message', detail: 'Faild', life: 3000, });
                    }
                })
            }
        });
    }



    const postData = (values, { resetForm }) => {
        setCheckStatus(false);
        let requestId = searchParams.get('accountactive');

        let license_type = [];
        let license_expiry_date = [];
        let issue_date = [];
        let forklift_license = [];
        let document_id = [];
        let modifyedDocument = [];

        const multipalLoopData = values.multiple_documents.map((data, index) => {
            license_type.push(data.license_type ? data.license_type : "");
            license_expiry_date.push(data.license_expiry_date ? new Date(data.license_expiry_date) : "");
            issue_date.push(data.issue_date ? data.issue_date : "");
            forklift_license.push(data.forklift_license ? data.forklift_license : "");
            document_id.push(data.document_id ? data.document_id : "");
            modifyedDocument.push(data.document_id && "");
        })

        let concatedData = modifyedDocument.concat(reciveResponse);
        let filtedConcatedData = concatedData.filter((item, index) => item !== undefined)
        let modifyedData = { ...values, request_id: requestId, company_id: reciveLocalData, role_type: '1', user_type_id: '4', multiple_documents: { driverdocument: '', license_type: license_type, license_expiry_date: license_expiry_date, issue_date: issue_date, forklift_license: forklift_license } }



        //let modifyValues = {...values, request_id:'306ce642-42af-48f9-92fa-1074f461baa9$er5678'}
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setLoader(true)
                addUserRegistrationInvite(modifyedData).then(({ status, type, message }) => {

                    setLoader(false)
                    toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
                    setCheckStatus(true);
                    setTimeout(() => {
                        navigate('/thank-you');
                    }, 3000)

                }).catch((error) => {
                    console.error('Error:', error);
                    // Set the loader to false to hide the loading indicator in case of an error.
                    setLoader(false);
                });
            }
        });
    }


    const getInviteKey = async () => {
        const inviteData = await getInvitationKey(searchParams.get('accountactive'))

        if (inviteData?.data !== null) {
            setInviteStatus(inviteData?.data?.invited_status)
            setUserInviteType(inviteData?.data?.user_typeId)
            setUserEmailId(inviteData?.data?.userData?.email_id)
            setReciveLocalData(inviteData?.data?.company_id)
            setInviteKey(inviteData?.data?.Invitation_key)
            setDriverId(inviteData?.data?.relation_id)
            setAcceptanceDate(inviteData?.data?.acceptance_date)
            setFirstName(inviteData?.data?.userData?.first_name)
            setLastName(inviteData?.data?.userData?.last_name)
            setTotalInvite(inviteData?.data?.TotalInvite)
            setKeyClockId(inviteData?.data?.userData?.keycloak_relation_id)
        } else {
            setInviteKey(searchParams.get('accountactive'))
        }
    }

    useEffect(() => {
        getAllCountries()

        getAllStates()
        // setInitialDriverState(prev => ({ ...prev, drivercode: getRandomNumber('DRI') }))
    }, [])

    useEffect(() => {
        getInviteKey()
        getDriverInfo(driverId)
        getAllTruckTypeData(reciveLocalData)
        getUniqueDriverNum()
        setInitialUserState(prev => ({ ...prev, email_id: userEmailId, first_name: firstName, last_name: lastName }))
        setInitialDriverState(prev => ({ ...prev, email_id: userEmailId, fname: firstName, lname: lastName }))
    }, [searchParams, userEmailId, firstName, lastName, userInviteType, reciveLocalData, inviteKey, checkStatus])

    const getUniqueDriverNum =async()=>{
        const params={
          type:"Driver",
        }
       const reciveData =await getUniquData(params);
       console.log('reciveData', reciveData)
       setInitialDriverState(prev => ({ ...prev, drivercode:reciveData.data }))
      }
    /*  useEffect(() => {
         getDriverInfo(driverId)
     }, [driverId]) */


    const linkAccepted = () => {

        if (inviteKey !== '' && inviteKey !== undefined) {

            let modifyedDataArr = { request_id: inviteKey, invite_status: '1' }

            confirmDialog({
                message: 'Are you sure want to accept?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    setLoader(true)
                    addUserRegistrationInvite(modifyedDataArr).then(({ status, type, message }) => {

                        setLoader(false)
                        toast.current.show({ severity: 'success', summary: 'Info Message', detail: 'User has been activated successfully.', life: 3000, });
                         
                        setTimeout(() => {
                            navigate('/thank-you');
                        }, 3000)
                        // <Route path="thank-you" element={<ThankYou />} />
                    }).catch((error) => {
                        console.error('Error:', error);
                        // Set the loader to false to hide the loading indicator in case of an error.
                        setLoader(false);
                    });
                }
            });
        }
    }


    function closeModal() {
        setModalShow(false);
    }

    function openModal(index) {
        setImagePos(index)
        setModalShow(true);
    }

    const uploadImageHandler = async (event) => {
        if (event.target.files[0].name !== null) {
            let image_name = event.target.files[0]


            if (image_name && (image_name.type === 'image/jpg' || image_name.type === 'image/jpeg' || image_name.type === 'image/png' || image_name.type === 'application/pdf')) {
                if (image_name !== undefined) {

                    const formData = new FormData();
                    formData.append(`document_name`, image_name)

                    setSelectedFile(image_name)
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Info Message', detail: 'Please select a valid JPG, JPEG, PNG, PDF file Only.', life: 3000, });
            }

        }

    };


    const saveFile = async (event) => {
        event.preventDefault()

        closeModal()

        if (selectedFile !== null) {

            const headers = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            };

            const formData = new FormData();
            formData.append('driver_id', driverId);
            formData.append('driverdocument', selectedFile);

            const res = await uploadDriverDocument(formData, headers)
            if (res.error !== null) {
                toast.current.show({ severity: 'error', summary: 'Info Message', detail: "Error: File size exceeds maximum limit 2 MB.", life: 3000, });
            }
            closeModal()

            getDriverInfo(driverId)

        }

    };
    // validationSchema={userInviteType ? userRegistrationInviteInitialStateValidateSchema : driverValidateSchema}
    return (
        <>
            <Loader show={loader} />
            <ConfirmDialog />
            <Toast ref={toast} position="top-center" />
            <div className="main-login-page">
                <div className="loginWrap">
                    <div className="login_container d-flex flex-column justify-content-center h-100">
                        <header className="text-center text-md-start">
                            <div className="login_container">
                            </div>
                        </header>
                        <div className="login-Announcement">
                            <div className="row w-100 mw-100">
                                <div className="col-md-10 offset-md-2">
                                    <div className="login-content w-100 mw-100 box-remove-mobile">
                                        <img src={Logo1} className="img-fluid" alt="" />
                                        {inviteStatus == '0' && <>
                                            <h1 className="mt-5 mb-3">Create Your Account</h1>
                                            <h3>You've been invited to manage LTTECH Transport account </h3>
                                        </>}

                                        {totalInvite > 1 && keyClockId !== null && <>
                                            <button type="button" className="btn btn-link" onClick={linkAccepted} ><br /><br /><br /><h3>Click Here to Accept</h3></button>
                                        </>}

                                        <br /><br />
                                        <Formik innerRef={formikRef} enableReinitialize initialValues={userInviteType == 4 ? initialDriverState : initialUserState} validationSchema={userInviteType == 4 ? driverValidateSchema : userRegistrationInviteInitialStateValidateSchema} onSubmit={userInviteType == 4 ? postData : userInviteSubmit}>
                                            {
                                                ({ values, setFieldValue, onChange }) => (

                                                    <Form encType="multipart/form-data">
                                                        {(userInviteType !== '4' && totalInvite == '1' && keyClockId == null) && <>
                                                            <div className="form-group">
                                                                <label htmlFor="" className="mb-2">Email Id <span className="text-danger">*</span></label>
                                                                <Field name="email_id" render={({ field }) =>
                                                                    <InputText className="form-control px-3 "   {...field} disabled />
                                                                } />
                                                                <ErrorMessage name="email_id" component="span" className="from-error-span" />
                                                            </div>
                                                            <div className="form-group">
                                                                <span>
                                                                    <ul>
                                                                        <li>At least one lowercase letter</li>
                                                                        <li>At least one uppercase letter</li>
                                                                        <li>At least one digit</li>
                                                                        <li>At least one special character (@, $, !, %, *, ?, &)</li>
                                                                        <li>Minimum length of 6 characters</li>
                                                                    </ul>
                                                                </span>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="" className="mb-2">Password <span className="text-danger">*</span></label>
                                                                <Field name="password" render={({ field }) =>
                                                                    <InputText type="password" className="form-control px-3 "   {...field} maxLength={12} />
                                                                } />
                                                                <ErrorMessage name="password" component="span" className="from-error-span" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="" className="mb-2">First Name <span className="text-danger">*</span></label>
                                                                <Field name="first_name" render={({ field }) =>
                                                                    <InputText className="form-control px-3 "   {...field} maxLength={50} />
                                                                } />
                                                                <ErrorMessage name="first_name" component="span" className="from-error-span" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="" className="mb-2">Last Name <span className="text-danger">*</span></label>
                                                                <Field name="last_name" render={({ field }) =>
                                                                    <InputText className="form-control px-3 "   {...field} maxLength={50} />
                                                                } />
                                                                <ErrorMessage name="last_name" component="span" className="from-error-span" />
                                                            </div>
                                                        </>
                                                        }
                                                        {/* Driver Form Started */}
                                                        {

                                                            (userInviteType === '4' && totalInvite == '1' && keyClockId == null) &&
                                                            <div className="overflow-auto flex-grow-1 mt-5 px-4">
                                                                <div className="card p-4">
                                                                    <div className="card-body">
                                                                        <div className="row gx-5">
                                                                            <input type="text" className="form-control px-3" hidden />
                                                                            <div className="col-12 mb-3">
                                                                                <h4 className="fw600 fz16 text-dark">Driver</h4>
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2 d-block">Email Id   <span className="text-danger">*</span></label>
                                                                                <Field name="email_id" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText className="form-control px-3" {...field} disabled />
                                                                                        <ErrorMessage name="email_id" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <span className="h-100">
                                                                                    <ul>
                                                                                        <li>At least one lowercase letter</li>
                                                                                        <li>At least one uppercase letter</li>
                                                                                        <li>At least one digit</li>
                                                                                        <li>At least one special character (@, $, !, %, *, ?, &)</li>
                                                                                        <li>Minimum length of 6 characters</li>
                                                                                    </ul>
                                                                                </span>
                                                                            </div>

                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2 d-block">Password <span className="text-danger">*</span></label>
                                                                                <Field name="password" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText type="password" className="form-control px-3" {...field} maxLength={12} autoComplete="off" />
                                                                                        <ErrorMessage name="password" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2 d-block">Driver Number  <span className="text-danger">*</span></label>
                                                                                <Field name="drivercode" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText className="form-control px-3" {...field} maxLength={30} disabled />
                                                                                        {/* 
                                <ErrorMessage name="drivercode" component="span" className="from-error-span" />
                                */}
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">Title  <span className="text-danger">*</span></label>
                                                                                <Field name="title" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText className="form-control px-3" {...field} maxLength={40} />
                                                                                        <ErrorMessage name="title" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2 d-block">First Name  <span className="text-danger">*</span></label>
                                                                                <Field name="fname" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText className="form-control px-3" {...field} maxLength={50} />
                                                                                        <ErrorMessage name="fname" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2 d-block">Last Name  <span className="text-danger">*</span></label>
                                                                                <Field name="lname" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText className="form-control px-3" {...field} maxLength={50} />
                                                                                        <ErrorMessage name="lname" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">Phone  <span className="text-danger">*</span></label>
                                                                                <Field name="driverphone" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int" />
                                                                                        <ErrorMessage name="driverphone" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">Mobile  <span className="text-danger">*</span></label>
                                                                                <Field name="drivermobile" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int" />
                                                                                        <ErrorMessage name="drivermobile" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-12 mt-4 mb-3">
                                                                                <h4 className=" fw600 fz16 text-dark">Emergency Contact Details  </h4>
                                                                            </div>
                                                                            <div className="overflow-auto">
                                                                                <table className="table multiple-feilds-table">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="col-sm-4 mb-4">
                                                                                                <label htmlFor="" className="mb-2">Name  </label>
                                                                                                <Field name="emergencycontactname" render={({ field }) => (
                                                                                                    <>
                                                                                                        <InputText {...field} className="form-control px-3" maxLength={50} />
                                                                                                        <ErrorMessage name="emergencycontactname" component="span" className="from-error-span" />
                                                                                                    </>
                                                                                                )} />
                                                                                            </td>
                                                                                            <td className="col-sm-4 mb-4">
                                                                                                <label htmlFor="" className="mb-2">Phone Number  </label>
                                                                                                <Field name="emergencyphone" render={({ field }) => (
                                                                                                    <>
                                                                                                        <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int" />
                                                                                                        <ErrorMessage name="emergencyphone" component="span" className="from-error-span" />
                                                                                                    </>
                                                                                                )} />
                                                                                            </td>
                                                                                            <td className="col-sm-4 mb-4">
                                                                                                <label htmlFor="" className="mb-2">Relationship </label>
                                                                                                <Field name="contactrelation" render={({ field }) => (
                                                                                                    <>
                                                                                                        <InputText {...field} className="form-control px-3" maxLength={100} />
                                                                                                        <ErrorMessage name="contactrelation" component="span" className="from-error-span" />
                                                                                                    </>
                                                                                                )} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div className="col-12 mt-4 mb-3">
                                                                                <h4 className=" fw600 fz16 text-dark">Address Details</h4>
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">Address  <span className="text-danger">*</span></label>
                                                                                <Field name="address" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText {...field} className="form-control px-3" />
                                                                                        <ErrorMessage name="address" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">Suburb  <span className="text-danger">*</span></label>
                                                                                <Field name="suburb" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText {...field} className="form-control px-3" maxLength={100} />
                                                                                        <ErrorMessage name="suburb" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">Zip Code  <span className="text-danger">*</span></label>
                                                                                <Field name="zipcode" render={({ field }) => (
                                                                                    <>
                                                                                        <InputText {...field} className="form-control px-3" keyfilter="int" maxLength={4} minLength={4} />
                                                                                        <ErrorMessage name="zipcode" component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">Country  <span className="text-danger">*</span></label>
                                                                                <Field name={`country_id`} render={({ field }) => (
                                                                                    <>
                                                                                        <Select className="w-100 px-3"  {...field} options={getCountryOption()} placeholder="Please select a country" />
                                                                                        <ErrorMessage name={`country_id`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-sm-6 mb-4">
                                                                                <label htmlFor="" className="mb-2">State  <span className="text-danger">*</span></label>
                                                                                <Field name={`state_id`} render={({ field }) => (
                                                                                    <>
                                                                                        <Select className="w-100 px-3"  {...field} options={getStateOption(values?.country_id)} placeholder="Please select a state" />
                                                                                        <ErrorMessage name={`state_id`} component="span" className="from-error-span" />
                                                                                    </>
                                                                                )} />
                                                                            </div>
                                                                            <div className="col-12 mt-4 mb-3">
                                                                                <h4 className=" fw600 fz16 text-dark">Multiple Documents</h4>
                                                                                <div className="overflow-auto mt-3 ">
                                                                                    <table className="table  multiple-feilds-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className="fw500 fz14" width="30px">
                                                                                                    #
                                                                                                </th>
                                                                                                <th className="fw500 fz14" width="120px">
                                                                                                    License Types
                                                                                                </th>
                                                                                                <th className="fw500 fz14">Upload Licence </th>
                                                                                                <th className="fw500 fz14" width="180px">
                                                                                                    Issue date
                                                                                                </th>
                                                                                                <th className="fw500 fz14" width="160px">
                                                                                                    License Expiry date
                                                                                                </th>
                                                                                                <th className="fw500 fz14" width="130px">
                                                                                                    Forklift License
                                                                                                </th>
                                                                                                <th className="fw500 fz14" width="30px"></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <FieldArray name="multiple_documents" render={({ push, remove }) => (
                                                                                                (values?.multiple_documents && values?.multiple_documents.length > 0 ? values?.multiple_documents : driverInitialState?.multiple_documents).map((val, idx) => (
                                                                                                    <tr key={idx}>
                                                                                                        <td>{idx + 1}</td>
                                                                                                        <td>
                                                                                                            <Field name={`multiple_documents[${idx}].license_type`} render={({ field }) => (
                                                                                                                <>
                                                                                                                    <Select className="w-100 px-3"  {...field}
                                                                                                                        options={licensTypeOption()}
                                                                                                                        placeholder="Please select a type" />

                                                                                                                    <ErrorMessage name={`multiple_documents[${idx}].license_type`} component="span" className="from-error-span" />

                                                                                                                </>
                                                                                                            )} />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {/*  <Field name="multiple_documents[${idx}].driverdocument" render={({ field }) => <input type="file" className="form-control px-3" onChange={(e) => { handleOnChange(e, idx); setFieldValue(`multiple_documents[${idx}].driverdocument`, e.target.files[0].name) }} />} /> */}


                                                                                                            {  val?.driverdocument === '' && <button type="button" className="btn btn-primary" data-toggle="modal" onClick={() => openModal(idx)}>
                                                                                                                New Document
                                                                                                            </button>}

                                                                                                            {val?.driverdocument !== '' && val?.driverdocument !== undefined && (((val?.driverdocument?.split('.')?.pop() === 'pdf')) ? <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.driverdocument}`} target="_blank"><i class='fas fa-file-pdf' style={{'fontSize':'18px','color':'red'}}></i></a> : <a href={`${REACT_APP_BASE_BACKEND_URL}${val?.driverdocument}`} target="_blank"> <img src={`${REACT_APP_BASE_BACKEND_URL}${val?.driverdocument}`} width="100" /></a>)}

                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Field name={`multiple_documents[${idx}].issue_date`} render={({ field }) => (
                                                                                                                <>
                                                                                                                    <Calendar dateFormat="dd/mm/yy" maxDate={val.license_expiry_date} inputClassName="form-control px-3" className="w-100" {...field} />
                                                                                                                    {/* 
                                                <ErrorMessage name={`multiple_documents[${idx}].issue_date`} component="span" className="from-error-span" />
                                                */}
                                                                                                                </>
                                                                                                            )} />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Field name={`multiple_documents[${idx}].license_expiry_date`} render={({ field }) => (
                                                                                                                <>
                                                                                                                    <Calendar dateFormat="dd/mm/yy" minDate={val.issue_date} inputClassName="form-control px-3" className="w-100" {...field} />
                                                                                                                    {/* 
                                                <ErrorMessage name={`multiple_documents[${idx}].license_expiry_date`} component="span" className="from-error-span" />
                                                */}
                                                                                                                </>
                                                                                                            )} />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Field name={`multiple_documents[${idx}].forklift_license`} render={({ field }) => (
                                                                                                                <>
                                                                                                                    <Select className="w-100 px-3"  {...field}
                                                                                                                        options={forkitTypeOption}
                                                                                                                        //onChange={(e) => getState(e, setFieldValue)}
                                                                                                                        placeholder="Select a forklift license"
                                                                                                                    />
                                                                                                                    {/* 
                                                <ErrorMessage name={`multiple_documents[${idx}].forklift_license`} component="span" className="from-error-span" />
                                                */}
                                                                                                                </>
                                                                                                            )} />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {idx > 0 ? (
                                                                                                                <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" onClick={() => {
                                                                                                                    remove(idx)
                                                                                                                    reciveResponse.splice(idx, 1)
                                                                                                                    removeDriverDocument(val.document_id)
                                                                                                                }} >
                                                                                                                    <i className="fa-regular fa-minus-circle "></i>
                                                                                                                </button>
                                                                                                            ) : (
                                                                                                                <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0" onClick={() => push(driverInitialState.multiple_documents[0])}  >
                                                                                                                    <i className="fa-regular fa-plus-circle "></i>
                                                                                                                </button>
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                                ))} />
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mt-4 mb-3">
                                                                            <h4 className=" fw600 fz16 text-dark"> Multiple Notes</h4>
                                                                            <div className="overflow-auto mt-3 ">
                                                                                <table className="table  multiple-feilds-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="fw500 fz14" width="30px">#</th>
                                                                                            <th className="fw500 fz14">Notes </th>
                                                                                            <th className="fw500 fz14" width="30px"></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <FieldArray name="notes" render={({ push, remove }) => (
                                                                                            values?.notes && values?.notes.map((val, idx) => (
                                                                                                <tr key={idx}>
                                                                                                    <td className="dynamicText">{idx + 1}</td>
                                                                                                    <td>
                                                                                                        <Field name={`notes[${idx}].notes_content`} render={({ field }) => (
                                                                                                            <>
                                                                                                                <textarea className="form-control px-3" {...field} />
                                                                                                                {/* 
                                            <ErrorMessage name={`notes[${idx}].notes_content`} component="span" className="from-error-span" />
                                            */}
                                                                                                            </>
                                                                                                        )} />
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {idx > 0 ? (
                                                                                                            <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" onClick={() => {
                                                                                                                remove(idx)
                                                                                                                removeDriverNote(val?.notes_id)
                                                                                                            }}
                                                                                                            >
                                                                                                                <i className="fa-regular fa-minus-circle "></i>
                                                                                                            </button>
                                                                                                        ) : (
                                                                                                            <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0" onClick={() => push(driverInitialState.notes[0])} >
                                                                                                                <i className="fa-regular fa-plus-circle "></i>
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                            ))} />
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className='text-center'>
                                                            {totalInvite == '1' && keyClockId == null && <Button type="submit" variant="" className="submitBTN mx-auto mw150 d-block text-white">Join</Button>}

                                                            {inviteStatus !== '0' &&
                                                                <div className="text-end">
                                                                    Already have an acount?
                                                                    <NavLink to="/" >Log In</NavLink>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal   */}

                <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" centered>
                    <Modal.Header closeButton={false}>
                        <Modal.Title> Add Customer Document </Modal.Title>
                        <Button className="btn-close ms-2 " onClick={() => setModalShow(false)} variant=""></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="px-0 table-wrapper">

                            <div className="row">
                                <div className="col-md-6 offset-3">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name" className="col-form-label">Upload  (jpg,png,jpeg,pdf 2 MB Only) </label>
                                            <input type="file" className="form-control" name="document_name" onChange={(e) => uploadImageHandler(e)} />
                                        </div>


                                        <div className="form-group">
                                            <div className="text-center btn-block p-3">
                                                <input type="submit" name="submit" className="btn btn-primary" value="Save" onClick={(e) => saveFile(e)} />
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                </Modal>

                <footer className="login_footer py-3 border-top">
                    <div className="container-fluid d-md-flex justify-content-between text-center text-md-start">
                        <ul className="mb-0">
                            <li><a href="">Terms of Service</a></li>
                            <li><a href="">Contact</a></li>
                            <li><a href="">Support</a></li>
                        </ul>
                        <p className="mb-0"> Copyright © 2023 LEOPOLD TRANSPORT PTY LTD. All rights reserved</p>
                    </div>
                </footer>
            </div>
        </>
    )
}
