import React from "react";
import { NavLink } from "react-router-dom";
const NotFound = () => {
  return (
    <>
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto justify-content-center align-items-center">
          <h1> You are not authorized </h1>
          <NavLink to="/transport/dashboard" className="btn btn-danger mt-3">
            Go to Dashboard
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default NotFound;

 