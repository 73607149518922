const dataTableConfig = {
  scrollable: true,
  responsiveLayout: "scroll",
  paginator: true,
  paginatorTemplate: "CurrentPageReport  FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
  currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
  // rows: 15,
  // rowsPerPageOptions: [15, 30, 50],
  reorderableColumns: true,
  reorderableRows: true,
 
};

export default dataTableConfig;
