import {createSlice} from '@reduxjs/toolkit';

const rolePermissionSlice = createSlice({
    name:'rolePermissionSlice',
    initialState: {},
    reducers:{       
        setRolePermissionInfo(state, {payload}){                 
            return  state = payload;
        }

    }
})

export  const {setRolePermissionInfo} = rolePermissionSlice.actions;
export default rolePermissionSlice.reducer;
 