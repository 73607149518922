
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REACT_APP_API_URL } from '../../config';
const initialState = []

export const companyEditThunk = createAsyncThunk('company-edit', async(formdata,id) => {
    const baseAPIUrl = REACT_APP_API_URL
    const res = await fetch(baseAPIUrl + `company/companyupdate/${id}`,
    {
        method:"PUT",
        headers:{
            "Content-Type":"application/json"
        },
       formdata:JSON.stringify(formdata)
})
    return await res.json(); 
})

const CompanyEditReducer = createSlice({
    name:'company',
    initialState,
    reducers:{
    },
    extraReducers: (builder) => {
            builder
                  .addCase(companyEditThunk.pending, (state, action) => {
                     console.log('pending')
                  })
                  .addCase(companyEditThunk.fulfilled, (state, {payload:{data}}) => {
                    //console.log('fulfilled', data)
                    state.push(data)
                  })
                  .addCase(companyEditThunk.rejected, (state, action) => {
                    console.log('rejected')
                  }) 
    } 
})

export default CompanyEditReducer.reducer