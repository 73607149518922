import {createSlice} from '@reduxjs/toolkit';

let initial = {}
if(localStorage.getItem("transportData") !== 'null'){
    if(localStorage.getItem("transportData") !== 'undefined'){
        initial = JSON.parse(localStorage.getItem("transportData"))
    }
}

const transportloginSlice = createSlice({
    name:'sidebar_toggle',
    initialState:initial,
    reducers:{       
        setTransportLoginDetails(state, action){    
            localStorage.setItem('transportData',JSON.stringify(action.payload))       
            return  state = action.payload;
        }

    }
})

export  const {setTransportLoginDetails} = transportloginSlice.actions;
export default transportloginSlice.reducer;