import React, { useRef, useState, useEffect } from "react";
import dateFormat from "dateformat";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Ripple } from 'primereact/ripple';
import Moment from 'react-moment';
import moment from 'moment'
//import { Dropdown } from 'primereact/dropdown';
//import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { listData, removeData, title, activeInactive } from "../../transport-portal/controllers/timeSheet";
import { Dropdown, Button } from "../../BootstrapComponents";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import FilterOffset from "./components/OffsetRight";
//import RequestEmail from "./components/RequestEmail";   
import dataTableConfig from "../../config/dataTableOptions";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter';
import { getCompanyList } from "../../redux/company/companyAddSlice";
import { useResizeListener } from 'primereact/hooks';
import Loader from "../../features/loader/Loader";
function List() {
  const { transportUserInfo } = useSelector(state => state);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState("0px");
  const reciveLocalData = transportUserInfo.company_id;
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [list, setList] = useState([]);
  const [addPopup, setAddPopup] = useState(false);
  //const [filters , globalFilterValue, onGlobalFilterChange] = useDataTableFilter();
  const [loader, setloader] = useState(false);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [timeSheetList, setCustomers] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState();
  const [getSelectedCompany, setSelectedComapny] = useState([])
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);

  let timesheetListArr = []
  let timesheetArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Timesheet')?.map(val => {
    if (!timesheetListArr.includes(val?.menuData?.menu_name)) {
      timesheetListArr.push(val?.menuData?.menu_name)
    }

    if (!timesheetArr.includes(val?.menuActionData?.action_name)) {
      timesheetArr.push(val?.menuActionData?.action_name)
    }
  })

  if (timesheetListArr.length == 0) {
    navigate('/transport/404');
  }
  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
  });

  useEffect(() => {
    bindWindowResizeListener();

    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    globalFilterValue: "",
    status: "",
    company_id: reciveLocalData,
    filters: {
      'timesheet_id': { value: '', matchMode: 'contains' },
      'first_name': { value: '', matchMode: 'contains' },
      'timesheet_date': { value: '', matchMode: 'contains' },
      'start_time': { value: '', matchMode: 'contains' },
    }
  });
  // Fetching  List Data
  const customerService = listData();

  let loadLazyTimeout = null;
  useEffect(() => {
    loadLazyData();
  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: (lazyParams) }).then(data => {
        if (data?.data !== undefined) {
          setTotalRecords(data.data.data.count);
          setCustomers(data.data.data.rows);

        }
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      //page:event.pageCount,
      pageCount: event.pageCount,
      first: event.first,
      // page:event.page===0 ?1:event.page ===1?2:event.page,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
      sortOrder: lazyParams.sortOrder,
      // sortOrder: event.sortOrder === "desc" ? "asc" : event.sortOrder === "asc" ? "desc":"desc",
    }, []);
  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if (value.length > 3) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
      listData({ lazyEvent: (lazyParams) }).then(data => {
        // if (data?.data !== undefined) {
        setTotalRecords(data?.count);
        setCustomers(data?.rows);

      })

    } else if (value.length === 0) {
      loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });

    }
  }


  const advanceSearch = (value) => {
    //console.log("value.....",value);
    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.companyname, status: value.status });
      // loadLazyData();
      setoffsetFilter(true);
      listData({ lazyEvent: (lazyParams) }).then(data => {

        setTotalRecords(data?.count);
        setCustomers(data?.rows);

      })


    } else {
      setoffsetFilter(false);
      loadLazyData();
      setLazyParams({ ...lazyParams });
    }
  }


  let istyping = false
  const throttleFunction = (func, delay) => {

    clearTimeout(istyping);
    istyping = setTimeout(() => {
      func();
    }, delay);
  }

  // Delete Entry Function Start Here =====
  const deleteEntry = (reciveLocalData, id) => {
    setloader(true);
    removeData(reciveLocalData, id).then(({ type, message }) => {
      let updatedList = list.filter(val => val.time_id !== id);
      setList(updatedList);
      setloader(false);
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

      setTimeout(() => {
        loadLazyData();
        navigate('/transport/timesheet')
      }, 3000)
    })
  }
  const deleteConfirm = (reciveLocalData, time_id) => {
    setIsListUpdated(false)
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(reciveLocalData, time_id)
      }
    });

  }
  // Delete Entry Function end Here =====

  // Active Inactive Entry Function Start Here =====
  const activeInactiveStatus = (status, id) => {
    setloader(true)
    activeInactive(status, id, reciveLocalData).then(({ type, message }) => {
      loadLazyData();
      //setList(updatedList)
      setIsListUpdated(true)
      setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {
    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data.status === '1' ? 'Pending' : 'Approve'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data.status, data.time_id);
      }
    });
  }
  // Active Inactive Entry Function end Here =====


  // Table body Html Entry Function Start Here =====
  const actionBodyTemplate = (rowData) => {
    return (<div className="d-flex gap-2">
      <Link to={`./edit/${rowData.time_id}`} className={`btn fz16 p-0 edit-btn ${timesheetArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`}>
        <i className="fa-light fa-edit"></i>
      </Link>
      <Link to={`./view/${rowData.time_id}`} className={`btn fz16 p-0 edit-btn ${timesheetArr.includes('View') ? '' : 'border-0 shadow-none disabled'}`}>
        <i className="fa-light fa-eye"></i>
      </Link>
      <Button className={`deleteRow btn fz16 p-0 delete-btn ${timesheetArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} variant="" onClick={() => deleteConfirm(reciveLocalData, rowData.time_id)}>
        <i className="fa-light fa-trash"></i>
      </Button>

    </div>);
  };
  const statusBodyTemplate = (rowData) => {
    return <button onClick={(e) => activeInactiveHandler(e, rowData)} icon="pi pi-check" label="Confirm" className={`fw600 border-0 badge py-2 fs-5 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Approved" : "Pending"} <i className="fa-light fa-angle-down"></i></button>;
  };
  const userBodyTemplate = (rowData) => {
    //return <RequestEmail com_id={rowData.driver_id} com_name={rowData.company_name} />;
  };
  const addressBodyTemplate = (rowData) => {
    return <span>{rowData?.drivers.address}  {rowData?.drivers.suburb} , {rowData?.drivers.state} {rowData?.drivers.zipcode} {rowData?.drivers.country}</span>
  }
  const dateBodyTemplate = (rowData) => {
    const now = new Date(rowData?.timesheet_date);
    return dateFormat(now, "dd mmmm, yyyy");

  }
  const startBodyTemplate = (rowData) => {
    let time = rowData.start_time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [rowData.start_time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('   ');

  }

  const endTimeBodyTemplate = (rowData) => {
    let time = rowData.end_time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [rowData.end_time];
    //console.log(rowData);
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('   ');

  }
  // Table body Html Entry Function end Here =====

   const calculateBreakTime =(rowData)=>{

    let brackTime=0;

    const data = rowData.restdetails.reduce((accu , item, index)=>{

      var now = moment(new Date(item.start_time) ); //todays date
     //console.log("this is log....",item.start_time);
      var end = moment(new Date(item.end_time) ); // another date
      const time = item?.start_time;
      const [ h, m ] = time.split(":");
      const ms = new Date().setHours(h,m);
      const date = moment(new Date(ms))

      const time1 = item?.end_time;
      const [ h1, m1 ] = time1.split(":");
      const ms1 = new Date().setHours(h1,m1);
      const date1 = moment(new Date(ms1))
      //console.log("this is log....",date);

      var duration = moment.duration(date1.diff(date));    
      var minutes = duration.asMinutes();
      if( accu + minutes){
        return accu + minutes;
      }else{
        return 0;
      }

    }, 0)

    const hours = Math.floor(data / 60);
      const minutes =  Math.round(data % 60);
      return  hours + ':' + minutes ;  
   }

  // const calculateBreakTime = (rowData) => {
    
  //   console.log("this is log",rowData);
  //   return rowData?.restdetails.map(element => {
      
  //     if (element.start_time != '' && element.end_time != "") {
  //       const start = element.start_time.split(":");
  //       const end = element.end_time.split(":");
  //       var startDate = new Date(0, 0, 0, +start[0], +start[1], 0);
  //       // return "abc";
  //       var endDate = new Date(0, 0, 0, +end[0], +end[1], 0);
  //       var diff = +endDate.getTime() - startDate.getTime();
  //       var hours = +Math.floor(diff / 1000 / 60 / 60);

  //       diff -= hours * 1000 * 60 * 60;
  //       var minutes = Math.floor(diff / 1000 / 60);
  //       console.log(diff, "this is data....", minutes);
  //       //rowData
  //       // If using time pickers with 24 hours format, add the below line get exact hours
  //       if (hours < 0)
  //         hours = hours + 24;

  //       return (hours <= 9 ? "0" : "") + hours + ":"  + minutes;
  //     }
  //     else{
  //       return "---";
  //     }


  //   });

  //   //})

  // }

  const driverFirstNameTemplate = (rowData) => {
    return <span>{rowData?.drivers.first_name}  {rowData?.drivers.last_name} </span>

  }

  const activeInactiveStatusBulk = (status, id) => {
    setLoading(true)
    activeInactive(status, id, reciveLocalData).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoading(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }

  const bulkActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.time_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Reject'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }

  const bulkDeActivate = () => {
    let selectedId = [];
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.time_id);
    })
    setIsListUpdated(false)
    setSelectedCustomers()
    confirmPopup({
      message: `Are you sure you want to 'Approve'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("0", selectedId);
      }
    });
    setSelectedCustomers();
  }

  const DeleteBulk = (id) => {
    setLoading(true)
    removeData(reciveLocalData, id).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCustomers([])
      loadLazyData();
      setLoading(false)
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const bulkDelete = () => {

    let selectedId = [];
    //console.log("this is log....",selectedId);
    selectedCustomers && selectedCustomers.length > 0 && selectedCustomers.map((item, index) => {
      return selectedId.push(item.time_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-building"></i>&nbsp; {title}
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to="./add" className={`bg-green text-white btn fz14 me-3 ${timesheetArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}>
                  <i className="fa-light fa-plus-circle"></i>&nbsp;
                  Add New
                </Link>
                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => throttleFunction(() => onGlobalFilterChange1(event), 1000)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <ul>
                      <li>
                        <Link className={`dropdown-item ${timesheetArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} to="#" onClick={() => bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#" onClick={() => bulkDeActivate()} >
                          <i className="fa-light me-2 fa-check"></i>
                          Approved
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#" onClick={() => bulkActivate()} >
                          <i className="fa-light me-2 fa-ban"></i>
                          Pending
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={timeSheetList} selectionMode="checkbox" lazy dataKey="timesheet_id"
              scrollable=" true" responsiveLayout="scroll"
              {...{ scrollHeight: tableHeight }}
              scrollDirection="both" {...dataTableConfig}
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)}
            >
              <Column field="driver_id" selectionMode="multiple" headerStyle={{ width: "3em" }} ></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "9rem" }}></Column>
              <Column field="timesheet_id" header="TimeSheet Id" sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="first_name" header="Driver Name" body={driverFirstNameTemplate} style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="timesheet_date" header="Date" body={dateBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="start_time" header="Start Time" body={startBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="end_time" header="End Time" body={endTimeBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="address" header="Break Time" body={calculateBreakTime} style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "60px" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
