import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink} from "react-router-dom"; 
 
const SidebarComp = ({ allUserRolePermissionData }) => { 

  const removeDuplicatesByName = (arr) => {
    const uniqueNames = new Set();
    return arr?.filter((item) => {
      if (!uniqueNames.has(item?.menuData?.menu_name)) {
        uniqueNames.add(item?.menuData?.menu_name);
        return true;
      }
      return false;
    });
  };
  
   let uniqueData = removeDuplicatesByName(allUserRolePermissionData); 

 
  return (
    <div className="sidebar">
      <ul  className="h-100">
        {uniqueData && uniqueData.map((item) => (
          <> 
          <li key={item?.menuData?.id}>
            <NavLink to={item?.menuData?.url_path} activeclassname="active">
              <i className={item?.menuData?.icon}></i>&nbsp;
              <span>{item?.menuData?.menu_name}</span>
            </NavLink>
          </li>
          </>
        ))}
      </ul>
    </div>
  );
}; 
export default SidebarComp;
