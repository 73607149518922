import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import { Dropdown, Button } from "../../BootstrapComponents";
// import { FilterOffset } from "../../components/OffsetRight";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import dataTableConfig from "./config/DatatableConfig";
import { Link, useNavigate } from 'react-router-dom'
import { emailLoggerListData } from "./emailLoggerController";
import Loader from "../../features/loader/Loader";
import Moment from 'react-moment';

function EmailLogger() {
    const { customerUserInfo } = useSelector(state => state);

    const toast = useRef(null);
    const navigate = useNavigate();
    const [tableHeight, setTableHeight] = useState("0px");
    const reciveLocalData = customerUserInfo.company_id;
    const relationData = customerUserInfo.relation_id;
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(false);
    const [isListUpdated, setIsListUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [emailLoggerData, setEmailLoggerData] = useState(null);
    const [offsetFilter, setoffsetFilter] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [modalShow, setModalShow] = React.useState(false);
    const [mailContent, setMailContent] = React.useState(false);
    const [mailFrom, setMailFrom] = React.useState();
    const [mailTo, setMailTo] = React.useState();



    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 12,
        page: 1,
        sortField: "",
        sortOrder: false,
        status: "",
        filters: "",
        globalFilterValue: "",
        company_id: reciveLocalData,
        relation_company_id: relationData,
        user_type_id: 2,
        panel_name: 'Customer',
        filters: {
            'subject': { value: '', matchMode: 'contains' },
            'company_name': { value: '', matchMode: 'contains' },
            'user_name': { value: '', matchMode: 'contains' },
            'from_mail': { value: '', matchMode: 'contains' },
            'to_mail': { value: '', matchMode: 'contains' },
            'user_type_id': { value: '', matchMode: 'contains' },
            'content': { value: '', matchMode: 'contains' },
        }
    });

    let loadLazyTimeout = null;
    useEffect(() => {
        loadLazyData();

    }, [lazyParams])
    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            emailLoggerListData({ lazyEvent: (lazyParams) }).then(data => {
                setTotalRecords(data?.data?.data?.count);
                setEmailLoggerData(data?.data?.data?.rows);
                setTableHeight(document?.querySelector(".table-wrapper").scrollHeight - 50 + "px");
                setLoading(false);

            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        const { page } = lazyParams
        setLazyParams({
            ...lazyParams,
            pageCount: event.pageCount,
            first: event.first,
            page: event.page === 0 ? 1 : event.page + 1,
            rows: 12,
            multiSortMeta: event.multiSortMeta,
            filters: event.filters,
            sortField: event.sortField,
            globalFilterValue: "",
            sortOrder: lazyParams.sortOrder,
        }, []);
    }

    const onSort = (event) => {
        setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }
    const onGlobalFilterChange1 = (event) => {
        const value = event.target.value;
        if (value.length > 2) {
            setLazyParams({ ...lazyParams, globalFilterValue: value });
        } else if (value.length === 0) {
            setLazyParams({ ...lazyParams, globalFilterValue: "" });
        }
    }

    const advanceSearch = (value) => {
        if (value) {
            setLazyParams({ ...lazyParams, globalFilterValue: value.document_type });
            setoffsetFilter(true);

        } else {
            setoffsetFilter(false);
            setLazyParams({ ...lazyParams });
        }
    }

    const getCompanyNameTemplate = (rowData) => {

        return (rowData.user_type_id == 2) ? rowData?.customer_details?.customer_company_name : rowData?.companyData?.company_name
    }

    const getPanelNameTemplate = (rowData) => {
        return rowData?.userTypeData?.user_type_name
    }

    const getContentTemplate = (rowData) => (
        <button className="btn view-btn" onClick={(e) => showModel(rowData?.content, rowData?.from_mail, rowData?.to_mail)}><i className="fa-light fa-eye" aria-hidden="true"></i>
        </button>
    )


    const showModel = (content, from, to) => {
        setMailContent(content)
        setMailFrom(from)
        setMailTo(to)
        setModalShow(true)
        setLoader(false)
    }

    function closeModal() {
        setModalShow(false);
    }

    const createdDateTemplate = (rowData) => {
        return <span>{rowData?.createdAt !== '' ? <Moment date={rowData?.createdAt} format="DD-MM-YYYY HH:mm:ss" /> : ''}</span>
    }

    return (
        <>
            <Loader show={loader} />
            <Toast ref={toast} position="top-center" />

            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
                    <div className="body-content-top">
                        <div className="d-sm-flex align-items-center flex-wrap">
                            <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0"><i className="fa-light fa-briefcase"></i>&nbsp; Email Log </h2>
                            </div>
                            <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">

                                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                                    <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                                    <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                                        <i className="fa-light fa-search"></i>
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="table-wrapper flex-grow-1">

                        <DataTable value={emailLoggerData} lazy selectionMode="checkbox" dataKey="document_type" scrollDirection="both"
                            paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
                            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                            onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                            selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)} {...dataTableConfig}
                        >
                            <Column resizeable style={{ minWidth: 100 }}  field='content' header="Action" body={getContentTemplate} />
                            <Column resizeable style={{ minWidth: 250 }} sortable field="createdAt" header="Email Date" body={createdDateTemplate} />
                            <Column resizeable style={{ minWidth: 250 }} sortable field='to_mail' header="Email To" />
                            <Column resizeable style={{ minWidth: 250 }} sortable field='subject' header="Subject" />
                            <Column resizeable style={{ minWidth: 150 }} sortable field='user_type_id' header="Area" body={getPanelNameTemplate} />
                            <Column resizeable style={{ minWidth: 250 }} sortable field='company_id' header="Company Name" body={getCompanyNameTemplate} />
                            
                        </DataTable>
                    </div>

                </div>
            </div>



            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Email Log Content</Modal.Title>
                    <Button variant="" className="btn btn-gradient ms-auto" onClick={closeModal}>
                        <Button className="btn-close ms-auto " onClick={() => setModalShow(false)} variant=""></Button>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-0 table-wrapper">
                        <p><strong>From</strong>: {mailFrom}</p>
                        <p><strong>To</strong>: {mailTo}</p>
                        <span dangerouslySetInnerHTML={{ __html: JSON.parse(mailContent) }} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EmailLogger

