import * as yup from 'yup'

const initialState={
        truck_type: "",
        truck_detail: "",      
      
}
const initialFiterState ={
    truckType:"",
    status:""
}
const TruckValidation = yup.object().shape({
    truck_type:yup.string().required('This Field is required'),
    truck_detail:yup.string().required('This Field is required'),
  
})

const TruckFilterValidation = yup.object().shape({
    //truckType:yup.string().required('This Field is required'),
   // status:yup.string().required('This Field is required'),
  
})

export {TruckValidation,initialState,initialFiterState,TruckFilterValidation};