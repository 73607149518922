import React, { useRef, useState, useEffect } from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { listData } from './controllers';
import { Dropdown, Button } from "../../BootstrapComponents";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import dataTableConfig from "../../config/dataTableOptions";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter';
import { listData1 } from '../../actions/CompanyAction';
import { getCompanyList } from "../../redux/company/companyAddSlice";
import Loader from "../../features/loader/Loader";
import { fetchData } from "../../store/CompanySlice";
import { useResizeListener } from 'primereact/hooks';
function List() {

  //const  {status, entries} = useSelector(state => state.company)

  const toast = useRef(null);
  const [tableHeight, setTableHeight] = useState("0px");
  const [list, setList] = useState([]);
  const [loader, setloader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState();

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    globalFilterValue: "",
    status: "",
    filters: {
      'id': { value: '', matchMode: 'contains' },
      'role_name': { value: '', matchMode: 'contains' } 
    }
  });


  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: (lazyParams) }).then(data => { 
        setTotalRecords(data.data?.data.count);
        setCustomers(data.data?.data.rows);
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);


  }

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
});

useEffect(() => {
  bindWindowResizeListener();

  return () => {
      unbindWindowResizeListener();
  };
}, [bindWindowResizeListener, unbindWindowResizeListener]);


  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
    }, []);

  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if (value.length > 3) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
      //loadLazyData();
    } else if (value.length === 0) {
      loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });

    }
  }


  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2">
          <Link to={"edit/" + rowData?.id+ "/" +  rowData?.user_type_id} className="btn fz16 p-0 edit-btn">
            <i className="fa-light fa-edit"></i>
          </Link> 
        </div>
      </>
    );
  };

   

  const statusBodyTemplate = (rowData) => {
    return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Active" : "Inactive"}</span>;
  };

  const addSerialNumber = (rowData, index) => {
     return index.rowIndex + 1;
  };

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-building"></i>&nbsp; Role & Permissions
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to="./add" className="bg-green text-white btn fz14 me-3">
                  <i className="fa-light fa-plus-circle"></i>&nbsp; 
                  Add New
                </Link>

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>


              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={customers} selectionMode="checkbox" lazy dataKey="id"
              scrollable=" true" responsiveLayout="scroll"
              {...{ scrollHeight: tableHeight }}
              scrollDirection="both"
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedCompany} onSelectionChange={e => setSelectedCompany(e.value)}
              {...dataTableConfig}
            >
              <Column field="serialNumber" header="S.No." style={{ flexGrow: 1, flexBasis: "120px" }} body={addSerialNumber}></Column> 
              <Column field="role_name" header="Role Name" sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="userTypeData.user_type_name" header="Panel Name" sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="status" header="Status"  body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }} frozen></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
