import React, { useState,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Table } from "../../BootstrapComponents";
import {singleDataManifest} from "../controllers/ConsignmentController";
//import View from "./List";
import List from "./List";



function View(props) {
    const {customerUserInfo} = useSelector(state => state);
    const [initialState, setInitialState] = useState({});
    const [loader, setloader] = useState();
    const toast = useRef(null);
    const reciveLocalData= customerUserInfo.company_id;
    const [printData, setprintData]= useState(false);
    const [palletsData,setpalletsData]=useState();
    const [spacesData,setspacesData]=useState();
    const [dispatchDate,setdispatchDate]=useState()

    const {id} = useParams();
    useEffect(()=>{
        if(id){
            const response= singleDataManifest(id,reciveLocalData);
            response.then(({status,message,type,error, data})=> {           
                if(status){        
                  setInitialState({
                    ...data.data
                  })       
                  setloader(false)
                  qlityTemplate(data.data)
                  dateBodyTemplate(data.data)
                  
                }else{
                  toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});    
                  setloader(false)    
                }      
              })
        }
           
    },[id])
    

    const qlityTemplate = (rowData) => {
        const pallets = rowData.manifest_detail.reduce((acc , curre)=> {
          return Number(acc) + Number(curre.pallets)
        }, 0);   
        const spaces = rowData.manifest_detail.reduce((acc , curre)=> {
          return Number(acc) + Number(curre.spaces)
        }, 0);
       setpalletsData(pallets);
       setspacesData(spaces);
    return palletsData
      };

      const dateBodyTemplate = (rowData) => {
        const now = new Date(rowData?.dispatch_date);
       // console.log("this is log.....", dateFormat(now, "dd mmmm, yyyy"));
        setdispatchDate(dateFormat(now, "dd mmmm, yyyy"))
      }
    const downloadPdf=()=>{
        const input = document.getElementById("downloadPdf");
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.setFontSize(30); 
                pdf.addImage(imgData, "PNG", 15, 30, 180, 200); 
                pdf.save(`downloaded.pdf`); 
            })
    }
    return (
        <>
            <div className="page-wrapper-without-aisde">
                <div className="body-content-wrap overflow-auto flex-grow-1  px-5">
                    <div id="editor"></div>

                    <div className="d-flex justify-content-end gap-3 my-5">
                        <Link to="../" className="btn btn-gradient"><i className="fa-light fa-times"></i> Cancel</Link>
                        <button className="btn bg-green text-white" id="exportForm"  onClick={()=>{downloadPdf()}}><i className="fa-light fa-print"></i> Print</button>
                        <button className="btn bg-green text-white" data-bs-target="#sendMail" data-bs-toggle="modal"><i className="fa-light fa-envelope"></i> Send To Email</button>
                    </div>

                    <div className="invoice-wrapper shadow bg-white mt-0 mb-5 py-5" > 
                        <span  id="downloadPdf">
                        <div id="section-to-print">
                            <div className="mb-5 fz26 fw600">
                                Manifest
                            </div>
                            <div className="row pb-lg-5 mt-4">
                                <div className="col-12">
                                    <div className="from-invoice">
                                        <div className="fw500">
                                            <div>LTTECH</div>
                                            {/* <div>10 WESTGATE DRIVE</div>
                                            <div>LAVERTON NORTH, VIC 3026</div> */}
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Table className="table table-bordered">
                                            <tbody><tr>
                                                <th>Phone:</th>
                                                <td>{initialState?.phone}</td>
                                                <th rowSpan="3" className="align-middle">Receiver Signature:</th>
                                            </tr>
                                                <tr>
                                                    <th>Carrier:</th>
                                                    <td>{initialState?.carrier} </td>
                                                </tr>
                                                <tr>
                                                    <th>DISPATCH DATE:</th>
                                                    <td> {dispatchDate} </td>

                                                </tr>
                                                <tr>
                                                    <th>MANIFEST NO:</th>
                                                    <td>{initialState?.manifest_number}</td>
                                                    <th rowSpan="3" className="align-middle">Date Received:</th>
                                                </tr>
                                                <tr>
                                                    <th>QTY:</th>
                                                    <td>{palletsData}Pallets/ {spacesData} spaces</td>
                                                </tr>
                                                <tr>
                                                    <th>PALLET DKT: </th>
                                                    <td>{initialState?.pallet_dkt}</td>

                                                </tr>
                                            </tbody></Table>
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-auto">
                                <Table className="table mt-5 table-bordered">
                                    <thead>
                                        <tr><th>Consignment No</th>
                                            {/* <th>Sender Ref</th> */}
                                            <th>From Company Name</th>
                                            <th>To Company Name</th>
                                            <th>To Address</th>
                                            <th>To City</th>
                                            <th>To Postcode</th>
                                            <th>Total Pallets</th>
                                            <th>Pallet Spaces</th>
                                            <th>Weight (KG)</th>
                                            <th>Job Temp</th>
                                        </tr></thead>
                                    <tbody>
                                        
        
                                          
                                            { initialState &&
                                        initialState.manifest_detail?.length > 0 &&
                                         initialState.manifest_detail.map((data,index)=>
                                        { 
                                          return(  <tr>
                                            <td> {data.consignment_number}</td>
                                            {/* <td>{data.sender_ref}</td> */}
                                            <td>{data.from_company_name}</td>
                                            <td>{data.to_company_name}</td>
                                            <td>{data.address}</td>
                                            <td>{data.city}</td>
                                            <td>{data.postcode}</td>
                                            <td>{data.pallets}</td>
                                            <td>{data.spaces}</td>
                                            <td>{data.weight}</td>
                                            <td>{data.job_temp}</td>
                                             </tr>)

                                        })}
                                     
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        </span>
                        {printData===true &&
            <List
            {...props}
            downloadPdf={downloadPdf}
            />}
          
                    </div>

                </div>


            </div>
        </>
    );
}

export default View;
