import {REACT_APP_API_URL} from '../../config'
import {appAxios} from './common/AxiosFile';

    export const title = "Set-Up"
   // transportsetup/transportsetlist/ba4dd0ed-5bf3-4087-824a-a1cf3493a4a9
   export async function listData(params){
       
    try {
        const {rows,page,sortOrder,sortField,globalFilterValue,company_id}=params?.lazyEvent;
         
        const res = await appAxios().get(`${REACT_APP_API_URL}transportsetup/transportsetlist/${company_id}?keyword=${globalFilterValue}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);

        //const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getAllConsignments/${company_id}?keyword=${globalFilterValue}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);   


        const result = await res.data;  
        if(result.data.count){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: res.data,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}

export async function getTimesheetSingleData(id,reciveLocalData){        
    try {
        const res = await appAxios().get(`${REACT_APP_API_URL}checklist/checklist-vehicle-popup/${reciveLocalData}/${id}`);  
        const result = await res.data;               
        if(result.status){   
            return {
                status: 1,
                message:"Data Successfully fetched.",                    
                type:'success',
                error:null,
                data: res.data
            }                  
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {     
       
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}



export async function emailLoggerListData(params){
       
    try {
        const {rows,page,sortOrder,sortField,globalFilterValue,company_id,user_type_id,panel_name}=params?.lazyEvent; 

        const res = await appAxios().get(`${REACT_APP_API_URL}emailLogger/loggerList/${company_id}?keyword=${globalFilterValue}&panel_name=${panel_name}&user_type_id=${user_type_id}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);  

        const result = await res.data;  
        if(result.data.count){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null, 
                data: result,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}

    // export async function listData(){
    //     try {
    //         const res = await appAxios().get(`${REACT_APP_API_URL}consignments/getAllConsignments`);
    //         const result = await res.data;  
    //         if(result.data.count){   
    //             return {
    //                 status: 1,
    //                 message:"List Successfully fetched.",                    
    //                 type:'success',
    //                 error:null,
    //                 data: res.data
    //             }                  
    //         }else{
    //             return {
    //                 status: 0,
    //                 message:"Data not found!",
    //                 type:'info',
    //                 error:null,
    //             }
    //         } 
    //     } catch (error) {           
    //         return {
    //             status: 0,
    //             message:error.message,                
    //             type:'info',
    //             error:error
    //         }     
    //     }
            
    // }

    export async function singleData(id,company_id){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}transportsetup/edittransportsetup/${company_id}/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }
    export async function addData(data){
        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}transportsetup/addtransportsetup`, data);
            const result = await res.data;   
                 
            if(result.status){                  
                if(result.data.success){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

   

    export async function editData(data,company_id,id){
    //    console.log("company_idcompany_idcompany_id",company_id);
        try {
             const res = await appAxios().put(`${REACT_APP_API_URL}transportsetup/transportsetupupdate/${company_id}/${id}`, data);
             const result = await res.data;                      
             if(result.status){          
                 return {                      
                     status: result.status,
                     message:"Data Successfully Updated.",
                     type:'success',
                     error:null,                       
                     data:result.data.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }
     export async function removeData(id, companyId){  
      
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}transportsetup/transportsetupdelete/${companyId}`,{id:[id]});         
            const result = await res.id;                       
            if(result.status){        
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null, 
                    id:[id]                                                             
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }


    //  Document Type
    export async function documentTypeListData(params){
       
        try {
            const {rows,page,desc,filters,sortOrder,sortField,status,globalFilterValue,company_id}=params?.lazyEvent;
          
            const res = await appAxios().get(`${REACT_APP_API_URL}documentType/allDocumentTypes/${company_id}?keyword=${globalFilterValue}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);

            const result = await res.data;   

            if(result.data.length){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result,                  
                } 
                               
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function addDocumentTypeData(data){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}documentType/addDocumentType`, data);
            const result = await res.data;    
            if(result.status){                 
                if(result.status){
                    return {                      
                        status: result.status,
                        message:result.data,
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: false,
                        message:result.data,
                        type:'info',
                        error:null,
                        data:result.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function documentTypeByIdData(id){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}documentType/editDocumentType/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function documentTypeUpdateData(data,id){
        try {
             const res = await appAxios().put(`${REACT_APP_API_URL}documentType/updateDocumentType/${id}`, data);
             const result = await res.data;                      
             if(result.status){          
                 return {                      
                     status: result.status,
                     message:"Data Successfully Updated.",
                     type:'success',
                     error:null,                       
                     data:result.data.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }

     export async function documentTypeDeleteData(id){      
       
        try {
            const res = await appAxios().put(`${REACT_APP_API_URL}documentType/deleteDocumentType`,{id:[id]});         
            const result = await res.data;  
            
            if(result.status){        
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null, 
                    id:[id]                                                             
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }


    export async function activeInactive(status,id){
        try {
          const res = await appAxios().put(`${REACT_APP_API_URL}documentType/changeStatus/${id}/${status === '1'? 0:1}`);
          const result = await res.data;   
  
          if(result.status){   
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:"Data not found!",         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.message,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }


      
export async function activeInactiveBulk(status,ids,companyId){ 
    
    try {
      const res = await appAxios().put(`${REACT_APP_API_URL}documentType/bulkChangeStatus/${companyId}`,{document_id:ids,status});
      const result = await res.data;                
      if(result.status){
     
          return {               
              type:'success',
              error:null,                                    
              status: 1,
              message:res.data.data.msg,
              data: res.data
          }                  
      }else{
          return {                      
              status: 0,
              message:res.data.data.msg,         
              type:'info',
              error:null,                                         
          };   
      } 
    } catch (error) {
          return {                      
              status: 0,
              message:error.msg,         
              type:'info',
              error:error,                                         
          };   
    }
  }

  export async function removeDataBulk(ids, companyId){      
    try {
        const res = await appAxios().put(`${REACT_APP_API_URL}documentType/bulkDelete/${companyId}`,{document_id:ids});
        const result = await res.data;                        
        if(result.status){   
            return {
                status: result.status,
                message:"Data Successfully Deleted.",
                type:'success',
                error:null,                                                              
            }                  
        }else{
            return {
                status: 0,                    
                message:"Data not found!" ,
                type:'info',   
                error:null                                    
            }
        } 
    } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,                                         
        };   
    }
}

// Vehicle Bulk Delete 

export async function vehicleBulkDelete(ids, companyId){      
    try {
        const res = await appAxios().put(`${REACT_APP_API_URL}docManager/vehicleBulkDelete/${companyId}`,{truck_setup_id:ids});
        const result = await res.data;                        
        if(result.status){   
            return {
                status: result.status,
                message:"Data Successfully Deleted.",
                type:'success',
                error:null,                                                              
            }                  
        }else{
            return {
                status: 0,                    
                message:"Data not found!" ,
                type:'info',   
                error:null                                    
            }
        } 
    } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,                                         
        };   
    }
}

export async function documentTypeDropDawnData(company_id){
       
    try {
        // const res = await appAxios().get(`${REACT_APP_API_URL}documentType/allDocumentTypes/${company_id}`);
        const res = await appAxios().get(`${REACT_APP_API_URL}common/get-doc-types/${company_id}`); 

        const result = await res.data;   

        if(result.data.length){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: result,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}

export async function addFaultReportData(data){
  
    try {
        const res = await appAxios().post(`${REACT_APP_API_URL}settings/add`, data);
        const result = await res.data;    
        if(result.status){                 
            if(result.status){
                return {                      
                    status: result.status,
                    message:"Data Successfully Added.",
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };     
            }else{
                return {                      
                    status: false,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                    data:result.data,                                           
                };                         
            }                    
        }else{
            return {                      
                status: 0,
                message:'Something went wrong.',
                type:'info',
                error:null,                                           
            };                    
        } 
    } catch (error) {                
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,                                           
        };                  
    }         
}

export async function faultReportByIdData(id){      
    try {
        const res = await appAxios().get(`${REACT_APP_API_URL}settings/edit/${id}`);  
        const result = await res.data;               
        if(result.status){   
            return {
                status: 1,
                message:"Data Successfully fetched.",                    
                type:'success',
                error:null,
                data: res.data
            }                  
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {     
       
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}

export async function faultreportUpdateData(data,id){
   
    try {
         const res = await appAxios().put(`${REACT_APP_API_URL}settings/update/${id}`, data);
         const result = await res.data;                      
         if(result.status){          
             return {                      
                 status: result.status,
                 message:"Data Successfully Updated.",
                 type:'success',
                 error:null,                       
                 data:result.data.data,
             };               
         }else{
             return {
                 status:0,
                 message:"Data not found!",
                 type:'info',
                 error:null,                       
                 data:null,                    
             }
         } 
    } catch (error) {       
         return {                      
             status: 0,
             message:error.message,         
             type:'info',
             error:error,                                         
         };   
    }
 }

 export async function faultReportListData(params){
    try {
        const {rows,page,desc,filters,sortOrder,sortField,globalFilterValue,company_id}=params?.lazyEvent;
      
        const res = await appAxios().get(`${REACT_APP_API_URL}settings/list/${company_id}?keyword=${globalFilterValue}&sortkey=${sortField}&sortvalue=&page=${page}&size=${rows}`);

        const result = await res.data;   
        // console.log("resultresult>>>>>>>>>>",result.data);
        if(result.data.rows.length>0){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: result.data.rows,                  
            } 
                           
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}

export async function vehicleCheckList(params){
    try {
         let {rows,page,desc,filters,sortOrder,sortField,globalFilterValue,vehicleId,company_id}=JSON.parse(params?.lazyEvent)   

        const res = await appAxios().get(`${REACT_APP_API_URL}checklist/all-checklist-vehicle/${company_id}/${vehicleId}?keyword=${globalFilterValue}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);   
       
        const result = await res.data;  
        if(result.data.count){   
            return {
                status: 1,
                message:"List Successfully fetched.",                    
                type:'success',
                error:null,
                data: result
            }                  
        }else{
            return {
                status: 0,
                message:"Data not found!",
                type:'info',
                error:null,
            }
        } 
    } catch (error) {           
        return {
            status: 0,
            message:error.message,                
            type:'info',
            error:error
        }     
    }
        
}