import {REACT_APP_API_URL} from '../config'
import axios from './common/AxiosFile';

 export const title = "Driver Management"

    export async function listData(params){
       
        try {
            const {rows,page,sortOrder,sortField,globalFilterValue,status,company_id,last_name}=params?.lazyEvent;    
            
            const res = await axios.get(`${REACT_APP_API_URL}transport/driverlist/${company_id}?keyword=${globalFilterValue}&last_name=${last_name}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
            const result = await res.data;  
            if(result.data.count){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data,                  
                } 
                               
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function singleData(id){        
        try {
            const res = await axios.get(`${REACT_APP_API_URL}transport/editdriver/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function addData(data, header){ 
        try {

            const res = await axios.post(`${REACT_APP_API_URL}transport/adddriver`, data, header);
            const result = await res.data; 

            if(result?.status){                  
                if(result?.data?.success){ 
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
                return {                      
                    status: 0,
                    message:'Something went wrong.',
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    

    
    export async function activeInactive(status,id){
      try {
        const res = await axios.put(`${REACT_APP_API_URL}transport/companydriverstatusupdate/${id}/${status === '1'? 0:1}`);
        const result = await res.data;                
        if(result.status){   
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:"Data not found!",         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
      }
    }

    export async function removeDriverDocument(id){        
        try {
            const res = await axios.delete(`${REACT_APP_API_URL}transport/driverdocdelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!",
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function removeDriverNote(id){        
        try {
            const res = await axios.delete(`${REACT_APP_API_URL}transport/drivernotesdelete/${id}`);
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!",
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function toValidateEmailIdExistOrNot(emailId){
        try {
             const res = await axios.get(`${REACT_APP_API_URL}common/getEmailExistOrNot/${emailId}`); 
             const result = await res?.data;   
             if(result?.status){        
                 return {                      
                     status: result.status,
                     message: result?.data,
                     type:'success',
                     error:null,                       
                     data:result?.data,
                 };               
             }else{
                 return {
                     status:0,
                     message:"Data not found!",
                     type:'info',
                     error:null,                       
                     data:null,                    
                 }
             } 
        } catch (error) {       
             return {                      
                 status: 0,
                 message:error.message,         
                 type:'info',
                 error:error,                                         
             };   
        }
     }

     // Active Driver

    
    export async function activeInactiveBulk(status,ids, companyId){ 
        try {
          const res = await axios.put(`${REACT_APP_API_URL}transport/driverBulkChangeStatus/${companyId}`,{driver_id:ids,status});
          const result = await res.data;                
          if(result.status){
         
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:res.data.data.msg,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:res.data.data.msg,         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {
              return {                      
                  status: 0,
                  message:error.msg,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }
    
      export async function removeDataBulk(ids,companyId ){      
        try {
            const res = await axios.put(`${REACT_APP_API_URL}transport/driverBulkDelete/${companyId}`,{driver_id:ids});
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }


    export async function addUserRegistrationInvite(data) { 
       
        try {
            const res = await axios.post(`${REACT_APP_API_URL}company/useracceptrequest`, data);
            const result = await res.data;    
            
            if (result.status) {
                return {
                    status: result.status,
                    message: "Registration Successfully.",
                    type: 'success',
                    error: null,
                    data: result.data.data,
                };
            } else {
                
                return {
                    status: 0,
                    message: "Registration Successfully.",
                    type: 'info',
                    error: null,
                    data: null,
                }
            } 
        } catch (error) {
            return {
                status: 0,
                message: error.message,
                type: 'info',
                error: error,
            };
        }
    
    }
    export async function ChangePasswordDataAPI(data) {
        try {
            const res = await axios.post(`${REACT_APP_API_URL}company/updatepassword`, data);
            const result = await res.data; 
             
            if (result.status) {
                return {
                    status: result.status,
                    message: "Password updated Successfully.",
                    type: 'success',
                    error: null,
                    data: result?.data,
                };
            } else {
                return {
                    status: 0,
                    message: "Something went worg !!",
                    type: 'info',
                    error: null,
                    data: null,
                }
            }
        } catch (error) {
            return {
                status: 0,
                message: error.message,
                type: 'info',
                error: error,
            };
        }
    }
      
    export async function ChangePasswordKeyVarification(data) {
        try {
            const res = await axios.post(`${REACT_APP_API_URL}company/restkeyverification`, {key:data});
            const result = await res.data; 
             
            if (result.status) {
                return {
                    status: result.status,
                    message: "Varified",
                    type: 'success',
                    error: null,
                    data: result?.data,
                };
            } else {
                return {
                    status: 0,
                    message: "Invalied",
                    type: 'info',
                    error: null,
                    data: null,
                }
            }
        } catch (error) {
            return {
                status: 0,
                message: error.message,
                type: 'info',
                error: error,
            };
        }
    }


