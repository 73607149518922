import { REACT_APP_API_URL } from '../../config'
import axios from './common/AxiosFile';

export const title = "Driver Management"

export async function listData(params) {

    try {
        const { rows, page, desc, filters, sortOrder, sortField, globalFilterValue, status } = params?.lazyEvent;

        let modifiedSortField = sortField
        if(sortField === 'userTypeData.user_type_name'){
            modifiedSortField = 'user_type_id'
        }

        const res = await axios.get(`${REACT_APP_API_URL}permissions/getAllRoles?keyword=${globalFilterValue}&status=${status}&sortkey=${modifiedSortField}&sortvalue=${sortOrder === false ? "desc" : "asc"}&page=${page}&size=${rows}`);

        const result = await res.data;
        if (result.data.count) {
            return {
                status: 1,
                message: "List Successfully fetched.",
                type: 'success',
                error: null,
                data: res.data,
            }

        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}

export async function getAllRoleData() {

    try {

        const res = await axios.get(`${REACT_APP_API_URL}permissions/getRoles`);
        const result = await res.data;
        if (result) {
            return {
                status: 1,
                message: "List Successfully fetched.",
                type: 'success',
                error: null,
                data: res?.data?.data,
            }

        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}

export async function getAllMenuActionData() {

    try {

        const res = await axios.get(`${REACT_APP_API_URL}permissions/getAllMenuAction`);
        const result = await res.data;
        if (result) {
            return {
                status: 1,
                message: "List Successfully fetched.",
                type: 'success',
                error: null,
                data: res?.data?.data,
            }

        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}

export async function addData(data, header) {
    try {

        const res = await axios.post(`${REACT_APP_API_URL}permissions/addRolePermission`, data, header);
        const result = await res.data;

        if (result?.status) {
            return {
                status: true,
                message: result?.data,
                type: 'success',
                error: null,
                data: result?.data,
            };
        } else {
            return {
                status: 0,
                message: 'Something went wrong.',
                type: 'info',
                error: null,
            };
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}

export async function singleData(id) {  
     
    try {
        const res = await axios.get(`${REACT_APP_API_URL}permissions/getRolePermissionById/${id}`);
        const result = await res.data;  
         
        if (result.status) {
            return {
                status: 1,
                message: "Data Successfully fetched.",
                type: 'success',
                error: null,
                data: res.data
            }
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            }
        }
    } catch (error) {

        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}

export async function updateData(data, id) {
    try {
        const res = await axios.put(`${REACT_APP_API_URL}permissions/updateRolePermission/${id}`, data);
        const result = await res.data;
        if (result.status) {
            return {
                status: result.status,
                message: "Data Successfully Updated.",
                type: 'success',
                error: null,
                data: result.data.data,
            };
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
                data: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}

export async function checkRoleExist(data) {
    try {
        const res = await axios.post(`${REACT_APP_API_URL}permissions/role_existcheck`, data);
        const result = await res.data;
       
        if (result.status) {
            return {
                status: 1,
                message: result?.data,
                type: 'success',
                error: null,
                data: result.data
            }
        } else {
            return {
                status: 0,
                message: result?.data,
                type: 'info',
                error: null,
            }
        }
    } catch (error) {

        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}

export async function getRoleById(id) {
    try {
        const res = await axios.get(`${REACT_APP_API_URL}permissions/getrolebyid/${id}`);
        const result = await res.data;
        if (result.status) {
            return {
                status: 1,
                message: result?.data,
                type: 'success',
                error: null,
                data: result.data
            }
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            }
        }
    } catch (error) {

        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}




