import React, { useRef, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useParams } from 'react-router-dom';
import { Toast } from "../PrimeReactComponents";
import { rowCount } from './controllers/common/Query'
import { useSelector } from 'react-redux';
import { REACT_APP_BASE_BACKEND_URL } from '../config';
import Loader from "../features/loader/Loader";
let BASE_BACKEND_URL = REACT_APP_BASE_BACKEND_URL

function Dashboard() {
  const userInfoSelector = useSelector(state => state.userInfo);
  const toast = useRef(null);
  const [companyCount, setCompanyCount] = React.useState(0)
  const [loader, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    rowCount('company/companylist').then(({ status, message, count }) => {
      setCompanyCount(count)
      setLoading(false);
    }).catch((err) => {
      // toast?.current.show({ severity: 'info', summary: 'Info Message', detail: err.message, life: 3000, });
    })
  }, [])


  return (
    <>
     <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto">
          <div className="d-flex container align-items-center py-5 pb-2">
            <div className="card w-100 bg-transparent shadow-none">
              <div className="card-body d-flex align-items-center">
              <figure style={{ width: "100px" , height:100}} className="rounded-circle overflow-hidden">
                {userInfoSelector?.resultData?.userData?.user_image && <img src={`${BASE_BACKEND_URL}${userInfoSelector?.resultData?.userData?.user_image}`} style={{"object-fit": "cover", "width": "100%", "height": "100%"}} alt="" />}
                </figure>
                <div className="ms-4">
                  <h2 className="fz40">Welcome {userInfoSelector?.resultData?.userData?.first_name} {userInfoSelector?.resultData?.userData?.last_name}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex-grow-1  p-4 p-lg-5 dashboard_Page pt-lg-0">
            <div className="container d-flex flex-column h-100 ">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row g-5">
                    <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Company Management</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../companies">
                                    <i className="fa-light me-2 fa-eye"></i> View Company List
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="../companies/add">
                                    <i className="fa-light me-2 fa-plus"></i> Add New Company
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-danger m-auto">
                          <h5 className="fw-bold  mb-0">{companyCount?companyCount:0}</h5>
                          <p className="fw500 mb-0">Total Company</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Consignments</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../consignments">
                                    <i className="fa-light me-2 fa-eye"></i> View Consignment List
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-success m-auto">
                          <h5 className="fw-bold  mb-0">10</h5>
                          <p className="fw500 mb-0">Total Active Consignment</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Users</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../user">
                                    <i className="fa-light me-2 fa-eye"></i> View Users List
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="../user/add">
                                    <i className="fa-light me-2 fa-plus"></i> Add New User
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-info m-auto">
                          <h5 className="fw-bold  mb-0">950</h5>
                          <p className="fw500 mb-0">Total Active Users</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Drivers</h4>
                          <Dropdown>
                            <Dropdown.Toggle type="button" className="btn fz14 shadow-none px-0 dropdown-icon-none" variant="">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="../drivers">
                                    <i className="fa-light me-2 fa-eye"></i> View Drivers List
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="../drivers/add">
                                    <i className="fa-light me-2 fa-plus"></i> Add New Driver
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="text-center text-warning m-auto">
                          <h5 className="fw-bold  mb-0">350</h5>
                          <p className="fw500 mb-0">Total Active Drivers</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="card p-4 shadow h-100 justify-content-between">
                        <div className="title-dropdown d-flex justify-content-between align-items-center px-3">
                          <h4 className="mb-0">Invoice</h4>
                        </div>
                        <div className="text-center text-dark m-auto">
                          <h5 className="fw-bold  mb-0">200</h5>
                          <p className="fw500 mb-0">Total Active Invoicing</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
