import React, { useRef, useState, useEffect, useCallback } from "react";
import {  useNavigate, } from "react-router-dom";
import * as yup from 'yup';
import { InputText, Dropdown as Select, confirmDialog, Toast, ConfirmDialog } from "../PrimeReactComponents";
import { Formik, Form, Field,  ErrorMessage } from "formik";
import { Button } from "../BootstrapComponents";
import { useDispatch, useSelector } from 'react-redux';
import { ChangePasswordDataAPI,ChangePasswordKeyVarification } from "./controllers";
import Loader from "../features/loader/Loader";

import Logo1 from '../assets/img/logo-black.png'

const PlatformChangePasswordInitialState = { 
     email:"",     
     newpassword: '',
     confirmpassword: '',
     key:""
};

const PlatformChangePasswordValidateSchema = yup.object().shape({  
  newpassword: yup.string().required('Please Enter your password').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,12})/,
    "Must Contain 6 Characters and Maximum 12 Characters, One Uppercase, One Lowercase, One Number and One Special Character"),
    confirmpassword: yup.string().oneOf([yup.ref('newpassword'), null], 'Passwords must match').required('Confirm Password is required'),
})
const ChangePasswordMail = () => {
  const { platformUserInfo } = useSelector(state => state);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loader, setLoader] = useState(); 
  const [initialState, setInitState] = useState(PlatformChangePasswordInitialState)
  const [userEmailId, setUserEmailId] = useState()
  const [inviteStatus, setInviteStatus] = useState();
  const [acceptanceDate, setAcceptanceDate] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const dispatch = useDispatch();

  const companyId = platformUserInfo?.company_id;
  const userId = platformUserInfo?.user_id;
  let reciveLocation= window.location.href;
  let keyadata= reciveLocation.split("=").pop()
 let varificationData 
  const getVarficationToken = async () => {
    varificationData = await ChangePasswordKeyVarification(keyadata);  
    if(varificationData.status===0){
      navigate("/linkexparied");
      return;
    }

}
//transModiFyedId = transId.split("=").pop()
useEffect(() => {
  getVarficationToken();
}, [])


  const changePlatformPasswordSubmit = async (values, { resetForm }) => {
    let modifyData = {...values, email:varificationData.data,key:keyadata} 
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoader(true)
          ChangePasswordDataAPI(modifyData).then(({ status, type, message }) => {
               if (status) {
                   setLoader(false)
                   resetForm({ all_values: '' })
                   toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, }); 
                      navigate('/thankyourest') 
               } else {
                   setLoader(false)
                   toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
               }
           }) 
      }
    });
  }
  return (
    <> 
        <Loader show={loader} />
        <ConfirmDialog />
        <Toast ref={toast} position="top-center" />
{/* {varificationData.status===1 ? */}
        <div className="main-login-page">
            <div className="loginWrap">
                <div className="login_container d-flex flex-column justify-content-center h-100">
                    <header className="text-center text-md-start">
                        <div className="login_container">
                        </div>
                    </header>
                    <div className="login-Announcement">
                        <div className="row w-100 mw-100">
                            <div className="col-md-10 offset-md-2">
                                <div className="login-content w-100 mw-100 box-remove-mobile">
                                    <img src={Logo1} className="img-fluid" alt="" />
                                    <br></br>                                
                                        <h3 className="mt-5 mb-3">Change your password</h3>                                                              
                                    <Formik enableReinitialize initialValues={initialState} validationSchema={PlatformChangePasswordValidateSchema} onSubmit={changePlatformPasswordSubmit}>
        {
          ({ values, setFieldValue }) => (
            <Form className="d-flex flex-column overflow-auto" encType="multipart/form-data">
              <div className="tab-pane fade active show pt-5" id="change_pass">
                <div className="card p-4 mt-5">
                  <div className="card-body">
                    <div className="row gx-5"> 
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">New Password <span className="text-danger">*</span></label>
                        <Field name="newpassword" render={({ field }) => <InputText  type="password" className="form-control px-3"   {...field} />} maxLength="20" />
                        <ErrorMessage name="newpassword" component="span" className="from-error-span" />
                      </div>
                      <div className="col-sm-6 mb-4">
                        <label htmlFor="" className="mb-2">Confirm Password <span className="text-danger">*</span></label>
                        <Field name="confirmpassword" render={({ field }) => <InputText type="password" className="form-control px-3"   {...field} />} maxLength="20" />
                        <ErrorMessage name="confirmpassword" component="span" className="from-error-span" />
                      </div>
                    </div>
                    <div className="text-end">
                      <Button type="submit" variant="" className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save Changes</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="login_footer py-3 border-top">
                <div className="container-fluid d-md-flex justify-content-between text-center text-md-start">
                    <ul className="mb-0">
                        <li><a href="">Terms of Service</a></li>
                        <li><a href="">Contact</a></li>
                        <li><a href="">Support</a></li>
                    </ul>
                    <p className="mb-0"> Copyright © 2023 LEOPOLD TRANSPORT PTY LTD. All rights reserved</p>
                </div>
            </footer>
        </div>
         {/* :(<LinkExpaired/>)} */}
    </>
)
}

export default ChangePasswordMail
