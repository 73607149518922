import React , {useRef, useState}  from "react";
import { Link,useNavigate } from "react-router-dom";
import { InputText, Dropdown as Select, confirmDialog  , Toast} from "../../PrimeReactComponents";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {companyInitialState, companyValidateSchema} from '../Validations/companyValidation';
import { getCountry, getState } from '../../services/commonController';
import {addData,getUniquData} from '../controllers/CompanyController'; 



//import {getUniquData} from '../../services/commonController'; 
import Loader from "../../features/loader/Loader";
//import {companyThunk} from "../../redux/company/companyAddSlice";
import { useEffect } from "react";
function Add(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);   
  const [country, setCountry] = useState();
  const [states, setStates] = useState();
  const [loader, setloader] = useState();
  const [initialState, setInitialState] = useState(companyInitialState);
  const pathname = window.location.pathname 
   
   
  let platformCompanyArr = []
  let companyPermission = JSON.parse(localStorage.getItem('rolePermissionPlatformInfo'))

  companyPermission.length > 0 && companyPermission.filter(e => e.menuData?.menu_name === 'Company Management')?.map(val => {
    if (!platformCompanyArr.includes(val?.menuActionData?.action_name)) {
      platformCompanyArr.push(val?.menuActionData?.action_name)
    }
  }) 


  useEffect( () => {
    getUniqueConsigmentNum()
    getAllCountries()
    getAllStates()

    if (pathname.split('/')[3] === 'add') {
      if (!platformCompanyArr.includes('Add')) { 
        navigate('/platform/404');
      }
    } 
  },[])
 
  const getUniqueConsigmentNum =async()=>{
    const params={
      type:"Company",
    }
   const reciveData =await getUniquData(params);
  
   setInitialState(prev => ({ ...prev, companycode:reciveData.data }))
  //  console.log(initialState,"this is initial state",reciveData.data);
  }


  const getAllCountries = async () => {
    const results = await getCountry();
    setCountry(results.data)
  }

  const getAllStates = async () => {
    const results = await getState();
    setStates(results.data)
  }


  const getCountryOption = () => {
    const option = [];
    country?.map((ele) => {
      option.push({ value: ele.id, label: ele.country_name })
    })
    return option;
  }

  const getStateOption = (CoutryId) => {
    const option = [];
    states?.filter((ele) => {
      if (ele.country_id == CoutryId) {
        option.push({ value: ele.id, label: ele.state_name })
      }
    })
    return option;
  }

  const postData = (values, {resetForm})=> {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        addData(values).then(({status,type,message})=> {
          if(status){
            setloader(false)                  
            resetForm({values:''})
            toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});      
            setTimeout(() => {
              navigate('/platform/companies')
            }, 3000)
          }else{
            setloader(false)
            toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});     
          }
        })    
      }
    }); 
  }
 

  return (
    <>   
        <Loader show={loader} />   
        <Toast ref={toast} position="top-center"/>    
        <Formik initialValues={initialState}  validationSchema={companyValidateSchema} enableReinitialize onSubmit={postData} render={({values})=> (
          <div className="page-wrapper-without-aisde">         
            <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
              <div className="container d-flex flex-column h-100 ">
              <Form className="d-flex flex-column  h-100">
                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                      <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">Add New Company</h2>
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      <Link to="../" className="btn-gradient btn fz14 me-3">
                        <i className="fa-regular fa-times"></i> Cancel
                      </Link>
                      <button type="submit" className="bg-green text-white btn fz14 ">
                        <i className="fa-regular fa-save"></i> Save
                      </button>
                    </div>              
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">
                  <div className="card p-4">
                    <div className="card-body">
                      <div className="row gx-5">
                        <input type="text" className="form-control px-3" hidden />                    
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Company Number <span className="text-danger">*</span></label>
                          <Field name="companycode" render={({field})=> (
                          <>
                              <InputText   className="form-control px-3" {...field} readOnly disabled />        
                              <ErrorMessage name="companycode" component="span" className="from-error-span" />   
                          </>           
                          )} />
                          
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Company Name <span className="text-danger">*</span></label>                    
                          <Field name="companyname" render={({field})=> (
                          <>
                              <InputText   className="form-control px-3" {...field}  />        
                              <ErrorMessage name="companyname" component="span" className="from-error-span" />   
                          </>           
                          )} />
                        </div>                   
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Phone <span className="text-danger">*</span></label>
                          <Field name="companyphone" render={({field})=> (
                          <>                         
                              <InputText {...field} minLength={10} maxLength={10} className="form-control px-3" keyfilter="int" max={10} min={10} />
                              <ErrorMessage name="companyphone"  component="span" className="from-error-span" />   
                          </>           
                          )} />
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Mobile <span className="text-danger">*</span></label>
                          <Field name="companymobile" render={({field})=> (
                          <>                         
                              <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int"  />
                              <ErrorMessage name="companymobile" component="span" className="from-error-span" />   
                          </>           
                          )} />                    
                        </div>

                        <div className="col-12 mt-4 mb-3">
                            <h4 className=" fw600 fz16 text-dark">Address Details </h4>
                        </div>

                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Address <span className="text-danger">*</span></label>
                          <Field name="companyaddress" render={({field})=> (
                          <>                         
                              <InputText {...field} className="form-control px-3"  />
                              <ErrorMessage name="companyaddress" component="span" className="from-error-span" />   
                          </>           
                          )} />                    
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Suburb <span className="text-danger">*</span></label>
                          <Field name="companysuburb" render={({field})=> (
                          <>                         
                              <InputText {...field} className="form-control px-3" />
                              <ErrorMessage name="companysuburb" component="span" className="from-error-span" />   
                          </>           
                          )} />                    
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Zip Code <span className="text-danger">*</span></label>
                          <Field name="companyzipcode" render={({field})=> (
                          <>                         
                              <InputText {...field} className="form-control px-3" minLength={4} maxLength={4} keyfilter="int"  />
                              <ErrorMessage name="companyzipcode" component="span" className="from-error-span" />   
                          </>           
                          )} />                    
                        </div>   

                        <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">Country <span className="text-danger">*</span></label>
                              <Field name="companycountry" render={({ field }) => <Select options={getCountryOption()} placeholder="Please select country name" {...field} className="w-100 px-3"   />}  />
                              <ErrorMessage name="companycountry"  component="span" className="from-error-span" />
                            </div>
                            <div className="col-sm-6 mb-4">
                              <label htmlFor="" className="mb-2">State <span className="text-danger">*</span></label>
                              <Field name="companystate" render={({ field }) => <Select options={getStateOption(values.companycountry)} placeholder="Please select a state" {...field} className="w-100 px-3" />} />
                              <ErrorMessage name="companystate" component="span" className="from-error-span" />
                            </div> 

                      </div>
                    </div>
                  </div>
                </div>
                </Form>
              </div>
            </div>
          </div>
      )} />
    </>
  );
}

export default Add;
