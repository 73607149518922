import * as yup from 'yup';
const initSelect=[{
    reciveLatestJob:""
}]

const timeSheetInitialState = {
    timesheetid:  '',
    //companyid: "4b78417f-bd5a-4fca-8928-f959140dcc56",
    timesheetdate: "",
    starttime: "",
    startodometer: "",
    endtime: "",
    endodometer:"",
    driver_id: "",
    drivermobile: "",
    truck: "",
    trailer: '',    
    job_details: [{
        consignment_id:"",
        job_name: "",
        customer_name: "",
        delivery_customer_name: "",
        address: "",
        suburb: "",
        delivery_suburb: "",  
        arrival_time: "",
        depart_time: "",
        delivery_arrive_time: "",
        delivery_depart_time: "",
        pickup_status: "",
        delivery_status: "",
        reference_number: "",
        temp: "",
        delivered_chep: "",
        delivered_loscomp: "",
        delivered_plain: "",
        picked_up_chep: "",
        picked_up_loscomp: "",
        picked_up_plain: "",
        weight: "" 
    }],
    rest_details: [
        {
          description: "",
          start_time: "",
          end_time: ""
        }
      ],
    //  signature: "" 
  };

const timeSheetValidateSchema = yup.object().shape({
        timesheetid:yup.string().required('This Field is required'),
        starttime:yup.string().required('This Field is required'),
        startodometer:yup.number().typeError("That doesn't look like Phone Number").integer("This Field can't include a decimal point or Alphabet").required('This Field is required'),
        timesheetdate:yup.string().required('This Field is required'),
        //endtime:yup.string().required('This Field is required'),
       // endodometer:yup.string().required('This Field is required'),
        driver_id:yup.string().required('This Field is required'),
      //  timesheetdate:yup.string().required('This Field is required'),
        truck:yup.string().required('This Field is required'),
        drivermobile: yup.string().typeError("That doesn't look like Phone Number").min(8, 'This Field must be at least 8 characters').required('This Field is required'),                    
        trailer:yup.array().required('This Field is required'), 
       // signature:yup.string().required('This Field is required'),
    //     job_details :yup.array().of( yup.object().shape({  
    //         job_name:yup.string().required('This Field is required'),
    //         customer_name:yup.string().required('This Field is required'),
    //         address:yup.string().required('This Field is required'),
    //         suburb:yup.string().required('This Field is required'),
    //         arrival_time:yup.string().required('This Field is required'),
    //         depart_time:yup.string().required('This Field is required'),
    //         delivery_status:yup.string().required('This Field is required'),
    //         delivery_status:yup.string().required('This Field is required'),
    //         reference_number:yup.string().required('This Field is required'),
    //         temp:yup.string().required('This Field is required'),
    //         delivered_chep:yup.string().required('This Field is required'),
    //         delivered_loscomp:yup.string().required('This Field is required'),
    //         delivered_plain:yup.string().required('This Field is required'),
    //         picked_up_chep:yup.string().required('This Field is required'),
    //         picked_up_loscomp:yup.string().required('This Field is required'),
    //         picked_up_plain:yup.string().required('This Field is required'),
    //         weight:yup.string().required('This Field is required'),     
    //     })      
    // ),
//     rest_details :yup.array().of( yup.object().shape({  
//         description:yup.string().required('This Field is required'),
//         start_time:yup.string().required('This Field is required'),
//         end_time:yup.string().required('This Field is required'),
       
        
//     })      
// )
})

export {timeSheetValidateSchema , timeSheetInitialState,initSelect}