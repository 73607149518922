import React, { useRef, useState, useEffect } from "react";
import dateFormat from "dateformat";
import { Link,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';

import { listData, removeData, title, activeInactive, activeInactiveBulk, removeDataBulk } from "../controllers/CompanyController";
import { Dropdown, Button } from "../../BootstrapComponents";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import FilterOffset from "./components/OffsetRight";
import RequestEmail from "./components/RequestEmail";
import dataTableConfig from "../../config/dataTableOptions";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter';
import { listData1 } from '../../actions/CompanyAction';
import { getCompanyList } from "../../redux/company/companyAddSlice";
import Loader from "../../features/loader/Loader";
import { fetchData } from "../../store/CompanySlice";
import { useResizeListener } from 'primereact/hooks';
function List() {

  //const  {status, entries} = useSelector(state => state.company)

  const toast = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [tableHeight, setTableHeight] = useState("0px");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [list, setList] = useState([]);
  const [addPopup, setAddPopup] = useState(false);
  //const [filters , globalFilterValue, onGlobalFilterChange] = useDataTableFilter();
  const [loader, setloader] = useState(false);
  const [offsetFilter, setoffsetFilter] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [getSelectedCompany, setSelectedComapny] = useState([])
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: false,
    filters: "",
    globalFilterValue: "",
    status: "",
    filters: {
      'company_code': { value: '', matchMode: 'contains' },
      'company_name': { value: '', matchMode: 'contains' },
      'company': { value: '', matchMode: 'contains' },
      'representative.name': { value: '', matchMode: 'contains' },
    }
  });
  let platformCompanyListArr = []
  let platformCompanyArr = []
  let loadLazyTimeout = null;
  let companyPermission = JSON.parse(localStorage.getItem('rolePermissionPlatformInfo'))

  companyPermission.length > 0 && companyPermission.filter(e => e.menuData?.menu_name === 'Company Management')?.map(val => {
    if (!platformCompanyListArr.includes(val?.menuData?.menu_name)) {
      platformCompanyListArr.push(val?.menuData?.menu_name)
    }

    if (!platformCompanyArr.includes(val?.menuActionData?.action_name)) {
      platformCompanyArr.push(val?.menuActionData?.action_name)
    }
  })

  if (platformCompanyListArr.length == 0) {
    navigate('/platform/404');
  }
  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 250 + "px");
    }
});

useEffect(() => {
  bindWindowResizeListener();

  return () => {
      unbindWindowResizeListener();
  };
}, [bindWindowResizeListener, unbindWindowResizeListener]);

  // Fetching  List Data
  //const customerService = listData();

 

  // console.log(entries);
  // useEffect(() => {
  //   dispatch(fetchData());

  // },[])

  useEffect(() => {
    loadLazyData();
  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      listData({ lazyEvent: (lazyParams) }).then(data => {
        setTotalRecords(data.data?.data.count);
        setCustomers(data.data?.data.rows);
        setTableHeight(document.querySelector(".table-wrapper").scrollHeight - 100 + "px");
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);


  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
    }, []);

  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
  
    const value = event.target.value;
    if (value.length > 3) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
      listData({ lazyEvent: (lazyParams) }).then(data => {
        setTotalRecords(data?.count);
        setCustomers(data?.rows);      
      });
    } else if (value.length === 0) {
      //loadLazyData();
      setLazyParams({ ...lazyParams, globalFilterValue: "" });

    }
  }
  let istyping = false
  const throttleFunction=(func, delay)=>{

  clearTimeout(istyping);
  istyping = setTimeout(() => {
    func();
  }, delay);
  }

  const advanceSearch = (value) => {

    if (value) {
      setLazyParams({ ...lazyParams, globalFilterValue: value.companyname, status: value.status });
      setoffsetFilter(true);
      listData({ lazyEvent: (lazyParams) }).then(data => {
        setTotalRecords(data?.count);
        setCustomers(data?.rows);      
      });

    } else {
      setoffsetFilter(false);
      setLazyParams({ ...lazyParams });
    }
  }

  // Delete Entry Function Start Here =====
  const deleteEntry = (id) => {
    setloader(true);
    removeData(id).then(({ type, message }) => {
      let updatedList = list.filter(val => val.company_id !== id);
      setList(updatedList);
      loadLazyData();
      setloader(false);
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const deleteConfirm = ({ company_id }) => {
    setIsListUpdated(false)
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteEntry(company_id)
      }
    });

  }
  // Delete Entry Function end Here =====

  const activeInactiveStatus = (status, id) => {
    setloader(true)
    activeInactive(status, id).then(({ type, message }) => {
      setIsListUpdated(true)
      loadLazyData();
      setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
    })
  }
  const activeInactiveHandler = (event, data) => {
    setIsListUpdated(false)
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${data.status === '1' ? 'Inactivate' : 'Activate'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatus(data.status, data.company_id);
      }
    });

  }
  // Active Inactive Entry Function end Here =====


  // Table body Html Entry Function Start Here =====
  const actionBodyTemplate = (rowData) => {
    return (<div className="d-flex gap-2">
      <Link to={`./edit/${rowData.company_id}`} className={`btn fz16 p-0 edit-btn ${platformCompanyArr.includes('Edit') ? '' : 'border-0 shadow-none disabled'}`}>
        <i className="fa-light fa-edit"></i>
      </Link>
      <Button className={`deleteRow btn fz16 p-0 delete-btn ${platformCompanyArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} variant="" onClick={() => deleteConfirm(rowData)}>
        <i className="fa-light fa-trash"></i>
      </Button>

    </div>);
  };
  const statusBodyTemplate = (rowData) => {
    return <button onClick={(e) => activeInactiveHandler(e, rowData)} icon="pi pi-check" label="Confirm" className={`fw800 border-0 badge py-2 fs-5 badge-bg-${rowData.status === '1' ? "green" : "red"}`}>{rowData.status === '1' ? "Active" : "Inactive"} <i className="fa-light fa-angle-down"></i></button>;
  };
  const userBodyTemplate = (rowData) => {
    return <RequestEmail com_id={rowData.company_id} com_name={rowData.company_name} />;
  };
  const addressBodyTemplate = (rowData) => {
    return <span>{rowData.address}  {rowData.suburb} , {rowData?.states?.state_name} {rowData.zipcode} {rowData?.countries?.country_name}</span>
  }
  const dateBodyTemplate = (rowData) => {
    const now = new Date(rowData.createdAt);
    return dateFormat(now, "dd mmmm, yyyy");
    // return getDate(rowData.createdAt)
  }
  const updated_atBodyTemplate = (rowData) => {
    if (rowData?.updatedAt) {
      const now = new Date(rowData?.updatedAt);
      return dateFormat(now, "dd mmmm, yyyy");
    }
    return '';

  }
  // Table body Html Entry Function end Here =====


  const activeInactiveStatusBulk = (status, id) => {
    setloader(true)
    activeInactiveBulk(status, id).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCompany([])
      loadLazyData();
      setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail:id.length>0? message: "No company selected", life: 3000, });
    })
  }


  const bulkActivate = () => {
    let selectedId = [];
    selectedCompany && selectedCompany.length > 0 && selectedCompany.map((item, index) => {
      return selectedId.push(item.company_id);
    })
    setIsListUpdated(false)
    setSelectedComapny()
    confirmPopup({
      message: `Are you sure you want to 'Activate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("1", selectedId);
      }
    });
  }




  const bulkDeActivate = () => {
    let selectedId = [];
    selectedCompany && selectedCompany.length > 0 && selectedCompany.map((item, index) => {
      return selectedId.push(item.company_id);
    })
    setIsListUpdated(false)
    setSelectedComapny()
    confirmPopup({
      message: `Are you sure you want to 'Inactivate'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activeInactiveStatusBulk("0", selectedId);
      }
    });
    setSelectedCompany();
  }

  const DeleteBulk = (id) => {
    
    setloader(true)
    removeDataBulk(id).then(({ type, message }) => {
      setIsListUpdated(true)
      setSelectedCompany([])
      loadLazyData();
      setloader(false)
      toast.current.show({ severity: type, summary: 'Info Message', detail:id.length > 0 ? message:"No company selected to delete", life: 3000, });
    })
  }
  const bulkDelete = () => {
    let selectedId = [];
    selectedCompany && selectedCompany.length > 0 && selectedCompany.map((item, index) => {
      return selectedId.push(item.company_id);
    })
    setIsListUpdated(false)
    confirmPopup({
      message: `Are you sure you want to 'Delete'?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        DeleteBulk(selectedId);
      }
    });
  }
  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-building"></i>&nbsp; {title}
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                <Link to="./add" className={`bg-green text-white btn fz14 me-3 ${platformCompanyArr.includes('Add') ? '' : 'border-0 shadow-none disabled'}`}>
                  <i className="fa-light fa-plus-circle"></i>&nbsp; 
                  Add New
                </Link>
                <FilterOffset filterHandle={advanceSearch} offsetFilter={offsetFilter} />

                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => throttleFunction(()=> onGlobalFilterChange1(event), 1000)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul>
                      <li>
                        <Button className={`dropdown-item ${platformCompanyArr.includes('Delete') ? '' : 'border-0 shadow-none disabled'}`} to="#" onClick={() => bulkDelete()}>
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={() => bulkActivate()} >
                          <i className="fa-light me-2 fa-check"></i>
                          Active
                        </Button>
                      </li>
                      <li>
                        <Button className="dropdown-item" onClick={() => bulkDeActivate()}>
                          <i className="fa-light me-2 fa-ban"></i>
                          Inactive
                        </Button>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="table-wrapper flex-grow-1">
            <DataTable value={customers} selectionMode="checkbox" lazy dataKey="company_id"
              scrollable=" true" responsiveLayout="scroll"
              {...{ scrollHeight: tableHeight }}
              scrollDirection="both"
              paginator rows={12} totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={loading}
              selection={selectedCompany} onSelectionChange={e => setSelectedCompany(e.value)}
              {...dataTableConfig}

            >
              <Column field="company_id" selectionMode="multiple" headerStyle={{ width: "3em" }}  ></Column>
              <Column field="action" header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "9rem" }}></Column>
              <Column field="company_code" header="Company Code" sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="company_name" header="Company Name" sortable style={{ flexGrow: 1, flexBasis: "200px" }}></Column>
              <Column field="mobile" header="Mobile" sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="phone" header="Phone" sortable style={{ flexGrow: 1, flexBasis: "100px" }}></Column>
              <Column field="address" header="Address" body={addressBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "230px" }}></Column>
              <Column field="Users" header="Users" body={userBodyTemplate} style={{ flexGrow: 1, flexBasis: "50px" }}></Column>
              <Column field="createdAt" header="Created On" sortable dataType="date" style={{ flexGrow: 1, flexBasis: "120px" }} body={dateBodyTemplate} />
              <Column field="updatedAt" body={updated_atBodyTemplate} header="Updated On" sortable style={{ flexGrow: 1, flexBasis: "120px" }}></Column>
              <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ flexGrow: 1, flexBasis: "60px" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
