import React, { useRef, useState, useEffect } from "react";
import * as yup from 'yup'
import { Button, Modal } from "../../../BootstrapComponents";
import { InputText, Dropdown, confirmDialog, Toast, Dropdown as Dropdownas } from "../../../PrimeReactComponents";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { addData, companyUserListData } from '../../controllers/UserController'
import Loader from "../../../features/loader/Loader";
import { userRoleUpdate, getUserType, resendInvit } from "../../controllers/UserController";

const RequestEmail = (props) => {
  const [loader, setloader] = React.useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowEmail, setModalShowEmail] = React.useState(false);
  const [modalEditShowEmail, setModalEditShowEmail] = React.useState(false);
  const [list, setList] = React.useState([])
  const [getUserDropDwan, setGetUserDropDwan] = useState([])
  const toast = useRef(null);
  const [currentUserType, setCurrentUserType] = useState();
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    role_type: '',
    company_id: '',
    user_type_id: 1,
    user_id: '',
    userrelationid:''
  });




  const handleChange = (id) => {
    const params = {
      id: id,
      first: 0,
      rows: 12,
      page: 1,
      sortField: "",
      sortOrder: "desc",
      filters: "",
      globalFilterValue: "",
      status: ""
    }
    setModalShow(true)
    setloader(true)
    companyUserListData(params).then(({ status, message, data, type }) => {
      setList(data?.rows);
      // if(!status){
      //   toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});             
      // }  
      setloader(false)
    })
  }

  const newUserInitialState = {
    email_id: '',
    role_type: '',
    firstname: '',
    lastname: '',
    panel_name:'Platform',
    company_id: props.com_id,
    relationid: props.com_id,
    user_type_id: 1 
  }

  const getUserDropdawan = async () => {

    await getUserType().then(data => {
      let option = [];
      data?.data.filter(data => data.user_type_id === 1).map((ele) => {
        option.push({ value: ele.id, label: ele.role_name })
      })
      setGetUserDropDwan(option)
    });

  }
  let loadLazyTimeout = null;

  useEffect(() => {
    getUserDropdawan();

  }, [])

  const newUserFilterSchema = yup.object().shape({
    email_id: yup.string().trim().email('Please enter valid email').required('This Field is required'),
    role_type: yup.string().required('This Field is required')
  })

  function closeModal() {
    setModalShowEmail(true);
    setModalShow(false);

  }

  const addNewUser = (data) => {
    setloader(true)
    addData(data).then(({ message, type }) => {
      console.log(message, type, "msg and type");
      toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
      setloader(false)
      setModalShowEmail(false)
    })
  }


  const updateUserRole = (data) => {
    setloader(true)

    let all_data = {
      ...data,
      // ...userData
    }


    userRoleUpdate(all_data).then(({ message, type }) => {
      toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
      setloader(false)
      setModalEditShowEmail(false)
    })
  }


  function userShowPopup(company_id, user_id, role_id, firstName, lastName,user_relation_id) {
    setModalShowEmail(false)
    setModalShow(false);
    setUserData({ company_id: company_id, user_id: user_id, firstname: firstName, lastname: lastName,userrelationid:user_relation_id,role_type:role_id });
    setCurrentUserType(parseInt(role_id))
    setModalEditShowEmail(true);
  }

  const updateUserFilterSchema = yup.object().shape({
    role_type: yup.string().required('This Field is required')
  })
  const userResendMail = (id) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        resendInvit({ id: id,panel_name:'Platform' }).then(({ status, type, message }) => {
          if (status) {
            setloader(false)
            // resetForm({ all_values: '' })
            toast.current.show({ severity: 'success', summary: 'Info Message', detail: message, life: 3000, });
          } else {
            setloader(false)
            toast.current.show({ severity: 'error', summary: 'Info Message', detail: message, life: 3000, });
          }
        })
      }
    });
  }

  return (
    <>
      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />

      <Button variant="" onClick={() => handleChange(props.com_id)} className="btn text-mute shadow-none btn-sm px-3">
        <i className="fa-regular fa-users"></i>
      </Button>

      <Modal scrollable show={modalShow} onHide={() => setModalShow(false)} size="lg" centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>{props.com_name} - User List</Modal.Title>
          <Button variant="" className="btn btn-gradient ms-auto" onClick={closeModal}>
            <i className="fa-light fa-paper-plane"></i> New User
          </Button>
          <Button className="btn-close ms-2 " onClick={() => setModalShow(false)} variant=""></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="px-0 table-wrapper">
            <table className="table ">
              <thead>
                <tr className="position-sticky top-0 bg-white">
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Invited Staus</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {
                  list?.length ? list.map((data, idx) => (

                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{data?.userData?.first_name} {data?.userData?.last_name}</td>
                      <td>{data.userData?.email_id}</td>
                      <span className={`fw600 border-0 badge py-2 badge-bg-${data.invited_status === '1' ? "green" : "red"}`}>{data.invited_status === "1" ? "Accepted " : "Pending "}
                        {data.invited_status === "0" && (
                          <span variant="" className="btn btn-gradient ms-2" title="Resend Invite" onClick={() => userResendMail(data.user_relation_id)}>
                            <i className="fa-light fa-send"></i>
                          </span>)}
                      </span>
                      <td>{data?.tbl_role?.role_name}</td>
                      <Button onClick={() => userShowPopup(data.company_id, data.user_id, data.roleId, data.userData.first_name, data.userData.last_name,data?.user_relation_id)} variant=""> <i className="fa-light fa-edit"></i></Button>

                      {/* <td>{data.tbl_user.first_name} {data.tbl_user.last_name}</td> */}
                      {/* <td>{data.tbl_user.email_id}</td>
                      <td>{data.tbl_role.role_name}</td> */}
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan="4" className="text-center">Data Not Found</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={modalShowEmail} onHide={() => setModalShowEmail(false)} size="lg" centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <i className="fa-light fa-paper-plane"></i> New User
          </Modal.Title>
          <Button className="btn-close ms-auto" onClick={() => setModalShowEmail(false)} variant=""></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik enableReinitialize initialValues={newUserInitialState} validationSchema={newUserFilterSchema} onSubmit={(value) => {
            confirmDialog({
              message: 'Are you sure you want to proceed?',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                addNewUser(value)
              }
            });
          }} render={() => (

            <Form>
              <div className="row align-items-center p-5 pt-0 gx-2">
                <div className="col-sm-5">
                  <div className="form-group flex-grow-1 mt-2">
                    <label htmlFor="">First Name <span className="text-danger">*</span></label>
                    <Field name="firstname" render={({ field }) => (
                      <>
                        <InputText {...field} className="form-control mt-2 px-3" />
                        <ErrorMessage name="firstname" component="span" className="from-error-span position-absolute" />
                      </>
                    )} />

                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group flex-grow-1 mt-2">
                    <label htmlFor="">Last Name <span className="text-danger">*</span></label>
                    <Field name="lastname" render={({ field }) => (
                      <>
                        <InputText {...field} className="form-control mt-2 px-3" />
                        <ErrorMessage name="lastname" component="span" className="from-error-span position-absolute" />
                      </>
                    )} />

                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group flex-grow-1 mt-2">
                    <label htmlFor="">Email Address <span className="text-danger">*</span></label>
                    <Field name="email_id" render={({ field }) => (
                      <>
                        <InputText {...field} className="form-control mt-2 px-3" />
                        <ErrorMessage name="email_id" component="span" className="from-error-span position-absolute" />
                      </>
                    )} />

                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group flex-grow-1 mt-2">
                    <label htmlFor="">Role <span className="text-danger">*</span></label>
                    <Field name="role_type" render={({ field }) => (
                      <>
                        <Dropdown className="w-100  mt-2 px-3" {...field} options={getUserDropDwan} placeholder="Select Role" />
                        <ErrorMessage name="role_type" component="span" className="from-error-span position-absolute" />
                      </>
                    )} />

                  </div>
                </div>
                <div className="col-sm-2">
                  <button type="submit" className="btn fz14 bg-green text-white mt-5"><i className="fa-light fa-save"></i> Save</button>
                </div>
              </div>
            </Form>
          )} />

        </Modal.Body>
      </Modal>

      {/* Edit the Role */}

      <Modal show={modalEditShowEmail} onHide={() => setModalEditShowEmail(false)} size="lg" centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>Update User </Modal.Title>
          <Button className="btn-close ms-2 " onClick={() => setModalEditShowEmail(false)} variant=""></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik enableReinitialize initialValues={userData} onSubmit={(value) => {
            confirmDialog({
              message: 'Are you sure you want to proceed?',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                updateUserRole(value)
              }
            });
          }}>
            {({ setFieldValue }) => (
              <Form>
                <div className="row align-items-center p-5 pt-0 gx-2">
                  <div className="col-sm-5">
                    <div className="form-group flex-grow-1 mt-2">
                      <label htmlFor="">First Name <span className="text-danger">*</span></label>
                      <Field name="firstname" render={({ field }) => (
                        <>
                          <InputText {...field} className="form-control mt-2 px-3" />
                          <ErrorMessage name="firstname" component="span" className="from-error-span position-absolute" />
                        </>
                      )} />

                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group flex-grow-1 mt-2">
                      <label htmlFor="">Last Name <span className="text-danger">*</span></label>
                      <Field name="lastname" render={({ field }) => (
                        <>
                          <InputText {...field} className="form-control mt-2 px-3" />
                          <ErrorMessage name="lastname" component="span" className="from-error-span position-absolute" />
                        </>
                      )} />

                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group flex-grow-1 mt-2">
                      <label htmlFor="">Role <span className="text-danger">*</span></label>
                      <Field name="role_type" render={({ field }) => (
                        <>
                          <Dropdownas className="w-100  mt-2 px-3" {...field} options={getUserDropDwan} placeholder="Select Role" value={currentUserType} onChange={(e) => { setFieldValue('role_type', e.target.value); setCurrentUserType(e.target.value) }} />
                          <ErrorMessage name="role_type" component="span" className="from-error-span position-absolute" />
                        </>
                      )} />

                    </div>
                  </div>
                  <div className="col-sm-2">
                    <button type="submit" className="btn fz14 bg-green text-white mt-5"><i className="fa-light fa-save"></i> Save</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestEmail;
