import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { InputText, Dropdown as Select, confirmDialog, Toast, Calendar, MultiSelect } from "../../PrimeReactComponents";
import { Formik, Form, Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { timeSheetInitialState, timeSheetValidateSchema, initSelect } from '../Validations/timeSheetValidation';
import { getCountry, getState, getAllTruckInfo, getAllTrailerInfo, getAllDriver, getAllTimesheetTemp,uploadDocument } from '../../services/commonController';
import { removeDriverDocument, removeDriverNote } from '../controllers/DriverControllers/controllers';
import { addData, getDriverJobDetail, singleData, editData, removeTimeSheetJob, removeTimeSheetJobRest, getUniquData } from '../controllers/timeSheet';

import states from '../json_files/states.json';
import Loader from "../../features/loader/Loader";
import { companyThunk } from "../../redux/company/companyAddSlice";
import { useEffect } from "react";

import { REACT_APP_BASE_BACKEND_URL } from '../../config';
import { getRandomNumber } from '../../utility/helper';

function Add(props) {
  const { transportUserInfo } = useSelector(state => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filename, setFilename] = useState([]);
  const reciveLocalData = transportUserInfo.company_id;
  const toast = useRef(null);
  const formikRef = useRef();
  const [loader, setloader] = useState();
  const [initialState, setInitialState] = useState(timeSheetInitialState);
  const { id } = useParams();
  const [country, setCountry] = useState();
  const [states, setStates] = useState();
  const [allDriver, setAllDriver] = useState();
  const [allTimesheetTemp, setAllTimesheetTemp] = useState();
  const [driverData, setDriverData] = useState();
  const [driverMobile, setdriverMobile] = useState(null);
  const [allTruck, setallTruck] = useState([]);
  const [allTrailer, setallTrailer] = useState([]);
  const [dateId, setDateId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [newJobOption, setNewJobOption] = useState([]);

  const pathname = window.location.pathname

  let timesheetArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Timesheet')?.map(val => {
    if (!timesheetArr.includes(val?.menuActionData?.action_name)) {
      timesheetArr.push(val?.menuActionData?.action_name)
    }
  })

  if (pathname.split('/')[3] === 'add') {
    if (!timesheetArr.includes('Add')) {
      navigate('/transport/404');
    }
  }

  if (pathname.split('/')[3] === 'edit') {
    if (!timesheetArr.includes('Edit')) {
      navigate('/transport/404');
    }
  }


  let reciveResponse = [];
  let dataToUpdate = [];
  let recivedNewJob = [];
  const getAllCountries = async () => {
    const results = await getCountry();
    setCountry(results.data)
  }
  const getAllStates = async () => {
    const results = await getState();
    setStates(results.data)
  }
  const getAllDriverData = async (reciveLocalData) => {
    const results = await getAllDriver(reciveLocalData);
    setAllDriver(results.data)
  }
  const getAllTruckTypeData = async (reciveLocalData) => {
    const results = await getAllTruckInfo(reciveLocalData);
    setallTruck(results.data)
  }
  const getAllTruckTypeDataOption = () => {
    const option = [];
    allTruck?.map((ele) => {
      option.push({ value: ele.truck_setup_id, label: ele.truck_registration })
    })
    return option;
  }

  const getAllTrailerTypeData = async (reciveLocalData) => {
    const results = await getAllTrailerInfo(reciveLocalData);
    setallTrailer(results.data)
  }
  const getAllTrailerTypeDataOption = () => {
    const option = [];
    allTrailer?.map((ele) => {
      option.push({ value: ele?.truck_setup_id, label: ele?.truck_registration })
    })
    return option;
  }

  const getDriverMobileNumber = async (driverId, setFieldValue) => {
    const results = await getAllDriver(reciveLocalData);
    let resultData = results.data.filter((ele) => ele?.drivers?.driver_id === driverId).map(data => data)[0]
    setFieldValue('drivermobile', resultData.drivers?.mobile);
  }

  const getTimesheetTemp = async (tempId, setFieldValue) => {
    const results = await getAllTimesheetTemp();
    let resultData = results.data.filter((ele) => ele?.id === tempId).map(data => data)[0]
    setFieldValue('drivermobile', resultData.drivers?.mobile);
  }


  const getAllTimesheetTempData = async () => {
    const results = await getAllTimesheetTemp();
    setAllTimesheetTemp(results.data)
  }

  const getSingleDate = async (id) => {

    if (id) {
      const { status, message, type, error, data } = await singleData(id, reciveLocalData);
      setloader(true)

      let modifyedJob = []

      if (data?.data?.newConsignment.length > 0) {
        modifyedJob = data.data?.newConsignment.map((item) => {
          return {
            ...item, "customer_name": item.customer_pickup_details?.customer_company_name,
            "delivery_customer_name": item.customer_delivery_details?.customer_company_name,
            "suburb": item.customer_pickup_details?.suburb,
            "delivery_suburb": item.customer_delivery_details?.suburb,
            "picked_up_chep": item?.chep,
            "picked_up_loscomp": item?.loscom,
            "picked_up_plain": item?.plain,
            "delivered_chep": item?.chep,
            "delivered_loscomp": item?.loscom,
            "delivered_plain": item?.plain,
            "weight": item?.total_weight,
            // "reference_number": item?.consignmentDetails?.senders_no, 
            // "temp": item?.consignmentDetails?.job_temp, 
            "jobKey": 1
          }
        })
      }


      let modifyedJobOld = data?.data.timesheetjobs?.map((item) => {
        return { ...item, "jobKey": 0 }
      })

      let newJobDetail = []
      if (modifyedJob.length > 0) {
        newJobDetail = [].concat(modifyedJobOld, modifyedJob);
      } else {
        newJobDetail = modifyedJobOld
      }
      setDriverId(data?.data?.driver_id)
      if (status) {
        // debugger;


        setTimeout(() => {
          setInitialState({
            job_details: newJobDetail,
            timesheetid: data?.data?.timesheet_id,
            timesheetdate: new Date(data?.data?.timesheet_date),
            starttime: data?.data?.start_time,
            startodometer: data?.data?.start_odometer,
            endtime: data?.data?.end_time,
            endodometer: data?.data?.end_odometer,
            driver_id: data?.data?.driver_id,
            drivermobile: data?.data?.driver_mobile,
            truck: data?.data?.truck,
            trailer: data?.data?.trailer,
          /*   job_details: data?.data?.timesheetjobs?.map((ele, i) => {
              if (ele.temp) {
                ele['temp'] = parseInt(ele.temp);
              }
              return ele;
            }), */
            rest_details: data?.data?.restdetails?.map((ele, i) => {
              if (ele.start_time) {
                ele['start_time'] = ele.start_time;
              }
              if (ele.end_time) {
                ele['end_time'] = ele.end_time;
              }
              return ele;
            })
          })
        }, 1000);
        setloader(false)
      } else {
        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
        setloader(false)
      }
      // })
    }
  }
  useEffect(() => {
    getAllCountries();
    getAllStates();
    getAllTimesheetTempData();
    getAllDriverData(reciveLocalData);
    getAllTruckTypeData(reciveLocalData);
    getAllTrailerTypeData(reciveLocalData);
    getUniqueTimesheetNum()
    // setInitialState(prev => ({ ...prev, timesheetid: getRandomNumber('TIM') }))
    getSingleDate(id);
  }, [id])

  const getUniqueTimesheetNum = async () => {
    const params = {
      type: "TimeSheet",
    }
    const reciveData = await getUniquData(params);
    setInitialState(prev => ({ ...prev, timesheetid: reciveData.data }))
  }

  useEffect(() => {
    (async () => {
      if (dateId && driverId) {
        if (formikRef.current) {
          const paramsData = { driver_id: driverId, pickup_date: moment(dateId).format('YYYY-MM-DD'), company_id: reciveLocalData }
          const { data } = await getDriverJobDetail(paramsData);
          setDriverData(data)

          data?.data?.resultData && data?.data?.resultData.length > 0 && data?.data?.resultData.forEach((element, i) => {
            formikRef.current.setFieldValue('job_details[' + i + '].consignment_id', element.consignment_id);
            formikRef.current.setFieldValue('drivermobile', element.driver_mobile_number);
            formikRef.current.setFieldValue('job_details[' + i + '].job_name', element.job_number);
            formikRef.current.setFieldValue('job_details[' + i + '].customer_name', element.customer_pickup_details?.customer_company_name);
            formikRef.current.setFieldValue('job_details[' + i + '].delivery_customer_name', element.customer_delivery_details?.customer_company_name);
            formikRef.current.setFieldValue('job_details[' + i + '].address', element.customer_address);
            formikRef.current.setFieldValue('job_details[' + i + '].suburb', element?.customer_pickup_details?.suburb);
            formikRef.current.setFieldValue('job_details[' + i + '].delivery_suburb', element.customer_delivery_details?.suburb);
            //formikRef.current.setFieldValue('job_details[' + i + '].reference_number', element?.consignmentDetails?.senders_no);
            //formikRef.current.setFieldValue('job_details[' + i + '].temp', element?.consignmentDetails?.job_temp);
            formikRef.current.setFieldValue('job_details[' + i + '].weight', element?.total_weight);
            formikRef.current.setFieldValue('job_details[' + i + '].delivered_chep', element?.chep);
            formikRef.current.setFieldValue('job_details[' + i + '].delivered_loscomp', element?.loscom);
            formikRef.current.setFieldValue('job_details[' + i + '].delivered_plain', element?.plain);
            formikRef.current.setFieldValue('job_details[' + i + '].picked_up_chep', element?.chep);
            formikRef.current.setFieldValue('job_details[' + i + '].picked_up_loscomp', element?.loscom);
            formikRef.current.setFieldValue('job_details[' + i + '].picked_up_plain', element?.plain);

          });
        }
      }
    })()
  }, [dateId, driverId])


  useEffect(() => {
    formikRef.current.setFieldValue('job_details', timeSheetInitialState.job_details)
  }, [dateId, driverId])


  const recivedNewJobAdd = () => {
    // initialState.job_details.splice(2,1)
    recivedNewJob.push(initSelect);


  }

  const getAllDriverOption = () => {
    const option = [];
    allDriver?.map((ele) => {
      option.push({ value: ele?.drivers?.driver_id, label: ele?.drivers?.first_name.concat(" ", ele?.drivers?.last_name) })
    })
    return option;
  }

  const getAllTimesheetOption = () => {
    const option = [];
    allTimesheetTemp?.map((ele) => {
      option.push({ value: ele?.id, label: ele?.name })
    })
    return option;
  }

  const postData = (values, { resetForm }) => {
    const dataAdd = { ...values, companyid: reciveLocalData }
    //  console.log("Log no submite",dataAdd.job_details);
    const modifyedData = {
      ...values, companyid: reciveLocalData,
      job_details: values.job_details.map((ele) => ({
        ...ele,
        timesheet_job_id: ele.timesheet_job_id ? ele.timesheet_job_id : ""
      })),
      rest_details: values.rest_details.map((item) => (
        {

          description: item.description,
          end_time: item.end_time,
          rest_id: item.rest_id ? item.rest_id : "",
          start_time: item.start_time,
          //  timesheet_id:id
        })
      )
    }

    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        setloader(true)
        if (id) {

          editData(modifyedData, id, reciveLocalData).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ values: '' })
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

              setTimeout(() => {
                navigate('/transport/timesheet')
              }, 3000)

            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            }
          })
        } else {
          addData(dataAdd).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ values: '' })
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

              setTimeout(() => {
                navigate('/transport/timesheet')
              }, 3000)
            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            }
          })
        }
      }
    });
  }

  const selectedNewJob = async (push) => {
    const paramsData = { driver_id: initialState.driver_id, pickup_date: moment(initialState.timesheetdate).format('YYYY-MM-DD') }
    const { data } = await getDriverJobDetail(paramsData);
    setDriverData(data.data.data?.resultData);
    const reciveResult = await singleData(id, reciveLocalData);
    var res = data.data.data.filter(n => !reciveResult.data.data.timesheetjobs.some(n2 => n.consignment_id == n2.consignment_id));
    setNewJobOption(res);

    if (res.length > 0) {
      push((timeSheetInitialState.job_details[0]));
    } else {
      toast.current.show({ severity: "warn", summary: 'Info Message', detail: "There is no job, for selected driver on selected date", life: 3000, });
    }


  }

  const onJobSelect = (idx, values) => {
    let selectedJob = newJobOption && newJobOption.length > 0 && newJobOption.filter((element) => {
      return element.consignment_id === values
    })

    formikRef.current.setFieldValue('job_details[' + idx + '].timesheet_id', selectedJob[0].id)
    formikRef.current.setFieldValue('job_details[' + idx + '].timesheet_job_id', "")
    formikRef.current.setFieldValue('job_details[' + idx + '].consignment_id', selectedJob[0].consignment_id);
    formikRef.current.setFieldValue('job_details[' + idx + '].job_name', selectedJob[0].job_number);
    formikRef.current.setFieldValue('job_details[' + idx + '].customer_name', selectedJob[0]?.customer_pickup_details?.customer_company_name);
    formikRef.current.setFieldValue('job_details[' + idx + '].address', selectedJob[0].customer_address);
    formikRef.current.setFieldValue('job_details[' + idx + '].suburb', selectedJob[0]?.customer_pickup_details?.suburb);
    // formikRef.current.setFieldValue('job_details[' + idx + '].reference_number', selectedJob[0]?.consignmentDetails?.senders_no);
    // formikRef.current.setFieldValue('job_details[' + idx + '].temp', selectedJob[0]?.consignmentDetails?.job_temp);
    formikRef.current.setFieldValue('job_details[' + idx + '].weight', selectedJob[0]?.total_weight);
    formikRef.current.setFieldValue('job_details[' + idx + '].delivered_chep', selectedJob[0]?.chep);
    formikRef.current.setFieldValue('job_details[' + idx + '].delivered_loscomp', selectedJob[0]?.loscom);
    formikRef.current.setFieldValue('job_details[' + idx + '].delivered_plain', selectedJob[0]?.plain);
    formikRef.current.setFieldValue('job_details[' + idx + '].picked_up_chep', selectedJob[0]?.chep);
    formikRef.current.setFieldValue('job_details[' + idx + '].picked_up_loscomp', selectedJob[0]?.loscom);
    formikRef.current.setFieldValue('job_details[' + idx + '].picked_up_plain', selectedJob[0]?.plain);
  }

  const deleteConfirm = (val, idx, remove) => {
    console.log("val.timesheet_job_id", val);
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        removeTimeSheetJob(val.timesheet_job_id, reciveLocalData);
        remove(idx);
      }
    });

  }

  const deleteRestJobConfirm = (val, idx) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        removeTimeSheetJobRest(val.rest_id, reciveLocalData);
        //remove(idx);
        getSingleDate(id)
      }
    });

  }

  const getJobOption = () => {
    const option = [];
    newJobOption?.map((ele) => {
      option.push({ value: ele.consignment_id, label: ele.job_number })
    })
    return option;
  }


  // validationSchema={timeSheetValidateSchema} 

  return (

    <>

      <Loader show={loader} />
      <Toast ref={toast} position="top-center" />
      <Formik enableReinitialize initialValues={initialState} validationSchema={timeSheetValidateSchema} innerRef={formikRef} onSubmit={postData} render={({ values, setFieldValue }) => (
        <div className="page-wrapper-without-aisde">
          <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
            <div className="container d-flex flex-column h-100 ">
              <Form className="d-flex flex-column  h-100" encType="multipart/form-data">

                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                      <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">{(id === "" || id === undefined) ? "Add New TimeSheet" : "Update TimeSheet"}</h2>
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      <Link to="../timesheet" className="btn-gradient btn fz14 me-3">
                        <i className="fa-regular fa-times"></i> Cancel
                      </Link>
                      <button type="submit" className="bg-green text-white btn fz14 ">
                        <i className="fa-regular fa-save"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">
                  <div className="card p-4">
                    <div className="card-body">
                      <div className="row gx-5">
                        <input type="text" className="form-control px-3" hidden />
                        <div className="col-12 mb-3">
                          <h4 className="fw600 fz16 text-dark">Timesheet </h4>
                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Timesheet ID <span className="text-danger">*</span></label>
                          <Field name="timesheetid" render={({ field }) => (
                            <>
                              <InputText type="text" name="timesheetid" className="form-control px-3" {...field} readOnly disabled />
                              <ErrorMessage name="timesheetid" component="span" className="from-error-span" />
                            </>
                          )} />

                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2">Date <span className="text-danger">*</span></label>
                          <Field name="timesheetdate" render={({ field }) => (
                            <>
                              <Calendar
                                onSelect={(e) => {
                                  setDateId(e.value)
                                  setFieldValue('timesheetdate', e.value)
                                }}
                                dateFormat="dd-mm-yy"
                                inputClassName="form-control px-3"
                                className="w-100" {...field} />
                              <ErrorMessage name="timesheetdate" component="span" className="from-error-span" />
                            </>
                          )} />
                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Start Time <span className="text-danger">*</span></label>
                          <Field name="starttime" render={({ field }) => (
                            <>
                              <InputText type="time" className="form-control px-3" {...field} />
                              <ErrorMessage name="starttime" component="span" className="from-error-span" />
                            </>
                          )} />

                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Start Odometer <span className="text-danger">*</span></label>
                          <Field name="startodometer" render={({ field }) => (
                            <>
                              <InputText type="text" className="form-control px-3" {...field} />
                              <ErrorMessage name="startodometer" component="span" className="from-error-span" />
                            </>
                          )} />

                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2 d-block">End Time </label>
                          <Field name="endtime" render={({ field }) => (
                            <>
                              <InputText type="time" className="form-control px-3" {...field} />
                              {/* <ErrorMessage name="endtime" component="span" className="from-error-span" /> */}
                            </>
                          )} />

                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2">End Odometer</label>
                          <Field name="endodometer" render={({ field }) => (
                            <>
                              <InputText {...field} type="text" className="form-control px-3" keyfilter="int" />
                              {/* <ErrorMessage name="endodometer" component="span" className="from-error-span" /> */}
                            </>
                          )} />
                        </div>


                        <div className="col-12 mt-4 mb-3">
                          <h4 className=" fw600 fz16 text-dark">Driver </h4>
                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Driver Name <span className="text-danger">*</span></label>

                          <Field name="driver_id" render={({ field }) => <Select options={getAllDriverOption()}
                            placeholder="Please select a driver"
                            {...field} className="w-100 px-3" maxLength="100"
                            onChange={(e) => {
                              getDriverMobileNumber(e.target.value, setFieldValue); setDriverId(e.value); setFieldValue('driver_id', e.value)
                              /*  onChange={(event) => {
                                 setDriverId(event.value); setFieldValue('driver_id', event.value) */
                            }}
                          />} />
                          <ErrorMessage name="driver_id" component="span" className="from-error-span" />

                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2">Driver Mobile <span className="text-danger">*</span></label>
                          <Field name="drivermobile" render={({ field }) => (
                            <>
                              <InputText className="form-control px-3"  {...field} readOnly disabled />
                              <ErrorMessage name="drivermobile" component="span" className="from-error-span" />
                            </>
                          )} />
                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Vehicle <span className="text-danger">*</span></label>
                          <Field name="truck" render={({ field }) => (
                            <>
                              <Select  {...field}
                                className="w-100 px-3"
                                options={getAllTruckTypeDataOption()}
                                placeholder="Please select"
                                maxLength="100"

                              />
                              <ErrorMessage name="truck" component="span" className="from-error-span" />
                            </>
                          )} />

                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                          <label htmlFor="" className="mb-2 d-block">Trailer <span className="text-danger">*</span></label>
                          <Field name="trailer" render={({ field }) => (
                            <>
                              <MultiSelect {...field}
                                options={getAllTrailerTypeDataOption()}
                                placeholder="Please select"
                                className="w-100 px-3"
                                maxLength="100"
                              />
                              <ErrorMessage name="trailer" component="span" className="from-error-span" />
                            </>
                          )} />

                        </div>


                        <div className="col-12 mt-4 mb-3">
                          <h4 className=" fw600 fz16 text-dark">Job Details</h4>
                          <div className="overflow-auto mt-3 ">
                            <table className="table  multiple-feilds-table" style={{ minWidth: '2220px' }}>
                              <thead>
                                <tr>
                                  <th className="fw500 fz14" width="30px">
                                    #
                                  </th>
                                  <th className="fw500 fz14" width="320px">
                                    Job Number
                                  </th>
                                  <th className="fw500 fz14" width="180px" >Name of Customer</th>
                                  <th className="fw500 fz14" width="150px">
                                    Address
                                  </th>
                                  <th className="fw500 fz14" width="150px">
                                    Suburb
                                  </th>
                                  <th className="fw500 fz14" width="180px">
                                    Pickup Arrive Time
                                  </th>
                                  <th className="fw500 fz14" width="180px">
                                    Pickup Depart Time
                                  </th>
                                  <th className="fw500 fz14" width="200px">Delivery Arrive Time  </th>
                                  <th className="fw500 fz14" width="200px"> Delivery  Depart Time </th>
                                  <th className="fw500 fz14" width="150px">Load Description</th>
                                  <th className="fw500 fz14" width="180px">
                                    Delivery Temp
                                  </th>
                                  <th className="fw500 fz14" width="320px">
                                    Delivered ( C / L / P)

                                  </th>
                                  <th className="fw500 fz14" width="320px">
                                    Picked Up ( C / L / P)

                                  </th>
                                  <th className="fw500 fz14" width="320px">

                                    Weights
                                  </th>


                                  {/* <th className="fw500 fz14" width="50px"></th> */}
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name="job_details" render={({ push, pop, remove }) => (
                                  values?.job_details && values?.job_details.map((val, idx) => (
                                    <tr key={idx} className={val.jobKey === 1 ? "bg-light-blue" : ""}>
                                      <td> {idx + 1}  {val.jobKey === 1 ? <span className='badge bg-success'>New</span> : ""}</td>
                                      {val.job_name === "" ? (
                                        <td>
                                          <Field name="reciveLatestJob" render={({ field }) => <Select optionLabel="job_number"
                                            optionValue="consignment_id" options={newJobOption}  {...field}
                                            placeholder="Please select Consignment" className="w-100 px-3"
                                            maxLength="100" onChange={(e) => onJobSelect(idx, e.value)}
                                            hideselectedoptions={false} isMulti
                                          />} />

                                        </td>
                                      ) : (<td>
                                        <Field name={`job_details[${idx}].job_name`} render={({ field }) => (
                                          <>
                                            <Link to={"../consignments/view/" + val?.consignment_id} target="_blank">
                                              <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                            </Link>

                                            {/* <ErrorMessage name={`job_details[${idx}].job_name`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>)}

                                      <td>


                                        <Field name={`job_details[${idx}].customer_name`} render={({ field }) => (
                                          <>
                                            <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                            {/* <ErrorMessage name={`job_details[${idx}].customer_name`} component="span" className="from-error-span" /> */}
                                          </>
                                        )}
                                        />


                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].address`} render={({ field }) => (
                                          <>
                                            <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                            {/* <ErrorMessage name={`job_details[${idx}].address`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].suburb`} render={({ field }) => (
                                          <>
                                            <InputText className="form-control px-3"  {...field} readOnly disabled />
                                            {/* <ErrorMessage name={`job_details[${idx}].suburb`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].arrival_time`} render={({ field }) => (
                                          <>
                                            <InputText type="time" className="form-control px-3" {...field} />
                                            {/* <ErrorMessage name={`job_details[${idx}].arrival_time`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].depart_time`} render={({ field }) => (
                                          <>
                                            <InputText type="time" className="form-control px-3" {...field} />
                                            {/* <ErrorMessage name={`job_details[${idx}].depart_time`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].delivery_arrive_time`} render={({ field }) => (
                                          <>
                                            <InputText type="time" className="form-control px-3" {...field} />
                                            {/* <ErrorMessage name={`job_details[${idx}].delivery_arrive_time`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].delivery_depart_time`} render={({ field }) => (
                                          <>
                                            <InputText type="time" className="form-control px-3" {...field} />
                                            {/* <ErrorMessage name={`job_details[${idx}].delivery_depart_time`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].reference_number`} render={({ field }) => (
                                          <>
                                            <InputText type="text" className="form-control px-3" {...field} />
                                            {/* <ErrorMessage name={`job_details[${idx}].reference_number`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        {/* <Field name={`job_details[${idx}].temp`} render={({ field }) => <Select options={getAllTimesheetOption()}
                                          placeholder="Please select"
                                          {...field} className="w-100 px-3" maxLength="100"

                                        />} /> */}


                                        <Field name={`job_details[${idx}].temp`} render={({ field }) => (
                                          <>
                                            <InputText type="text" className="form-control px-3" {...field} />
                                            {/* <ErrorMessage name={`job_details[${idx}].temp`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      <td>
                                        <div className="d-flex gap-2">

                                          <div>
                                            <Field name={`job_details[${idx}].delivered_chep`} render={({ field }) => (
                                              <>
                                                <InputText type="text" className="form-control px-3" {...field} readOnly disabled />
                                                {/* <ErrorMessage name={`job_details[${idx}].delivered_chep`} component="span" className="from-error-span" /> */}
                                              </>
                                            )} />
                                          </div>
                                          <div>
                                            <Field name={`job_details[${idx}].delivered_loscomp`} render={({ field }) => (
                                              <>
                                                <InputText type="text" className="form-control px-3" {...field} readOnly disabled />

                                                {/* <ErrorMessage name={`job_details[${idx}].delivered_loscomp`} component="span" className="from-error-span" /> */}
                                              </>
                                            )} />
                                          </div>
                                          <div>

                                            <Field name={`job_details[${idx}].delivered_plain`} render={({ field }) => (
                                              <>
                                                <InputText type="text" className="form-control px-3" {...field} readOnly disabled />

                                                {/* <ErrorMessage name={`job_details[${idx}].delivered_plain`} component="span" className="from-error-span" /> */}
                                              </>
                                            )} />
                                          </div>
                                        </div>

                                      </td>
                                      <td>
                                        <div className="d-flex gap-2">

                                          <div>
                                            <Field name={`job_details[${idx}].picked_up_chep`} render={({ field }) => (
                                              <>
                                                <InputText type="text" className="form-control px-3" {...field} readOnly disabled />

                                                {/* <ErrorMessage name={`job_details[${idx}].picked_up_chep`} component="span" className="from-error-span" /> */}
                                              </>
                                            )} />
                                          </div>  <div>
                                            <Field name={`job_details[${idx}].picked_up_loscomp`} render={({ field }) => (
                                              <>
                                                <InputText type="text" className="form-control px-3" {...field} readOnly disabled />

                                                {/* <ErrorMessage name={`job_details[${idx}].picked_up_loscomp`} component="span" className="from-error-span" /> */}
                                              </>
                                            )} />
                                          </div><div>

                                            <Field name={`job_details[${idx}].picked_up_plain`} render={({ field }) => (
                                              <>
                                                <InputText type="text" className="form-control px-3" {...field} readOnly disabled />

                                                {/* <ErrorMessage name={`job_details[${idx}].picked_up_plain`} component="span" className="from-error-span" /> */}
                                              </>
                                            )} />
                                          </div>

                                        </div>
                                      </td>
                                      <td>
                                        <Field name={`job_details[${idx}].weight`} render={({ field }) => (
                                          <>
                                            <InputText type="text" className="form-control px-3" {...field} readOnly disabled />

                                            {/* <ErrorMessage name={`job_details[${idx}].weight`} component="span" className="from-error-span" /> */}
                                          </>
                                        )} />

                                      </td>
                                      {/* <td>

                                        {idx > 0 ? (

                                          <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0" disabled={(id === "" || id === undefined) === true} onClick={() => { deleteConfirm(val, idx, remove) }} >
                                            <i className="fa-regular fa-minus-circle "></i>
                                          </button>
                                        ) : (
                                          <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0" disabled={(id === "" || id === undefined) === true} onClick={() => { selectedNewJob(push) }} >
                                            <i className="fa-regular fa-plus-circle "></i>
                                          </button>
                                        )}
                                      </td> */}
                                    </tr>
                                  )
                                  ))} />


                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-4 mb-3">
                        <h4 className=" fw600 fz16 text-dark"> Rest details</h4>
                        <div className="overflow-auto mt-3 ">
                          <table className="table  multiple-feilds-table">
                            <thead>
                              <tr>
                                <th className="fw500 fz14" width="30px">#</th>
                                <th className="fw500 fz14">Description </th>
                                <th className="fw500 fz14">Start Time </th>
                                <th className="fw500 fz14">End Time </th>
                                <th className="fw500 fz14" width="30px"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray name="rest_details" render={({ push, remove }) => (
                                values.rest_details && values.rest_details.map((val, idx) => (
                                  <tr key={idx}>
                                    {/* <td>{idx + 1}</td> */}

                                    <td className="dynamicText">{idx + 1}</td>
                                    <td>
                                      <Field name={`rest_details[${idx}].description`} render={({ field }) => (
                                        <>
                                          <InputText type="text" className="form-control px-3" {...field} />
                                        </>
                                      )} /></td>
                                    <td>
                                      <Field name={`rest_details[${idx}].start_time`} render={({ field }) => (
                                        <>
                                          <InputText type="time" className="form-control px-3" onChange={(e) => { setFieldValue(`rest_details[${idx}].start_time`, e.value) }} {...field} />

                                          {/* <Calendar min className="w-100" {...field}
                                            //maxDate={new Date(val.end_time)}
                                            onChange={(e) => { setFieldValue(`rest_details[${idx}].start_time`, e.value) }}
                                            inputClassName="form-control px-3" timeOnly />   */}
                                        </>
                                      )} /></td>
                                    <td>
                                      <Field name={`rest_details[${idx}].end_time`} render={({ field }) => (
                                        <>
                                          <InputText type="time" className="form-control px-3"
                                            onChange={(e) => {
                                              console.log("val", val);
                                              setFieldValue(`rest_details[${idx}].end_time`, e.value)
                                            }} {...field} />
                                          {/* <Calendar className="w-100" {...field}
                                            //minDate={new Date(val.start_time)}                                                                                           
                                            onChange={(e) => { console.log("val", val); 
                                            setFieldValue(`rest_details[${idx}].end_time`, e.value) }}
                                            inputClassName="form-control px-3" timeOnly />  */}
                                        </>
                                      )} /></td>
                                    <td>
                                      {values.rest_details.length - 1 === idx ? (
                                        <>
                                          <div className="d-flex gap-2">
                                            {values.rest_details.length > 1 &&
                                              <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0"
                                                onClick={() => { val.rest_id ? deleteRestJobConfirm(val, idx) : remove(idx) }}
                                              >
                                                <i className="fa-regular fa-minus-circle "></i>
                                              </button>}
                                            <button type="button" className="btn fw500 fz14 ms-auto text-success shadow-none px-0"
                                              onClick={() => push(timeSheetInitialState.rest_details[0])} >
                                              <i className="fa-regular fa-plus-circle "></i>
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <button type="button" className="btn fw500 fz14 ms-auto text-danger shadow-none px-0"
                                          onClick={() => { val.rest_id ? deleteRestJobConfirm(val, idx) : remove(idx) }}
                                        >
                                          <i className="fa-regular fa-minus-circle "></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                )
                                ))} />

                            </tbody>
                          </table>
                          {/* <div className="d-flex align-items-center mt-6">
                           <div> <span>Signature <span className="text-danger">*</span></span>&nbsp; &nbsp; </div>                           
                             <Field name="signature" render={({ field }) => (
                                 <>
                                   <textarea name="signature" className="form-control px-3" {...field} />
                                   <ErrorMessage name="signature" component="span" className="from-error-span" />
                                              </>
                                                )} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )} />
    </>
  );
}

export default Add;
