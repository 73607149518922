
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REACT_APP_API_URL } from '../../../config'
import axios from './../../AxiosFile';

export const consignmentThunk = createAsyncThunk('consignment', async (data) => {
  return  axios.post(`${REACT_APP_API_URL}/consignments/addConsignment/`, data)
          .then( (response) => response.data )   
})

export const consignmentAllThunk = createAsyncThunk('consignment-all', async () => {
  return axios.get(`${REACT_APP_API_URL}/consignments/getAllConsignments`)
              .then( (response) => response.data ) 
 })

const ConsignmentReducer = createSlice({
  name: 'consignment',
  initialState:{
    consignments:[],
    consignment:{}
  },
  reducers: {
    getConsignmentById(state, {payload}) {
        state.consignment = state.consignments.filter(el => el.consignment_id == payload)
        state.consignments = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(consignmentThunk.pending, (state, action) => {
        console.log('pending')
      })
      .addCase(consignmentThunk.fulfilled, (state, action) => {
           console.log('fulfilled')
           //state.consignments = action.payload
      })
      .addCase(consignmentThunk.rejected, (state, action) => {
        console.log('rejected')
      })

      .addCase(consignmentAllThunk.pending, (state, action) => {
        console.log('pending')
      })
      .addCase(consignmentAllThunk.fulfilled, (state, { payload: { data } }) => {
        console.log('fulfilled')
        if (data?.rows?.length) {
            state.consignments = [data.rows[0]]
        }
      })
      .addCase(consignmentAllThunk.rejected, (state, action) => {
        console.log('rejected')
      })
  }
})

export const {getConsignmentById} = ConsignmentReducer.actions
export default ConsignmentReducer.reducer