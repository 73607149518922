import React,{useEffect} from "react";
import { Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "../assets/css/style.css";
import "../assets/css/media.css";
import Sidebar from "./includes/Sidebar";
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import { useSelector } from "react-redux";
import {ConfirmDialog, ConfirmPopup } from '../PrimeReactComponents'
// import platformkeycloakAuthentication from "../middleware/platformkeycloakauthentication"

function Layout() {
  // platformkeycloakAuthentication();
  const navToggleRedux = useSelector((state) => state.sidebar_toggle);
  let tokenData =null
  let tokenDataModifyed=null

   tokenData = localStorage.getItem('keyCloakaccesstokenData');
  try {
    let decoded = jwt_decode(tokenData);
   
  } catch (error) {
    if(error.message){
      window.location.href="../platform"
      return;
    }
  }
  return (
    <>  
     <ConfirmDialog />
     <ConfirmPopup />   
      <div className={`template-default platform-admin  ${navToggleRedux ? "menu-closed" : ""}`} id="template-default-page">
        <Sidebar />
        <div className="fixed-header-content">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Layout;
