import React from "react";
import {TabView, TabPanel} from "../../PrimeReactComponents";
import Trucks from "./components/Trucks";

function Setup() {
    const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <>
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 bg-white">
          <div className="table-wrapper mt-5">
            <div className="row verticle-tabs">
              <div className="col-xl-2 col-lg-3">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-cog"></i> Setup
                </h2>
                <div className="card mt-5">
                  <div className="card-body">
                    <ul className="nav flex-column ">
                      <li>
                        <button className={activeIndex === 0 && 'active'} onClick={() => setActiveIndex(0)}>
                          <i className="fa-light fa-truck"></i> Vehicles
                        </button>
                      </li>
                      <li>
                        <button className={activeIndex === 1 && 'active'} onClick={() => setActiveIndex(1)}>
                          <i className="fa-light fa-folders"></i> Document Type
                        </button>
                      </li>
                      <li>
                        <button className={activeIndex === 2 && 'active'} onClick={() => setActiveIndex(2)}>
                          <i className="fa-light fa-envelope"></i> Email Log
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-10 col-lg-9 setup__page">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} scrollable>
                    <TabPanel >
                        <Trucks />
                    </TabPanel>
                    <TabPanel>
                       
                    </TabPanel>
                    <TabPanel>
                       
                    </TabPanel>
                </TabView>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Setup;
