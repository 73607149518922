import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Dropdown, Button, Modal } from "../../BootstrapComponents";
import { FilterOffset } from "../components/OffsetRight";
import { InputText, DataTable, Column, confirmDialog, confirmPopup, Toast } from "../../PrimeReactComponents";
import dataTableConfig from "./config/DatatableConfig";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { listData, removeData, vehicleCheckList, getTimesheetSingleData } from "../controllers/SetUpController";
import useDataTableFilter from '../../features/customUseHook/useDataTableFilter'
import Loader from "../../features/loader/Loader";
import Moment from 'react-moment';
import VehicleChecklistCustomModal from "./../timesheets/components/VehicleChecklistCustom";


function CheckList() {
  const { transportUserInfo } = useSelector(state => state);
  const toast = useRef(null);
  const [loader, setLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const reciveLocalData = transportUserInfo.company_id;
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [tableHeight, setTableHeight] = useState("0px");
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [checkList, setCheckList] = useState(null);
  const [vehicleData, setVehicleData] = useState(null);
  const { id } = useParams();
  const [initialState, setInitialState] = useState();
  const [show, setShow] = useState(true);


  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalItemId, setModalItemId] = useState(null);

  const handleShowModal = (itemId) => {
    setModalVisibility(true);
    setModalItemId(itemId);
  };

  const handleHideModal = () => {
    setModalVisibility(false);
    setModalItemId(null);
  };


  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 12,
    page: 1,
    sortField: "",
    sortOrder: "",
    vehicleId: id,
    filters: "",
    globalFilterValue: "",
    company_id: reciveLocalData,
    filters: {
      'driver_id': { value: '', matchMode: 'contains' },
      'status': { value: '', matchMode: 'contains' },
      'checklist_date': { value: '', matchMode: 'contains' }
    }
  });

  let loadLazyTimeout = null;
  useEffect(() => {
    loadLazyData();
  }, [lazyParams])

  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      vehicleCheckList({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {
        setTotalRecords(data?.data?.data?.count);
        setCheckList(data?.data?.data?.rows?.[0]?.checklistData);
        setVehicleData(data?.data?.data?.rows)
        setTableHeight(document?.querySelector(".table-wrapper").scrollHeight - 50 + "px");
        setLoading(false);

      });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    const { page } = lazyParams
    setLazyParams({
      ...lazyParams,
      pageCount: event.pageCount,
      first: event.first,
      page: event.page === 0 ? 1 : event.page + 1,
      rows: 12,
      multiSortMeta: event.multiSortMeta,
      filters: event.filters,
      sortField: event.sortField,
      globalFilterValue: "",
      sortOrder: lazyParams.sortOrder,
    }, []);
  }

  const onSort = (event) => {
    setLazyParams({ ...lazyParams, sortField: event.sortField, sortOrder: !lazyParams.sortOrder });
  }

  const onFilter = (event) => {
    event['first'] = 0;
    setLazyParams(event);
  }
  const onGlobalFilterChange1 = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      setLazyParams({ ...lazyParams, globalFilterValue: value });
    } else if (value.length === 0) {
      setLazyParams({ ...lazyParams, globalFilterValue: "" });
    }
  }

  let getAllData = async (vid) => {
    try {
      const { status, message, type, error, data } = await getTimesheetSingleData(vid, reciveLocalData);

      if (status) {
        setInitialState({
          checklistDetail: data?.data?.[0],
        });

      } else {
        toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000 });
      }
    } catch (error) {
      console.error('Error fetching timesheet data:', error);
      // Handle error, show an error message, etc.
    }
  }

  let getVehicleChecklist = async (val) => {
    if (val) {
      getAllData(val?.id)
      setShow(true)
      setModalVisibility(true);
      setModalItemId(val?.id);
    }

    // If val is not truthy or there is an issue, return null or another fallback
    return null;
  };


  let getVehicleChecklist1 = async (val) => {
    if (val) {
      try {
        const { status, message, type, error, data } = await getTimesheetSingleData(val.id, reciveLocalData);

        if (status) {
          setInitialState({
            checklistDetail: data?.data?.[0],
          });


          // Corrected conditional rendering
          /* return initialState?.checklistDetail   
            ? <VehicleChecklistCustomModal initialState={initialState} />
            : null; */
        } else {
          toast?.current?.show({ severity: type, summary: 'Info Message', detail: message, life: 3000 });
        }
      } catch (error) {
        console.error('Error fetching timesheet data:', error);
        // Handle error, show an error message, etc.
      }
    }

    // If val is not truthy or there is an issue, return null or another fallback
    return null;
  };



  /* let getVehicleChecklist1 = (vehicleId) => { 
     setVehicleId(vehicleId.id) 

     vehicleId && getTimesheetData(vehicleId)   

   vehicleId && <VehicleChecklistCustomModal initialState={initialState} />
  } */

  const actionButton = (rowData) => (
    <div className="d-flex gap-2">
      <Button onClick={(e) => getVehicleChecklist(rowData)} variant='' className="btn fz16 p-0 edit-btn"><i className="fa-light fa-eye"></i></Button>


      {/*  <span  className="btn fz16 p-0 edit-btn" > 
       {initialState.checklistDetail && initialState.checklistDetail.length > 1 ? <VehicleChecklistCustomModal initialState={initialState} />:''}
      </span> */}


      {/* <Button variant=''
         className="deleteRow btn fz16 p-0 delete-btn"
           onClick={() => deleteConfirm(rowData)} >
            <i className="fa-light fa-eye"></i></Button> */}
    </div>
  )

  const submitDateTemplate = (rowData) => {
    return <span>{rowData?.checklist_date !== '' ? <Moment date={rowData?.checklist_date} format="D MMM YYYY" /> : ''}</span>
  }

  const driverTemplate = (rowData) => {
    return <span>{rowData?.driver !== '' ? rowData?.driver?.first_name + ' ' + rowData?.driver?.last_name : ''}</span>
  }

  const statusDateTemplate = (rowData) => {
    if (rowData.status === '0') {
      return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-yellow`}>Pending</span>;
    } else if (rowData.status === '1') {
      return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-green`}>Approved</span>;
    } else if (rowData.status === '2') {
      return <span className={`fw600 border-0 badge py-2 fs-5 badge-bg-red`}>Rejected</span>;
    }
  }




  return (
    <>
      <Loader />
      <Toast position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-hidden flex-grow-1 bg-white">
          <div className="body-content-top">
            <div className="d-sm-flex align-items-center flex-wrap">
              <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                  <i className="fa-light fa-truck"></i>
                  Vehicle
                </h2>
              </div>
              <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                {/*  <Link to="./add-truck" className="bg-green text-white btn fz14 me-3">
                  <i className="fa-light fa-plus-circle"></i>
                </Link>Add New */}
                {/* <FilterOffset /> */}



                <div className="position-relative flex-grow-1 flex-md-grow-0 me-3">
                  <InputText placeholder="Search" name="globalFilterValue" onChange={(event) => onGlobalFilterChange1(event)} className="form-control fw500  p-2" />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                {/*  <Dropdown className="order-4">
                  <Dropdown.Toggle type="button" className="btn-gradient btn fz14 shadow-none border dropdown-icon-none" variant="">
                    <i className="fa-light fa-ellipsis"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <ul>
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fa-light me-2 fa-trash"></i>
                          Delete Selected
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fa-light me-2 fa-check"></i>
                          Active
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fa-light me-2 fa-ban"></i>
                          Inactive
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
            </div>
          </div>


          <div className="table-wrapper flex-grow-1  mt-5">

            <div className="row">
              <div className="col-sm-4">
                <div className="mb-2"><label for="" className="text-dark">Fleet Number:</label> <strong>{vehicleData?.[0]?.feet_number}</strong></div>
                <div className="mb-2"><label for="" className="text-dark">Vehicle Registration: </label> <strong>{vehicleData?.[0]?.truck_registration}</strong></div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2"><label for="" className="text-dark">Make/Model:</label> <strong>{vehicleData?.[0]?.truck_model}</strong></div>
                <div className="mb-2"><label for="" className="text-dark">Type: </label> <strong>{(vehicleData?.[0]?.truck_type === '1') ? 'Truck' : 'Trailer'}</strong></div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2"><label for="" className="text-dark">Last Serviced (KMS):</label> <strong>{vehicleData?.[0]?.last_service_detail}</strong></div>
                <div className="mb-2"><label for="" className="text-dark">Next Service due (KMS): </label> <strong>	{vehicleData?.[0]?.next_service_due}</strong></div>
              </div>
            </div>
          

            <DataTable value={checkList} selectionMode="checkbox"  dataKey="checklist_date" paginator rows={12} totalRecords={totalRecords} onPage={onPage} {...dataTableConfig} scrollDirection="both"
              selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)} {...dataTableConfig}
            >

              {/* <Column headerStyle={{ width: "3em" }} selectionMode="multiple" /> */}
              <Column header="Action" body={actionButton} style={{ flexGrow: 1, flexBasis: "100px" }}/>
              <Column sortable field='checklist_date' body={submitDateTemplate} header="Date" style={{ flexGrow: 1, flexBasis: "120px" }} />
              <Column sortable field='driver_id' body={driverTemplate} header="Driver Name" style={{ flexGrow: 1, flexBasis: "120px" }} />
              <Column sortable field='status' header="Status" body={statusDateTemplate} style={{ flexGrow: 1, flexBasis: "150px" }} value="Yes" />
              {/* <Column sortable field='truck_model' header="Make/Model" style={{ flexGrow: 1, flexBasis: "100px" }} />
                <Column sortable field='truck_year' header="Year" style={{ flexGrow: 1, flexBasis: "100px" }} />
                <Column sortable field='truck_type' header="Type" style={{ flexGrow: 1, flexBasis: "100px" }} />
                <Column sortable field='last_service_detail' header="Last Serviced (date and KMS)" style={{ flexGrow: 1, flexBasis: "250px" }} />
                <Column sortable field='next_service_due' header="Next Service due (kms)" style={{ flexGrow: 1, flexBasis: "200px" }} />
                <Column  field='' header="Vehicle check list" body={vehicleActionButton} style={{ flexGrow: 1, flexBasis: "200px" }} /> */}
            </DataTable>

            {initialState?.checklistDetail && <VehicleChecklistCustomModal initialState={initialState} isVisible={isModalVisible} itemId={modalItemId} onHide={handleHideModal} reload={loadLazyData} id={modalItemId} />}
          </div>
        </div>
      </div>


    </>


  )
}

export default CheckList

