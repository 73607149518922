import {REACT_APP_API_URL} from '../../../config'
import {appAxios} from './AxiosFile';

export async function rowCount(path){
    const res = await appAxios().get(`${REACT_APP_API_URL}${path}`);
    const result = await res.data;  

    if(result.data.count){   
        return {
            status: 1,
            message:"List Successfully fetched.",
            count:result.data.count
        }                  
    }else{
        return {
            status: 0,
            message:"Data not found!"
        }
    } 
}