import * as yup from 'yup';

const roleActionValidateSchema = yup.object().shape({
    user_type_id: yup.string().required('This Field is required.'),
    role_name: yup.string().required('This Field is required.'),

})

const roleActionInitialState = { 
    user_type_id:'',
    role_name: '', 
    menu_id: "" 


};

export { roleActionValidateSchema, roleActionInitialState }