import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import "../../assets/css/login.css";
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password';
import Button from 'react-bootstrap/Button'
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Form, ErrorMessage, Field, Formik } from 'formik';
import { LoginValidationSchema } from '../../platform-admin/Validations/LoginValidation'
import { LoginIntionalSate, loginValidateSchema } from "../../transport-portal/Validations/LoginValidation";
import Logo from '../../assets/img/logo.png'
import Logo2 from '../../assets/img/login-imge.png'
import { setTransportLoginDetails } from "../../store/transportLoginSlice"
import { getLoginData } from '../controllers/UserController'
import Loader from "../../features/loader/Loader";
import { loginApiUrl, TransportLoginDetails } from "../../config/keyClockConfig";
import { getAllUserRolePermissionData } from "../../services/commonController";
import { setRolePermissionInfo } from "../../store/rolePermissionSlice";
//const initialValue= { email:'', password:''};


function LoginTransport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState('');
  const [loader, setloader] = useState(false);
  const [invalidUser, setInvalidUser] = useState('hide');
  const toast = useRef(null);



  const onLoginTransport = async (values) => {
    var details = { ...TransportLoginDetails, "username": values.username, "password": values.password, };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    try {
      setloader(true)
      let responsData = await fetch(loginApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
      })
      responsData = await responsData.json()

      if (responsData?.error === "invalid_grant") {
        setloader(false)
        setInvalidUser('show')
       // toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Invalid user name/ password", life: 3000, });
        return;
      }
      
      if (responsData?.access_token) {
        let now = new Date();
        let isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
        let newDateTransport = now.setSeconds(now.getSeconds() + responsData?.expires_in);
        let newrefresh = new Date();
        let isoDateRefresh = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
        let newRefreshDateTransport = newrefresh.setSeconds(now.getSeconds() + responsData?.refresh_expires_in);
        localStorage.setItem("keyCloakaccesstokenTransport", responsData?.access_token)
        localStorage.setItem("keyCloakaccesstokenData", responsData?.access_token)
        localStorage.setItem("keyCloakrefreshData", responsData?.refresh_token)
        localStorage.setItem("newTimeTransport", newDateTransport)
        localStorage.setItem("newRefreshTimeTransport", newRefreshDateTransport)
        getLoginData(responsData?.access_token, 1).then(data => {
        
          localStorage.setItem('transportData', JSON.stringify(data?.data));
          dispatch(setTransportLoginDetails(data?.data))


          // Setting Role & Permission Data
          let transportData = data?.data;
         
          if(transportData !== undefined){ 
            getUserRolePermissionData(transportData?.company_id, transportData?.roleId, transportData?.user_id, transportData?.user_typeId);
          } 

          if (typeof data.data === 'object' && data.data !== null) {
            //toast.current.show({ severity: "success", summary: 'Info Message', detail: "Login Success", life: 3000, });
            navigate('/transport/dashboard')

            // setTimeout(() => {
            //   window.location.href = '/transport/dashboard'
            // }, 1000)

          } else if (data?.data === 'Invalid User') {
           // toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Invalid User", life: 3000, });
           setInvalidUser('show')
          }
          else {
            setInvalidUser('show')
          }

          setloader(false);
        })
      } else {
        setloader(false) 
      }
    } catch (error) {
      setloader(false);
      console.log("this is error", error)
    }
  }

  const getUserRolePermissionData = async (company_id, role_id, user_id, user_type_id) => {

    let results = await getAllUserRolePermissionData({ company_id, role_id, user_id, user_type_id });

    if (results?.data) {
      localStorage.setItem("rolePermissionTransportInfo", JSON.stringify(results?.data))
      dispatch(setRolePermissionInfo(results?.data))
    }
  }

  const getClose = (e) => {
      setInvalidUser('hide')
  }

  return (

    <div className="main-login-page">
      <Loader show={loader} />
      <Toast ref={toast} />
      <div className="loginWrap">
        <div className="login_container d-flex flex-column justify-content-center h-100">
          <header className="text-center text-md-start">
            <div className="login_container">
              <Link to="" className="logo">
                <img src={Logo} alt="" style={{ maxWidth: "250px" }} />
              </Link>
            </div>
          </header>
          <div className="login-Announcement">
            <div className="login-content box-remove-mobile">

              <h1 className="text-center text-lg-start">Sign in to your account</h1>

              {invalidUser === 'show' && <div className={`alert alert-danger alert-dismissible fade ${invalidUser}`}>
                <strong>Error!</strong> Invalid Username / Password
                <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={(e)=>getClose(e)}></button>
              </div>} 
              
              <Formik initialValues={LoginIntionalSate} validationSchema={loginValidateSchema} onSubmit={(values) => { onLoginTransport(values) }}
                render={
                  (props) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="">Email Address*</label>
                        <Field name="username" render={({ field }) => (
                          <>
                            <InputText className="form-control" name="username" {...field} />
                            <ErrorMessage name="username" component="span" className="from-error-span" />
                          </>
                        )} />

                      </div>
                      <div className="form-group">
                        <label htmlFor="">Password*</label>
                        <Field name="password" render={({ field }) => (
                          <>
                            <Password inputClassName="form-control" feedback={false} name="password"{...field} className="w-100" />
                            <ErrorMessage name="password" component="span" className="from-error-span" />
                          </>
                        )} />
                      </div>
                      <div className="remember-forgot">
                        <div>
                          <Checkbox inputId="Remember" checked={checked} onChange={e => setChecked(e.checked)} />
                          <label htmlFor="Remember" className="ms-3 fw600 fz16">Remember Me</label>
                        </div>
                        <Link to="forgot-password" className="ms-3 fw600 fz16">Forgot Password ?</Link>
                      </div>
                      <Button variant="" type="submit" className="submitBTN d-block text-center text-white fz16">Login</Button>
                    </Form>
                  )}
              />

            </div>

            <div className="content-wrap">
              <h2 className="text-color-green main-heading">WHY LEOPOLD TRANSPORT PORTAL</h2>
              <h3 className="sub-heading">Leopold Transport is the biggest refrigerated transport company in Geelong, Victoria.</h3>
              <p>Established in 1976, our family-owned business has a proud tradition of going the extra mile to help our customers with their logistics and transport needs.</p>
              <p>With a history in the transport industry spanning nearly 40 years, we are trusted by long-term clients who know we will get the job done on time.</p>
              <figure>
                <img src={Logo2} className="img-fluid" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <footer className="login_footer py-3 border-top">
        <div className="container-fluid d-md-flex justify-content-between text-center text-md-start">
          <ul className="mb-0">
            <li>
              <Link to="">Terms of Service</Link>
            </li>
            <li>
              <Link to="">Contact</Link>
            </li>
            <li>
              <Link to="">Support</Link>
            </li>
          </ul>
          <p className="mb-0">Copyright © 2022 LTTech TRANSPORT PTY LTD. All rights reserved</p>
        </div>
      </footer>
    </div>

  )
}

export default LoginTransport;
