import * as yup from 'yup';
 
const consignmentMailInitState={
    email_id: "", 
    subject: "", 
    body: "" 
}

const connsignmentMailValidation = yup.object().shape({
    email_id: yup.string().email('Invalid email format').required('This Field is required'),
    subject: yup.string().required('This Field is required'),
   // body: yup.string().required('This Field is required'), 
})

export {connsignmentMailValidation,consignmentMailInitState }; 