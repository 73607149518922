import * as yup from 'yup';

const LoginIntionalSate={
    username:"",
    password:"",
    grant_type:"",   
    grant_type:"password",
    client_id:"lttech-customer",
    client_secret:"mdjcobgtRvieUzpRZLpNnCwsfXjzp4W6",
   //" bearer-only":true,
    //"enable-cors":true
}


const loginValidateSchema = yup.object().shape({
    // username: yup.string().required('This Field is required'),
    username:yup.string().email('This Field must be a valid email').required('This Field is required'),
    password: yup.string().required('This Field is required'),
})

export { loginValidateSchema, LoginIntionalSate, }