import React, { useEffect, useRef, useState } from "react";
import { NavLink, json, useParams,useLocation,useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Logo1 from '../../assets/img/logo.png'
import LogoIcon from '../../assets/img/logo-icon.png'
import SidebarToggleButton from "../components/SidebarToggleButton";
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../store/userInfo'
import { getUserInfo ,getUserInfoPlateform} from '../../services/commonController';
import { loginApiUrl, platformRefreshlogin } from "../../config/keyClockConfig";
import { getLoginData } from '../controllers/UserController';
import {setLoginDetails} from "../../store/platformLoginSlice";
import SidebarComp from './sidebarComponent';
import { getAllUserRolePermissionData } from "../../services/commonController";

function Sidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {userInfo,platformUserInfo} = useSelector(state => state);

 // const { transId, userId } = useParams();
  const toast = useRef(null);
  const history = useParams();
  const location = useLocation();
  let userModiFyedId  = ''
  let transModiFyedId = ''
  const [loader, setloader] = useState(false);
  let tokenData=''
  let tokenDataModifyed=''
    tokenData = localStorage.getItem('tokenData');

    if(!localStorage.getItem('userId') || !localStorage.getItem('transId')||!localStorage.getItem('userId1') || tokenData ===null){
      // window.location.href = '/transport'
       navigate('/platform');
       } 


    tokenDataModifyed=  tokenData!=="undefined" ? JSON.parse(tokenData):{}
    let newTimeWithsession=localStorage.getItem("newTime")
    let newRefreshTime=localStorage.getItem("newRefreshTime")
   let tokenDataKey = localStorage.getItem('keyCloakrefreshData');
    var now = new Date(); 
    var isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
    var currentDate =now.getTime()
   
 // if(transId){

   // transModiFyedId = transId.split("=").pop()
    
    localStorage.setItem('transId', tokenDataModifyed?.company_id);
   // userModiFyedId = userId.split("=").pop()
    localStorage.setItem('userId1', tokenDataModifyed?.user_id);
    localStorage.setItem('userId', tokenDataModifyed?.relation_id);
    //}
    
    if(localStorage.getItem('userId1')){
     userModiFyedId = localStorage.getItem('userId1');
    
  }
  if(localStorage.getItem('transId')){
    transModiFyedId = localStorage.getItem('transId');
   
 }
     const getUserInfoData = async () => {
    const results = await getUserInfoPlateform(platformUserInfo?.user_id,platformUserInfo?.company_id);
    localStorage.setItem('userId', results?.data?.resultData?.relation_id); 
    dispatch(setUserInfo(results?.data));
    }

    
    useEffect(() => {
      getUserInfoData()
      getUserRolePermissionData(tokenDataModifyed?.company_id,tokenDataModifyed?.roleId, tokenDataModifyed?.user_id, tokenDataModifyed?.user_typeId);
    },[])

        
    useEffect(() => {
      refreshTokencheck()    
    },[location])


let refreshTokencheck =async ()=>{
 // console.log("ascces token expait"); 
  try {
    if(currentDate > newTimeWithsession){
      if(parseInt(newRefreshTime) > currentDate ){
     
        var details = {...platformRefreshlogin,refresh_token:tokenDataKey};
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
       
        formBody = formBody.join("&");     
        try {
          setloader(true)
          let responsData = await fetch(loginApiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
          }) 
          responsData = await responsData.json()
          console.log("respons", responsData);
          if (responsData?.error === "invalid_grant") {
            setloader(false)
            //toast?.current?.show({ severity: "warn", summary: 'Info Message', detail: "Invalid user name/ password", life: 3000, });
            return;
          }
          if (responsData?.access_token){
            let now = new Date(); 
            let isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();      
            let newDate = now.setSeconds(now.getSeconds() + responsData?.expires_in);
            let newrefresh = new Date(); 
            let isoDateRefresh = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
            let newRefreshDate = newrefresh.setSeconds(now.getSeconds() + responsData?.refresh_expires_in);
            localStorage.setItem("keyCloakaccesstokenData", responsData?.access_token);
            localStorage.setItem("keyCloakrefreshData", responsData?.refresh_token) ;       
            localStorage.setItem("newTime",newDate);    
            localStorage.setItem("newRefreshTime",newRefreshDate); 
            localStorage.setItem("keyCloakaccesstokenPlatfrom", responsData?.access_token);
            getLoginData(responsData?.access_token, 3).then(data => {
              localStorage.setItem('tokenData', JSON.stringify(data?.data));         
              dispatch(setLoginDetails(data?.data))

              let companyRoleData = data?.data

              getUserRolePermissionData(companyRoleData?.company_id,companyRoleData?.roleId, companyRoleData?.user_id, companyRoleData?.user_typeId);

              if (typeof data.data === 'object' && data.data !== null) {
                
               // toast?.current?.show({ severity: "success", summary: 'Info Message', detail: "Login Success", life: 3000, });
               // navigate('/platform/dashboard')
                //window.location.href='/platform/dashboard'
                console.log("object");
              } else if (data?.data === 'Invalid User') {
                toast?.current?.show({ severity: "warn", summary: 'Info Message', detail: "Invalid User", life: 3000, });
                // navigate('/platform/dashboard')
              }
              else {
                toast?.current?.show({ severity: "warn", summary: 'Info Message', detail: "Some thing went worng", life: 3000, });
              }
    
              setloader(false);
            })          
          } else {
            setloader(false)        
          }
        } catch (error) {
          setloader(false);
          console.log("this is error", error)
        }

      }else if (newRefreshTime < currentDate){ 
        //toast?.current?.show({ severity: "warn", summary: 'Info Message', detail: "Your session has been expired!!", life: 3000, });
        window.location.href="/platform" 
        localStorage.removeItem('plateFromData');
        localStorage.removeItem('newTime');
        localStorage.removeItem('newRefreshTime'); 
        localStorage.removeItem("keyCloakaccesstokenPlatfrom");
        toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Your session has been expired!!", life: 3000, })
      } 
    }
  } catch (error) {
    if(error){
     console.log("error",error);

    }
  }
}

const getUserRolePermissionData = async (company_id, role_id, user_id, user_type_id) => {   
      
  let results = await getAllUserRolePermissionData({company_id, role_id, user_id, user_type_id }); 
  if (results?.data) {    
    localStorage.setItem("rolePermissionPlatformInfo", JSON.stringify(results?.data)) 
  } 
}

let rolePermissionPlatformInfoData = JSON.parse(localStorage.getItem('rolePermissionPlatformInfo')) 
  return (
    <>
      <aside className="aside-bar main-nav" id="menuBar-toggle">
        <div className="position-relative sidebar-logo">
          <NavLink to="dashboard">
            <div className="logo py-sm-2 px-4 d-flex justify-content-between align-items-center">
              <img src={Logo1} className="lg-logo img-fluid" alt="" style={{ maxWidth: "156px" }} />
              <img src={LogoIcon} className="sm-logo img-fluid m-0" alt="" />
              <SidebarToggleButton/>
            </div>
          </NavLink>
        </div>

        <SidebarComp allUserRolePermissionData={rolePermissionPlatformInfoData}  />

        <ul className="h-100">
        {/*   <li>
            <NavLink to="dashboard" activeclassname="active">
              <i className="fa-light fa-gauge-high"></i> <span> Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="companies" activeclassname="active">
              <i className="fa-light fa-building"></i> <span> Company Management</span>
            </NavLink>
          </li>     
          <li>
            <NavLink to="usermanagment" activeclassname="active">
            <i className="fa-light fa-users"></i> <span> User Management</span>
            </NavLink>
          </li>     */} 
          <li>
            <NavLink to="emailloggers" activeclassname="active">
              <i className="fa-light fa-briefcase"></i> <span> Email Log</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="rolemanagement" activeclassname="active">
            <i className="fa-light fa-users"></i> <span> Role  Management</span>
            </NavLink>
          </li>    
          {/* <li>
            <NavLink to="setup" activeclassname="active">
              <i className="fa-light fa-cog"></i> <span> Setup</span>
            </NavLink>
          
          </li>  */}
        </ul>  
      </aside>
    </>
  );
}

export default Sidebar;
