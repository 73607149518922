import { REACT_APP_API_URL } from '../../config'
import {appAxios} from './common/AxiosFile';

export const title = "Consignment Management"

export async function listData(params) {
    try {
        let { rows, page, desc, filters, sortOrder, sortField,status, globalFilterValue,company_id,document_number,document_type } = JSON.parse(params?.lazyEvent)

        const res = await appAxios().get(`${REACT_APP_API_URL}docManager/allDocManager/${company_id}?keyword=${globalFilterValue}&document_type=${document_type}&document_number=${document_number}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);

        const result = await res.data;
        if (result.data.count) {
            return {
                status: 1,
                message: "List Successfully fetched.",
                type: 'success',
                error: null,
                data: result
            }
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}

export async function singleData(id) {
    try {
        const res = await appAxios().get(`${REACT_APP_API_URL}docManager/editDocManagerById/${id}`);

        const result = await res.data;
        if (result.status) {
            return {
                status: 1,
                message: "Data Successfully fetched.",
                type: 'success',
                error: null,
                data: result
            }
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            }
        }
    } catch (error) {

        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error
        }
    }

}

export async function addData(data, headers) {

    try {
        const res = await appAxios().post(`${REACT_APP_API_URL}docManager/addDocManager`, data, headers);
        const result = await res.data;   

        if (result.status) {
            return {
                status: result.status,
                message: result.data,
                type: 'success',
                error: null,
                data: result.data.data,
            };
        } else {
            return {
                status: 0,
                message: "Something is error!",
                type: 'info',
                error: null,
                data: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }

}

export async function updateData(data, id, headers) {
    try {
        const res = await appAxios().put(`${REACT_APP_API_URL}docManager/updateDocManager/${id}`, data, headers);
        const result = await res.data;
        if (result.status) {
            return {
                status: result.status,
                message: "Data Successfully Updated.",
                type: 'success',
                error: null,
                data: result.data.data,
            };
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
                data: null,
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}

export async function deleteData(data) {  
    try {
        const res = await appAxios().put(`${REACT_APP_API_URL}docManager/deleteDocManager`, {id:[data]}); 
        const result = await res.data; 

        if (result.status) {
            return {
                status: result.status,
                message: "Data Successfully Deleted.",
                type: 'success',
                error: null,
            }
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null
            }
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}
 

export async function uploadDocument(data, headers) {

    try {
        const res = await appAxios().post(`${REACT_APP_API_URL}transport/driverdocumentupload`, data, headers);
        const result = await res.data;

        if (result.status) {
            if (result.status) {
                return {
                    status: result.data.success,
                    message: "Data Successfully Added.",
                    type: 'success',
                    error: null,
                    data: result.data,
                };
            } else {
                return {
                    status: result.data.success,
                    message: result.data.data,
                    type: 'info',
                    error: null,
                    data: result.data,
                };
            }
        } else {
            return {
                status: 0,
                message: 'Something went wrong.',
                type: 'info',
                error: null,
            };
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}

export async function activeInactive(status, id) {
    try {
        const res = await appAxios().put(`${REACT_APP_API_URL}docManager/changeStatus/${id}/${status === '1' ? 0 : 1}`);
        const result = await res.data;

        if (result.status) {
            return {
                type: 'success',
                error: null,
                status: 1,
                message: `Data Successfully ${status === '1' ? 'Deactivated' : 'Activated'}.`,
                data: res.data
            }
        } else {
            return {
                status: 0,
                message: "Data not found!",
                type: 'info',
                error: null,
            };
        }
    } catch (error) {
        return {
            status: 0,
            message: error.message,
            type: 'info',
            error: error,
        };
    }
}

export async function activeInactiveBulk(status,ids, companyId){ 
    try {
      const res = await appAxios().put(`${REACT_APP_API_URL}docManager/bulkChangeStatus/${companyId}`,{doc_manager_id:ids,status});
      const result = await res.data;                
      if(result.status){
     
          return {               
              type:'success',
              error:null,                                    
              status: 1,
              message:res.data.data.msg,
              data: res.data
          }                  
      }else{
          return {                      
              status: 0,
              message:res.data.data.msg,         
              type:'info',
              error:null,                                         
          };   
      } 
    } catch (error) {
          return {                      
              status: 0,
              message:error.msg,         
              type:'info',
              error:error,                                         
          };   
    }
  }

  export async function removeDataBulk(ids,companyId ){      
    try {
        const res = await appAxios().put(`${REACT_APP_API_URL}docManager/bulkDelete/${companyId}`,{doc_manager_id:ids});
        const result = await res.data;                        
        if(result.status){   
            return {
                status: result.status,
                message:"Data Successfully Deleted.",
                type:'success',
                error:null,                                                              
            }                  
        }else{
            return {
                status: 0,                    
                message:"Data not found!" ,
                type:'info',   
                error:null                                    
            }
        } 
    } catch (error) {
        return {                      
            status: 0,
            message:error.message,         
            type:'info',
            error:error,                                         
        };   
    }
}

