import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams, useLocation ,useNavigate} from "react-router-dom";
import Logo1 from '../../assets/img/logo-black.png'
import LogoIcon from '../../assets/img/logo-icon.png'
import SidebarToggleButton from "../components/SidebarToggleButton";
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../store/userInfo'
import { getUserInfo } from '../../services/commonController';
import { loginApiUrl, customerRefreshlogin } from "../../config/keyClockConfig";
import { getLoginData } from '../controllers/UserController';
import { setCustomerLoginDetails } from "../../store/customerLoginSlice"
import SidebarComp from './sidebarComponent';
import { getAllUserRolePermissionData } from "../../services/commonController";

function Sidebar() {
  const dispatch = useDispatch();
  // const userInfoSelector = useSelector(state => state.userInfo);
  const { userInfo, customerUserInfo } = useSelector(state => state);
  const { transId, userId, customerId } = useParams();
  const location = useLocation();
  const [loader, setloader] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  let userModiFyedId = ''
  let transModiFyedId = ''
  let customerModifiedId = ''
  let tokenData = ''
  let tokenDataModifyed = ''
  tokenData = localStorage.getItem('customerData');

  if(!localStorage.getItem('userId') || !localStorage.getItem('transId')||!localStorage.getItem('userId1') || tokenData ===null){
    // window.location.href = '/transport'
     navigate('/customer');
     } 
  tokenDataModifyed = tokenData !== "undefined" ? JSON.parse(tokenData) : {}
  let newTimeWithsession = localStorage.getItem("newTimeCustomer")
  let newRefreshTime = localStorage.getItem("newRefreshTimeCustomer")
  let tokenDataKey = localStorage.getItem('keyCloakrefreshData');
  var now = new Date();
  var isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
  var currentDate = now.getTime()
  //if(transId){
  // transModiFyedId = transId.split("=").pop()
  localStorage.setItem('transId', tokenDataModifyed?.company_id);

  // userModiFyedId = userId.split("=").pop()
  localStorage.setItem('userId1', tokenDataModifyed?.user_id);

  // customerModifiedId = customerId.split("=").pop()
  localStorage.setItem('userId', tokenDataModifyed?.relation_id);
  // }


  if (localStorage.getItem('userId1')) {
    userModiFyedId = localStorage.getItem('userId1');
  }

  if (localStorage.getItem('userId')) {

    customerModifiedId = localStorage.getItem('userId');
  }
  if (localStorage.getItem('transId')) {
    transModiFyedId = localStorage.getItem('transId', transModiFyedId);
  }




  const getUserInfoData = async () => {
    const results = await getUserInfo(customerUserInfo.user_id, customerUserInfo.company_id, customerUserInfo.relation_id);
    //console.log("sidebar log.....",results);
    localStorage.setItem('userId', results.data?.resultData.relation_id);
    localStorage.setItem('loggedIn', true);
    dispatch(setUserInfo(results.data));
  }

  //}
  useEffect(() => {
    // console.log("this is side bar......",customerUserInfo);
    getUserInfoData();
    if (tokenDataModifyed !== '') {
      getUserRolePermissionData(tokenDataModifyed?.company_id, tokenDataModifyed?.roleId, tokenDataModifyed?.user_id, tokenDataModifyed?.user_typeId);
    }
  }, [])
  useEffect(() => {
    refreshTokencheck()
    // console.log('dadasdsadsadas');

  }, [location])


  let refreshTokencheck = async () => {
    // console.log("ascces token expait"); 
    try {
      if (currentDate > newTimeWithsession) {
        if (parseInt(newRefreshTime) > currentDate) {

          var details = { ...customerRefreshlogin, refresh_token: tokenDataKey };
          var formBody = [];
          for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }

          formBody = formBody.join("&");
          try {
            setloader(true)
            let responsData = await fetch(loginApiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: formBody
            })
            //console.log(responsData,"responsData");
            responsData = await responsData.json()
            // console.log("respons", responsData);
            if (responsData?.error === "invalid_grant") {
              setloader(false)
              //toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Invalid user name/ password", life: 3000, });
              return;
            }

            if (responsData?.access_token) {
              let now = new Date();
              let isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
              let newDate = now.setSeconds(now.getSeconds() + responsData?.expires_in);
              let newrefresh = new Date();
              let isoDateRefresh = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
              let newRefreshDate = newrefresh.setSeconds(now.getSeconds() + responsData?.refresh_expires_in);
              localStorage.setItem("keyCloakaccesstokenData", responsData?.access_token);
              localStorage.setItem("keyCloakrefreshData", responsData?.refresh_token);
              localStorage.setItem("newTimeCustomer", newDate);
              localStorage.setItem("newRefreshTimeCustomer", newRefreshDate);
              localStorage.setItem('keyCloakaccesstokenCustomer', responsData?.access_token)
              //console.log("this is log ne token",localStorage.getItem('keyCloakaccesstokenCustomer',responsData?.access_token));
              getLoginData(responsData?.access_token, 2).then(data => {
                localStorage.setItem('customerData', JSON.stringify(data?.data));
                dispatch(setCustomerLoginDetails(data?.data))




                if (typeof data.data === 'object' && data.data !== null) {
                  // toast.current.show({severity:"success", summary: 'Info Message', detail: "Login Success" , life: 3000,});
                  // navigate('/customer/dashboard')
                  // window.location.href="/customer/dashboard"
                 
                } else if (data?.data === 'Invalid User') {
                  toast?.current?.show({ severity: "warn", summary: 'Info Message', detail: "Invalid User", life: 3000, });
                }
                else {
                  toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Some thing went worng", life: 3000, });
                }
                setloader(false);
              })
            } else {
              setloader(false)
            }
          } catch (error) {
            setloader(false);
            console.log("this is error", error)
          }

        } else if (newRefreshTime < currentDate) {
          // toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Your session has been expired!!", life: 3000, });
          window.location.href = "/customer"
          toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Your session has been expired!!", life: 3000, });
          localStorage.removeItem('customerData');
          localStorage.removeItem('newTimeCustomer');
          localStorage.removeItem('keyCloakrefreshData');
          localStorage.removeItem('keyCloakaccesstokenCustomer');
        }
      }
    } catch (error) {
      if (error) {
        console.log("error", error);

      }
    }
  }


  const getUserRolePermissionData = async (company_id, role_id, user_id, user_type_id) => { 

    let results = await getAllUserRolePermissionData({ company_id, role_id, user_id, user_type_id });
    if (results?.data) {
      localStorage.setItem("rolePermissionCustomerInfo", JSON.stringify(results?.data))
    }
  }

  let rolePermissionCustomerInfoData = JSON.parse(localStorage.getItem('rolePermissionCustomerInfo'))
  return (
    <>
      <aside className="aside-bar main-nav" id="menuBar-toggle">
        <div className="position-relative sidebar-logo">
          <NavLink to="dashboard">
            <div className="logo py-sm-2 px-4 d-flex justify-content-between align-items-center">
              <img src={Logo1} className="lg-logo img-fluid" alt="" style={{ maxWidth: "156px" }} />
              <img src={LogoIcon} className="sm-logo img-fluid m-0" alt="" />
              <SidebarToggleButton />
            </div>
          </NavLink>
        </div>

        <SidebarComp allUserRolePermissionData={rolePermissionCustomerInfoData} />

        <ul className="h-100">
          <li>
            <NavLink to="emailloggers" activeclassname="active">
              <i className="fa-light fa-briefcase"></i> <span> Email Log</span>
            </NavLink>
          </li>
        </ul>

        {/* <ul className="h-100">
          <li>
            <NavLink to="dashboard" activeclassname="active">
              <i className="fa-light fa-gauge-high"></i> <span> Dashboard</span>
            </NavLink>
          </li> 
          <li>
            <NavLink to="consignments" activeclassname="active">
              <i className="fa-light fa-briefcase"></i> <span> Consignments</span>
            </NavLink>
          </li>         
          <li>
            <NavLink to="usermanagment" activeclassname="active">
            <i className="fa-light fa-users"></i> <span> User Management</span>
            </NavLink>
          </li>         
        </ul> */}
      </aside>
    </>
  );
}

export default Sidebar;
