import {REACT_APP_API_URL} from '../../config'
import {appAxios} from './common/AxiosFile';

 export const title = "Company Management"

    export async function listData(params){
       
        try {
            const {rows,page,desc,filters,sortOrder,sortField,globalFilterValue,status}=params?.lazyEvent;
            const {company_code,company_name}=filters         
            const res = await appAxios().get(`${REACT_APP_API_URL}company/companylist?keyword=${globalFilterValue}&status=${status}&sortkey=${sortField}&sortvalue=${sortOrder === false ?"desc":"asc"}&page=${page}&size=${rows}`);
            const result = await res.data;  
            if(result.data.count){   
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data,                  
                } 
                               
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function singleData(id){        
        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}company/editcompany/${id}`);  
            const result = await res.data;               
            if(result.status){   
                return {
                    status: 1,
                    message:"Data Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: res.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {     
           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }

    export async function addData(data){

        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/addcompany/`, data);
            const result = await res.data;            
            if(result.status){                  
                if(result.data.success){
                    return {                      
                        status: result.data.success,
                        message:"Data Successfully Added.",
                        type:'success',
                        error:null,                       
                        data:result.data.data,
                    };     
                }else{
                    return {                      
                        status: result.data.success,
                        message:result.data.data,
                        type:'info',
                        error:null,
                        data:result.data.data,                                           
                    };                         
                }                    
            }else{
               
                return {                      
                    status: 0,
                    message:result?.message,
                    type:'info',
                    error:null,                                           
                };                    
            } 
        } catch (error) {                
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                           
            };                  
        }         
    }

    export async function editData(data,id){
       try {
            const res = await appAxios().put(`${REACT_APP_API_URL}company/companyupdate/${id}`, data);
            const result = await res.data;                      
            if(result.status){          
                return {                      
                    status: result.status,
                    message:"Data Successfully Updated.",
                    type:'success',
                    error:null,                       
                    data:result.data.data,
                };               
            }else{
                return {
                    status:0,
                    message:"Data not found!",
                    type:'info',
                    error:null,                       
                    data:null,                    
                }
            } 
       } catch (error) {       
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
       }
    }

    export async function removeData(companyId){        
        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/companybulkdelete`, {companyid:[companyId]});
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function activeInactive(status,id){
      try {
        const res = await appAxios().put(`${REACT_APP_API_URL}company/companyupdate/${id}/${status === '1'? 0:1}`);
        const result = await res.data;                
        if(result.status){   
            return {               
                type:'success',
                error:null,                                    
                status: 1,
                message:`Data Successfully ${status === '1'? 'Deactivated' : 'Activated'}.`,
                data: res.data
            }                  
        }else{
            return {                      
                status: 0,
                message:"Data not found!",         
                type:'info',
                error:null,                                         
            };   
        } 
      } catch (error) {
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
      }
    }


    export async function activeInactiveBulk(status,ids){
        
        try {
          const res = await appAxios().post(`${REACT_APP_API_URL}company/companyBulkChangeStatus`,{companyid:ids,status});
          const result = await res.data;                
          if(result.status){
         
              return {               
                  type:'success',
                  error:null,                                    
                  status: 1,
                  message:res.data.data.msg,
                  data: res.data
              }                  
          }else{
              return {                      
                  status: 0,
                  message:res.data.data.msg,         
                  type:'info',
                  error:null,                                         
              };   
          } 
        } catch (error) {      
              return {                      
                  status: 0,
                  message:error.msg,         
                  type:'info',
                  error:error,                                         
              };   
        }
      }
  

      export async function removeDataBulk(ids){        
        try {
            const res = await appAxios().post(`${REACT_APP_API_URL}company/companybulkdelete`,{companyid:ids});
            const result = await res.data;                        
            if(result.status){   
                return {
                    status: result.status,
                    message:"Data Successfully Deleted.",
                    type:'success',
                    error:null,                                                              
                }                  
            }else{
                return {
                    status: 0,                    
                    message:"Data not found!" ,
                    type:'info',   
                    error:null                                    
                }
            } 
        } catch (error) {
            console.log("this is error",error);
            return {                      
                status: 0,
                message:error.message,         
                type:'info',
                error:error,                                         
            };   
        }
    }

    export async function getUniquData(params){
        const {type}= params
        try {
         
            
            const res = await appAxios().get(`${REACT_APP_API_URL}custom/generate-random-number/${type}`);
            const result = await res.data;  
;
            if(result.status===1){   
               // console.log("this is log.....2323",result)
                return {
                    status: 1,
                    message:"List Successfully fetched.",                    
                    type:'success',
                    error:null,
                    data: result.data.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }


    export async function getUserInfo(id,company_id="",customerId=""){

        try {
            const res = await appAxios().get(`${REACT_APP_API_URL}company/userinfo/${id}/${company_id}/${customerId}`); 
            const result = await res.data;   
            if(result){   
                return {
                    status: 1,                    
                    type:'success',
                    error:null,
                    data: res.data?.data
                }                  
            }else{
                return {
                    status: 0,
                    message:"Data not found!",
                    type:'info',
                    error:null,
                }
            } 
        } catch (error) {           
            return {
                status: 0,
                message:error.message,                
                type:'info',
                error:error
            }     
        }
            
    }



