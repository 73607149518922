import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '../../../BootstrapComponents'
import { InputText, Dropdown as Select, confirmDialog, Toast } from "../../../PrimeReactComponents";
import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik';
import { addressBookInitialState, addressBookValidateSchema } from '../../Validations/addressBookValidation'
import { getCountry, getState } from '../../../services/commonController';
import { addCustomerAddress } from '../../controllers/customerController';
import { acceptChecklist, singleData } from "../../controllers/timeSheet"
import RejectModal from './RejectChecklist';


function VehicleChecklistCustomModal(props) { 
 

  const { transportUserInfo } = useSelector(state => state);
  const [rejectModalShow, setRejectModalShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(props.show)
  const [loader, setLoader] = useState();
  const navigate = useNavigate();
  const toast = useRef(null);
  const { id } = useParams(); 
 
  const reciveLocalData = transportUserInfo.company_id;

  let modifyedData = props.initialState?.checklistDetail?.checklist_value
  let jsonpasrsConvertedData = JSON.parse(modifyedData)

  const customerAddressSubmit = () => { 

   acceptChecklist(props.initialState.checklistDetail.id).then(({ status, type, message }) => {
      if (status) {
        setModalShow(false)
        singleData(id, reciveLocalData)
        toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
        setLoader(false)
        props.onHide()
        props.reload(props.id)
        navigate(`/transport/vehicle/checklist/${props.initialState.checklistDetail?.truck_setup_id}`)
      }
    })    
  }

  const handleRejectButtonClick = () => {
    setRejectModalShow(true);
  };

  const handleRejectModalClose = () => {
    setRejectModalShow(false);
    setModalShow(false)
    props.onHide()
    singleData(id, reciveLocalData)
  };

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Formik initialValues={addressBookInitialState} onSubmit={customerAddressSubmit}
        render={({ values }) => (
          <>

            <Modal show={props.isVisible} onHide={() => setModalShow(false)} size="xl" centered id={props.initialState?.checklistDetail?.id}>
               <Form className='d-flex flex-column overflow-auto'>
                <Modal.Header closeButton={false} className="p-4">
                  <Modal.Title>Vehicle Checklist</Modal.Title>

                  <Modal.Title> 
                  {(props.initialState.checklistDetail.status === '0') && (
                      <Modal.Title>
                        <strong>Status</strong>:
                        <h3 className='btn fz14 text-danger'>
                          <strong>Pending</strong>
                        </h3>
                      </Modal.Title>
                    )}

                    {(props.initialState.checklistDetail.status === '1') && (
                      <Modal.Title>
                        <strong>Status</strong>:
                        <h3 className='btn fz14 text-green'>
                          <strong>Approved</strong>
                        </h3>
                      </Modal.Title>
                    )}

                    {(props.initialState.checklistDetail.status === '2') && (
                      <Modal.Title>
                        <strong>Status</strong>:
                        <h3 className='btn fz14 text-danger'>
                          <strong>Rejected</strong>
                        </h3>
                      </Modal.Title>
                    )} 
                    
                  </Modal.Title>
                  <Button className="btn-close ms-2" onClick={props.onHide} variant=""></Button>

                </Modal.Header>
                <Modal.Body className='overflow-auto'>
                  <div className="modal-body p-4">

                    <div className="row g-5">
                      {jsonpasrsConvertedData && jsonpasrsConvertedData.length > 1 && jsonpasrsConvertedData.map((val, indx) =>
                        <div className="col-md-6">
                          <h3 className="d-flex justify-content-between mb-3 text-black">{val.checklist_name} <span className="btn p-0" data-bd-customclassName="text-start" data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-original-title="<ul className='d-flex flex-column'>
                                                <li> <span>»</span> Pressure/vacuum gauge indicator shows a failure  </li>
                                                <li> <span>»</span> Pressure, vacuum or low level warning devices or gauges are not operating </li>
                                                <li> <span>»</span> Air reservoir drain valves are not operational</li>                                            
                                            </ul>"><i className="fa-light fa-info-circle fz16 "></i></span></h3>
                          {val && val?.subcategories && val?.subcategories.map((sub, idx) =>
                            <ul className="list-group">
                              <li className="list-group-item p-3 d-flex align-items-center gap-3">
                                <input type="checkbox" readOnly checked={sub.checklistvalue === true} className="mb-0" />
                                {sub.checklist_name}</li>

                            </ul>
                          )}
                        </div>
                      )}

                    </div>

                    {(props.initialState.checklistDetail.status === '2') &&
                      (<><p className="mt-5"><strong>Reason for Rejection</strong></p>
                        <p>{props.initialState.checklistDetail?.comment}</p></>)}

                  </div>

                </Modal.Body>
                <div className="modal-footer py-3">
                  <div className="btn-group">
                    <button type="button" className="btn fz14 btn-gradient" onClick={props.onHide}>Cancel</button>

                    {(props.initialState.checklistDetail.status === '0') && (<>
                      <button type="button" className="btn fz14 bg-red text-white" onClick={handleRejectButtonClick}>Reject</button>
                    </>)}


                    {(props.initialState.checklistDetail.status !== '1') && (<>
                      <button type="submit" className="btn fz14 bg-green text-white">
                        Approve</button></>)} 
 
                  </div>
                </div>
              </Form>
            </Modal>

            <RejectModal rejectModalShow={rejectModalShow} handleRejectModalClose={handleRejectModalClose}
              initialState={props.initialState}
              checklistId={props.initialState?.checklistDetail?.id}
              reload={props.reload}
              id={props.id}
            />

          </>
        )}


      />
    </>
  ) 
}

export default VehicleChecklistCustomModal

