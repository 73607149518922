import * as yup from 'yup';

const roleActionValidateSchema = yup.object().shape({
 /*  user_type_id: yup.string().required('This Field is required.'),
    role_name: yup.string().required('This Field is required.'),  */
})
 

const roleActionInitialState2 = {
    data: [
        { 
            id:"" ,
            user_type_id:"" ,
            role_name:"" ,
            role_id:"" ,
            menu_name:"" ,
            userTypeData: {
                id: 1,
                user_type_name:"" ,
                createdAt:"" ,
                updatedAt:"" ,
                is_deleted:"" ,
                status:"" 
            },
            menuActionData: [
                {
                    id:"" ,
                    action_name:"" ,
                    status:"" 
                },
                {
                    id:"" ,
                    action_name:"" ,
                    status: ""
                },
                {
                    id:"" ,
                    action_name:"" ,
                    status: ""
                },
                {
                    id:"" ,
                    action_name:"" ,
                    status: ""
                }
            ]
        }
    ] 
};


 const roleActionInitialState = { 
    menuDetails:[]
   /*  menuDetails:[{
        menu_id:"",
        menu_name:"",
        user_type_id:"1",
        status:"",
        permissionDetails:[{
            action_id:"",
            action_name:"",
            action_status:""
        }]
    }] */
};
 

 

export { roleActionValidateSchema, roleActionInitialState }