import React, { useEffect } from 'react'
import { TabView, TabPanel } from "../../PrimeReactComponents";
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import { useParams } from 'react-router-dom';



const TransportUserProfile = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const {id} = useParams();
 
  const getActiveData = () => {
    if(id === 'change-password'){
      setActiveIndex(1)
     }else{
      setActiveIndex(0)
     }
  }
 
  useEffect(() =>{
    getActiveData()
  },[id])
   
  return (
    <div className="page-wrapper-without-aisde">
      <div className="body-content-wrap overflow-auto flex-grow-1 bg-white">
        <div className="table-wrapper mt-5">
          <div className="row verticle-tabs">
            <div className="col-xl-2 col-lg-3">
              <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0"><i className="fa-light fa-cog" /> Setup</h2>
              <div className="card mt-5 ">
                <div className="card-body">
                  <ul className="nav flex-column nav-pills">
                    <li><button data-bs-toggle="pill" className={activeIndex === 0 && 'active'} onClick={() => setActiveIndex(0)}><i className="fa-light fa-user" /> Profile</button></li>
                    <li><button data-bs-toggle="pill" className={activeIndex === 1 && 'active'} onClick={() => setActiveIndex(1)}><i className="fa-light fa-key" /> Change Password</button></li>
                    {/* <li><button data-bs-toggle="pill" data-bs-target="#tab-Settings"><i className="fa-light fa-cog" /> Settings</button></li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-9 setup__page">
              <div className="tab-content" id="v-pills-tabContent"> 

                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
                  <TabPanel><Profile /></TabPanel>
                  <TabPanel><ChangePassword /></TabPanel>
                </TabView>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransportUserProfile
