import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import LandingPage from "./LandingPage";

import {
  Login, ForgotPassword, Profile, Dashboard, Layout, PageError, LoginPlateform,
  Companies, CompanyList, AddCompany, ViewCompany, EditCompany,NotFound as PlatformNotFound,
  EditTruckType, AddTruckType, TrucksType, PlatformUserManagementLayout, PlatformUserManagementList, RolePermissionLayout, RolePermissionList, AddRolePermission, EditRolePermission
} from "./platform-admin/ExportPages";

import {
  Dashboard as TransportDashboard, Layout as TransportLayout, PageError as TransportError, NotFound as TransportNotFound, ConsignmentLayout, ConsignmentList, ConsignmentAdd, ConsignmentEdit, ConsignmentView,
  ManifestLayout, ManifestList, ManifestAdd, ManifestEdit, ManifestView, CustomerLayout, CustomerList,
  CustomerAdd, CustomerEdit, CustomerView, CustomerAddressView, AddTruck, Setup, UserManagementList, UserManagementLayout,
  DriverManagmentLayout, DriverManagementList, DriverManagementAdd, LoginTransport,
  TimeSheetList, TimeSheetAdd, Vehicle, CheckList, CheckListDetail, TimeSheetView, AddDocumentType, DocManagerLayout, DocManagerList, DocManagerAdd, DocManagerEdit, UserDetailForm
} from "./transport-portal/ExportPages";


import {
  Dashboard as CustomerDashboard, Layout as CustomerPortalLayout, PageError as CustomerError, CustomerConsignmentLayout, CustomerConsignmentList, CustomerConsignmentAdd, CustomerConsignmentEdit, CustomerConsignmentView,
  CustomerManifestLayout, CustomerManifestList, CustomerManifestAdd, CustomerManifestEdit, CustomerManifestView,NotFound as CustomerNotFound,
  CustomerUserManagementList, CustomerUserManagementLayout, LoginCustomer,CustomerAddressBooks
} from "./customer-portal/ExportPages";


import Registeration from "./auth/Registeration";
import TransportUserProfile from "./transport-portal/auth/TransportUserProfile";
import CustomerUserProfile from "./customer-portal/auth/CustomerUserProfile";
import PlatformUserProfile from "./platform-admin/auth/PlatformUserProfile";
import ThankYou from "./auth/ThankYou";
import ChangePasswordMail from "./auth/ResetPassword";
import LinkExpaired from "./auth/Expairedlink";
import ThankYouRest from "./auth/Resetthankyou";  
import PlatformEmailLogger from "./platform-admin/emailLogger/PlatformEmailLogger"; 
import EmailLogger from "./customer-portal/emailLogger/EmailLogger"; 
import FaultReports from "./transport-portal/reports/FaultReports"; 
import View from "./transport-portal/reports/view";
 
function App() {
 
  return (

    <Provider store={store}>
      <BrowserRouter>
        <Routes> 
          <Route path="/" element={<LandingPage />} />
          <Route path="registration" element={<Registeration />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="reset-password" element={<ChangePasswordMail />} />
          <Route path="linkexparied" element={<LinkExpaired />} />
          <Route path="thankyourest" element={<ThankYouRest />} /> 
        {/* Transport Panel */}


          <Route exact path="transport" >
            <Route index element={<LoginTransport />} />
            <Route path="forgot-password" element={<ForgotPassword />} />

            <Route path="/transport" element={<TransportLayout />}>
              <Route path="profile" element={<TransportUserProfile />} />
              <Route path="profile/:id" element={<TransportUserProfile />} />
              <Route path="dashboard" element={<TransportDashboard />} />
              <Route path="dashboard/:transId&:userId" element={<TransportDashboard />} />
              <Route path="fault-reports" element={<FaultReports />} />
              <Route path="fault-reports/view/:id" element={<View />} />


              <Route path="customers" element={<CustomerLayout />}>
                <Route index element={<CustomerList />} />
                <Route path="add" element={<CustomerAdd />} />
                <Route path="edit/:id" element={<CustomerEdit />} />
                <Route path="view/:id" element={<CustomerView />} />
                <Route path="address-book/:customer_id/:company_id" element={<CustomerAddressView />} />
              </Route>

              <Route path="consignments" element={<ConsignmentLayout />}>
                <Route index element={<ConsignmentList />} />
                <Route path="add/" element={<ConsignmentAdd />} />
                <Route path="edit/:id" element={<ConsignmentEdit />} />
                <Route path="view/:id" element={<ConsignmentView />} />
                <Route path="UserDetailForm/" element={<UserDetailForm />} />

                <Route path="manifest" element={<ManifestLayout />}>
                  <Route index element={<ManifestList />} />
                  <Route path="add" element={<ManifestAdd />} />
                  <Route path="edit/:id" element={<ManifestEdit />} />
                  <Route path="view/:id" element={<ManifestView />} />
                </Route>
              </Route>

              <Route path="usermanagment" element={<UserManagementLayout />}>
                <Route index element={<UserManagementList />} />
              </Route>

              <Route path="drivers" element={<DriverManagmentLayout />}>
                <Route index element={<DriverManagementList />} />
                <Route path="add" element={<DriverManagementAdd />} />
                <Route path="edit/:id" element={<DriverManagementAdd />} />
              </Route>

              <Route path="doc-manager" element={<DocManagerLayout />}>
                <Route index element={<DocManagerList />} />
                <Route path="add" element={<DocManagerAdd />} />
                <Route path="edit/:id" element={<DocManagerEdit />} />
              </Route>

              <Route path="timesheet" element={<TimeSheetList />} />
              <Route path="timesheet/add" element={<TimeSheetAdd />} />
              <Route path="timesheet/edit/:id" element={<TimeSheetAdd />} />
              <Route path="timesheet/view/:id" element={<TimeSheetView />} />
              <Route path="vehicle" element={<Vehicle />} />
              <Route path="vehicle/add-truck" element={<AddTruck />} />
              <Route path="vehicle/edit/:id" element={<AddTruck />} />
              <Route path="vehicle/checklist/:id" element={<CheckList />} />
              <Route path="vehicle/checklist/:id/checkDetail" element={<CheckListDetail />} />
              <Route path="setup" element={<Setup />} />
              <Route path="setup/add-document-type" element={<AddDocumentType />} />
              <Route path="setup/edit-document-type/:id" element={<AddDocumentType />} />
              <Route path="/transport/404" element={<TransportNotFound />} />
              <Route path="*" element={<PageError />} />

              <Route path="*" element={<TransportError />} />
              
            </Route>

          </Route> 

        {/* Customer Panel */}
          <Route exact path="customer" >
            <Route index element={<LoginCustomer />} />
            <Route path="forgot-password" element={<ForgotPassword />} />

            <Route path="/customer" element={<CustomerPortalLayout />}>

              <Route path="dashboard" element={<CustomerDashboard />} />
              <Route path="dashboard/:transId&:userId&:customerId" element={<CustomerDashboard />} />
              <Route path="profile" element={<CustomerUserProfile />} />
              <Route path="profile/:id" element={<CustomerUserProfile />} />

              <Route path="customer-address-books/:customer_id/:company_id" element={<CustomerAddressBooks />} />


              <Route path="consignments" element={<CustomerConsignmentLayout />}>
                <Route index element={<CustomerConsignmentList />} />
                <Route path="add" element={<CustomerConsignmentAdd />} />
                <Route path="edit/:id" element={<CustomerConsignmentEdit />} />
                <Route path="view/:id" element={<CustomerConsignmentView />} />                

                <Route path="manifest" element={<CustomerManifestLayout />}>
                  <Route index element={<CustomerManifestList />} />
                  <Route path="add" element={<CustomerManifestAdd />} />
                  <Route path="edit/:id" element={<CustomerManifestEdit />} />
                  <Route path="view/:id" element={<CustomerManifestView />} />
                </Route>
              </Route>

              <Route path="usermanagment" element={<CustomerUserManagementLayout />}>
                <Route index element={<CustomerUserManagementList />} />
              </Route>

              <Route path="emailloggers" element={<EmailLogger />} />

              <Route path="/customer/404" element={<CustomerNotFound />} />

              <Route path="*" element={<CustomerError />} />
            </Route>

          </Route> 

        {/* Plateform Panel */}
         <Route exact path="platform" >
            <Route index element={<LoginPlateform />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ChangePasswordMail />} />

            <Route path="/platform" element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="dashboard/:transId&:userId" element={<Dashboard />} />
              <Route path="profile" element={<PlatformUserProfile />} />
              <Route path="profile/:id" element={<PlatformUserProfile />} />


              <Route path="companies" element={<Companies />}>
                <Route index element={<CompanyList />} />
                <Route path="add" element={<AddCompany />} />
                <Route path="edit/:id" element={<EditCompany />} />
                <Route path="view" element={<ViewCompany />} />
              </Route>

              <Route path="usermanagment" element={<PlatformUserManagementLayout />}>
                <Route index element={<PlatformUserManagementList />} />
              </Route>

              <Route path="rolemanagement" element={<RolePermissionLayout />}>
                <Route index element={<RolePermissionList />} />
                <Route path="add" element={<AddRolePermission />} />
                <Route path="edit/:id/:user_type_id" element={<EditRolePermission />} />
              </Route>

              <Route path="emailloggers" element={<PlatformEmailLogger />} />

              <Route path="setup" element={<TrucksType />} />
              <Route path="setup/add-truck" element={<AddTruckType />} />
              <Route path="setup/edit-truck/:id" element={<EditTruckType />} />
              <Route path="/platform/404" element={<PlatformNotFound />} />
              <Route path="*" element={<PageError />} />


            </Route>

          </Route> 

        </Routes>
      </BrowserRouter>
    </Provider>

  );
}

export default App;
