import React, { useEffect, useRef, useState } from 'react'
import { Link} from "react-router-dom";
import './assets/css/landingPage.css';


export default function LandingPage() { 
 const[companySessionId, setcompanySessionId]=useState("44eef57e-8d41-4cae-a9fa-876983a6ea2c")
 const[userIdsessionId, setuserIdsessionId]=useState("194c390a-6ef7-58ed-bvee-00ff2902ee14")

 const[customerCompanySessionId, setCustomerCompanySessionId]=useState("a25fe2d6-13ab-4571-aff0-5fc302ce6741")
 const[customerUserIdsessionId, setCustomerUserIdsessionId]=useState("d2c90409-5d4e-4f47-8420-5e568deec0df")

 const[platformCompanySessionId, setPlatformCompanySessionId]=useState("7a67614e-823a-459b-a5cc-6e6ce33d96bg")
 const[platformUserIdsessionId, setPlatformUserIdsessionId]=useState("b4b53c56-8d6d-428d-a002-dabab166b498")

  return (
 
    <div className="container p-sm-5 p-3">
            
        <div className="page-wrapper ">
            {/* <img src="https://lttech.broadwayinfotech.net.au/html/v1/assets/img/logo.png" className="lg-logo img-fluid" alt="" /> */}
            <h2 className="mt-5 ">Leopold Transport</h2>
            <ul className="inner-link d-flex gap-4 ps-0 mt-5 flex-wrap">
                {/* <li className=""><Link className="border" to="./transport">Transporter Admin </Link></li> */}
                <li className=""><Link className="border" to={"./transport"}>Transporter Admin </Link></li>
                <li className="">
                <Link className="border" to={"./customer"}>Customer Portal</Link>
                 </li>
                <li className=""><Link className="border" to={"./platform"}>Platform Portal</Link></li>
            </ul>
        </div> 
     
    </div>

     
  )
}
