import React, { useRef, useState, useEffect } from "react";
import {useSelector } from 'react-redux';
import { InputText, Calendar, confirmDialog,Toast } from "../../PrimeReactComponents";
import { Link, useParams,useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { documentTypeValidation, initialState } from '../Validations/documentTypeValidation';
import Button from 'react-bootstrap/Button';
import { addDocumentTypeData, documentTypeByIdData, documentTypeUpdateData } from "../controllers/SetUpController"
import Loader from "../../features/loader/Loader";

function AddDocumentType() {
  const {transportUserInfo} = useSelector(state => state); 
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loader, setloader] = useState();
  const { id } = useParams();  
  const reciveLocalData= transportUserInfo.company_id;

  const [initDocumentType, setInitDocumentType] = useState(initialState)
  const pathname = window.location.pathname 

  let documentTypeArr = []
  let transportPermission = JSON.parse(localStorage.getItem('rolePermissionTransportInfo'))

  transportPermission.length > 0 && transportPermission.filter(e => e.menuData?.menu_name === 'Setup')?.map(val => {
    if (!documentTypeArr.includes(val?.menuActionData?.action_name)) {
      documentTypeArr.push(val?.menuActionData?.action_name)
    }
  })

  if (pathname.split('/')[3] === 'add-document-type') {
    if (!documentTypeArr.includes('Add')) {
      navigate('/transport/404');
    }
  }

  if (pathname.split('/')[3] === 'edit-document-type') {
    if (!documentTypeArr.includes('Edit')) {
      navigate('/transport/404');
    }
  }


  useEffect(() => {
    if (id) {
      const response = documentTypeByIdData(id);
      response.then(({ status, message, type, error, data }) => {

        if (status) {

          setInitDocumentType({
            document_type: data.data.document_type 
          }); 
          setloader(false)
        } else {
          toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
          setloader(false)
        }
      })
    }
  }, [id])

  const postData = (values, { resetForm }) => { 
     let modifiesValues = {...values, company_id:reciveLocalData} 

    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        if (id) {
          documentTypeUpdateData(modifiesValues, id).then(({ status, type, message }) => {
            if (status) {
              setloader(false)
              resetForm({ values: '' })
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
                   
              setTimeout(() => {
                navigate('/transport/setup')
              }, 3000)
               
            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            }
          })
        } else {
           
           addDocumentTypeData(modifiesValues).then(({ status, type, message }) => { 
            if (status) {
              setloader(false)
              resetForm({ values: '' })
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });

              setTimeout(() => {
                navigate('/transport/setup')
              }, 3000)

            } else {
              setloader(false)
              toast.current.show({ severity: type, summary: 'Info Message', detail: message, life: 3000, });
            }
          }) 
        }
      }
    });
  }


  return (
    <>
     <Loader show={loader} />
            <Toast ref={toast} position="top-center" />
      <div className="page-wrapper-without-aisde">
        <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
          <div className="container d-flex flex-column h-100 ">
            <Formik enableReinitialize initialValues={initDocumentType} validationSchema={documentTypeValidation} onSubmit={postData} render={({ values, setFieldValue }) => (<>
              <Form className="d-flex flex-column overflow-auto">
                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                      <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">
                       {(pathname.split('/')[3] === 'add-document-type')? 'Add':'Edit'} Document Type</h2>
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      <Link to="../setup" className="btn-gradient btn fz14 me-3"><i className="fa-light fa-times"></i> Cancel</Link>
                      <Button type='submit' variant='' className="bg-green text-white btn fz14 "><i className="fa-light fa-save"></i> Save</Button>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">

                  <div className="card p-4">
                    <div className="card-body">
                      <div className="row gx-5">
                        <div className="col-sm-6 offset-3">
                          <InputText className="form-control px-3" name="company_code" hidden />

                          <label htmlFor="" className="mb-2">Document Type <span className="text-danger">*</span></label>
                          <Field name="document_type" render={({ field }) => <InputText className="form-control px-3 "   {...field} />} />
                          <ErrorMessage name="document_type" component="span" className="from-error-span" />
                        </div>
                      </div> 
                    </div>
                  </div>

                </div>
              </Form>
            </>)} />
          </div>

        </div>

      </div>
    </>
  )
}

export default AddDocumentType