import * as yup from 'yup';

const addressBookInitialState = {
    address: [
        {
            customer_company_name: '',
            address: '',
            suburb: '',
            zip_code: '',
            country_id: '',
            state_id: ''
        }
    ]
}

const addressBookValidateSchema = yup.object().shape({
    address: yup.array().of(
        yup.object().shape({
            customer_company_name: yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
            address: yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
            suburb: yup.string().min(2, 'This Field must be at least 2 characters').required('This Field is required'),
            zip_code: yup.string().min(4, 'This Field must be at least 4 characters').max(4, 'This Field must be maxium 4 characters').required('This Field is required'),
            country_id: yup.number().required('This Field is required'),
            state_id: yup.number().required('This Field is required')
        })
    )
})

export { addressBookValidateSchema, addressBookInitialState }