import React , {useRef, useState}  from "react";
import { Link } from "react-router-dom";
import { InputText, Dropdown as Select, confirmDialog  , Toast} from "../PrimeReactComponents";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
//import {companyInitialState, companyValidateSchema} from './Validations/companyValidation';
//import {addData} from '../controllers/CompanyController';
//import states from '../json_files/states.json';
//import Loader from "../../features/loader/Loader";
//import {companyThunk} from "../../redux/company/companyAddSlice";
import { useEffect } from "react";
function UserDetailForm(props) {
  const dispatch = useDispatch();
  const toast = useRef(null); 
  
  const [loader, setloader] = useState();
  
useEffect(()=>{
 
},[])
//   const postData = (values, {resetForm})=> {
//     confirmDialog({
//       message: 'Are you sure you want to proceed?',
//       header: 'Confirmation',
//       icon: 'pi pi-exclamation-triangle',
//       accept: () => {
//         setloader(true)
//         addData(values).then(({status,type,message})=> {
//           if(status){
//             setloader(false)                  
//             resetForm({values:''})
//             toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});     
        
//           }else{
//             setloader(false)
//             toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});     
//           }
//         })    
//       }
//     });



//   }

  


  return (
    <>   
        {/* <Loader show={loader} />   
        <Toast ref={toast} position="top-center"/>     */}
        <Formik    render={({values})=> (
          <div className="page-wrapper-without-aisde">         
            <div className="body-content-wrap overflow-auto flex-grow-1 p-sm-3 ps-sm-4">
              <div className="container d-flex flex-column h-100 ">
              <Form className="d-flex flex-column  h-100">
                <div className="px-4 mt-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
                      <h2 className="fz24 me-md-3 text-slate-800  me-md-2 fw500 mb-0">User Detail</h2>
                    </div>
                    <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
                      <Link to="../" className="btn-gradient btn fz14 me-3">
                        <i className="fa-regular fa-times"></i> Cancel
                      </Link>
                      <button type="submit" className="bg-green text-white btn fz14 ">
                        <i className="fa-regular fa-save"></i> Save
                      </button>
                    </div>              
                  </div>
                </div>
                <div className="overflow-auto flex-grow-1 mt-5 px-4">
                  <div className="card p-4">
                    <div className="card-body">
                      <div className="row gx-5">
                        <input type="text" className="form-control px-3" hidden />                    
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2 d-block">First Name <span className="text-danger">*</span></label>
                          <Field name="companycode" render={({field})=> (
                          <>
                              <InputText   className="form-control px-3" {...field} />        
                              <ErrorMessage name="companycode" component="span" className="from-error-span" />   
                          </>           
                          )} />
                          
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Last Name<span className="text-danger">*</span></label>                    
                          <Field name="companyname" render={({field})=> (
                          <>
                              <InputText   className="form-control px-3" {...field}  />        
                              <ErrorMessage name="companyname" component="span" className="from-error-span" />   
                          </>           
                          )} />
                        </div>                   
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Phone <span className="text-danger">*</span></label>
                          <Field name="companyphone" render={({field})=> (
                          <>                         
                              <InputText {...field} minLength={9} maxLength={10} className="form-control px-3" keyfilter="int" max={10} min={10} />
                              <ErrorMessage name="companyphone"  component="span" className="from-error-span" />   
                          </>           
                          )} />
                        </div>
                        <div className="col-sm-6 mb-4">
                          <label htmlFor="" className="mb-2">Mobile <span className="text-danger">*</span></label>
                          <Field name="companymobile" render={({field})=> (
                          <>                         
                              <InputText {...field} className="form-control px-3" minLength={9} maxLength={10} keyfilter="int"  />
                              <ErrorMessage name="companymobile" component="span" className="from-error-span" />   
                          </>           
                          )} />                    
                        </div>
                                                                                                                                      
                      </div>
                    </div>
                  </div>
                </div>
                </Form>
              </div>
            </div>
          </div>
      )} />
    </>
  );
}

export default UserDetailForm;
