import {createSlice} from '@reduxjs/toolkit';


let initial = {}
if(localStorage.getItem("plateFromData") !== 'null'){
    if(localStorage.getItem("plateFromData") !== 'undefined'){
        initial = JSON.parse(localStorage.getItem("plateFromData"))
    }
}

const loginSlice = createSlice({
    name:'sidebar_toggle',
    initialState: initial,
    reducers:{       
        setLoginDetails(state, action){    
            localStorage.setItem('plateFromData',JSON.stringify(action.payload))       
            return  state = action.payload;
        }

    }
})

export  const {setLoginDetails} = loginSlice.actions;
export default loginSlice.reducer;