import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Keycloak from 'keycloak-js'
import SidebarToggleButton from "../components/SidebarToggleButton";
import { Dropdown as Select, Menu } from "../../PrimeReactComponents";
import { useSelector } from 'react-redux';
import { logoutApiUrl, customerLogouDetails } from "../../config/keyClockConfig";
import { Toast } from 'primereact/toast';
import { getTransportOnUser, getCustomerOnUserLogin } from "../controllers/UserController"

function Header() {
  const userInfoSelector = useSelector(state => state.userInfo);
  //const companyReciveId= userInfoSelector?.resultData?.company_id
  const customerRecivedata = userInfoSelector?.customerCompanyData?.customer_id
  const toast = useRef(null);
  const [reciveOption, setReciveOption] = useState();
  const [reciveOptionCustomer, setReciveOptionCustomer] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [companyReciveId, setCompanyReciveId] = useState()
  const menuRight = useRef(null);
  const navigate = useNavigate();
  const getTransport = async () => {
    const userId = await userInfoSelector?.resultData?.user_id

    if (userId !== undefined) {
      const reciveOptionTransport = await getTransportOnUser({ user_id: userId, user_type: "2" })
      setReciveOption(reciveOptionTransport.data)
    }
  }
  const getCustomer = async () => {
    const userId = userInfoSelector?.resultData?.user_id
    const companyReciveId = userInfoSelector?.resultData?.company_id

    if (userId !== undefined && companyReciveId !== undefined) {

      const reciveOptionCustomer1 = await getCustomerOnUserLogin({ user_id: userId, user_type: "2", company_id: companyReciveId })
      setReciveOptionCustomer(reciveOptionCustomer1.data)
    }
  }
  useEffect(() => {
    setCompanyReciveId(userInfoSelector?.resultData?.company_id)
    getTransport()
    getCustomer()
  }, [userInfoSelector])

  const onUserChange = async (e) => {
    const userId = userInfoSelector?.resultData?.user_id
    const tokenData = localStorage.getItem('customerData');
    const tokenDataModifyed = tokenData !== "undefined" ? JSON.parse(tokenData) : {}
    const newUpdated = { ...tokenDataModifyed, company_id: e.value }
    setCompanyReciveId(e.value)

    if (userId !== undefined &&  e.value !== undefined) {
      const reciveOptionCustomer1 = await getCustomerOnUserLogin({ user_id: userId, user_type: "2", company_id: e.value })
      setReciveOptionCustomer(reciveOptionCustomer1.data)
    }
    //localStorage.setItem('tokenData', JSON.stringify(newUpdated));
    localStorage.setItem('customerData', JSON.stringify(newUpdated));
    // localStorage.setItem('transportData', JSON.stringify(newUpdated));
    localStorage.setItem('transId', e.value);
    setSelectedUser(e.value)
    // window.location.reload();
  }

  const onCustomerChange = (e) => {
    const tokenData = localStorage.getItem('customerData');
    const tokenDataModifyed = tokenData !== "undefined" ? JSON.parse(tokenData) : {}
    // console.log("this is change log......",JSON.stringify(tokenDataModifyed));
    const newUpdated = { ...tokenDataModifyed, relation_id: e.value }
    //localStorage.setItem('tokenData', JSON.stringify(newUpdated));
    localStorage.setItem('customerData', JSON.stringify(newUpdated));
    // localStorage.setItem('transportData', JSON.stringify(newUpdated));
    localStorage.setItem('transId', e.value);
    setSelectedUser(e.value)
    window.location.reload();
  }

  const getCompanyOption = () => {
    const option = [];
    reciveOption?.map((ele) => {
      option.push({ value: ele.company_id, label: ele.company_name })
    })
    return option;
  }
  const getCustomerOption = () => {
    const option = [];
    reciveOptionCustomer?.map((ele) => {
      option.push({ value: ele.customer_id, label: ele.customer_company_name })
    })
    return option;
  }

  const SelectedItem = (data) => {
    return <li><i className="fa-light"><input type="radio"></input></i> {data.label} </li>
  }
  const logOut = async () => {
    let access_token = localStorage.getItem('keyCloakaccesstokenData');
    let refresh_token = localStorage.getItem('keyCloakrefreshData');
    let details = { ...customerLogouDetails, "refresh_token": refresh_token };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    await fetch(logoutApiUrl, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    }, () => { toast.current.show({ severity: "warn", summary: 'Info Message', detail: "Logout Successfully !!", life: 3000, }); })
    localStorage.removeItem('customerData');
    //sessionStorage.clear();
   //window.location.href = '../../customer'
    setTimeout(() => {navigate('/customer')},500) 
  }
  const items = [
    {
      label: 'User Profile',
      icon: 'fa-light fa-user',
      command: () => {
        navigate('/customer/profile');
      }
    },
    {
      label: 'Change Password',
      icon: 'fa-light fa-key',
      command: () => {
        navigate('/customer/profile/change-password');
      }
    },
    { separator: true },
    {
      label: 'Logout',
      icon: 'fa-light fa-right-from-bracket',
      command: () => {
        logOut()
      }
    },
  ];

  return (
    <>
      <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" style={{ minWidth: 170 }} />
      <Toast ref={toast} />
      <div className="top-strip">
        <div className="container-fluid">
          <div className="d-flex gap-3 align-items-center">
            <SidebarToggleButton />
            <div className="dropdown coles-supermarket">
              <button type="button" className="w-100 d-block btn shadow-none fw600 p-0">
                Welcome to {userInfoSelector?.companyData?.company_name?.charAt(0)?.toUpperCase() + userInfoSelector?.companyData?.company_name?.slice(1)} 

              </button>
            </div>

            <div className="d-flex align-items-center ms-auto gap-3">
              {reciveOption && reciveOption.length > 1 && (<>
                <label htmlFor=""  >Company</label>

                <Select
                  itemTemplate={SelectedItem}
                  options={getCompanyOption()}
                  name="user"
                  placeholder="Please select User"
                  value={companyReciveId}
                  onChange={(e) => { onUserChange(e) }}
                  className="w-80 px-3"

                />

              </>)}
              {reciveOptionCustomer && reciveOptionCustomer.length > 0 && (<>
                <label htmlFor=""  >Customer</label>

                <Select
                  //itemTemplate={SelectedItem}
                  options={getCustomerOption()}
                  name="customer"
                  placeholder="Please select User"
                  value={customerRecivedata}
                  onChange={(e) => { onCustomerChange(e) }}
                  className="w-80 px-3"

                />

              </>)}
              <ul className="header-right-links d-flex align-items-center">
                <li className="ms-4 ">

                  <button className="btn p-0" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup>
                    <span width="30" height="30" className="profile-icon rounded-circle">
                      {(userInfoSelector?.resultData?.userData?.first_name)?.charAt(0)} {(userInfoSelector?.resultData?.userData?.last_name)?.charAt(0)}
                    </span>
                    <span className="d-none d-lg-inline ms-4">
                      {userInfoSelector?.resultData?.userData?.first_name} {userInfoSelector?.resultData?.userData?.last_name}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
