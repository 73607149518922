import React,{useState,useRef} from "react";
import { Link } from "react-router-dom";
import * as yup from 'yup';
import "../../assets/css/login.css";
import {InputText} from 'primereact/inputtext'
import Button from 'react-bootstrap/Button'
import { Form, ErrorMessage, Field, Formik } from 'formik';
import {ConfirmDialog, confirmDialog,Toast} from "../../PrimeReactComponents";
//import { ConfirmDialog } from "../../PrimeReactComponents";
import Logo from '../../assets/img/logo.png'
import Logo2 from '../../assets/img/login-imge.png'
import {forgetPassword} from "../controllers/UserController"
import Loader from "../../features/loader/Loader";

const initialValue= { email:''};
const loginValidateSchema = yup.object().shape({
   email: yup.string().required('This Field is required'),
 // password: yup.string().required('This Field is required'),
})

function ForgotPassword() {
  const [loader, setloader] = useState();
  const toast = useRef(null); 
  const postData = (values, {resetForm})=> {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setloader(true)
        forgetPassword(values).then(({status,type,message})=> {
          if(status){
            setloader(false)                  
            resetForm({values:''})
            toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});     
          }else{
            setloader(false)
            toast.current.show({severity:type, summary: 'Info Message', detail: message , life: 3000,});     
          }
        })    
      }
    });



  }
  return (

      <div className="main-login-page">
         <Loader show={loader} />   
        <Toast ref={toast} />  
        <ConfirmDialog />
        <div className="loginWrap">
          <div className="login_container d-flex flex-column justify-content-center h-100">
            <header className="text-center text-md-start">
              <div className="login_container">
                <Link to="" className="logo">
                  <img src={Logo} alt="" style={{ maxWidth: "250px" }} />
                </Link>
              </div>
            </header>
            <div className="login-Announcement">
              <div className="login-content box-remove-mobile">                
                <h1 className="text-center text-lg-start">Forgot Your Password?</h1>               
          <Formik initialValues={initialValue} validationSchema={loginValidateSchema} onSubmit={postData} render= { (values)=>(
                    <Form>
                      <div className="form-group">
                        <label htmlFor="">Email Address<span className="text-danger">*</span></label>
                        <Field name="email" render={({field})=> (
                          <>
                            <InputText className="form-control" {...field}  />
                            <ErrorMessage name="email" component="span"  className="from-error-span" />
                          </>
                        )}  />
                                              
                      </div>                                           
                      <Button variant="" type="submit" className="submitBTN d-block text-center text-white fz16">Submit</Button>         
                      <div className="or_sign_with"><Link to="../">Back to Login</Link></div>       
                      <div style={{background:"#f8f8f8", padding:"20px", fontSize: "14px", textAlign:" center",borderRadius:" 18px", fontWeight: "500"}}>Enter your username or email address and we will send you instruction on how to create a new password.</div>
                    </Form>
                  )}
                />                
              </div>

              <div className="content-wrap">
                <h2 className="text-color-green main-heading">Why LTTech Transport</h2>              
                <h3 className="sub-heading">LTTech Transport is the biggest refrigerated transport company in Geelong, Victoria.</h3>
                <p>Established in 1976, our family-owned business has a proud tradition of going the extra mile to help our customers with their logistics and transport needs.</p>
                <p>With a history in the transport industry spanning nearly 40 years, we are trusted by long-term clients who know we will get the job done on time. </p>
                <figure>
                  <img src={Logo2} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <footer className="login_footer py-3 border-top">
          <div className="container-fluid d-md-flex justify-content-between text-center text-md-start">
            <ul className="mb-0">
              <li>
                <Link to="/">Terms of Service</Link>
              </li>
              <li>
                <Link to="/">Contact</Link>
              </li>
              <li>
                <Link to="/">Support</Link>
              </li>
            </ul>
            <p className="mb-0">Copyright © 2022 LTTech TRANSPORT PTY LTD. All rights reserved</p>
          </div>
        </footer>
      </div>
 
  )}

export default ForgotPassword;
